import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import { Line } from 'react-chartjs-2';

import {
  overallDateFormat,
  defaultDateFormat,
  defaultDateTimeValueFormat,
  defaultDateValueFormat
} from "@constants";
import { setActionElements, setSpecialBreadcrumb } from "@common";
import { getContextChannel } from "@resources/actions/app";
import { navigator } from "@common/navigation";
import { store } from "@resources/store";
import { readStorageItem, writeStorageItem } from "@common/storage";
import ChannelPkContext from "@containers/MasterPage/channelPkContext";
import { T } from '@utils/languageProvider';
import { dateValueFormatter, convertTimeToLocal } from "@utils";

import LayoutWrapper from "@components/utility/layoutWrapper/index";
import Box from "@components/utility/box/index";
import Block from "@components/Block";
import Span from "@components/Span";
import DateRangePicker from "@components/DateRangePicker";
import Select, { SelectOption as Option } from "@components/UIElements/Select";
import Icon from '@components/Intrinsic'

import {
  getChannelInfo,
  getChannelOverallInfo,
  getChannelTopCities,
  getChannelTopCategories,
  getChannelTopProducts,
  getChannelChartInfo
} from "@resources/actions/channel";

import basicStyle from "@common/theme/basicStyle";
import "./Dashboard.scss";

const DataNotFound = () => {
  return <Block className="dataWarning">
    <Icon className="icon-warning_triangle"/>
    <Span>
      {T("data.not.found")}
    </Span>
  </Block>;
}

class Dashboard extends Component {

  state = {
      loading : true,
      // Default filter values while locaStorage is empty
      filters : {
        cardStartDate: null,
        cardEndDate: null,
        chartEndDate: null,
        topFiveCategoryValue: 1,
        topFiveCityValue: 1,
        topFiveProductValue: 1
      }
  }

  filters  = JSON.parse(readStorageItem('dashboardFilters')) || {}

  static contextType = ChannelPkContext;

  componentDidMount() {
    setActionElements();
    setSpecialBreadcrumb({},"dashboard-breadcrumb");
    this.callServices()
  }

  componentWillUnmount() {
    setSpecialBreadcrumb(undefined,"");
    setActionElements();
  }

  componentDidUpdate(){
    const filterItems = JSON.stringify(this.state.filters)
    writeStorageItem('dashboardFilters', filterItems)
  }
  
  onChannelChange = value => {
    const selectedChannel = this.props.channels.find(channel => channel.pk === value)
    writeStorageItem('contextChannel',selectedChannel.pk);
    writeStorageItem('channelType',selectedChannel.channel_type);
    navigator.channelPk = selectedChannel.pk
    store.dispatch(getContextChannel());
    this.callServices();
  };

  generateDateParams = (start, end) => {
    return {
      start_date:
      dateValueFormatter(start, defaultDateFormat, defaultDateValueFormat) +
        "T00:00:00+0300",
      end_date:
      dateValueFormatter(end, defaultDateFormat, defaultDateValueFormat) +
        "T23:59:59+0300"
    };
  };

  onDateRangeChange = ({ start, end }) => {
    const dateParams = this.generateDateParams(start, end);

    this.cardServices(dateParams);
    this.chartServices(dateParams);
    this.getTopFiveProducts(dateParams);
    this.getTopFiveCategories(dateParams);
    this.getTopFiveCities(dateParams);
  };

  getTopFiveProducts = (params) =>{
    const contextChannelPK = this.context
    const { getChannelTopProducts } = this.props
    this.filters = { ...this.filters, topFiveProductValue : params};
    this.setState({filters: this.filters},() => getChannelTopProducts({ ...params, channel: contextChannelPK}))
  }

  getTopFiveCategories = (params) =>{
    const contextChannelPK = this.context
    const { getChannelTopCategories } = this.props
    this.filters = { ...this.filters, topFiveCategoryValue : params}
    this.setState({filters: this.filters},() => getChannelTopCategories({ ...params, channel: contextChannelPK}))
  }

  getTopFiveCities = (params) =>{
    const contextChannelPK = this.context
    const { getChannelTopCities } = this.props
    this.filters = { ...this.filters, topFiveCityValue : params};
    this.setState({filters : this.filters},() => getChannelTopCities({ ...params, channel: contextChannelPK}));
  }

  cardServices=(params = {}) => {
    const contextChannelPK = this.context
    const { startDate , endDate } = this.props
    let dateParams = this.generateDateParams(startDate,endDate) 
    params = {
      ...dateParams,
      channel: contextChannelPK,
      ...params
    };
    const { start_date, end_date } = params
    this.filters = { 
      ...this.filters,
      cardStartDate : start_date,
      cardEndDate : end_date
    }
    this.setState({filters : this.filters})

    this.props.getChannelInfo(params)
    this.props.getChannelOverallInfo(params)
  }

  chartServices=(params = {})=>{
    const contextChannelPK = this.context
    params = {
      channel: contextChannelPK,
      ...params
    };
    const { start_date, end_date } = params;
    this.filters = {
      ...this.filters,
      chartStartDate : start_date,
      chartEndDate : end_date
    }
    this.setState({filters : this.filters})
    params.start_date !== "Invalid dateT00:00:00Z" && this.props.getChannelChartInfo(params);
  } 

  callServices = () => {
    const contextChannelPK = this.context;
    const {
      getChannelOverallInfo,
      getChannelInfo,
      getChannelTopCategories,
      getChannelTopCities,
      getChannelTopProducts,
      getChannelChartInfo
    } = this.props;

    this.setState(({prevState}) => ({filters : this.filters || prevState.filters}), () => {
    const {
        cardStartDate,
        cardEndDate
       } = this.state.filters;

       const cardDateParams = this.generateDateParams( moment(cardStartDate), moment(cardEndDate));

       contextChannelPK && Promise.all([
         getChannelOverallInfo({...cardDateParams, channel: contextChannelPK}),
         getChannelInfo({...cardDateParams,channel: contextChannelPK}),
         getChannelChartInfo({...cardDateParams,channel: contextChannelPK}),
         getChannelTopCategories({...cardDateParams, channel: contextChannelPK,}),
         getChannelTopCities({...cardDateParams, channel: contextChannelPK,}),
         getChannelTopProducts({...cardDateParams, channel: contextChannelPK,})
       ]).then(() => this.setState({ loading: false }))
    })
  };

  createChart() {
    const { chart } = this.props;
    const labels = chart.filter(Boolean).map(i => i.time);
    const values = chart.filter(Boolean).map(i => i.count);
    const data = {
        labels,
        datasets: [{
          backgroundColor: "rgba(98, 109, 144, 0.3)",
          borderColor: "#037cf9",
          borderWidth: 4,
          hoverBackgroundColor: "rgba(98, 109, 144, 1)",
          hoverBorderColor: "rgba(98, 109, 144, 1)",
          strokeColor: "#fff",
          lineTension: .1,
          data: values,
        }]
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }]
      },
      tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
          label: tooltipItem => T("total.order.count.with.no").format(tooltipItem.yLabel)
        }
      }
    };

    return chart.length > 0
      ? <Line data={data} options={options}/>
      : <DataNotFound/>;
  }

  render() {
    const { 
      cardStartDate,
      cardEndDate
     } = this.state.filters;
    const { rowStyle } = basicStyle;
    // TODO : cardEndDate + T23:59:59Z" formatında geldiği için datePicker' bir üst yuvarlıyor ondan dolayı split edildi TZ'siz gönderiliyor component'e
    const splittedCardEndDate = cardEndDate && cardEndDate.split('T')[0]
    const {
      overall_data,
      top_products,
      top_cities,
      top_categories,
      channels,
      channel,
      startDate,
      endDate
    } = this.props;

    const responsiveChart = this.createChart();

    const channelOptions = channels.map(channel => (
      <Option
        key={channel.pk}
        value={channel.pk}
      >
        {channel.name}
      </Option>
    ));

    const currencyType = {
      TL: "₺",
      EUR: "€",
      USD: "$"
    }

    const moneyFormater = new Intl.NumberFormat({style: 'currency'});
    const localUpdatedAt = convertTimeToLocal(overall_data.updated_at, overallDateFormat, defaultDateTimeValueFormat);

    return (
      <LayoutWrapper className="dashboard" key={this.context} loading = { this.state.loading }>
        <Block className="sale-channel">
          <Block className="left-side">
            <Span className="sale-channel-title"> {T("sale.channel")} </Span>
            <Span className="sale-channel-updated-at"> {T("last.update")}: {localUpdatedAt} </Span>
          </Block>
          <Block className="right-side">
            <Select
              placeholder={T('change.channel')}
              onChange={this.onChannelChange}
              value={Number(this.context)}
              suffixIcon={<Icon className='icon-triangle'/>}
            >{channelOptions}</Select>
          </Block>
        </Block>
        <Block className="box-container">
            <Block className="channel-card-box-wrapper">
              <Block className="channel-card-head">
                <DateRangePicker startDate = {moment(cardStartDate || startDate)} 
                endDate = {moment(splittedCardEndDate || endDate)} onChange={this.onDateRangeChange}/>        
              </Block>
                <Block className="card-box--container">
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("total.product.count").upper()} </Block>
                        <Block className="card-box--content"> {channel.total_product_count} </Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("total.product.stocks").upper()} </Block>
                        <Block className="card-box--content"> {channel.total_product_stocks} </Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("turnover")} </Block>
                        <Block className="card-box--content success"> {currencyType[overall_data.currency_type]}{moneyFormater.format(overall_data.turnover)}</Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("total.order.item.quantity").upper()} </Block>
                        <Block className="card-box--content"> {overall_data.total_orderitem_quantity} </Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("avarage.sale.price").upper()} </Block>
                        <Block className="card-box--content"> {Number(overall_data.average_sale_price).toFixed(0)} </Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("order.quantity").upper()} </Block>
                        <Block className="card-box--content"> {overall_data.total_order_quantity} </Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("total.discount").upper()} </Block>
                        <Block className="card-box--content fail">{currencyType[overall_data.currency_type]}{moneyFormater.format(overall_data.total_discount)} </Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("avarage.basket.price").upper()} </Block>
                        <Block className="card-box--content">{currencyType[overall_data.currency_type]}{moneyFormater.format(overall_data.average_basket_price)} </Block>
                    </Block>
                    <Block className="card-box--item">
                        <Block className="card-box--header"> {T("total.shipping.amount").upper()} </Block>
                        <Block className="card-box--content">{currencyType[overall_data.currency_type]}{moneyFormater.format(overall_data.total_shipping_amount)}</Block>
                    </Block>
                </Block>
            </Block>

            <Block className="box-container--chart">
              <Block className="chart-head">
                <Span className="chart-name"> {T("dashboard.orderPerHour")} </Span>
              </Block>
                <Box className="chart-wrapper">{responsiveChart}</Box>
            </Block>

        </Block>
        <Block className="box-container" style={rowStyle}>
          
          <Block className="box-container--item">

            <Block className="card-head--title">
              <Span className="card-name product">{T('top.five.product')}</Span>
            </Block>
            
            <Box className="box-item">
              <Block className="card-head">
                <Block className="card-head--desc"> {T("product").upper()} </Block>
                <Block className="card-head--value" > {T("total.sales").upper()} </Block>
              </Block>
              { top_products.length > 0 ? top_products.slice(0, 5).map((item, index) => {
                return(
                  <Block className="card-item" key={index}>
                    <Block className="card--title"> {item.name} </Block>
                    <Block className="card--value" > {item.product_count} </Block>
                  </Block>
                )
              })
              :   
                <Block className="dataWarning">
                  <Icon className="icon-warning_triangle"/> 
                  <Span> 
                      {T("data.not.found")}
                  </Span>
                </Block>
              
            }
            </Box>
          </Block>
        
          <Block className="box-container--item">

            <Block className="card-head--title">
              <Span className="card-name category">{T('top.five.category')}</Span>
            </Block>

            <Box className="box-item" >
              <Block className="card-head">
                <Block className="card-head--desc"> {T("category").upper()} </Block>
                <Block className="card-head--value" > {T("total.sales").upper()} </Block>
              </Block>
              {top_categories.length > 0 ? top_categories.slice(0, 5).map((item, index) => {
                return(
                  <Block className="card-item" key={index}>
                    <Block className="card--title"> {item.name} </Block>
                    <Block className="card--value" > {item.item_count} </Block>
                  </Block>
                )
              })
              :
                <Block className="dataWarning">
                  <Icon className="icon-warning_triangle"/> 
                  <Span> 
                      {T("data.not.found")}
                  </Span>
                </Block>
              }
            </Box>
          </Block>
        
          <Block className="box-container--item">

            <Block className="card-head--title">
              <Span className="card-name city">{T('top.five.city')}</Span>
            </Block>

            <Box className="box-item" >
              <Block className="card-head">
                <Block className="card-head--desc"> {T("town").upper()} </Block>
                <Block className="card-head--value" > {T("total.sales").upper()} </Block>
              </Block>
            {top_cities.length > 0 ? top_cities.slice(0, 5).map((item, index) => {
                return(
                  <Block className="card-item" key={index}>
                    <Block className="card--title"> {item.name} </Block>
                    <Block className="card--value" > {item.item_count} </Block>
                  </Block>
                )
              })
            :
              <Block className="dataWarning">
                <Icon className="icon-warning_triangle"/> 
                <Span> 
                    {T("data.not.found")}
                </Span>
              </Block>
            }
            </Box>
          </Block>
        
        </Block>
      </LayoutWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    channel: state.channelReducer.channel,
    overall_data: state.channelReducer.overall_data,
    overall_array: state.channelReducer.overall_array,
    top_cities: state.channelReducer.top_cities,
    top_products: state.channelReducer.top_products,
    top_categories: state.channelReducer.top_categories,
    chart: state.channelReducer.chart,
    channels: state.appReducer.channels,
  };
}

Dashboard.defaultProps = {
  startDate: new Date(),
  endDate: new Date()
};

export default connect(
  mapStateToProps,
  {
    getChannelInfo,
    getChannelOverallInfo,
    getChannelTopCategories,
    getChannelTopCities,
    getChannelTopProducts,
    getChannelChartInfo
  }
)(Dashboard);

