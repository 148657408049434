import {
  GET_PROMOTION_GATEWAY_SUCCESS,
  SAVE_PROMOTION_GATEWAY_SUCCESS,
  CLEAR_PROMOTION_GATEWAY_STATE
} from "../actiontypes";

const initialState = {
  promotionGateway: {}
};

export default function (state = initialState, action) {
  const result = action.payload;
  switch (action.type) {
    case GET_PROMOTION_GATEWAY_SUCCESS:
    case SAVE_PROMOTION_GATEWAY_SUCCESS:
      return {
        ...state,
        promotionGateway: result || initialState.promotionGateway
      };
    case CLEAR_PROMOTION_GATEWAY_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
