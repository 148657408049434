import {
  GET_POS_DETAIL_SUCCESS,
  GET_POS_SCHEMA_SUCCESS,
  SAVE_POS_DETAIL_SUCCESS,
  GET_ALL_POS_SUCCESS,
  CLEAR_POS_DETAIL_STATE
} from "../actiontypes";

const initialState = {
  posDetail: {},
  posSchema: {},
  allPos:{}
};

export default function (state = initialState, action) {
  const result = action.payload;
  switch (action.type) {
    case GET_POS_DETAIL_SUCCESS:
    case SAVE_POS_DETAIL_SUCCESS:
      return {
        ...state,
        posDetail: result || initialState.posDetail
      };
    case GET_POS_SCHEMA_SUCCESS:
      return {
        ...state,
        posSchema: result || initialState.posSchema
      };
    case GET_ALL_POS_SUCCESS:
      return {
        ...state,
        allPos: result || initialState.allPos
      };
    case CLEAR_POS_DETAIL_STATE:
      return initialState;
    default:
      return state;
  }
}
