import React, { PureComponent } from "react";
import { Col, Row } from "antd";

import { inputTypes } from "../DynamicForm/constants";
import { defaultDateFormat } from "@constants";
import { isDefined } from "@utils";

import Input from "../UIElements/Input";
import Datepicker from "../UIElements/DatePicker";

class MultipleInput extends PureComponent {
  state = {};
  onKeyPress = (e, index) => {
    if (e.key === "Enter") this.onChange(e.target.value, index);
  };
  onChange = (value, index) => {
    const { keys, onChange } = this.props;
    this.setState(
      {
        [keys[index]]: value
      },
      () => {
        if (onChange)
          onChange(
            !isDefined(this.state[keys[0]]) && !isDefined(this.state[keys[1]])
              ? null
              : {
                [keys[0]]: this.state[keys[0]],
                [keys[1]]: this.state[keys[1]]
              }
          );
      }
    );
  };
  createInput = index => {
    const { inputProps = {}, allowClear } = this.props;
    switch (inputProps.inputType) {
      case inputTypes.datepicker.alias:
        return (
          <Datepicker
            format={inputProps.dateFormat || defaultDateFormat}
            onChange={e => this.onChange(e, index)}
            allowClear={allowClear}
          />
        );
      default:
        return (
          <Input
            onKeyPress={e => this.onKeyPress(e, index)}
            allowClear={allowClear}
          />
        );
    }
  };
  render() {
    const { seperator } = this.props;
    return (
      <>
        <Row gutter={10}>
          <Col span={seperator ? 11 : 12}>{this.createInput(0)}</Col>
          {seperator && <Col span={2}>{seperator}</Col>}
          <Col span={seperator ? 11 : 12}>{this.createInput(1)}</Col>
        </Row>
      </>
    );
  }
}
export default MultipleInput;