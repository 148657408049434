import {
  GET_CATALOGS,
  GET_CATALOG_DETAIL,
  CLEAR_STATE_CATALOG,
  GET_CATALOG_FEED_DETAIL,
  GET_CATEGORY_TREE,
  GET_CATALOG_ALL_DETAIL,
  GET_CATEGORY_NODE,
  UPDATE_FIELD_STORE,
  GET_ATTRIBUTES,
  GENERATE_SUB_FIELD,
  SET_MAIN_FEED_FIELD,
  REMOVE_SUB_FEED_FIELD,
  UPDATE_SUB_FEED_FIELD,
  ADD_CHILD_FEED_FIELD,
  UPDATE_CHILD_FEED_FIELD,
  REMOVE_CHILD_FEED_FIELD,
  RESET_CHILD_FEED_FIELD,
  INITIALIZE_FEED_DATA,
  GET_CACHED_ATTRIBUTE
} from "../actiontypes";

export const initialState = {
  catalogs: [],
  catalogDetail: {},
  catalogFeedDetail: {},
  categoryTree: [],
  fields: [],
  contentTypes: [],
  generatedFields: [],
  selectedMainFeedField: null,
  childFields: {},
  cachedAttribute:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CATALOGS:
      const catalogResult = action.payload;
      return {
        ...state,
        catalogs: catalogResult
      };
    case GET_CATALOG_DETAIL:
      const catalogDetail = action.payload;
      return {
        ...state,
        catalogDetail: catalogDetail
      };
    case GET_CATALOG_ALL_DETAIL:
      const catalogAllDetail = action.payload;
      return {
        ...state,
        catalogAllDetail: catalogAllDetail
      };
    case GET_CATALOG_FEED_DETAIL:
      const catalogFeedDetail = action.payload;
      return {
        ...state,
        catalogFeedDetail: catalogFeedDetail
      };
    case GET_CATEGORY_TREE:
      const catalogTreeData = action.payload;
      return {
        ...state,
        categoryTree: catalogTreeData
      };
    case GET_CATEGORY_NODE:
      const categoryNode = action.payload;
      return {
        ...state,
        categoryNode: categoryNode
      };
    case CLEAR_STATE_CATALOG:
      return initialState;
    case UPDATE_FIELD_STORE:
      const fields = action.payload;

      return {
        ...state,
        fields
      };
    case GET_ATTRIBUTES:
      const attributes = action.payload;

      return {
        ...state,
        attributes
      };
    case GENERATE_SUB_FIELD:
      const generatedField = action.payload;

      return {
        ...state,
        generatedFields: [...state.generatedFields, generatedField]
      };
    case SET_MAIN_FEED_FIELD:
      const selectedMainFeedField = action.payload;

      return {
        ...state,
        selectedMainFeedField,
        generatedFields: []
      };

    case REMOVE_SUB_FEED_FIELD:
      const feedIndex = action.payload;

      return {
        ...state,
        generatedFields: [
          ...state.generatedFields.slice(0, feedIndex),
          ...state.generatedFields.slice(feedIndex + 1)
        ]
      };
    case UPDATE_SUB_FEED_FIELD:
      const [updateIndex, data] = action.payload;

      return {
        ...state,
        generatedFields: [
          ...state.generatedFields.slice(0, updateIndex),
          { ...data },
          ...state.generatedFields.slice(updateIndex + 1)
        ]
      };
    case ADD_CHILD_FEED_FIELD:
      const { parent, data: childData } = action.payload;

      return {
        ...state,
        childFields: {
          ...state.childFields,
          [parent]: Array.isArray(state.childFields[parent])
            ? [...state.childFields[parent], childData]
            : [childData]
        }
      };
    case UPDATE_CHILD_FEED_FIELD:
      const {
        parent: parentField,
        index: fieldIndex,
        data: fieldData
      } = action.payload;

      return {
        ...state,
        childFields: {
          ...state.childFields,
          [parentField]: [
            ...state.childFields[parentField].slice(0, fieldIndex),
            { ...fieldData },
            ...state.childFields[parentField].slice(fieldIndex + 1)
          ]
        }
      };
    case REMOVE_CHILD_FEED_FIELD:
      const { parent: _parentField, index: _fieldIndex } = action.payload;

      return {
        ...state,
        childFields: {
          ...state.childFields,
          [_parentField]: [
            ...state.childFields[_parentField].slice(0, _fieldIndex),
            ...state.childFields[_parentField].slice(_fieldIndex + 1)
          ]
        }
      };
    case RESET_CHILD_FEED_FIELD:
      const _parent = action.payload;

      return {
        ...state,
        childFields: {
          ...state.childFields,
          [_parent]: []
        }
      }
    case INITIALIZE_FEED_DATA:
      const { generatedFieldsData, childFieldsData, mainField } = action.payload;

      return {
        ...state,
        selectedMainFeedField: mainField,
        childFields: childFieldsData,
        generatedFields: generatedFieldsData
      }
    case GET_CACHED_ATTRIBUTE:  
      const cachedAttribute =  action.payload && action.payload.results;
      return {
        ...state,
        cachedAttribute: cachedAttribute
      };
    default:
      return state;
  }
}
