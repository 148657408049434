import React, { Component } from "react";
import { connect } from "react-redux";

import { setActionElements } from "@common";
import { navigator } from "@common/navigation";
import { usersURL} from "@constants/serviceUrls";
import { inputTypes } from "@components/DynamicForm/constants";
import {
  activePassiveStringBoolOptions,
  yesNoOptions,
  isSuperUser
} from "@constants/commontypes";
import { dateFormatter, statusRenderer, latentNameRenderer, statusActivePassiveFormatter } from "@utils";
import { T } from "@utils/languageProvider";
import { checkAuth } from "@common";
import ChannelPkContext from "@containers/MasterPage/channelPkContext";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import DatatableWithFilter from "@components/DatatableWithFilter";
import Button from "@components/Button";

class Users extends Component {

  static contextType = ChannelPkContext;

  componentDidMount() {
    checkAuth("users-create") && setActionElements([
      <Button
      type="secondary"
      onClick={() => navigator.push({
        pathname:"/settings/users/form/"
      })}
      icon="icon-arti">
        {T("add.new.user")}
    </Button>
    ]);
  }

  componentWillUnmount(){
    setActionElements([]);
  }

  filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: "username",
      name: T("user.name"),
      pk: "s0",
      active: true,
      fieldProps : {
       placeholder : T('user.name')
      }
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: "first_name",
      name: T("first.name"),
      pk: "s1",
      active: true,
      fieldProps : {
       placeholder : T('first.name')
      }
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: "last_name",
      name: T("last.name"),
      pk: "s2",
      active: true,
      fieldProps : {
       placeholder : T('last.name')
      }
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: "email",
      name: T("email"),
      pk: "s3",
      active: true,
      fieldProps : {
       placeholder : T('email')
      }
    },
    {
      data_type: { value: inputTypes.select.alias },
      key: "is_admin",
      name: T("admin"),
      pk: "s4",
      active: true,
      visible_values: yesNoOptions,
      fieldProps : {
       placeholder : T('admin')
      }
    },
    {
      data_type: { value: inputTypes.select.alias },
      key: "is_staff",
      name: T("staff"),
      pk: "s5",
      active: true,
      visible_values: yesNoOptions,
      fieldProps : {
       placeholder : T('staff')
      }
    },
    {
      data_type: { value: inputTypes.select.alias },
      key: "is_active",
      name: T("status"),
      pk: "s6",
      active: true,
      visible_values : activePassiveStringBoolOptions,
      fieldProps : {
       placeholder : T('status')
      }
    }
  ];

  columns = [
    {
      key: 1,
      dataIndex: "pk",
      title: T("user.no")
    },
    {
      key: 2,
      dataIndex: "is_superuser",
      title: T("is.superuser"),
      render : (cellData) => statusRenderer(isSuperUser,cellData)
    },
    {
      key: 3,
      dataIndex: "is_active",
      title: T("status"),
      render: statusActivePassiveFormatter
    },
    {
      key: 4,
      dataIndex: "username",
      title: T("user.name"),
      render: cellData => latentNameRenderer(cellData)
    },
    {
      key: 5,
      dataIndex: "first_name",
      title: T("first.name")
    },
    {
      key: 6,
      dataIndex: "last_name",
      title: T("last.name")
    },
    {
      key: 7,
      dataIndex: "email",
      title: T("email"),
      render: cellData => latentNameRenderer(cellData)
    },
    {
      key: 8,
      dataIndex: "userprofile.phone_number",
      title: T("phone")
    },
    {
      key: 9,
      dataIndex: "date_joined",
      title: T("joined.date"),
      render: cellData => dateFormatter(cellData)
    },
    {
      key: 10,
      dataIndex: "last_login",
      title: T("last.login"),
      render: cellData => dateFormatter(cellData)
    }
  ];

  onRowClick = (row) => {
    navigator.push(`/settings/users/form/${row.pk}`)
  };

  render() {
    const channel = this.context;

    return (
      <LayoutContentWrapper>
        {channel && (
          <>
            <DatatableWithFilter
              filterProps={{
                filterInputs: this.filterInputs,
                filterViaFile: true
              }}
              datatableProps={{
                rowKey: "pk",
                url: usersURL,
                exportable: false,
                subtitle : T('sidebar.users'),
                columns: this.columns,
                onRowClick: this.onRowClick,
                locale: { emptyText: T("could.not.find.user.info") },
                isAuthorized:true,
                authActionName:"users-update",
                paginationText: T("show.variable").format(T("user"))
              }}
            />
          </>
        )}
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Users);
