import { getCurrentLanguage } from '@components/LanguageSwitcher/config';
import { getRemoteLanguageURL } from '@constants/serviceUrls';
import {sendRequest} from "@common/network";

import {
  CLEAR_REMOTE_LANGUAGE,
  GET_REMOTE_LANGUAGE
} from "../actiontypes"

const actions = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  ACTIVATE_LANG_MODAL: 'ACTIVATE_LANG_MODAL',
  switchActivation: () => ({
    type: actions.ACTIVATE_LANG_MODAL
  }),
  changeLanguage: language => {
    return {
      type: actions.CHANGE_LANGUAGE,
      language: getCurrentLanguage(language)
    };
  }
};
export default actions;

export function getChannelLanguage(channel) {
  return async () =>{
    return sendRequest({
      url: getRemoteLanguageURL.format(channel),
      onSuccess:(result) =>{
        return {
          type : GET_REMOTE_LANGUAGE,
          payload : result
        }
      }
    });
  }
}

export function clearRemoteLangState() {
  return async dispatch => {
    dispatch({
      type: CLEAR_REMOTE_LANGUAGE
    });
  };
}
