import {
  FETCH_NAVIGATION_DATA,
  FETCH_NAVIGATION_SCHEMA,
  CHANGE_NAVIGATION_ACTION,
  DELETE_NAVIGATION_DATA,
  WRITE_NAVIGATION_DATA,
  UPDATE_NAVIGATION_SCHEMA,
  SAVE_NAVIGATION_NODE,
  SAVE_NAVIGATION_SCHEMA,
  MANAGE_NAVIGATION_SCHEMA_VISIBILITY
} from "../actiontypes";

const initialState = {
  navigationData: [],
  navigationRootData: {},
  navigationSchema: {},
  navigationAction: {
    actionType: "none",
    data: {}
  },
  navigationSchemaVisible: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_NAVIGATION_DATA:
      let {result, navigationResult} = action.payload;
      return {
        ...state,
        navigationData: navigationResult,
        navigationRootData: result
      }
    case SAVE_NAVIGATION_SCHEMA:
      const updatedSchema = action.payload;
      return {
        ...state,
        navigationSchema: JSON.stringify(updatedSchema.schema, null, 2)
      }
    case MANAGE_NAVIGATION_SCHEMA_VISIBILITY:
      const navigationSchemaVisible = action.payload;
      return {
        ...state,
        navigationSchemaVisible 
      }

    case FETCH_NAVIGATION_SCHEMA:
      const navigationSchema = action.payload;
      return {
        ...state,
        navigationSchema: JSON.stringify(navigationSchema.schema, null, 2)
      }

    case UPDATE_NAVIGATION_SCHEMA:
      const schema = action.payload;
      return {
        ...state,
        navigationSchema: schema
      }

    case CHANGE_NAVIGATION_ACTION:
      const { actionType, data = {} } = action.payload;
      return {
        ...state,
        navigationAction: { actionType, data }
      }

    case DELETE_NAVIGATION_DATA:
      return { ...state }

    case SAVE_NAVIGATION_NODE:
      return {
        ...state,
        navigationAction: {
          actionType: "none",
          data: {}
        }
      }

    case WRITE_NAVIGATION_DATA:
      const navigationData = action.payload;
      return {
        ...state,
        navigationData
      }

    default:
      return state;
  }

}