import React from "react"
import { connect } from "react-redux";

import { inputRenderer, fileBase64} from "@utils";
import { formLayoutTypes, validationTypes } from "@constants/commontypes";
import { inputTypes } from "@components/DynamicForm/constants";
import { T } from "@utils/languageProvider/index.js";
import { navigator } from "@common/navigation";
import { logResponseMessages } from "@common";
import { saveSortingAlgorithm, changeSortingType } from "@resources/actions/sortingAlgorithm";

import { DynamicFormWithBox } from "@components/DynamicForm";

import {
  sortingTypes,
  visibilityTypes,
  requestTypes,
  sortingOptions,
  elasticSearchModes,
  staticProperties,
  propertyPrefix
} from "./constants";

const DynamicForm = DynamicFormWithBox("sortin-algorithm-form");

class GenericForm extends React.Component {
  constructor(props) {
    super(props);

    const { formData } = props;
    const initialState = {
      sortingType: formData.sorting_type,
      formValues: {},
      streamFile:[]
    }

    if (formData.hasOwnProperty("config__client")) {
      initialState["requestType"] = formData["config__client"];
    }

    this.state = initialState;
  }

  getFormInputs = () => {
    const { sortingType, requestType, formValues } = this.state;
    const { formData: { pk: id, ...initialValues } } = this.props;

    const inputs = [
      {
        pk: 0,
        name: T("sorting.label"),
        key: "name",
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T("sorting.label"))]
        },
      },
      {
        pk: 1,
        name: T("sorting.type"),
        key: "sorting_type",
        data_type: { value: inputTypes.select.alias },
        fieldProps: {
          disabled: !!id,
          options: sortingTypes.map((type) => {
            return {
              pk: type.value,
              value: type.value,
              label: type.label
            }
          })
        },
        fieldOptions: {
          rules: [validationTypes.required(T("sorting.type"))],
        },
      },
      {
        pk: 2,
        name: T("visibility"),
        key: "is_visible",
        data_type: { value: inputTypes.select.alias },
        fieldProps: {
          options: visibilityTypes
        },
        fieldOptions: {
          rules: [validationTypes.required(T("visibility"))]
        }
      },
      {
        pk: 3,
        name: T("catalog"),
        key: "catalog",
        data_type: { value: inputTypes.select.alias },
        fieldProps: {
          options: this.props.catalogs.map((catalog) => {
            return {
              pk: catalog.pk,
              value: catalog.pk.toString(),
              label: catalog.name
            }
          })
        },
        fieldOptions: {
          rules: [validationTypes.required(T("catalog"))]
        },
      },
      ...sortingType === "external" ? [
        {
          pk: 4,
          name: T("base.url"),
          key: "config__base_url",
          data_type: { value: inputTypes.input.alias },
          fieldOptions: {
            rules: [validationTypes.required(T("base.url"))]
          },
        },
        {
          pk: 5,
          name: T("client"),
          key: "config__client",
          data_type: { value: inputTypes.select.alias },
          fieldProps: {
            options: requestTypes.map((type) => {
              return {
                pk: type.value,
                value: type.value,
                label: type.label
              }
            })
          },
          fieldOptions: {
            // disable chrome autofill
            rules: [validationTypes.required(T("client"))]
          },
        },
        {
          pk: 6,
          name: T("user"),
          key: "config__username",
          data_type: { value: inputTypes.input.alias },
          fieldOptions: {
            rules: [validationTypes.required(T("user"))],
          },
          fieldProps: {
            // disable chrome autofill
            autoComplete: "new-password"
          }
        },
        {
          pk: 7,
          name: T("password"),
          key: "config__password",
          data_type: { value: inputTypes.password.alias },
          fieldOptions: {
            rules: [validationTypes.required(T("password"))]
          },
          fieldProps: {
            // disable chrome autofill
            autoComplete: "new-password"
          }
        },
        ...requestType === "metabase" ? [{
          pk: 8,
          name: T("question.id"),
          key: "config__card_id",
          data_type: { value: inputTypes.input.alias },
          fieldOptions: {
            rules: [validationTypes.required(T("question.id"))]
          },
        },] : [
            {
              pk: 9,
              name: T("pick.file"),
              key: "stream_file",
              data_type: { value: inputTypes.file.alias },
              fieldProps: {
                accept:".csv,xls,xlsx",
                fileList:this.state.streamFile,
                beforeUpload:this.handleChangeFile
              },

              // fieldOptions: {
              //   rules: [validationTypes.required(T("channel.select"))]
              // },
            },
            {
              pk: 10,
              name: T("file.format"),
              key: "config__file_format",
              data_type: { value: inputTypes.input.alias },
              fieldOptions: {
                rules: [validationTypes.required(T("file.format"))]
              },
            },
            {
              pk: 11,
              name: T("delimiter"),
              key: "config__delimiter",
              data_type: { value: inputTypes.input.alias },
              fieldOptions: {
                rules: [validationTypes.required(T("delimiter"))]
              },
            },
            {
              pk: 12,
              name: T("http.method"),
              key: "config__http_method",
              data_type: { value: inputTypes.input.alias },
              fieldOptions: {
                rules: [validationTypes.required(T("http.method"))]
              },
            }
          ],
        {
          pk: 13,
          name: T("sort.order"),
          key: "ruleset__order",
          data_type: { value: inputTypes.select.alias },
          fieldProps: {
            options: sortingOptions.map((type) => {
              return {
                pk: type.value,
                value: type.value,
                label: type.label
              }
            })
          },
          fieldOptions: {
            rules: [validationTypes.required(T("sort.order"))]
          },
        },
        {
          pk: 14,
          name: T("elastic.search.mode"),
          key: "ruleset__mode",
          data_type: { value: inputTypes.select.alias },
          fieldProps: {
            options: elasticSearchModes.map((type) => {
              return {
                pk: type.value,
                value: type.value,
                label: type.label
              }
            })
          },
          fieldOptions: {
            rules: [validationTypes.required(T("elastic.search.mode"))]
          },
        }
      ] : [],
    ];


    return inputRenderer(inputs, id, { ...initialValues, ...formValues });
  }


  normalizeExternalAlgorithm = (values) => {
    const result = {};
    const config = {};

    const normalizeConfigVal = (key, value) => {
      const [, keyName] = key.split("__");
      config[keyName] = value;
    }

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (key.includes("__")) {
          normalizeConfigVal(key, values[key]);
        } else if (key === "stream_file" && this.state.streamFile) {
          result[key] = this.state.streamFile.thumbUrl;
        }
        else {
          result[key] = values[key];
        }
      }
    }

    result["config"] = config;

    return result;

  }

  normalizeDynamicAlgorithmRuleset = () => {
    const { dynamicFormItems } = this.props;
    const pathFormat = "r0000";

    const resultList = dynamicFormItems.map((item, index) => {
      const rule_type = sortingOptions.some(option => option.value === item.sorting) ? "sorter" : "liquer";
      const isStaticProperty = staticProperties.some(property => property === item.property);
      const field = isStaticProperty ? item.property : `${propertyPrefix}${item.property}`;
      const path = pathFormat.slice(0, pathFormat.length - index.toString().length) + index.toString();

      let lookupProps = {};
      let orderProps = {};

      if (rule_type === "liquer") {
        lookupProps = {
          lookup: item.sorting,
          value: item.attribute
        };
      } else {
        orderProps = {
          order: item.sorting
        }
      }

      return {
        rule_type,
        exp: [{
          field,
          ...lookupProps
        }],
        path,
        ...orderProps,
      }
    });

    return resultList;
  }

  onSubmit = async (values) => {
    let normalizedValues;
    const { dynamicFormItems, formData, saveSortingAlgorithm } = this.props;

    if (values["sorting_type"] === "external") {
      normalizedValues = this.normalizeExternalAlgorithm(values);
    } else {
      if (!dynamicFormItems.length) {
        logResponseMessages({ type: "error", message: T("sorting.algorithms.required.text") });
        return;
      }
      normalizedValues = {
        ...values,
        ruleset: this.normalizeDynamicAlgorithmRuleset()
      }
    }

    saveSortingAlgorithm(normalizedValues, formData.pk)
    .then(() => navigator.push('/settings/sorting-algorithm/'))
  }

  handleFormChange = (values) => {
    const {
      sorting_type: sortingType,
      config__client: requestType,
    } = values;

    this.setState(() => {
      return {
        sortingType,
        requestType,
        formValues: values
      }
    }, () => {
      this.props.changeSortingType(sortingType);
    });
  }

  handleChangeFile = file => {
    fileBase64(file).then((result)=>{
      this.setState({streamFile:[result]})
    })
    return false;
  };

  render() {
    // TODO: make title dynamic for add and edit
    return <DynamicForm
            title={T("sorting.algoritm.add.or.edit")}
            subtitle={ T('sorting.algorithm.crud.subtitle')}
            inputs={this.getFormInputs()}
            onSubmit={this.onSubmit}
            onChange={this.handleFormChange}
            layoutType={formLayoutTypes.boxLayout}
          />
  }
}

function mapStateToProps(state) {
  const { formData, catalogs, dynamicFormItems } = state.sortingAlgorithmsReducer;

  return { formData, catalogs, dynamicFormItems }
}

export default connect(mapStateToProps, { saveSortingAlgorithm, changeSortingType })(GenericForm);