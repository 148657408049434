import { createBrowserHistory } from 'history';
import { readStorageItem, writeStorageItem } from '@common/storage';
import {
  extractChannelPkFromPathname,
  extractLanguageFromPathname,
} from '@utils/';
import { getAPILanguage } from '@common';
import { platformLanguage, contextChannel } from '@constants/';

export const history = createBrowserHistory();

class Navigator {
  goBack() {
    if (!history) return;
    history.goBack();
  }

  goForward() {
    if (!history) return;
    history.goForward();
  }

  push(newRoute, withChannel = false) {
    if (!history) return;
    const platformLang = `/${this.lastPlatformLang}`;
    const channelLang = `/${this.lastChannelLang}`;
    const languagePath = `${platformLang || ''}${channelLang || ''}`;

    // if (typeof newRoute === 'string' || newRoute instanceof String) {
    //   const pathname = withChannel
    //     ? `${languagePath}/channel/${this.lastChannel || ''}${newRoute}`
    //     : `${languagePath}${newRoute}`;

    //   newRoute = { pathname };
    // } else {
    //   const pathname = withChannel
    //     ? `${languagePath}/channel/${this.lastChannel || ''}${
    //         newRoute.pathname
    //       }`
    //     : `${languagePath}${newRoute.pathname}`;

    //   newRoute = { ...newRoute, pathname };
    // }
    history.push(newRoute);
  }

  // lastChannel = null;
  // lastPlatformLang = null;
  // lastChannelLang = null;

  // set platformLanguage(lang) {
  //   const platformLangFromStorage = readStorageItem(platformLanguage);
  //   const currentPlatformLanguage = lang || platformLangFromStorage;
  //   this.lastPlatformLang = currentPlatformLanguage;
  //   const historyPathname = history.location.pathname;
  //   let { pathWithoutLanguage } = extractLanguageFromPathname(historyPathname);

  //   if (currentPlatformLanguage) {
  //     this.push(pathWithoutLanguage);
  //   }
  // }

  // set channelLanguage(lang) {
  //   const currentChannelLanguage = lang || getAPILanguage() || 'tr-tr';
  //   this.lastChannelLang = currentChannelLanguage;
  //   const historyPathname = history.location.pathname;
  //   let { pathWithoutLanguage } = extractLanguageFromPathname(historyPathname);
  //   if (currentChannelLanguage) {
  //     this.push(pathWithoutLanguage);
  //   }
  // }

  // set channelPk(pk) {
  //   const channelFromStorage = readStorageItem(contextChannel);
  //   const currentPk = Number(pk) || channelFromStorage;
  //   this.lastChannel = currentPk;
  //   const historyPathname = history.location.pathname;
  //   let { lang, pathWithoutLanguage } = extractLanguageFromPathname(
  //     historyPathname
  //   );
  //   let { pathWithoutChannel } = extractChannelPkFromPathname(
  //     pathWithoutLanguage
  //   );

  //   if (historyPathname.split('/').includes('channel')) {
  //     pathWithoutChannel = `/channel/${currentPk}${pathWithoutChannel}`;
  //   }

  //   if (currentPk) {
  //     writeStorageItem(contextChannel, currentPk);
  //     this.push(pathWithoutChannel);
  //   }
  // }
}

export const navigator = new Navigator();
export const location = history.location;
