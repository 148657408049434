import React from "react";
import classnames from "classnames";

import "./style.scss";

const AntSwitch = Component => props => {
  const className = classnames(props.className, "antSwitch");
  return <Component {...props} className={className} />;
};

export default AntSwitch;
