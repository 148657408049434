import config, {
  getCurrentLanguage
} from "@components/LanguageSwitcher/config";

import {
  GET_REMOTE_LANGUAGE,
  CLEAR_REMOTE_LANGUAGE
} from  "../actiontypes"

import actions from "../actions/language";

export const initState = {
  isActivated: false,
  language: getCurrentLanguage(config.defaultLanguage || "turkish"),
  remoteLanguage: {}
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return {
        ...state,
        isActivated: !state.isActivated
      };
    case actions.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    case GET_REMOTE_LANGUAGE:
      return {
        ...state,
        remoteLanguage: action.payload
      };
    case CLEAR_REMOTE_LANGUAGE:
      return {
        ...state,
        remoteLanguage: {}
      };
    default:
      return state;
  }
}
