import {sendRequest} from "@common/network";
import { createURL, addQueryToURL } from "@utils";
import {httpMethods} from "@constants/commontypes";
import { T } from "@utils/languageProvider";

import {
  getSpecialPageDetailURL,
  postSpecialPageDetailURL,
  widgetTypeListByChannelURL,
  schemaTypesURL,
  contentTypesURL,
  formListURL,
  getLandingPageTypes,
  retailStoresURL,
  retailStoreDetailURL,
  retailStoresCountriesUrl,
  retailStoresCitiesUrl,
  retailStoresTownshipsUrl,
  retailStoresDistrictsUrl,
  postRetailStoreDistrictUrl,
  remoteScheduleRules,
  remoteSchedules,
  retailStoreTypeURL,
  patchRetailStoresURL,
  sitesURL,
  flatPagesByChannelURL,
  landingPagesURL,
  saveCategoryNodeLandingPagesURL,
  categoryNode
} from "@constants/serviceUrls";
import {
  CLEAR_STATE_SPECIAL_PAGE,
  GET_SPECIAL_PAGE_DETAIL,
  GET_WIDGET_TYPE_DETAIL_SUCCESS,
  SAVE_WIDGET_TYPE_DETAIL_SUCCESS,
  GET_SCHEMA_TYPE_DETAIL_SUCCESS,
  GET_CONTENT_TYPES_SUCCESS,
  GET_FORM_SUBJECT_DETAIL_SUCCESS,
  GET_LANDING_PAGE_TYPES,
  GET_RETAIL_STORE_DETAIL_SUCCESS,
  GET_RETAIL_STORES_DETAIL_SUCCESS,
  GET_RETAIL_STORES_COUNTRIES_SUCCESS,
  GET_RETAIL_STORES_CITIES_SUCCESS,
  GET_RETAIL_STORES_TOWNSHIPS_SUCCESS,
  GET_RETAIL_STORES_DISTRICTS_SUCCESS,
  GET_RETAIL_STORES_TYPE_SUCCESS,
  GET_REMOTE_SCHEDULES_SUCCESS,
  GET_REMOTE_SCHEDULE_RULES_SUCCESS,
  GET_SITES_SUCCESS,
  GET_STATIC_PAGE_DETAIL_SUCCESS,
  GET_CATEGORY_NODE_LANDING_PAGES_SUCCESS,
  CLEAR_CATEGORY_NODE_LANDING_PAGES,
  GET_CATEGORY_NODE_SUCCESS,
  DATA_SOURCE_PREPARED_SUCCESS,
  GET_LANDING_PAGES_SUCCESS,
  CLEAR_LANDING_PAGES
} from "../actiontypes";

export function getSpecialPageDetail(channel,id) {
  return async () => {
    return sendRequest({
      url: getSpecialPageDetailURL.replace("{0}",channel).replace("{1}",id),
      onSuccess:(result) =>{
        return{
          type: GET_SPECIAL_PAGE_DETAIL,
          payload: result
        }
      }
    });
  };
}

export function prepareDataSource( callback = () => {} ){
  return async ( dispatch, getState ) => {
    let dataSource = [];
    const { channels } = getState().appReducer

    channels.forEach(async channel => {
      if(channel.channel_type === "web"){
        const sites = await dispatch(getSites(channel.pk))
        if(sites){
          sites.results.forEach(site => dataSource.push({...channel,site}))
        }
        else{
          dataSource.push({...channel})
        }
        callback()
      }
      else{
        dataSource.push({...channel})
      }
    })
    dispatch({
      type : DATA_SOURCE_PREPARED_SUCCESS,
      payload : dataSource
    })
  }
}

export function patchSpecialPageDetail(channel,id,params) {
  return async () => {
    return sendRequest({
      url: getSpecialPageDetailURL.replace("{0}",channel).replace("{1}",id),
      method:httpMethods.PATCH,
      successMessage: T("update.success").format(T("special.page")),
      params,
    });
  };
}

export function postSpecialPageDetail(channel,params) {
  return async () => {
    return sendRequest({
      url: postSpecialPageDetailURL.replace("{0}",channel),
      method:httpMethods.POST,
      successMessage: T("add.success").format(T("special.page")),
      params,
    });
  };
}

export function getFormSubjectDetail(channel,id) {
  return async () => {
    return sendRequest({
      url: createURL(formListURL.format(channel) , [id]),
      onSuccess:(result) =>{
        return{
          type: GET_FORM_SUBJECT_DETAIL_SUCCESS,
          payload: result
        }
      }
    });
  };
}


export function saveFormSubject(channel,id,params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(formListURL.format(channel) , [id]) : formListURL.format(channel),
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("form")) : T("add.success").format(T("form")),
      params
    });
  };
}


export function deleteContactUsForm(channel,id) {
  return async () => {
    return sendRequest({
      url: createURL(formListURL.format(channel) , [id]),
      method:httpMethods.DELETE,
    });
  };
}

export function getWidgetSchemaDetail(channel, id) {
  return async () => {
    return sendRequest({
      url: createURL(widgetTypeListByChannelURL.format(channel), [id]),
      onSuccess: result => {
        return {
          type: GET_WIDGET_TYPE_DETAIL_SUCCESS,
          payload: result
        };
      }
    });
  };
}

export function saveWidgetSchemaDetail(params, channel, id) {
  return async () => {
    const postURL = widgetTypeListByChannelURL.format(channel);
    return sendRequest({
      url: id ? createURL(postURL, [id]) : postURL,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("widget.type")) : T("add.success").format(T("widget.type")),
      params,
      onSuccess: result => {
        return {
          type: SAVE_WIDGET_TYPE_DETAIL_SUCCESS,
          payload: result
        };
      }
    });
  };
}

export function getContentTypes(params){
  return async () => {
    return sendRequest({
      url : addQueryToURL(contentTypesURL , {limit : 100}),
      params,
      onSuccess : result => {
        return {
          type : GET_CONTENT_TYPES_SUCCESS,
          payload:result
        }
      },
    })
  }
}

export function getSchemaTypeDetail(contentType){
  return async () => {
    return sendRequest({
      url:createURL(schemaTypesURL,[contentType]),
      onSuccess : result => {
        return {
          type:GET_SCHEMA_TYPE_DETAIL_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function saveSchemaTypeDetail(params,id){
  return async () => {
    return sendRequest({
      url : id ? createURL(schemaTypesURL,[id]) : schemaTypesURL,
      method : id ? httpMethods.PATCH : httpMethods.POST,
      successMessage : id ? T("update.success").format(T("modal.schema")) : T("add.success").format(T("modal.schema")),
      params
    })
  }
}

export function getlandingPageTypes(channel) {
  return async () => {
    return sendRequest({
      url:getLandingPageTypes.format(channel),
      onSuccess : result => {
        return {
          type:GET_LANDING_PAGE_TYPES,
          payload:result
        }
      }
    })
  }
}

export function getRetailStoreDetail(id) {
  return async () => {
    return sendRequest({
      url: retailStoreDetailURL.format(id),
      onSuccess : result => {
        return {
          type: GET_RETAIL_STORE_DETAIL_SUCCESS,
          payload: result
        }
      }
    })
  }
}
          
export function getRetailStoresDetail(id) {
  return async () => {
    return sendRequest({
      url: id ? createURL(retailStoresURL,[id, "detailed"]) : "",
      onSuccess : result => {
        return {
          type:GET_RETAIL_STORES_DETAIL_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function getSites(channel){
  return async () => {
    return sendRequest({
      url : sitesURL.format(channel),
      onSuccess : result => {
        return {
          type : GET_SITES_SUCCESS,
          payload: result
        }
      }
    })
  }
}

export function getRetailStoresCountries() {
  return async () => {
    return sendRequest({
      url: retailStoresCountriesUrl,
      onSuccess : result => {
        return {
          type: GET_RETAIL_STORES_COUNTRIES_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function getStaticPageDetail(id,channel){
  return async () => {
    return sendRequest({
      url:createURL(flatPagesByChannelURL.format(channel),[id]),
      onSuccess : result => {
        return {
          type: GET_STATIC_PAGE_DETAIL_SUCCESS,
          payload : result
        }
      }
    })
  }
}

export function getRetailStoresCities(id) {
  return async () => {
    return sendRequest({
      url: addQueryToURL(retailStoresCitiesUrl, {country:id, limit:9999}),
      onSuccess : result => {
        return {
          type:GET_RETAIL_STORES_CITIES_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function getRetailStoresTownships(id) {
  return async () => {
    return sendRequest({
      url: addQueryToURL(retailStoresTownshipsUrl, {city:id, limit:9999}),
      onSuccess : result => {
        return {
          type:GET_RETAIL_STORES_TOWNSHIPS_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function getRetailStoresDistricts(id) {
  return async () => {
    return sendRequest({
      url: addQueryToURL(retailStoresDistrictsUrl,{township:id, limit:9999}),
      onSuccess : result => {
        return {
          type:GET_RETAIL_STORES_DISTRICTS_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function saveRetailStoreDistricts(id, params){
  return async () => {
    return sendRequest({
      params,
      url: postRetailStoreDistrictUrl.format(id),
      method: httpMethods.POST
    })
  }
}

export function patchRetailStore(id, params){
  return async () => {
    return sendRequest({
      params,
      url: patchRetailStoresURL.format(id),
      method: httpMethods.PATCH
    })
  }
}

export function getRemoteSchedules(id) {
  return async () => {
    return sendRequest({
      url: remoteSchedules.format(id),
      onSuccess: result => {
        return {
          type: GET_REMOTE_SCHEDULES_SUCCESS,
          payload: result
        }
      }
    })
  }
}

export function saveRemoteSchedules(id, params) {
  return async () => {
    return sendRequest({
      params,
      url: remoteSchedules.format(id),
      method: httpMethods.POST
    })
  }
}

export const removeRemoteSchedules = (channel, id) => {
  return async () => {
    return sendRequest({
      url: createURL(remoteSchedules.format(channel), [id]),
      method: httpMethods.DELETE
    })
  }
}

export function getRemoteScheduleRules(id) {
  return async () => {
    return sendRequest({
      url: remoteScheduleRules.format(id),
      onSuccess: result => {
        return {
          type: GET_REMOTE_SCHEDULE_RULES_SUCCESS,
          payload: result
        }
      }
    })
  }
}

export function saveRemoteScheduleRules(id, params) {
  return async () => {
    return sendRequest({
      params,
      url: remoteScheduleRules.format(id),
      method: httpMethods.POST
    })
  }
}

export const removeRemoteScheduleRules = (channel, id) => {
  return async () => {
    return sendRequest({
      url: createURL(remoteScheduleRules.format(channel), [id]),
      method: httpMethods.DELETE
    })
  }
}

export const scheduleRuleAddOrRemove = (channel, id, action, params) => {
  return async () => {
    return sendRequest({
      params,
      url: createURL(remoteSchedules.format(channel), [id, action]),
      method: httpMethods.POST
    })
  }
}

export function saveSites(channel,id,params){
  return async () => {
    const saveSiteURL = sitesURL.format(channel);
    return sendRequest({
      params,
      url: id ? createURL(saveSiteURL,[id]) : saveSiteURL,
      method : id ? httpMethods.PATCH : httpMethods.POST
    })
  }
}

export function uploadImage(params = {}, id) {
  return async () => {
    return sendRequest({
      params,
      url: createURL (retailStoresURL,[id]),
      method: httpMethods.PATCH,
    });
  };
}

export function specialPageUploadImage(params = {}, channel, id) {
  return async () => {
    return sendRequest({
      params,
      url: getSpecialPageDetailURL.format(channel, id),
      method: httpMethods.PATCH,
    });
  };
}



export function getRetailStoreType() {
  return async () => {
    return sendRequest({
      url: addQueryToURL(retailStoreTypeURL, {limit:9999}),
      onSuccess : result => {
        return {
          type:GET_RETAIL_STORES_TYPE_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function saveStaticPageDetail(id,channel,params){
  const flatPageURL = flatPagesByChannelURL.format(channel);
  return async () => {
    return sendRequest({
      url : id ? createURL(flatPageURL,[id]) : flatPageURL,
      method : id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("static.page")) : T("add.success").format(T("static.page")), 
      params
    })
  }
}

export function saveRetailStoresForm(id,params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(retailStoresURL,[id]): retailStoresURL,
      method: id ?httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("store")) : T("add.success").format(T("store")),
      params,
    });
  };
}

export function clearContentManagementState() {
  return async dispatch => {
    dispatch({
      type: CLEAR_STATE_SPECIAL_PAGE
    });
  };
}

export function getCategoryNodeLandingPages(id,channel) {
  return async () => {
    return sendRequest({
      url: createURL(saveCategoryNodeLandingPagesURL.format(channel), [id, "detailed"]),
      onSuccess : result => {
        return {
          type:GET_CATEGORY_NODE_LANDING_PAGES_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function getLandingPages(id,channel) {
  return async () => {
    return sendRequest({
      url: createURL(landingPagesURL.format(channel), [id, "detailed"]),
      onSuccess : result => {
        return {
          type:GET_LANDING_PAGES_SUCCESS,
          payload:result
        }
      }
    })
  }
}

export function saveLandingPages(params ,channel, pk) {
  return async () =>{
    return sendRequest({
      url: pk ? createURL(landingPagesURL, [pk]).format(channel) : landingPagesURL.format(channel),
      method: pk ? httpMethods.PATCH : httpMethods.POST,
      successMessage : pk ? T("update.success").format(T("landing.page")) : T("add.success").format(T("landing.page")),
      params
    })
  }
}

export function saveCategoryNodeLandingPages(params ,channel, id) {
  return async () =>{
    return sendRequest({
      url: id ? createURL(saveCategoryNodeLandingPagesURL.format(channel), [id]) : saveCategoryNodeLandingPagesURL.format(channel),
      method : id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("category.landing.page")) : T("add.success").format(T("category.landing.page")),
      params
    })
  }
}

export function getCategoryNode(channel){
  return async() => {
    return sendRequest({
      url: categoryNode.format(channel),
      onSuccess : result => {
        return {
          type:GET_CATEGORY_NODE_SUCCESS,
          payload:result
        }
      }
    })
  }
}
export function clearCategoryNodeLandingPagesState() {
  return dispatch => {
    dispatch({
      type: CLEAR_CATEGORY_NODE_LANDING_PAGES
    });
  };
}

export function clearLandingPages() {
  return { type: CLEAR_LANDING_PAGES };
}

