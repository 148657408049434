import React, { memo } from "react";
import PropTypes from "prop-types";

const Intrinsic = memo(props => {
  return <i {...props} />;
});

Intrinsic.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default Intrinsic;
