import moment from 'moment';
import {
  defaultDateFormat,
  defaultDateValueFormat,
  defaultDateTimeValueFormat,
  defaultDateTimeFormat,
} from '@constants';
import { inputTypes } from './constants';
import { dateValueFormatter } from '@utils/';

export function getValueByInputType(e, type, field) {
  switch (type) {
    case inputTypes.input:
    case inputTypes.password:
    case inputTypes.radio:
    case inputTypes.textarea:
      return e.target.value;
    case inputTypes.checkbox:
      return e.target.checked;
    case inputTypes.datepicker:
      const value = dateValueFormatter(
        e,
        defaultDateFormat,
        defaultDateValueFormat
      );
      const valueWithTimeZone = moment(value)
        .utc()
        .format(defaultDateTimeValueFormat);
      return (
        e && {
          key: field.timezone !== false ? valueWithTimeZone : value,
          label: e.format(defaultDateFormat),
        }
      );
    case inputTypes.dateRangePicker:
      if (!e) return null;
      const [start, end] = e;
      const { startValueKey = 'startValue', endValueKey = 'endValue' } = field;
      const dtFormat = field.withTimeZone
        ? defaultDateTimeFormat
        : defaultDateFormat;
      const dtValueFormat = field.withTimeZone
        ? defaultDateTimeValueFormat
        : defaultDateValueFormat;
      let startValue = moment(start, dtFormat).utc().format(dtValueFormat);
      let endValue = moment(end, dtFormat).utc().format(dtValueFormat);
      return e;
    case inputTypes.inputWithSelect:
      if (!e) return null;
      let result = {};
      let dateIndex = -1;
      let keys = Object.keys(e);
      keys.forEach((key, index) => {
        if (moment.isMoment(e[key])) {
          dateIndex = index;
        }
      });
      if (dateIndex === -1) return { ...e };
      const inputIndex = dateIndex === 0 ? 1 : 0;
      result = { [keys[inputIndex]]: e[keys[inputIndex]] };
      return {
        ...result,
        [keys[dateIndex]]: {
          key: moment(e[keys[dateIndex]], defaultDateTimeFormat).format(
            defaultDateTimeValueFormat
          ),
          label: e[keys[dateIndex]].format(defaultDateFormat),
        },
      };
    case inputTypes.multipleInputs:
      if (!e) return e;
      let response = {};
      let inputKeys = Object.keys(e);
      inputKeys.forEach((key) => {
        response = {
          ...response,
          [key]: moment.isMoment(e[key])
            ? {
                key: moment(e[key], defaultDateTimeFormat).format(
                  defaultDateTimeValueFormat
                ),
                label: e[key].format(defaultDateFormat),
              }
            : e[key],
        };
      });
      return response;
    case inputTypes.select:
      return e;
    default:
      return e;
  }
}

export function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => {
    if (typeof fieldsError[field] === 'object')
      return hasErrors(fieldsError[field]);
    return fieldsError[field];
  });
}

export function getError(
  isFieldTouched,
  getFieldError,
  fieldName,
  errorOnFocus,
  formSubmitted = false
) {
  const isFocused = formSubmitted || isFieldTouched(fieldName);
  const error = getFieldError(fieldName) || '';
  return {
    hasError: !!(errorOnFocus ? isFocused && error : error),
    error,
    validateStatus: error ? 'error' : '',
  };
}

export function getInputType(alias) {
  for (const key in inputTypes) {
    if (inputTypes.hasOwnProperty(key)) {
      const element = inputTypes[key];
      if (element.alias === alias) return element;
    }
  }
  return getDefaultInputType();
}

export function getDefaultInputType() {
  for (const key in inputTypes) {
    if (inputTypes.hasOwnProperty(key)) {
      const element = inputTypes[key];
      if (element.defaultType) return element;
    }
  }
}

export function deserializeStorageValue(value, type) {
  let val = value;
  if (value && value.key) val = val.key;
  switch (type) {
    // case inputTypes.datepicker.alias:
    //   return {key: moment(val, defaultDateValueFormat),
    //     label: dateValueFormatter(val, defaultDateValueFormat,defaultDateFormat)};
    default:
      return val;
  }
}

export function serializeStorageValue(value, type) {
  switch (type) {
    //case inputTypes.datepicker.alias:
    //  return value.format(defaultDateValueFormat);
    default:
      return value;
  }
}

export function formatDefaultValue(value) {
  if (moment.isMoment(value))
    return {
      key: dateValueFormatter(
        value,
        defaultDateValueFormat,
        defaultDateValueFormat
      ),
      label: moment(value, defaultDateValueFormat).format(defaultDateFormat),
    };
  return value;
}
