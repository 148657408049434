import {
  GET_PRODUCT_DETAIL_SUCCESS,
  SAVE_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_ATTRIBUTE_SETS_DETAIL_SUCCESS,
  GET_ATTRIBUTES,
  GET_PRODUCT_SPECIFICATION_DATATABLE,
  GET_OPTIONAL_ATTRIBUTE_DETAIL,
  CLEAR_PRODUCT_DETAIL_STATE,
  GET_SPECIFICATION_SET_DETAIL,
  GET_ALL_SPECS_SUCCESS,
  GET_SELECTED_SPECS_SUCCESS,
  GET_PRODUCT_VIDEO,
  GET_PRODUCT_IMAGE,
  GET_PRE_PRODUCT_ATTRIBUTE
} from "../actiontypes";

export const initialState = {
  productDetail: {},
  productAllDetail: {},
  attributes:{},
  attributeDetail:{},
  specificationDatatable:{},
  specificationSetDetail: {},
  allSpecs: [],
  selectedSpecs: [],
  productVideo:[],
  preProductsAttribute:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_DETAIL_SUCCESS:
    case SAVE_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state, 
        productDetail: action.payload.attributes,
        productAllDetail: action.payload,
      };
    case GET_PRODUCT_ATTRIBUTE_SETS_DETAIL_SUCCESS:
      return {
        ...state, 
        attributeSetDetail: action.payload,
      };
    case GET_ATTRIBUTES:
      return {
        ...state,
        attributes: action.payload,
      };
    case GET_PRODUCT_SPECIFICATION_DATATABLE:
      return {
        ...state,
        specificationDatatable: action.payload,
      };
    case GET_OPTIONAL_ATTRIBUTE_DETAIL:
      return {
        ...state,
        attributeDetail: action.payload,
      };
    case CLEAR_PRODUCT_DETAIL_STATE:
      return {
        ...initialState
      };
    case GET_SPECIFICATION_SET_DETAIL:
      return {
        ...state,
        specificationSetDetail: action.payload
      }
    case GET_ALL_SPECS_SUCCESS:
      return {
        ...state,
        allSpecs: action.payload.results
      }
    case GET_SELECTED_SPECS_SUCCESS:
      return {
        ...state,
        selectedSpecs: action.payload.results
      }
    case GET_PRODUCT_VIDEO:
      return {
        ...state,
        productVideo: action.payload.results
      }  
    case GET_PRODUCT_IMAGE:
      return {
        ...state,
        productImage: action.payload.results
      }    
    case GET_PRE_PRODUCT_ATTRIBUTE:
      return {
        ...state,
        preProductsAttribute: action.payload.results
      } 
    default:
      return state;
  }
}
