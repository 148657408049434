import React, { memo } from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../UIElements/ButtonGroups";
import Intrinsic from '@components/Intrinsic'

const Button = memo(({ children, icon, ...otherProps }) => {
  return <ButtonGroup {...otherProps}>
    {icon && <Intrinsic className={icon}/>}
    {children}
    </ButtonGroup>;
});

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;