import React, {Component} from "react";
import {connect} from "react-redux";

import {inputRenderer} from "@utils";
import {
  activePassiveBoolOptions, cancellationType,
  formLayoutTypes,
  validationTypes, trueFalseOptions
} from "@constants/commontypes";
import {
  clearSettingState,
  getCancellationReasonDetail,
  postCancellationReasonDetail
} from "@resources/actions/setting";
import {setActionElements} from "@common";
import {inputTypes} from "@components/DynamicForm/constants";
import { T } from "@utils/languageProvider";

import { DynamicFormWithBox } from "@components/DynamicForm";
import LayoutWrapper from "@components/utility/layoutWrapper/index";
import { navigator } from "@common/navigation";

const DynamicForm = DynamicFormWithBox("payment-option-form");

function getAttributeInputs() {
  return [
    {
      pk: 1,
      name: T("title"),
      key: "subject",
      data_type: {value: inputTypes.input.alias},
      fieldOptions: {
        rules: [validationTypes.required(T("title"))]
      },
      fieldProps: {
        placeholder: T("enter.variable").format(T("title"))
      }
    },
    {
      pk: 2,
      name: T("cancellation.type"),
      key: "cancellation_type",
      data_type: {value: inputTypes.select.alias},
      visible_values: cancellationType,
      fieldOptions: {
        rules: [validationTypes.required(T("cancellation.type"))]
      },
      fieldProps: {
        placeholder: T("select.variable").format(T("cancellation.type"))
      }
    },
    {
      pk: 3,
      name: T("status"),
      key: "is_active",
      data_type: {value: inputTypes.select.alias},
      visible_values: activePassiveBoolOptions,
      fieldProps: {
        placeholder: T("select.cancel.refund.status")
      }
    },
    {
      pk: 4,
      name: T("show.to.shop"),
      key: "send_to_remote",
      data_type: {value: inputTypes.select.alias},
      visible_values: trueFalseOptions,
      fieldProps: {
        placeholder: T("visible.extra")
      }
    },
    {
      pk: 5,
      name: T("extra.information1"),
      key: "extra_information_needed",
      data_type: {value: inputTypes.select.alias},
      visible_values: trueFalseOptions,
      fieldProps: {
        placeholder: T("visible.extra")
      }
    },
    {
      pk: 6,
      name: T("priority"),
      key: "order",
      data_type: {value: inputTypes.input.alias},
      fieldOptions: {
        rules: [validationTypes.number,validationTypes.required(T("priority")) ]
      },
      fieldProps: {
        placeholder: T("enter.variable").format(T("priority"))
      }
    }
  ];
}

class CancellationReasonDetail extends Component {

  state = {
    loading: false,
  };

  componentWillUnmount() {
    const {clearSettingState} = this.props;
    clearSettingState();
  }

  componentDidMount() {
    setActionElements();
    const {match: {params: {id} = {}} , getCancellationReasonDetail} = this.props;
    if (id) {
      getCancellationReasonDetail(id)
    }
  }


  onSubmit = async (_, values) => {
    const {
      match: {params: {id} = {}},
      postCancellationReasonDetail
    } = this.props;
    await postCancellationReasonDetail(id,values);
    navigator.push("/settings/cancellation-reasons");
  };

  inputRender = () => {
    const {
      match:{params:{id}},
      cancellationReasonDetail
    } = this.props;
    const staticInputs = getAttributeInputs();
    return inputRenderer(staticInputs,id,cancellationReasonDetail)
  };

  render() {
    const {
      loading,
    } = this.state;

    const formInputs = this.inputRender();
    return (
      <LayoutWrapper>
          <DynamicForm
            submitting={loading}
            inputs={formInputs}
            onSubmit={this.onSubmit}
            objectKey="visible_values"
            title={T("cancel.and.refund.add.or.edit")}
            subtitle={T("cancel.and.refund.add.or.edit.desc")}
            layoutType={formLayoutTypes.boxLayout}
          />
      </LayoutWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    cancellationReasonDetail : state.settingReducer.cancellationReasonDetail,
  };
}

export default connect(
  mapStateToProps,
  {
    postCancellationReasonDetail,
    getCancellationReasonDetail,
    clearSettingState
  }
)(CancellationReasonDetail);
