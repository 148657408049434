import {
  GET_BANK_DETAIL,
  GET_BANK_ACCOUNT,
  GET_BANK_FORM_INPUT,
  CLEAR_PAYMENT_MANAGEMENT_STATE,
  GET_CARD_DETAIL_SUCCESS,
  GET_CARD_BIN_DETAIL_SUCCESS,
  GET_COMMISSION_DETAIL_SUCCESS,
  GET_ALL_CARD_TYPE_SUCCESS,
  GET_ALL_BANK_SUCCESS,
  GET_CARD_TYPE_INPUT_SUCCESS,
  GET_CARD_TYPE_DETAIL_SUCCESS,
  GET_PAYMENT_OPTION_DETAIL,
  GET_BANK_ACCOUNT_DETAILED
} from "../actiontypes";

export const initialState = {
  bankDetail: {},
  bankFormInput: {},
  bankAccount: [
    {
      holder_name: "",
      slug: "",
      iban: "",
      description:"",
      sort_order: 0,
      is_active: "false"
    }
  ],
  bankAccountDetail: [],
  cardDetail: {},
  commissionDetail: {},
  cardBinDetail: {},
  allCardTyp: {},
  allBank: {},
  cardTypeDetail: {},
  cardTypeInput: {},
  paymentOptionDetail: {}
};

export default function(state = initialState, action) {
  const result = action.payload
  switch (action.type) {
    case GET_BANK_DETAIL:
      return {
        ...state,
        bankDetail: result
      };
    case GET_BANK_ACCOUNT_DETAILED:
      return {
        ...state,
        bankAccountDetail: (result.results && result.results.length) ? result.results : initialState.bankAccountDetail
      };
    case GET_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: (result.results && result.results.length) ? result.results : initialState.bankAccount
      };  

    case GET_BANK_FORM_INPUT:
      return {
        ...state,
        bankFormInput: result && result.actions && result.actions
      };
    case GET_CARD_DETAIL_SUCCESS:
      return{
        ...state,
        cardDetail:result
      }; 

    case GET_COMMISSION_DETAIL_SUCCESS:
      return{
        ...state,
        commissionDetail:result
      };

    case GET_CARD_BIN_DETAIL_SUCCESS:
      return{
        ...state,
        cardBinDetail:result
      };   
      
    case GET_ALL_CARD_TYPE_SUCCESS:
      return{
        ...state,
        allCardType:result
      };
    case GET_ALL_BANK_SUCCESS:
      return{
        ...state,
        allBank:result
      };

    case GET_CARD_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        cardTypeDetail: result
      };
      
    case GET_CARD_TYPE_INPUT_SUCCESS:
      return {
        ...state,
        cardTypeInput: result && result.actions && result.actions
      };

    case CLEAR_PAYMENT_MANAGEMENT_STATE:
      return {
        ...initialState
      };
    case GET_PAYMENT_OPTION_DETAIL:
      return {
        ...state,
        paymentOptionDetail: result
      };  
    default:
      return state;
  }
}
