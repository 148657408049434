import {
  GET_WIDGET_DETAIL_SUCCESS,
  CLEAR_WIDGET_DETAIL_STATE,
  GET_WIDGET_TYPES_SUCCESS,
  GET_COLLECTION_WIDGET_DETAIL_SUCCESS,
  GET_PRODUCT_COLLECTION_LIST_SUCCESS,
  CLEAR_WIDGET_STATE,
} from "../actiontypes";

export const initialState = {
  widgetDetail: {},
  widgetTypes: [],
  collectionWidgetDetail : {},
  productCollection : {}
};

export default function(state = initialState, action) {
  const result = action.payload && action.payload
  switch (action.type) {
    case CLEAR_WIDGET_DETAIL_STATE:
      return {
        ...state, 
        widgetDetail: initialState.widgetDetail
      };
    case GET_WIDGET_DETAIL_SUCCESS:
      return {
        ...state, 
        widgetDetail: result || initialState.widgetDetail
      };
    case GET_WIDGET_TYPES_SUCCESS:
      return {
        ...state, 
        widgetTypes: (action.payload && action.payload.results) || initialState.widgetTypes
      };
    case GET_COLLECTION_WIDGET_DETAIL_SUCCESS:
      return {
        ...state,
        collectionWidgetDetail: result || initialState.collectionWidgetDetail
      }
    case GET_PRODUCT_COLLECTION_LIST_SUCCESS : 
    return {
      ...state,
      productCollection: result || initialState.productCollection
    }
    case CLEAR_WIDGET_STATE:
      return {
        initialState
      }
    default:
      return state;
  }
}
