import React, {Component} from "react";
import {connect} from "react-redux";

import { getCatalogs } from "@resources/actions/catalog";
import {
  getAllChannels
} from "@resources/actions/app";
import {
  getSites,
  prepareDataSource
} from "@resources/actions/contentManagement";
import {setActionElements} from "@common";
import {dateFormatter, statusActivePassiveFormatter} from "@utils";
import {navigator} from "@common/navigation";
import { checkAuth } from "@common";
import { T } from "@utils/languageProvider";
import ChannelPkContext from "@containers/MasterPage/channelPkContext";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import Button from "@components/Button";
import {DatatableBox} from "@components/Datatable";
import Image from "@components/Image";

import amazon from '@assets/images/channel-logos/amazon.png'
import feed from '@assets/images/channel-logos/feed.png'
import gittigidiyor from '@assets/images/channel-logos/gittigidiyor.png'
import hepsiburada from '@assets/images/channel-logos/hepsiburada.png'
import morhipo from '@assets/images/channel-logos/morhipo.png'
import n11 from '@assets/images/channel-logos/n11.png'
import trendyol from '@assets/images/channel-logos/trendyol.png'
import web from '@assets/images/channel-logos/web.png'

import './style.scss'

function onNavigate(url) {
  navigator.push(url);
}

const logos = 
{
  amazon,
  feed,
  gittigidiyor,
  morhipo,
  n11,
  trendyol,
  hepsiburada,
  web
}

class ChannelList extends Component {

  static contextType = ChannelPkContext;

  state = {
    loading: true
  }

  componentDidMount() {
    const { getAllChannels, getCatalogs, prepareDataSource } = this.props;
    checkAuth("setting-channels-create") && setActionElements([
        <Button type = "secondary"
                icon="icon-arti"
                onClick={() => onNavigate(`/settings/channels/form`)}>
              {T('add.sale.channel')}
        </Button>
    ]);

    Promise.all([getAllChannels(), getCatalogs()])
    .then(() => {
      prepareDataSource(() => this.setState({ loading: false }))
    })
  }

  componentWillUnmount() {
    setActionElements();
  }

  onRowClick = (row, index) => {
    onNavigate(`/settings/channels/form/${row.pk}/${index}`);
  };

  catalogFormatter = val => {
    const catalog =  this.props.catalogs.results.find(catalog => catalog.pk === val)
    return catalog && catalog.name || '-'
  }

  logoFormatter = (val) => {
    return <Image className="cell-image" src = {logos[val] || logos["web"]}/>
  }

  columns = [
    {
      key: 1,
      title: T('logo'),
      dataIndex: "channel_type",
      render: this.logoFormatter,
    },
    {
      key: 2,
      dataIndex: "name",
      title: T("channel.name")
    },
    {
      key: 3,
      dataIndex: "modified_date",
      title: T('last.updated.date'),
      render: cellData => dateFormatter(cellData,false)
    },
    {
      key: 4,
      dataIndex: "catalog",
      title: T('catalog'),
      render: this.catalogFormatter
    },
    {
      key: 5,
      dataIndex: "site.name",
      title: T('site.name'),
      render : cellData => cellData || '-'
    },
    {
      key: 6,
      dataIndex: "site.domain",
      title: T('domain.name'),
      render : cellData => cellData || '-'
    },
    {
      key: 6,
      dataIndex: "is_active",
      title: T('status'),
      render : statusActivePassiveFormatter
    }
  ];

  render() {
    const channel = this.context;
    const {channelsWithSites} = this.props;
    const { loading } = this.state

    return (
      <LayoutContentWrapper loading = {loading} className="channels-list">
        {channel && 
        <>
          <DatatableBox
            rowKey="pk"
            exportable={false}
            columns={this.columns}
            onRowClick={this.onRowClick}
            subtitle={T('channels')}
            locale={{emptyText: T("result.not.found")}}
            dataSource = {channelsWithSites}
            remote={false}
            alwaysShowPagination={false}
            //paginationText={T("show.variable").format(T("sale.channel"))}
            isAuthorized
            authActionName= "setting-channels-update"
          />
        </>
        }
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    catalogs: state.catalogReducer.catalogs,
    channels: state.appReducer.channels,
    sites: state.contentManagementReducer.sites,
    channelsWithSites : state.contentManagementReducer.channelsWithSites
  };
}

export default connect(
  mapStateToProps,
  {
    getCatalogs,
    getAllChannels,
    getSites,
    prepareDataSource
  }
)(ChannelList);