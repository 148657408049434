import { 
  GET_STOCK_LOCATION_MOVEMENTS_SUCCESS,
  GET_STOCK_LOCATION_MOVEMENT_DETAIL_SUCCESS,
  GET_STOCK_LOCATIONS_SUCCESS,
  GET_STOCK_LOCATION,
  GET_STOCK_LOCATION_PACKAGES,
  CLEAR_STATE_LOCATION,
  PACKAGE_STATUS_LIST_SUCCESS,
  GET_TRANSFER_LOCATION_MOVEMENTS_SUCCESS,
  GET_TRANSFER_LOCATION_MOVEMENT_DETAIL_SUCCESS,
  TRANSFER_STATUS_LIST_SUCCESS,
  GET_TRANSFER_LOCATION_DETAIL
} from "../actiontypes";

export const initialState = {
  stockLocationMovements: [],
  stockLocationMovementDetail: [],
  stockLocations: [],
  stockLocation:[],
  stockLocationPackages:{},
  packageStatusList: [],
  transferLocationMovements: [],
  transferStatusList: [],
  transferLocationMovementDetail: [],
  transferOrderLocationDetail: []
};

export default function(state = initialState, action) {
  const result = action.payload && action.payload.results;
  switch (action.type) {
    case GET_STOCK_LOCATION_MOVEMENTS_SUCCESS:
      return {
        ...state, 
        stockLocationMovements: result || initialState.stockLocationMovements
      };
    case GET_STOCK_LOCATION_MOVEMENT_DETAIL_SUCCESS:
      return {
        ...state, 
        stockLocationMovementDetail: action.payload || initialState.stockLocationMovementDetail
      };
    case GET_STOCK_LOCATIONS_SUCCESS:
      return {
        ...state, 
        stockLocations: result || initialState.stockLocations
      };
    case GET_STOCK_LOCATION:
      return {
        ...state,
        stockLocation: result || initialState.stockLocation
      };
    case GET_STOCK_LOCATION_PACKAGES:
      const packageResult = action.payload && action.payload;
      return {
        ...state,
        stockLocationPackages: packageResult || initialState.stockLocationPackages
      };
    case CLEAR_STATE_LOCATION:
      return {
        ...initialState
      };
    case PACKAGE_STATUS_LIST_SUCCESS:
        return {
          ...state, 
          packageStatusList: result || initialState.packageStatusList
        };
    case GET_TRANSFER_LOCATION_MOVEMENTS_SUCCESS:
      return {
        ...state, 
        transferLocationMovements: result || initialState.transferLocationMovements
      };
    case GET_TRANSFER_LOCATION_MOVEMENT_DETAIL_SUCCESS:
        return {
          ...state, 
          transferLocationMovementDetail: action.payload || initialState.transferLocationMovementDetail
        };
    case TRANSFER_STATUS_LIST_SUCCESS:
        return {
          ...state, 
          transferStatusList: result || initialState.transferStatusList
        };
    case GET_TRANSFER_LOCATION_DETAIL:
        return {
          ...state,
          transferOrderLocationDetail: action.payload || initialState.transferOrderLocationDetail
        }
    default:
      return state;
  }
}
