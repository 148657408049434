import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Landing from './Landing';

import StockListRulesDetail from './StockListRules/StockListRulesDetail';
import StockListRulesList from './StockListRules/StockListRulesList';

import StockListsDetail from './StockLists/StockListsDetail';
import StockListsList from './StockLists/StockListsList';

import StockProvidersDetail from './StockProviders/StockProvidersDetail';
import StockProvidersList from './StockProviders/StockProvidersList';

import StockLocationsDetail from './StockLocations/StockLocationsDetail';
import StockLocationsList from './StockLocations/StockLocationsList';

import StockDetail from './Stock/StockDetail';
import StockList from './Stock/StockList';

const StockManagement = () => {
  return (
    <Switch>
      <Route exact path="/stock/stock-management" component={Landing} />
      <Route
        path="/stock/stock-management/stock-list-rules/form/:id?"
        component={StockListRulesDetail}
      />
      <Route
        path="/stock/stock-management/stock-list-rules"
        component={StockListRulesList}
      />
      <Route
        path="/stock/stock-management/stock-lists/form/:id?"
        component={StockListsDetail}
      />
      <Route
        path="/stock/stock-management/stock-lists"
        component={StockListsList}
      />
      <Route
        path="/stock/stock-management/stock-providers/form/:id?"
        component={StockProvidersDetail}
      />
      <Route
        path="/stock/stock-management/stock-providers"
        component={StockProvidersList}
      />
      <Route
        path="/stock/stock-management/stock-locations/form/:id?"
        component={StockLocationsDetail}
      />
      <Route
        path="/stock/stock-management/stock-locations/"
        component={StockLocationsList}
      />
      <Route
        path="/stock/stock-management/stock/form/:id?"
        component={StockDetail}
      />
      <Route path="/stock/stock-management/stock" component={StockList} />
    </Switch>
  );
};
export default StockManagement;
