import React from 'react'
import { connect } from "react-redux";
import { Layout} from "antd";
import FileSaver from 'file-saver'

import appActions from "@resources/actions/app";
import { readStorageItem, removeStorageItem } from "@common/storage";
import { T } from "@utils/languageProvider";

import SidebarWrapper from "@components/Sidebar/SidebarWrapper";
import Block, { ScrollableBlock } from "@components/Block";
import H2 from "@components/H2";
import ResponseMessages from './responseMessage'
import Button from "@components/Button";
import Anchor from "@components/Anchor";

const { Sider } = Layout;
const {
  toggleOpenDrawer,
  toggleCollapsed
} = appActions;


class RightbarWarningMessage extends React.Component{
  scrollRef = React.createRef()

  componentDidUpdate(prevProps){
    if(prevProps.rightBarCollapsed  && !this.props.rightBarCollapsed){
      this.scrollRef.current.scrollTo(0, 0)
    }
  }

  handleSave = () => {
    try {
      const responseMessages = JSON.parse(readStorageItem('responseMessages'))
      const csv = this.convertToCSV(responseMessages)
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvData, 'responseMessages.csv');
    } catch(err){
      console.error(err)
    }
  }

  convertToCSV = objArray =>  {
    const array = [Object.keys(objArray[0])].concat(objArray)
    return array.map(item => {
      return Object.values(item).toString()
    }).join('\n')
  }

  cleanAll = () => {
    removeStorageItem('responseMessages');
    this.setState({state: this.state})
  }

  render(){
    const { rightBarCollapsed } = this.props
    const responseMessages = JSON.parse(readStorageItem('responseMessages')) || [];
    const reversedMessages = [...responseMessages].reverse()
    
    return(
      <SidebarWrapper>
        <Sider
        trigger={null}
        collapsible={true}
        collapsed={rightBarCollapsed}
        collapsedWidth="0"
        className="isomorphicSidebar"
        width = {300}
        >
      <Block className="header-wrapper">
        <Block className="drawer-custom-header">
          <H2 className="drawer-title"> {T('error.and.warnings')}</H2>
          <H2 className="title-line"/>
          <Button className="download-action" icon="icon-download" onClick={this.handleSave}>CSV</Button>
        </Block>
        </Block>
        <Anchor className="clean-all-button" onClick={this.cleanAll}>
            {T("clean.all")}
        </Anchor>
        <ScrollableBlock className="message-wrapper" ref={this.scrollRef}>
          {
            reversedMessages.length > 0 && reversedMessages.map((message,index) => <ResponseMessages key = {index} {...message}/>)
          }
        </ScrollableBlock>
        </Sider>
      </SidebarWrapper>
    )
  }

}

const mapStateToProps = state => (
  {
    app: state.appReducer,
    rightBarCollapsed : state.appReducer.rightBarCollapsed
  }
)

export default connect(mapStateToProps, 
  {
    toggleOpenDrawer,
    toggleCollapsed 
  })(RightbarWarningMessage)