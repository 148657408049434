import {
  GET_STOCK_LOCATION_DETAIL_SUCCESS,
  GET_STOCK_LIST_RULES_SUCCESS,
  GET_STOCK_DETAIL_SUCCESS,
  GET_STOCK_LIST_DETAIL_SUCCESS,
  GET_STOCK_PROVIDERS_SUCCESS,
  CLEAR_STOCK_DETAIL,
} from '../actiontypes';

export const initialState = {
  stockListDetail: {},
  stockListRules: [],
  stockProviders: [],
  stockLocationDetail: {},
  stockDetail: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STOCK_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        stockListDetail: action.payload || initialState.stockListDetail,
      };
    case GET_STOCK_LIST_RULES_SUCCESS:
      return {
        ...state,
        stockListRules: action.payload || initialState.stockListRules,
      };
    case GET_STOCK_PROVIDERS_SUCCESS:
      return {
        ...state,
        stockProviders: action.payload || initialState.stockListRules,
      };
    case GET_STOCK_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        stockLocationDetail: action.payload || initialState.stockLocationDetail,
      };
    case GET_STOCK_DETAIL_SUCCESS:
      return {
        ...state,
        stockDetail: action.payload || initialState.stockDetail,
      };
    case CLEAR_STOCK_DETAIL:
      return {
        ...state,
        stockDetail: initialState.stockDetail,
      };
    default:
      return state;
  }
}
