import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { store } from "./resources/store";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { hot } from 'react-hot-loader/root'

import AppLocale from "./utils/languageProvider";
import config, {
  getCurrentLanguage
} from "./components/LanguageSwitcher/config";
import { history } from "./common/navigation";
import App from "./containers/App";
import { readStorageItem, writeStorageItem } from "@common/storage";
import { extractLanguageFromPathname } from "@utils/";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "turkish").locale];

const getInitialPlatformLanguage = () => {
  const { lang } = extractLanguageFromPathname(window.location.pathname);
  const { platform } = lang;

  if (platform && config.options.some((language)=> language.locale === platform)) {
    return platform;
  }

  const storagePlatformLang= readStorageItem("platform_language");
  if (storagePlatformLang && storagePlatformLang.locale) {
    return storagePlatformLang.locale;
  }

  return config.options[0].locale;
}

const storageLanguage =  JSON.parse(readStorageItem("platform_language"));

if(storageLanguage && storageLanguage.locale !== getInitialPlatformLanguage() ){
  const lang = getInitialPlatformLanguage();
  const activeLanguage = config.options.find(
    ({ languageId, locale }) => languageId === lang || locale === lang
  );
  writeStorageItem("platform_language", JSON.stringify(activeLanguage));
  window.location.reload();
}

document.documentElement.lang = currentAppLocale.locale;
class AppContainer extends Component {

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <ConfigProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
              <Provider store={store}>
                <Router history={history}>
                  <Route component={App} />
                </Router>
              </Provider>
          </IntlProvider>
        </ConfigProvider>
      </DndProvider>
    );
  }
}

export default process.env.NODE_ENV === "development" ? hot(AppContainer) : AppContainer;

export { AppLocale };
