import { IS_IN_OMS } from "../actiontypes"

const initialState = {
  isInOms: false,
};

export default function (state = initialState, action) {
  const result = action.payload;
  switch (action.type) {
    case IS_IN_OMS:
      return {
        ...initialState,
        isInOms: result || initialState.isInOms
      }
    default:
      return state;
  }
}