import React from "react";
import classnames from "classnames";

import "./style.scss";

const AntAutoComplete = Component => props => {
  const className = classnames(props.className, "antAutoComplete");
  return <Component {...props} className={className} />;
};

const AntAutoCompleteOption = Component => props => {
  const className = classnames(props.className, "antAutoCompleteOption");
  return <Component {...props} className={className} />;
};

export { AntAutoComplete, AntAutoCompleteOption };
