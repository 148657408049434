import React, { useEffect } from 'react';

import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';
import { dateFormatter, statusActivePassiveFormatter } from '@utils';
import DatatableWithFilter from '@components/DatatableWithFilter';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { activePassiveStringBoolOptions } from '@constants/commontypes';
import { stockListRulesURL } from '@constants/serviceUrls';
import { setActionElements } from '@common/index';
import Button from '@components/Button';

const StockListRulesList = () => {
  useEffect(() => {
    setActionElements([
      <Button
        key="excelAdd"
        type="secondary"
        icon="icon-arti"
        onClick={() =>
          onNavigate('/stock/stock-management/stock-list-rules/form/')
        }
      >
        {T('stockApp.new.add').format(T('stockApp.stock.list.rule'))}
      </Button>,
    ]);
    return () => setActionElements([]);
  }, []);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'name',
      name: T('new.rule'),
      pk: '1',
      active: true,
      fieldProps: {
        placeholder: T('new.rule'),
      },
    },
    {
      data_type: { value: inputTypes.select.alias },
      key: 'is_active',
      pk: '2',
      name: T('status'),
      active: true,
      visible_values: activePassiveStringBoolOptions,
      fieldProps: {
        placeholder: T('status'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'created_date__gte',
      name: T('created.date'),
      pk: '3',
      active: true,
      fieldProps: {
        placeholder: T('created.date'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'modified_date__lte',
      name: T('last.update'),
      pk: '4',
      active: true,
      fieldProps: {
        placeholder: T('last.update'),
      },
    },
  ];
  const columns = [
    {
      key: 0,
      dataIndex: 'id',
      title: 'id',
      visible: false,
      className: 'hide',
    },
    {
      key: 1,
      dataIndex: 'name',
      title: T('new.rule'),
    },
    {
      key: 5,
      dataIndex: 'is_active',
      title: T('status'),
      render: statusActivePassiveFormatter,
    },
    {
      key: 6,
      dataIndex: 'created_date',
      title: T('created.date'),
      render: (cellData) => dateFormatter(cellData, true),
    },
    {
      key: 7,
      dataIndex: 'modified_date',
      title: T('last.update'),
      render: (cellData) => dateFormatter(cellData, true),
    },
  ];
  const onNavigate = (url) => {
    navigator.push(url, false);
  };
  const onRowClick = (row) => {
    onNavigate(`/stock/stock-management/stock-list-rules/form/${row.id}`);
  };

  return (
    <LayoutContentWrapper className="stock-list-rules">
      <DatatableWithFilter
        filterProps={{ filterInputs }}
        datatableProps={{
          rowKey: 'id',
          doubleClickable: true,
          exportable: false,
          columns: columns,
          onRowClick: onRowClick,
          subtitle: T('stockApp.rules'),
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(''),
          url: stockListRulesURL,
        }}
      />
    </LayoutContentWrapper>
  );
};

export default StockListRulesList;
