import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Collapse, Tooltip, Icon } from 'antd';

import { isDefined, getRandom } from '@utils';
import { sendRequest } from '@common/network';
import { formLayoutTypes, httpMethods, labelAligns } from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { readStorageItem, writeStorageItem } from '@common/storage';
import { deserializeStorageValue, serializeStorageValue } from '../DynamicForm/helpers';
import { getEntries } from '@utils';

import Span from '@components/Span';
import Block from '@components/Block';
import Image from '@components/Image';
import FileInput from '@components/FileInput';
import Intrinsic from '@components/Intrinsic';
import CheckButton from '@components/CheckButton';
import Select from '@components/UIElements/Select';
import Button from '@components/UIElements/ButtonGroups';
import Paragraph from '@components/Paragraph';
import DynamicFormCreator from '@components/DynamicForm';
import { inputTypes } from '@components/DynamicForm/constants';
import Modal from '@components/Modal';

import simpleProductImage from '@assets/images/simple-product.svg';
import variantProductImage from '@assets/images/variant-product.svg';
import groupProductImage from '@assets/images/group-product.svg';
import bundleProductImage from '@assets/images/bundle-product.svg';
import miscProductImage from '@assets/images/misc-product.svg';

import './style.scss';


const DynamicForm = DynamicFormCreator('filter');
const pureKey = '?pures';
const Panel = Collapse.Panel;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.filterValues = {};
    this.state = {
      modalVisible: false,
      unmountOnClose: true,
      accumulatedPrimitives: [],
      filterFields: [],
      filterInputs: [],
      filterButtons: [...(props.filterButtons || [])],
      selectedFilters: [],
      inputFilters: [],
      buttonFilters: [],
      formDataFilters: [],
    };
  }

  componentDidMount() {
    const {
      getFiltersURL,
      staticFilters,
      preKey,
      posKey,
      urlMethod,
      filterInputs,
      getInputsFromStorage,
      pageAlias,
      filterFormVisible,
      seperatePageAliasAndPures, // for seperate staticFilters per page
    } = this.props;
    if (window.addEventListener) window.addEventListener('beforeunload', this.saveFilters);
    this.pageAlias = window.location.pathname;
    const statics = staticFilters
      .filter((filter) => typeof filter !== 'string')
      .map((filter) => ({ ...filter, isStatic: true }));
    const storageInputs = getInputsFromStorage
      ? this.getInputsFromStorage(
          seperatePageAliasAndPures ? this.pageAlias : pageAlias || this.pageAlias
        )
      : [];
    const { pureValues, accumulatedPrimitives } = this.getPureValuesFromStorage(
      pageAlias || this.pageAlias
    );

    const buttonFilters = storageInputs.filter(({ key }) => key === 'product_type');

    if (getFiltersURL) {
      sendRequest({
        url: getFiltersURL,
        method: urlMethod,
        onSuccess: (result) => {
          if (result?.results) {
            const resultList = this.buildFilter(result.results, statics, storageInputs, pureValues);
            this.initFilterValues(resultList);
            this.setState(
              {
                filterFields: this.buildFilterFields(resultList, storageInputs),
                filterInputs: this.buildFilterInputs(resultList, preKey, posKey),
                buttonFilters,
                accumulatedPrimitives,
              },
              () => {
                this.buildSelectedFilters(resultList, pureValues);
                if (!filterFormVisible) {
                  this.onChangeFilters();
                }
              },
            );
          }
        },
      });
    } else {
      const filters = this.buildFilter(filterInputs, statics, storageInputs, pureValues);
      this.initFilterValues(filters);
      this.setState(
        {
          filterFields: this.buildFilterFields(filters, storageInputs),
          filterInputs: this.buildFilterInputs(filters, preKey, posKey),
          buttonFilters,
          accumulatedPrimitives,
        },
        () => this.buildSelectedFilters(filters, pureValues)
      );
    }
  }

  componentWillUnmount() {
    this.saveFilters();
    if (window.removeEventListener) window.removeEventListener('beforeunload', this.saveFilters);
  }

  saveFilters = () => {
    const { getInputsFromStorage, pageAlias } = this.props;
    const { inputFilters, pureValues, buttonFilters, accumulatedPrimitives } = this.state;

    if (getInputsFromStorage)
      this.setInputsToStorage(
        pageAlias || this.pageAlias,
        this.createFilterInputs(),
        inputFilters,
        buttonFilters,
        pureValues,
        accumulatedPrimitives
      );
  };

  buildFilter(inputs = [], statics = [], storageInputs = [], pureValues = {}) {
    const inputList = [...statics, ...inputs];
    return inputList.map((input) => {
      const inStorage = storageInputs.find((filter) => filter.key === input.key);
      const active = inStorage ? true : storageInputs.length === 0 && !!input.active;
      let value =
        inStorage &&
        pureValues[inStorage.key] &&
        deserializeStorageValue(pureValues[inStorage.key].value, pureValues[inStorage.key].type);
      if (value && pureValues[inStorage.key].type === inputTypes.datepicker.alias)
        value = value.key;
      const default_value = inStorage
        ? pureValues[inStorage.key]
          ? value
          : inStorage.value
        : input.default_value;

      return {
        ...input,
        active,
        default_value,
        ...(!input.isStatic
          ? {
              fieldProps: { placeholder: input.name },
              noLabel: input.data_type?.label === 'Boolean',
            }
          : {}),
      };
    });
  }

  getInputsFromStorage(pageAlias) {
    return JSON.parse(readStorageItem(pageAlias)) || [];
  }

  getPureValuesFromStorage(pageAlias) {
    return JSON.parse(readStorageItem(pageAlias.concat(pureKey))) || [];
  }

  setInputsToStorage(
    pageAlias,
    filters = [],
    inputs = {},
    buttonFilters = {},
    pureValues = {},
    accumulatedPrimitives = []
  ) {
    const { seperatePageAliasAndPures } = this.props;
    let data = Object.keys(inputs).map((key) => {
      const filter = filters.find((filter) => filter.key === key);
      const type = filter?.data_type?.value ?? inputTypes.input.alias;
      return { key, value: inputs[key], type };
    });
    let filterData = filters
      .filter((filter) => data.findIndex((item) => item.key === filter.key) === -1)
      .map((item) => ({ ...item, type: item.data_type?.value }));
    writeStorageItem(
      seperatePageAliasAndPures ? this.pageAlias : pageAlias,
      JSON.stringify([...data, ...filterData, ...buttonFilters, accumulatedPrimitives]) || []
    );
    let values = {};

    Object.keys(pureValues).forEach((key) => {
      if (typeof pureValues[key] === 'string') return;
      const filter = filters.find((filter) => filter.key === key);
      const type = filter?.data_type?.value ?? inputTypes.input.alias;
      const value = serializeStorageValue(pureValues[key], type);
      values = { ...values, [key]: { value, type } };
    });
    writeStorageItem(
      pageAlias.concat(pureKey),
      JSON.stringify({ pureValues: values, accumulatedPrimitives })
    );
  }

  buildSelectedFilters = (values, pureValues = {}) => {
    // const pageAlias = this.props.pageAlias || window.location.pathname;
    let filterValues = {};
    values
      .filter((input) => input.active && input.default_value)
      .forEach((item) => {
        filterValues[item.key] = isDefined(pureValues[item.key])
          ? pureValues[item.key].value
          : item.default_value;
      });
    this.onChangeInputFilters(filterValues);
  };

  buildFilterInputs(resultList, preKey, posKey) {
    return resultList.map((input) => ({
      ...input,
      key: input.isStatic ? input.key : preKey + input.key + posKey,
      ...(input.data_type && !input.multiSelect && input.data_type.value === 'dropdown'
        ? { fieldProps: { ...input.fieldProps, labelInValue: false } }
        : {}),
    }));
  }

  buildFilterFields(resultList, defaults) {
    return resultList.map((input, index) => {
      const active =
        defaults.findIndex((filter) => filter.key === input.key) !== -1 ? true : !!input.active;
      return {
        ...input,
        pk: input.pk,
        data_type: { value: inputTypes.checkbox.alias },
        key: input.pk.toString(),
        active,
        noLabel: true,
        name: input.name,
        // fieldProps: {
        //   defaultChecked: active
        //   // text: input.label || (input.fieldProps ? input.fieldProps.text : null)
        // }
        default_value: active,
        columnProps: {
          key: 100 + index,
          dataIndex: input.key,
          title: input.name,
          active: true,
        },
      };
    });
  }

  initFilterValues = (filters) => {
    this.filterValues = {};
    for (const element of filters) {
      if (element.active) this.filterValues[element.pk] = true;
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
    this.onChangeFilters();
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  createFilterInputs = () => {
    const { filterFields, filterInputs } = this.state;
    const activeFilters = filterFields.filter((field) => field.active);
    return filterInputs.filter((input) =>
      activeFilters.find((field) => field && input && field.key === input.pk.toString())
    );
  };

  onChangeFilters = () => {
    const { filterFields } = this.state;
    const { onChangeActiveFilters } = this.props;
    const values = this.filterValues;
    const newFilterFields = filterFields.map((field) => {
      const active = !!values[field.pk];
      const key = field.key.concat('.').concat(getRandom());
      return {
        ...field,
        active,
        fieldProps: {
          ...field.fieldProps,
          key,
        },
        default_value: active,
      };
    });
    this.setState(
      {
        filterFields: newFilterFields,
      },
      () => {
        if (onChangeActiveFilters)
          onChangeActiveFilters(this.state.filterFields.filter((filter) => filter.active));
      }
    );
  };

  onChangeFiltersInputs = (values = {}) => {
    this.filterValues = values;
  };

  onChangeFilter = () => {
    const { buttonFilters, inputFilters, formDataFilters, accumulatedPrimitives } = this.state;
    const buttonFilterObject = {};
    for (const iterator of buttonFilters) {
      if (iterator.active)
        if (!buttonFilterObject[iterator.key]) buttonFilterObject[iterator.key] = [iterator.value];
        else buttonFilterObject[iterator.key].push(iterator.value);
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(
        {
          ...inputFilters,
          ...buttonFilterObject,
          ...accumulatedPrimitives.reduce((acc, current) => {
            const [[key, value]] = getEntries(current);
            return { ...acc, [key]: acc[key] ? [...acc[key], value] : [value] };
          }, {}),
        },
        formDataFilters
      );
    }
  };

  onDeleteAllFilters = () => {
    Promise.all([
      this.onDeselectAllInputs(),
      this.onDeselectAllButtons(),
      this.onDeselectAllFormData(),
      this.onDeselectAllSelectedFields(),
    ]).then(() => {
      this.onChangeFilter();
    });
  };

  findFilterInputPredicate = (key, value) => {
    return (input) =>
      input[key] === value ||
      (input.children ? input.children.findIndex((child) => child[key] === value) !== -1 : false);
  };

  inputFilterMapper = (values) => {
    return Object.keys(values)
      .map((key) => {
        const item = this.state.filterInputs.find(this.findFilterInputPredicate('key', key));
        if (!isDefined(values[key]) || (Array.isArray(values[key]) && !values[key].length))
          return null;
        return {
          name: item.name,
          value: item.children
            ? item.children
                .map((child) =>
                  values[child.key]?.label
                    ? values[child.key].label
                    : Array.isArray(values[child.key])
                    ? this.arrayToString(values[child.key], 'label', ', ')
                    : values[child.key]
                )
                .join(' ')
            : values[key]?.label
            ? values[key].label
            : Array.isArray(values[key])
            ? this.arrayToString(values[key], 'label', ', ')
            : values[key],
        };
      })
      .filter((item) => isDefined(item))
      .filter(function (item, index, self) {
        return self.findIndex((child) => item.name === child.name) === index;
      });
  };

  arrayToString(arr, key, splitter = ',') {
    let result = '';
    if (!arr) return result;
    for (let i = 0; i < arr.length; i++) {
      result += `${arr[i][key]}${i === arr.length - 1 ? '' : splitter}`;
    }
    return result;
  }

  inputFilterValueMapper = (values) => {
    if (!values) return values;
    let result = {};
    Object.keys(values).forEach((key) => {
      let value = null;
      if (!isDefined(values[key]) || (Array.isArray(values[key]) && !values[key].length))
        return value;
      if (Array.isArray(values[key])) value = values[key].map((item) => item.key);
      else if (isDefined(values[key].key)) {
        if (typeof values[key].key === 'object') {
          const keys = Object.keys(values[key].key);
          keys.forEach((subKey) => {
            result = { ...result, [subKey]: values[key].key[subKey] };
          });
          return result;
        } else value = values[key].key;
      } else if (typeof values[key] === 'object') {
        const keys = Object.keys(values[key]);
        keys.forEach((subKey) => {
          result = {
            ...result,
            [subKey]: values[key][subKey]?.key ? values[key][subKey].key : values[key][subKey],
          };
        });
        return result;
      } else value = values[key];
      result = { ...result, [key]: value };
    });
    return result;
  };

  accumulatePrimitives = (values) => {
    const { accumulatedPrimitives: oldValues = [] } = this.state;
    return [
      ...oldValues,
      ...Object.keys(values)
        .filter((formKey) => {
          return !oldValues.some((item) => {
            const [[oldKey, oldValue]] = getEntries(item);
            return formKey === oldKey && values[formKey] === oldValue;
          });
        })
        .map((inputKey) => ({ [inputKey]: values[inputKey] })),
    ];
  };

  entrieNameControl = (item, inputs) => {
    const [[key]] = getEntries(item);
    const findedInput = inputs.find((input) => input.key === key);
    return findedInput?.name !== undefined;
  };

  generatePrimitiveLabels = (primitiveValues, inputs) => {
    return primitiveValues
      .filter((value) => this.entrieNameControl(value, inputs)) // filter by exist names
      .map((item) => {
        const [[key, value]] = getEntries(item);
        const { name } = inputs.find((input) => input.key === key);
        return `${name}: ${value}`;
      });
  };

  onChangeInputFilters = (values) => {
    const { buttonFilters, formDataFilters, filterInputs } = this.state;
    const { multipleKeySearch } = this.props;
    const { complexValues, primitiveValues } = Object.keys(values).reduce(
      (acc, formKey) => {
        const newValues = acc;
        let isComplexValues =
          values[formKey] === 'True' ||
          values[formKey] === 'False' ||
          typeof values[formKey] !== 'string';
        /* Value değeri string olarak kullanılan dropdown türlerini complex olarak ayırması için yapıldı */
        filterInputs
          .filter((input) => input.key === formKey)
          .map((input) => {
            if (input.data_type?.value === inputTypes.select.alias && !input.multiSelect) {
              isComplexValues = true;
            }
            return input;
          });
        const getComplexValues = () => {
          newValues.complexValues = {
            ...acc.complexValues,
            [formKey]: values[formKey],
          };
        };
        if (multipleKeySearch) {
          if (isComplexValues) {
            getComplexValues();
          } else {
            this.formUtils.setFieldsValue({ [formKey]: '' });
            newValues.primitiveValues = {
              ...acc.primitiveValues,
              [formKey]: values[formKey],
            };
          }
        } else {
          getComplexValues();
        }
        return newValues;
      },
      { complexValues: {}, primitiveValues: [] }
    );
    const filters = this.inputFilterMapper(complexValues, filterInputs);
    const accumulatedPrimitives = this.accumulatePrimitives(primitiveValues);
    const primitiveLabels = this.generatePrimitiveLabels(accumulatedPrimitives, filterInputs);
    const selectedFilters = this.getSelectedFilters(buttonFilters, filters, formDataFilters);
    this.getFilter({ ...complexValues, ...primitiveValues });
    const valuesMapped = this.inputFilterValueMapper(complexValues, filterInputs);
    this.setState(
      {
        inputFilters: valuesMapped,
        selectedFilters: [...selectedFilters, ...primitiveLabels],
        pureValues: values,
        accumulatedPrimitives,
        primitiveLabels,
      },
      () => {
        this.onChangeFilter();
      },
    );
  };

  onChangeButtonFilters = (buttonObject, value) => {
    const { filterButtons, inputFilters: inputValues, formDataFilters, filterInputs } = this.state;
    const buttonIndex = filterButtons.findIndex((filter) => filter.id === buttonObject.id);
    const button = filterButtons[buttonIndex];
    const inputFilters = this.inputFilterMapper(inputValues, filterInputs);
    button.active = value;
    const newButtonFilters = filterButtons.filter((button) => button.active);
    const selectedFilters = this.getSelectedFilters(
      newButtonFilters,
      inputFilters,
      formDataFilters
    );
    this.setState(
      {
        buttonFilters: newButtonFilters,
        selectedFilters: [...selectedFilters, ...(this.state.primitiveLabels || [])],
      },
      () => {
        this.onChangeFilter();
      }
    );
  };

  getFilter = (values) => {
    const { getFiltersValue } = this.props;
    if (getFiltersValue) {
      getFiltersValue(values);
    }
  };

  onChangeFileFilter = (data) => {
    const { multipleFile } = this.props;
    const { buttonFilters, inputFilters: inputValues, formDataFilters, filterInputs } = this.state;
    const inputFilters = this.inputFilterMapper(inputValues, filterInputs);
    const newFormDataFilters =
      data && data.length
        ? multipleFile
          ? [
              ...formDataFilters,
              ...data.map((file) => ({
                name: file.name,
                file: file,
                key: file.uid,
              })),
            ]
          : [
              {
                name: data[0].name,
                file: data[0],
                key: data[0].uid,
              },
            ]
        : formDataFilters;
    const selectedFilters = this.getSelectedFilters(
      buttonFilters,
      inputFilters,
      newFormDataFilters
    );
    this.setState(
      {
        formDataFilters: newFormDataFilters,
        selectedFilters,
      },
      () => {
        this.onChangeFilter();
      }
    );
  };

  getSelectLabelWithName(input) {
    const { name, value } = input;
    const { filterInputs } = this.state;

    return filterInputs
      .filter((item) => item.name === name)
      .filter(({ data_type = {} }) => data_type?.value === inputTypes.select.alias)
      .filter((item) => !item.multiSelect)
      .map((item) => {
        const found = item.visible_values.find((item) => item.value === value);
        return found ? found.label : null;
      });
  }

  getSelectedFilters = (buttonFilters, inputFilters, formDataFilters) => {
    return [
      ...buttonFilters.filter((button) => button.active).map((button) => button.name),
      ...inputFilters.map((input) => {
        const selectedLabel = this.getSelectLabelWithName(input);
        const label = selectedLabel?.length
          ? selectedLabel
          : this.getObjectValueToShow(input.value);

        return `${input.name}: ${label}`;
      }),
      ...formDataFilters.map((formData) => formData.name),
    ];
  };

  getObjectValueToShow = (value) => {
    if (!isDefined(value)) return value;
    if (typeof value !== 'object') return value;
    const keys = Object.keys(value);
    let result = [];
    keys.forEach((key) => {
      if (key === 'key') return;
      if (key === 'label' && isDefined(value.label)) result.push(value.label);
      else if (isDefined(value[key])) result.push(this.getObjectValueToShow(value[key]));
    });
    return result.join(' - ');
  };

  onChangeActiveFiltersByUser = (values) => {
    this.setState({
      selectedFilters: values,
    });
  };

  onDeselect = (value) => {
    const {
      filterInputs,
      filterButtons,
      formDataFilters,
      primitiveLabels,
      accumulatedPrimitives,
    } = this.state;
    const inputValues = value && value.split(':');
    const inputValue = inputValues?.length ? inputValues[0] : {};

    this.clearPrimitiveFilterInput(inputValue, accumulatedPrimitives);
    if (primitiveLabels.some((label) => label === value)) {
      this.setState(
        {
          primitiveLabels: primitiveLabels.filter((label) => label !== value),
          accumulatedPrimitives: accumulatedPrimitives.filter((primitiveVal) => {
            const [[key, value]] = getEntries(primitiveVal);
            const { key: inputKey } = filterInputs.find((input) => input.name === inputValue);

            return !(inputKey === key && value === inputValues[1].trim());
          }),
        },
        () => {
          this.onChangeFilter();
        }
      );
      return;
    }

    let clearedInput = filterInputs.findIndex((input) => input.name === inputValue);

    if (clearedInput !== -1) {
      this.onDeselectInput(inputValue);
      return;
    }
    clearedInput = filterButtons.findIndex((button) => button.name === value);
    if (clearedInput !== -1) {
      this.onDeselectButton(value);
      return;
    }
    clearedInput = formDataFilters.findIndex((formData) => formData.name === value);
    if (clearedInput !== -1) {
      this.onDeselectFormData(value);
      return;
    }
  };

  clearPrimitiveFilterInput = (inputValue, accumulatedPrimitives) => {
    let foundKey = null,
      foundLength = 0;
    const { filterInputs, pureValues } = this.state;

    filterInputs.map((input) => {
      if (input.name === inputValue) foundKey = input.key;
      return input;
    });

    accumulatedPrimitives.map((item) => {
      Object.keys(item).map((key) => {
        key === foundKey && foundLength++;
        return key;
      });
      return item;
    });

    if (foundKey && foundLength === 1) {
      pureValues[foundKey] = undefined;

      const clearedInputIndex = filterInputs.findIndex((input) => input.key === foundKey);
      const clearedInput = filterInputs[clearedInputIndex];

      this.setState(
        {
          filterInputs: [
            ...filterInputs.slice(0, clearedInputIndex),
            ...filterInputs.slice(clearedInputIndex + 1, filterInputs.length),
          ],
        },
        () => {
          this.setState({
            filterInputs: [
              ...filterInputs.slice(0, clearedInputIndex),
              clearedInput,
              ...filterInputs.slice(clearedInputIndex + 1, filterInputs.length),
            ],
          });
          this.onChangeFilter();
        }
      );
    }
  };

  onDeselectInput = (value) => {
    const { filterInputs } = this.state;
    const clearedInputIndex = filterInputs.findIndex(this.findFilterInputPredicate('name', value));
    const clearedInput = filterInputs[clearedInputIndex];
    clearedInput.default_value = undefined;
    this.setState(
      {
        filterInputs: [
          ...filterInputs.slice(0, clearedInputIndex),
          ...filterInputs.slice(clearedInputIndex + 1, filterInputs.length),
        ],
      },
      () => {
        this.setState({
          filterInputs: [
            ...filterInputs.slice(0, clearedInputIndex),
            clearedInput,
            ...filterInputs.slice(clearedInputIndex + 1, filterInputs.length),
          ],
        });
        this.onChangeFilter();
      }
    );
  };

  onDeselectAllInputs = () => {
    return new Promise((resolve) => {
      const { filterInputs } = this.state;
      this.setState(
        {
          filterInputs: [],
          inputFilters: {},
          primitiveLabels: [],
          accumulatedPrimitives: [],
        },
        () => {
          this.setState({ filterInputs }, () => {
            resolve();
          });
        }
      );
    });
  };

  onDeselectButton = (value) => {
    const { filterButtons } = this.state;
    const clearedButtonIndex = filterButtons.findIndex((input) => input.name === value);
    filterButtons[clearedButtonIndex].active = false;
    this.setState(
      {
        filterButtons,
      },
      () => {
        this.onChangeFilter();
      }
    );
  };

  onDeselectAllButtons = () => {
    const { filterButtons } = this.state;
    for (const button of filterButtons) {
      button.active = false;
    }
    return new Promise((resolve) => {
      this.setState({ filterButtons, buttonFilters: [] }, () => {
        resolve();
      });
    });
  };

  onDeselectFormData = (value) => {
    this.setState(
      (prevState) => ({
        formDataFilters: [
          ...prevState.formDataFilters.filter((formData) => formData.name !== value),
        ],
      }),
      () => {
        this.onChangeFilter();
      }
    );
  };

  onDeselectAllFormData = () => {
    return new Promise((resolve) => {
      this.setState({ formDataFilters: [] }, () => {
        resolve();
      });
    });
  };

  onDeselectAllSelectedFields = () => {
    return new Promise((resolve) => {
      this.setState({ selectedFilters: [] }, () => {
        resolve();
      });
    });
  };

  productProperties = {
    simple: {
      title: 'simple.product',
      src: simpleProductImage,
    },
    variant: {
      title: 'variant.product',
      src: variantProductImage,
    },
    grouped: {
      title: 'grouped.product',
      src: groupProductImage,
    },
    bundle: {
      title: 'bundle.product',
      src: bundleProductImage,
    },
    misc: {
      title: 'miscellaneous.product',
      src: miscProductImage,
    },
  };

  iconRenderer = (productType) => {
    const { title, src } = this.productProperties[productType];

    return (
      <Tooltip title={T(title)}>
        <Image src={src} />
      </Tooltip>
    );
  };

  createActionButtons = () => {
    const { filterButtons = [] } = this.state;
    if (!filterButtons) return filterButtons;
    const onChange = this.onChangeButtonFilters;
    return filterButtons.map((button) => {
      const onClick = () => {
        onChange(button, !button.active);
      };
      return (
        <CheckButton isChecked={button.active} onClick={onClick} key={button.id}>
          {this.iconRenderer(button.img)}
          {button.label}
        </CheckButton>
      );
    });
  };

  formUtils = null;

  createFilterForm = () => {
    const filterInputs = this.createFilterInputs();
    return (
      <Row gutter={{ xs: 0, sm: 16, md: 16 }} className="ant-col-md-24 filter-wrapper">
        <DynamicForm
          onSubmit={this.onChangeInputFilters}
          onChange={this.onChangeInputFilters}
          inputs={filterInputs}
          labelAlign={labelAligns.left}
          hasSubmitButton={false}
          layoutType={formLayoutTypes.inline}
          getChangeWithEnterPress
          keyValueSelect
          getFormUtils={(formUtils) => {
            this.formUtils = formUtils;
          }}
        />
      </Row>
    );
  };

  render() {
    const {
      title = T('filters'),
      className,
      multipleFile,
      filterViaFile,
      collapsible,
      filterFormVisible,
      subtitle,
    } = this.props;
    const { modalVisible, filterFields, selectedFilters } = this.state;

    const buttons = this.createActionButtons();
    const filterForm = this.createFilterForm();

    return filterFormVisible ? (
      <Block className="filter-box">
        <Row className="ant-col-md-24 filter-wrapper filter-file">
          <Block className="filter-box-head">
            <Block className="filter-box-title">{title}</Block>
            {subtitle && <Block className="filter-box-subtitle">{subtitle}</Block>}
          </Block>
          <Block className="file-filter-box">
            {filterViaFile && (
              <>
                <FileInput multiple={multipleFile} accept=".csv" onChange={this.onChangeFileFilter}>
                  <Tooltip title={T('csv.filter')}>
                    <Button>
                      <Intrinsic className="icon-filter" />
                      {T('CSV')}
                    </Button>
                  </Tooltip>
                </FileInput>
                <FileInput multiple={multipleFile} accept=".xls" onChange={this.onChangeFileFilter}>
                  <Tooltip title={T('xls.filter')}>
                    <Button>
                      <Intrinsic className="icon-filter" />
                      {T('XLS')}
                    </Button>
                  </Tooltip>
                </FileInput>
              </>
            )}
            <Tooltip title={T('filters')}>
              <Button className="settings-button" onClick={this.openModal}> 
                <Intrinsic className="icon-filter" />
              </Button>
            </Tooltip>
          </Block>
        </Row>
        <Row
          gutter={{ xs: 0, sm: 16, md: 16 }}
          className="ant-col-md-24 filter-wrapper filter-options-area"
        >
          {buttons.length > 0 && buttons}
          <Block className="active-filters">
            <Select
              className="ant-col-md-24 ant-col-sm-24 ant-col-xs-24"
              open={false}
              mode="tags"
              onChange={this.onChangeActiveFiltersByUser}
              value={selectedFilters}
              onDeselect={this.onDeselect}
            />
            <Span className="filter-text">{T('active.filters')}</Span>
          </Block>
          <Icon className="filter-clear-button" onClick={this.onDeleteAllFilters} />
        </Row>
        {collapsible ? (
          <Collapse className="collapse-filter" bordered={false} defaultActiveKey={['1']}>
            <Panel header={title} key="1">
              {filterForm}
            </Panel>
          </Collapse>
        ) : (
          filterForm
        )}
        {this.props.extraContent}
        <Modal
          visible={modalVisible}
          onCancel={this.closeModal}
          closable
          title={title}
          className={className}
          centered
          footer={null}
        >
          <Paragraph className="filter-subtitle">{T('filters.subtitle')}</Paragraph>
          <DynamicForm
            onSubmit={this.closeModal}
            onChange={this.onChangeFiltersInputs}
            inputs={filterFields}
            hasSubmitButton={false}
            layoutType={formLayoutTypes.inline}
            labelAlign={labelAligns.left}
          />
        </Modal>
      </Block>
    ) : (
      <React.Fragment>
        <Tooltip title={T('filters')}>
          <Button className="settings-button" onClick={this.openModal}>
            <Intrinsic className="icon-filter" />
          </Button>
        </Tooltip>
        <Modal
          visible={modalVisible}
          onCancel={this.closeModal}
          closable
          title={title}
          className={className}
          centered
          footer={null}
        >
          <Paragraph className="filter-subtitle">{T('filters.subtitle')}</Paragraph>
          <DynamicForm
            onSubmit={this.closeModal}
            onChange={this.onChangeFiltersInputs}
            inputs={filterFields}
            hasSubmitButton={false}
            layoutType={formLayoutTypes.inline}
            labelAlign={labelAligns.left}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

Filter.defaultProps = {
  preKey: '',
  posKey: '',
  multipleFile: false,
  staticFilters: [],
  collapsible: true,
  urlMethod: httpMethods.GET,
  filterButtons: [],
  filterInputs: [],
  className: 'filter-modal',
  getInputsFromStorage: true,
  filterFormVisible: true,
  multipleKeySearch: false,
};

Filter.propTypes = {
  filterButtons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  getFiltersURL: PropTypes.string,
  preKey: PropTypes.string,
  posKey: PropTypes.string,
  multipleFile: PropTypes.bool,
  staticFilters: PropTypes.array,
  collapsible: PropTypes.bool,
  onChange: PropTypes.func,
  //onSubmit: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  filterViaFile: PropTypes.bool,
  urlMethod: PropTypes.string,
  filterInputs: PropTypes.array,
  onChangeActiveFilters: PropTypes.func,
  getInputsFromStorage: PropTypes.bool,
  filterFormVisible: PropTypes.bool,
  getFiltersValue: PropTypes.func,
  extraContent: PropTypes.node,
  multipleKeySearch: PropTypes.bool,
};

export default Filter;
