import React, {Component} from "react";

import {setActionElements, checkAuth} from "@common";
import {navigator} from "@common/navigation";
import { T } from "@utils/languageProvider";

import Label from "@components/Label";
import Button from "@components/Button";
import {DatatableBox} from "@components/Datatable";
import LayoutContentWrapper from "@components/utility/layoutWrapper/index";

function onNavigate(url) {
  navigator.push(url);
}

function arrayFormatter(cell) {
  const lastItem = cell.length - 1;
  return cell.map((item,index) => {
    const cellData = index === lastItem ? item.name : `${item.name}, `;
    return <Label key={index}>{cellData}</Label>
  });
}

class CatalogPermissionList extends Component {

  columns = [
    {
      key: 1,
      dataIndex: "group.name",
      title: T("group.name"),
    },
    {
      key: 2,
      dataIndex: "catalogs",
      title: T("catalog"),
      render: cellData => arrayFormatter(cellData)
    },
  ];

  componentDidMount() {
    checkAuth("catalog-permission-create") && setActionElements([
        <Button type = "secondary"
                icon="icon-arti"
                onClick={() => onNavigate(`/settings/catalog-permission/form`)}>
          {T("add.new.catalog.permission.group")}
        </Button>
    ]);
  }

  componentWillUnmount() {
    setActionElements();
  }

  onRowClick = (row) => {
    onNavigate(`/settings/catalog-permission/form/${row.pk}`);
  };

  render() {
    return (
      <LayoutContentWrapper>
        <DatatableBox
          rowKey="pk"
          columns={this.columns}
          exportable={false}
          url={"/catalog_groups/detailed/"}
          onRowClick={this.onRowClick}
          subtitle={T("catalog.permission.group")}
          locale={{emptyText: T("result.not.found")}}
          paginationText={T("show.variable").format(T("catalog.auth.group"))}
          isAuthorized
          authActionName= "catalog-permission-update"
        />
      </LayoutContentWrapper>
    );
  }
}

export default CatalogPermissionList;