import React, { forwardRef } from "react";
import { LayoutContentWrapper } from "./layoutContentWrapper";
import withLoader from "../../LoadingContainer"

const layoutContentWrapper = forwardRef(({ children,className,...otherProps },ref) => (
  <LayoutContentWrapper
    ref={ref}
    className={
      className != null
        ? `${className} isoLayoutContentWrapper`
        : "isoLayoutContentWrapper"
    }
    {...otherProps}
  >
    {children}
  </LayoutContentWrapper>
));

export default withLoader(layoutContentWrapper);
export const ScrollableContentWrapper = layoutContentWrapper;

