import React, {Component} from "react";
import {connect} from "react-redux";

import {
  getPermissionDetailList,
  getPermissionGroupDetail,
  savePermissionGroupDetail,
  clearSettingState
} from "@resources/actions/setting";
import {setActionElements} from "@common";
import { T } from "@utils/languageProvider";
import { navigator } from "@common/navigation";

import Span from "@components/Span";
import Block from "@components/Block";
import Button from "@components/Button";
import Box from "@components/utility/box/index";
import Intrinsic from "@components/Intrinsic";
import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import Modal from "@components/Modal";
import Input from "@components/UIElements/Input";
import Form from "@components/UIElements/form";


import "./style.scss";

function AllAddAndRemovePermissionsButton(props) {
  const {title, ...restProps} = props
  return <Span {...restProps} className="all-add-remove-button">{title}</Span>
}

class PermissionDetail extends Component {

  state = {
    treeData: [],
    loadTree: true,
    visible: false,
    fepermissions:[]
  };

  formItemLayout = {
    labelCol: {
      xs: { span: 22},
      sm: { span: 22 },
      md: { span: 22 }
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 20 },
    }
  };

  componentDidMount() {
    const {match: {params: {id}}, getPermissionDetailList ,getPermissionGroupDetail} = this.props;
    setActionElements([]);
    let queryObject = {root: 'admin-v2', depth__gt: 0 };
    getPermissionDetailList(id ? {group_pk: id, ...queryObject} : queryObject).then(() => {
      this.treeRenderer()
    });
    id && getPermissionGroupDetail(id).then((result)=>{
      this.setState({fepermissions:result.fepermissions})
    })
  }

  componentWillUnmount() {
    const {clearSettingState} = this.props;
    clearSettingState()
  }

  treeRenderer = () => {
    const {permissionDetailList} = this.props;
    const treeData = permissionDetailList.length > 0 && this.buildTree(permissionDetailList);

    this.setState(
      {
        treeData,
        loadTree: false
      }
    );
  };

  buildTree(tree) {
    const newTree = [];
    tree.forEach(item=>{
      if (item.depth === 2) {
        newTree.push({...item, children:[]});
      } else if (item.depth > 2) {
        newTree.length && newTree[newTree.length-1].children.push(item)
      }
    })
    return newTree;
  }

  onSubmit = e => {
    e.preventDefault();
    const {match: {params: {id} = {}}, form } = this.props;
    const {fepermissions} = this.state;

    form.validateFields((err, values) => {
      const params = {...values, fepermissions, ...id? {_pk : id} : {} }
      if (!err) {
        this.setState({
          formValue : params,
          visible: true
        })
      }
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const {match: {params: {id}},savePermissionGroupDetail} = this.props;
    const {formValue} = this.state;
    savePermissionGroupDetail(id , formValue).then( () =>{
      this.setState({
        visible : false
      })
      navigator.push('/settings/general-permission/')
    })
  };



  permissionItem = (item, selectedView, iconClassName) => {
    return <Block key={item.pk} className="permission-item">
      {item.name}
      <Intrinsic
        onClick={() => this.onClickPermissionPk(item.pk, selectedView)}
        className={iconClassName}
      />
    </Block>;
  }

  permissionListRender = (selectedView) => {
    const { treeData,fepermissions } = this.state;
    const iconClassName = selectedView ? "icon-minus" : "icon-plus";

    return treeData ? treeData
      .filter(parent => parent.children.filter(i => fepermissions.includes(i.pk) === selectedView).length)
      .map((parentItem, index) => {
        const children = parentItem.children;
        return (<Block key={index} className="general-permission-group">
          <Span className="general-permission-group--name">{parentItem.name}</Span>
          {children && children.map(permissionItem=> {
            if(fepermissions.includes(permissionItem.pk) === selectedView ){
              return this.permissionItem(permissionItem, selectedView, iconClassName)
            }
          })}
        </Block>);
    }): <></>;
  }

  onClickPermissionPk = (pk, status) => {
    status ? this.removePermission(pk) : this.addPermission(pk) 
  }

  addPermission=(pk)=>{
    const { treeData, fepermissions } = this.state;
    const fepermissionsArray = [...fepermissions];

    treeData.forEach(parent => {
      parent.children.forEach(child => {
          const newPk = pk || child.pk
          if(!fepermissionsArray.includes(newPk) && (pk ? child.pk === pk : true)  ){
            child.is_visible_for_group = true; 
            fepermissionsArray.push(newPk)
            if(!fepermissionsArray.includes(parent.pk)){
              parent.is_visible_for_group = true;
              fepermissionsArray.push(parent.pk)
            }
          }
      })
    })
    this.setState({fepermissions : fepermissionsArray})
  }

  

  removePermission=(pk)=>{
    const { treeData, fepermissions } = this.state;
    const fepermissionsArray = [...fepermissions]

    treeData.forEach(parent => {
      parent.children.forEach(child => {
          const newPk = pk || child.pk
          if(fepermissionsArray.includes(newPk) && (pk ? child.pk === pk : true)){
            child.is_visible_for_group = false;
            const fepermissionsIndex = fepermissionsArray.indexOf(newPk)
            fepermissionsArray.splice(fepermissionsIndex, 1);
            const parentPermissions = parent.children.filter(i => fepermissionsArray.includes(i.pk) === true).length
            if(!parentPermissions){
              parent.is_visible_for_group = false;
              const fepermissionsIndex = fepermissionsArray.indexOf(parent.pk)
              fepermissionsArray.splice(fepermissionsIndex, 1);
            }
          }
      })
    })
    this.setState({fepermissions:fepermissionsArray})
  }

  permissionDetailListInfo = values=>{
    return (
      <Block className="permission-detail-list-info" >
        <Span>{values}</Span>
      </Block>
    )
  }

  render() {
    const { loadTree, visible} = this.state;
    const {
      permissionDetail,
      match: {params: {id}},
      form: {getFieldDecorator},
    } = this.props;

    return (
      <>
      <LayoutContentWrapper className="permission-detail">
        <Box 
        title={T(id ?"edit.permission.group" : "add.permission.group")}
        subtitle= { T('generalperm.crud.subtitle')}
        className="box-primary form-box"
        >
          <Block className="permission-group">
            <Form className="permission-group--form" onSubmit={this.onSubmit} {...this.formItemLayout}>
              <Form.Item className="form-button">
                <Button key="save" type="primary" htmlType="submit">
                  {T("save.settings").upper()}
                </Button>
              </Form.Item>
              <Form.Item label={T("permission.group.name")}  className="form-input">
                {getFieldDecorator('name',
                  {
                    rules: [{required: true, message: T("permission.group.name")}],
                    initialValue: permissionDetail.name && permissionDetail.name
                  })
                (<Input placeholder={T("enter.variable").format(T("permission.group.name"))}/>)}
              </Form.Item>
              <Form.Item label={ T("permission.group.description")} className="form-input">
                {getFieldDecorator('description',
                  {
                    initialValue: permissionDetail.description && permissionDetail.description
                  })
                (<Input placeholder={T("enter.variable").format(T("permission.group.description"))}/>)}
              </Form.Item>
            </Form>
          </Block>
        </Box>
        <Modal
          visible={visible}
          title={ T("permission.modal.title")}
          className="permission-save-modal"
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              {T("cancel")}
            </Button>,
            <Button key="save" type="primary" onClick={this.handleOk}>
              {T("save.settings")}
            </Button>,
          ]}
        >
          {T("permission.modal.description")}
        </Modal>
      </LayoutContentWrapper>
      <LayoutContentWrapper  className="permission-detail-list">
        <Box 
          title={T("select.permission")} 
          loading={loadTree} className="box-primary form-box" 
          actions={
            <AllAddAndRemovePermissionsButton 
              title={T("add.all")} 
              onClick={()=>this.addPermission()}/>}>
          <Block>
            {
              this.permissionListRender(false).length > 0 
                ? this.permissionListRender(false) 
                : this.permissionDetailListInfo(T("select.permission")) 
            }
          </Block>
        </Box>
        <Box 
          title={T("added.permissions")} 
          loading={loadTree} 
          className="box-primary form-box" 
          actions={
            <AllAddAndRemovePermissionsButton 
              title={T("remove.all")} 
              onClick={()=>this.removePermission()}/>}>
          <Block>
            {
              this.permissionListRender(true).length > 0 
                ? this.permissionListRender(true) 
                : this.permissionDetailListInfo(T("added.permissions")) 
            }
          </Block>
        </Box>
      </LayoutContentWrapper>
      </>
    );
  }
}

const WrappedPermissionDetailForm = Form.create({name: 'permission_group'})(PermissionDetail);

function mapStateToProps(state) {
  return {
    permissionDetail: state.settingReducer.permissionDetail,
    permissionDetailList: state.settingReducer.permissionDetailList
  };
}

export default connect(
  mapStateToProps,
  {
    clearSettingState,
    getPermissionDetailList,
    getPermissionGroupDetail,
    savePermissionGroupDetail
  }
)(WrappedPermissionDetailForm);