import React, { memo } from "react";
import PropTypes from "prop-types";

const Label = memo(({ children, ...otherProps }) => {
  return <label {...otherProps}>{children}</label>;
});

Label.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  htmlFor: PropTypes.string
};

export default Label;
