import React from "react";
import classnames from "classnames";

import "./style.scss";

const BoxWrapper = (props) => {
  const className = classnames(props.className, "boxWrapper");
  return <div {...props} className={className}>{props.children}</div>;
};

const IndexBox = (props) => {
  const className = classnames(props.className, "indexBox");
  return <div {...props} className={className}>{props.children}</div>;
};

export { BoxWrapper , IndexBox };
