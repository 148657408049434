import { T } from '@utils/languageProvider';

export default [
  {
    default: true,
    name: T('sidebar.dashboard'),
  },
  {
    path: '/language-settings',
    name: T('language.settings'),
    description: T('language.settings.desc'),
    alias: 'language-settings',
  },
  {
    path: '/active-user',
    name: T('profile'),
    description: T('profile.edit.desc'),
    alias: 'active-user',
  },
  {
    path: '/dashboard',
    currentPath: '/',
    name: T('sidebar.dashboard'),
    description: T('dashboard.desc'),
    alias: 'dashboard',
  },
  {
    path: '/oms/dashboard',
    name: T('sidebar.dashboard'),
    alias: 'omsDashboard',
  },
  {
    path: '/download',
    name: T('download.1'),
    description: T('download.desc'),
    alias: 'download',
  },
  {
    path: '/products-and-categories',
    name: T('sidebar.product'),
    description: T('products.catalogs.desc'),
    alias: 'products-and-categories',
  },
  {
    path: '/products-and-categories/products/product-pool',
    name: T('product.pool'),
    description: T('product.pool.subtitle'),
    alias: 'products-and-categories/product-pool',
    extraElement: {
      name: T('product.pool'),
    },
  },
  {
    path: '/products-and-categories/products/product-pool/product-form',
    name: T('product.add.edit'),
    description: T('product.pool.subtitle'),
    alias: 'products-and-categories',
    extraElement: {
      name: T('product.pool'),
    },
  },
  {
    path: '/order',
    name: T('orders'),
    description: T('order.orders.desc'),
    alias: 'order',
  },
  {
    path: '/order/orders',
    name: T('orders'),
    description: T('orders.desc').strippedString(),
    alias: 'orders',
  },
  {
    path: '/order/orders/order-history-log',
    name: T('order.logs'),
    alias: 'orderLogs',
  },
  {
    path: '/order/orders/order-detail',
    alias: 'orderDetails',
    extraElement: {
      name: T('sidebar.order.detail'),
    },
  },
  {
    path: '/order/cancellation-plan',
    name: T('cancellation.plan'),
    description: T('cancellation.plan.desc').strippedString(),
    alias: 'cancellationPlan',
  },
  {
    path: '/order/cancellation-requests',
    name: T('sidebar.order1'),
    description: T('cancellation.request.desc').strippedString(),
    alias: 'cancellationRequests',
  },
  {
    path: '/order/transactions',
    name: T('sidebar.order2'),
    description: T('transactions.desc').strippedString(),
    alias: 'transaction',
  },
  {
    path: '/order/omnitron-transactions',
    name: T('cancelled.refunded.transactions'),
    description: T('cancelled.refunded.transactions.subtitle').strippedString(),
    alias: 'omnitronTransaction',
  },
  {
    path: '/products-and-categories/stocks',
    name: T('stock.lists'),
    description: T('stock.detail.desc'),
    alias: 'stocks',
  },
  {
    path: '/products-and-categories/stocks/form',
    name: T('new.add.stock.list'),
    description: T('stock.detail.desc'),
    alias: 'stock-form',
    extraElement: {
      name: T('stock.lists'),
      description: T('stock.detail.desc'),
    },
  },
  {
    path: '/products-and-categories/stocks/stock-detail/:id',
    alias: 'stock-detail',
    extraElement: {
      name: T('stock.lists'),
      description: T('stock.detail.desc'),
    },
  },
  {
    path: '/products-and-categories/product-collections',
    name: T('collections'),
    description: T('collections.subtitle'),
    alias: 'product-collections',
  },
  {
    path: '/products-and-categories/product-collections/form',
    name: T('new.collection.add.edit'),
    description: T('collections.subtitle'),
    alias: 'product-collections-form',
    extraElement: {
      name: T('collections'),
    },
  },
  {
    path: '/products-and-categories/prices',
    name: T('price.lists'),
    description: T('price.detail.desc'),
    alias: 'prices',
  },
  {
    path: '/products-and-categories/prices/form',
    name: T('add.new.price.list'),
    description: T('price.detail.desc'),
    alias: 'priceForm',
    extraElement: {
      name: T('price.lists'),
    },
  },
  {
    path: '/products-and-categories/prices/price-detail/:id',
    description: T('price.detail.desc'),
    alias: 'priceDetail',
    extraElement: {
      name: T('price.lists'),
    },
  },
  {
    path: '/products-and-categories/catalog-feeds',
    name: T('catalog.feeds'),
    description: T('catalog.feeds.desc'),
    alias: 'catalogFeeds',
  },
  {
    path: '/products-and-categories/catalog-feeds/form',
    name: T('catalog.feeds.add.or.edit'),
    description: T('catalog.feeds.desc'),
    alias: 'catalogFeedForm',
    extraElement: {
      name: T('catalog.feeds'),
    },
  },
  {
    path: '/marketing',
    name: T('sidebar.marketing'),
    description: T('marketing.desc'),
    alias: 'marketing',
  },
  {
    path: '/marketing/campaigns',
    name: T('campaigns'),
    description: T('campaigns.subtitle'),
    alias: 'campaigns',
  },
  {
    path: '/marketing/campaigns/form',
    name: T('campaign.detail'),
    description: T('campaigns.subtitle'),
    alias: 'basketOfferDetail',
    extraElement: {
      name: T('campaigns'),
    },
  },
  {
    path: '/marketing/vouchers',
    name: T('vouchers'),
    description: T('vouchers.subtitle'),
    alias: 'vouchers',
  },
  {
    path: '/marketing/vouchers/form',
    name: T('add.voucher'),
    description: T('vouchers.subtitle'),
    alias: 'voucherDetail',
    extraElement: {
      name: T('vouchers'),
    },
  },
  {
    path: '/marketing/bulk-voucher',
    name: T('bulk.vouchers'),
    description: T('bulk.voucher.desc'),
    alias: 'bulkVouchers',
  },
  {
    path: '/marketing/bulk-voucher/form',
    name: T('bulk.voucher.add.edit'),
    description: T('bulk.voucher.desc'),
    alias: 'bulkVoucherDetail',
    extraElement: {
      name: T('bulk.vouchers'),
    },
  },
  {
    path: '/marketing/discounts',
    name: T('discount.codes'),
    description: T('discount.codes.subtitle'),
    alias: 'discounts',
  },
  {
    path: '/marketing/discounts/form',
    name: T('add.discount.code'),
    description: T('discount.codes.subtitle'),
    alias: 'discountDetail',
    extraElement: {
      name: T('discount.codes'),
    },
  },
  {
    path: '/marketing/seo',
    name: T('seo.settings'),
    description: T('seo.desc'),
    alias: 'seo',
  },
  {
    path: '/marketing/seo/form',
    name: T('seo.settings.add.or.edit'),
    description: T('seo.desc'),
    alias: 'seo-form',
    extraElement: {
      name: T('seo.settings'),
    },
  },
  {
    path: '/marketing/url',
    name: T('url.settings'),
    description: T('url.settings.desc').strippedString(),
    alias: 'url',
  },
  {
    path: '/marketing/url/form',
    name: T('url.setting.add.edit'),
    description: T('url.settings.desc').strippedString(),
    alias: 'urlSettings',
    extraElement: {
      name: T('url.settings'),
    },
  },
  {
    path: '/marketing/redirects',
    name: T('url.redirections'),
    description: T('url.redirections.desc').strippedString(),
    alias: 'redirects',
  },
  {
    path: '/marketing/redirects/form',
    name: T('url.redirect.add.edit'),
    description: T('url.redirections.desc').strippedString(),
    alias: 'redirects',
    extraElement: {
      name: T('url.redirections'),
    },
  },
  {
    path: '/marketing/product-configs',
    name: T('product.url.settings'),
    description: T('product.url.settings.1.desc').strippedString(),
    alias: 'urlConfigsProduct',
  },
  {
    path: '/marketing/category-configs',
    name: T('category.url.settings'),
    description: T('category.url.settings.desc').strippedString(),
    alias: 'urlConfigsCategory',
  },
  {
    path: '/oms',
    name: T('sidebar.oms'),
    description: T('akinon.oms.desc'),
    alias: 'oms',
  },
  {
    path: '/oms/location-movement-detail',
    currentPath: '/oms/location-movement',
    name: T('sidebar.oms4'),
    description: T('stocklocation.breadcrumb.desc'),
    alias: 'stockLocationMovements',
  },
  {
    path: '/oms/location-movement',
    name: T('sidebar.oms4'),
    description: T('stocklocation.breadcrumb.desc'),
    alias: 'stockLocationMovementDetail',
  },
  {
    path: '/oms/transfer-location-movement',
    name: T('sidebar.oms5'),
    description: T('transfer.location.desc'),
    alias: 'transferLocationMovementDetail',
  },
  {
    path: '/oms/transfer-location-movement-detail',
    currentPath: '/oms/transfer-location-movement',
    name: T('sidebar.oms5'),
    description: T('transfer.location.desc'),
    alias: 'transferLocationMovement',
  },
  {
    path: '/oms/stock-location-transfer-orders',
    name: T('transfer.location.movement'),
    description: 'Transfer hareketlerini bu sayfada yönetebilirsiniz.',
    alias: 'transferLocationDetail',
  },
  {
    path: '/oms/stock-locations',
    name: T('sidebar.oms6'),
    description: T('stock.locations.desc'),
    alias: 'stockLocations',
  },
  {
    path: '/oms/location-movement-detail/packages',
    name: T('location.movement.detail'),
    description: T('stocklocation.breadcrumb.desc'),
    alias: 'stockLocationDetail',
    extraElement: {
      name: T('sidebar.oms4'),
    },
  },
  {
    path: '/oms/stock-locations/create-stock-location',
    name: T('stock.locations.add.or.edit'),
    description: T('stock.locations.desc'),
    alias: 'createLocation',
    extraElement: {
      name: T('sidebar.oms6'),
    },
  },
  {
    path: '/oms/scenarios',
    name: T('scenarios'),
    description: T('scenarios.desc'),
    alias: 'scenarios',
  },
  {
    path: '/oms/scenarios/distribution',
    name: T('sidebar.oms7'),
    description: T('scenarios.distribution.landing').strippedString(),
    alias: 'distribution_scenarios',
  },
  {
    path: '/oms/scenarios/transfer',
    name: T('sidebar.oms10'),
    description: T('scenarios.transfer.landing').strippedString(),
    alias: 'transfer_scenarios',
  },
  {
    path: '/oms/orders/order-detail',
    name: T('sidebar.order.detail'),
    description: T('sidebar.order.detail.subtitle'),
    alias: 'orderDetail',
  },
  {
    path: '/oms/orders',
    name: T('orders'),
    description: T('sidebar.oms1.desc').strippedString(),
    alias: 'orders',
  },
  {
    path: '/oms/transfer-orders',
    name: T('sidebar.oms3'),
    description: T('transfer.orders.desc'),
    alias: 'transferOrders',
  },
  {
    path: '/oms/scenarios/distribution/scenario-creation',
    currentPath: '/oms/scenarios/distribution/scenario-creation',
    name: T('distribution.scenario.add.or.edit'),
    description: T('scenarios.distribution.landing').strippedString(),
    alias: 'createScenario',
    extraElement: {
      name: T('sidebar.oms7'),
    },
  },
  {
    path: '/oms/scenarios/transfer/scenario-creation',
    currentPath: '/oms/scenarios/transfer/scenario-creation',
    name: T('transfer.scenario.add.or.edit'),
    description: T('scenarios.distribution.landing').strippedString(),
    alias: 'createTransferScenario',
    extraElement: {
      name: T('sidebar.oms10'),
    },
  },
  {
    path: '/oms/package-orders',
    name: T('package.orders'),
    description: T('sidebar.oms2.desc').strippedString(),
    alias: 'packageOrders',
  },
  {
    path: '/oms/package-orders/package-detail',
    name: T('package.detail'),
    description: T('package.detail.desc'),
    alias: 'packageDetail',
  },
  {
    path: '/oms/package-orders/package-webhook',
    name: T('package.webhook'),
    description: T('package.webhook.desc'),
    alias: 'packageWebHook',
  },
  {
    path: '/oms/transfer-orders/transfer-detail',
    name: T('transfer.location.movement'),
    description: T('transfer.location.movement.subtitle'),
    alias: 'transferDetail',
  },
  {
    path: '/oms/transfer-orders/transfer-webhook',
    name: T('transfer.webhook'),
    description: T('transfer.webhook.desc'),
    alias: 'transferWebHook',
  },
  {
    path: '/oms/settings/product-report',
    currentPath: '/oms/settings/stock-report',
    name: T('stock.landing.0'),
    description: T('stock.landing.1').strippedString(),
    alias: 'stockReportDetail',
  },
  {
    path: '/oms/settings',
    name: T('akinon.oms.settings'),
    description: T('akinon.oms.settings.desc'),
    alias: 'omsSettings',
  },
  {
    path: '/oms/settings/cargos',
    name: T('cargo.lists'),
    description: T('cargo.lists.desc'),
    alias: 'cargoList',
  },
  {
    path: '/oms/settings/cargos/cargo-form',
    name: T('cargo.add.or.edit'),
    description: T('cargo.lists.desc'),
    alias: 'cargoDetail',
    extraElement: {
      name: T('cargo.lists'),
    },
  },
  {
    path: '/products-and-categories/product-specifications',
    name: T('product.attributes'),
    description: T('product.attributes.subtitle'),
    alias: 'productSpecs',
  },
  {
    path: '/products-and-categories/specification-sets',
    name: T('specification.sets'),
    description: T('specification.sets.desc'),
    alias: 'productSpecSets',
  },
  {
    path: '/products-and-categories/specification-sets/specification-set',
    name: T('new.specification.set.add.or.edit'),
    description: T('specification.sets.desc'),
    alias: 'productSpecSet',
    extraElement: {
      name: T('specification.sets'),
    },
  },
  {
    path: '/products-and-categories/product-specifications/specification-form',
    name: T('add.property'),
    description: T('product.attributes.subtitle'),
    alias: 'productSpecForm',
    extraElement: {
      name: T('product.attributes'),
    },
  },
  {
    path: '/products-and-categories/catalogs',
    name: T('catalog.list'),
    description: T('catalog.list.desc'),
    alias: 'catalogs',
  },
  {
    path: '/products-and-categories/catalogs/:id/category-tree',
    name: T('category.tree'),
    description: T('category.tree.desc'),
    alias: 'catalogs',
  },
  {
    path: '/products-and-categories/catalogs/:id/price-list',
    name: T('price.list'),
    description: T('price.list.desc'),
    alias: 'catalogPriceList',
  },
  {
    path: '/products-and-categories/catalogs/:id/product-list',
    name: T('product.list'),
    description: T('product.list.desc'),
    alias: 'catalogsProductList',
  },
  {
    path: '/products-and-categories/catalogs/:id/stock-list',
    name: T('stock.list'),
    description: T('stock.list.desc'),
    alias: 'catalogsStockList',
  },
  {
    path: '/products-and-categories/catalogs/:id/check-list',
    name: T('sidebar.catalog-control'),
    description: T('catalog-control.1'),
    alias: 'catalogs',
  },
  {
    path: '/products-and-categories/catalogs/:id/settings',
    name: T('settings'),
    description: T('settings.form.desc'),
    alias: 'catalogSettings',
  },
  {
    path: '/products-and-categories/catalogs/:id/channels',
    name: T('channels'),
    description: T('channels.subtitle'),
    alias: 'catalogChannels',
  },
  {
    path: '/content-management',
    name: T('content.management'),
    description: T('content.management.desc'),
    alias: 'contentManagement',
  },
  {
    path: '/content-management/widgets',
    name: T('widget.management'),
    description: T('widget.desc').strippedString(),
    alias: 'widgets',
  },
  {
    path: '/content-management/widgets/form',
    name: T('widget.detail.title'),
    description: T('widget.management.description'),
    alias: 'widget-detail',
    extraElement: {
      name: T('widget.management'),
    },
  },
  {
    path: '/content-management/widget-schema',
    name: T('widget.schema.management'),
    description: T('widget.schema.management.description').strippedString(),
    alias: 'widgetSchemaList',
  },
  {
    path: '/content-management/widget-schema/form',
    name: T('widget.schema.add.or.edit'),
    description: T('widget.schema.management.description').strippedString(),
    alias: 'widgetSchemaForm',
    extraElement: {
      name: T('widget.schema.management'),
    },
  },
  {
    path: '/content-management/schema-type',
    name: T('sidebar.content2'),
    description: T('model.schema.management.desc'),
    alias: 'modelSchemaList',
  },
  {
    path: '/content-management/schema-type/form',
    name: T('model.schema.add.or.edit'),
    description: T('model.schema.management.desc'),
    alias: 'modelSchemaForm',
    extraElement: {
      name: T('sidebar.content2'),
    },
  },
  {
    path: '/content-management/content-manager-forms',
    name: T('forms'),
    description: T('forms.desc'),
    alias: 'form',
  },
  {
    path: '/content-management/content-manager-forms/content-subject',
    name: T('content.manager.forms'),
    description: T('forms.desc'),
    alias: 'contentSubjectList',
  },
  {
    path: '/content-management/content-manager-forms/content-subject/form',
    name: T('add.or.edit.form'),
    description: T('forms.desc'),
    alias: 'contentSubject',
    extraElement: {
      name: T('forms'),
    },
  },
  {
    path: '/content-management/landing-pages',
    name: T('sidebar.content8'),
    description: T('landing.pages.list.desc'),
    alias: 'landingPagesList',
  },
  {
    path: '/content-management/landing-pages/form',
    name: T('new.landing.page.add.or.edit'),
    description: T('landing.pages.list.desc'),
    alias: 'landingPagesForm',
    extraElement: {
      name: T('sidebar.content8'),
    },
  },
  {
    path: '/content-management/static-pages',
    name: T('sidebar.content5'),
    description: T('static.pages.desc').strippedString(),
    alias: 'staticPages',
  },
  {
    path: '/content-management/static-pages/form',
    name: T('static.page.add.or.edit'),
    description: T('static.pages.desc').strippedString(),
    alias: 'staticPages',
    extraElement: {
      name: T('sidebar.content5'),
    },
  },
  {
    path: '/content-management/special-pages',
    name: T('sidebar.content6'),
    description: T('special.pages.subtitle'),
    alias: 'specialPages',
  },
  {
    path: '/content-management/special-pages/form',
    name: T('special.pages.add'),
    description: T('special.pages.subtitle'),
    alias: 'specialPagesForm',
    extraElement: {
      name: T('sidebar.content6'),
    },
  },
  {
    path: '/content-management/product-collection-widgets',
    name: T('sidebar.content3'),
    description: T('product.collection.widgets.desc'),
    alias: 'productCollectionType',
  },
  {
    path: '/content-management/product-collection-widgets/form',
    name: T('collection.widget.add.or.edit'),
    description: T('product.collection.widgets.desc'),
    alias: 'productCollectionDetail',
    extraElement: {
      name: T('sidebar.content3'),
    },
  },
  {
    path: '/content-management/navigation',
    name: T('navigation.menu'),
    description: T('navigation.settings.description'),
    alias: 'navigationSettings',
  },
  {
    path: '/customers',
    name: T('customers'),
    description: T('customers.desc'),
    alias: 'customers',
  },
  {
    path: '/customers/customer-detail',
    name: T('customers.detail'),
    description: T('customers.detail.desc'),
    alias: 'customerDetail',
  },
  {
    path: '/shop-users',
    name: T('sidebar.allusers'),
    description: T('shop.users.desc'),
    alias: 'shopUsers',
  },
  {
    path: '/shop-users/user-detail',
    name: T('shop.users.detail'),
    description: T('shop.users.detail.desc'),
    alias: 'userDetail',
  },
  {
    path: '/settings',
    name: T('settings'),
    description: T('settings.description'),
    alias: 'settings',
  },
  {
    path: '/settings/channels',
    name: T('channels'),
    description: T('settings.channels.desc'),
    alias: 'settingsChannels',
  },
  {
    path: '/settings/channels/form',
    name: T('channel.detail.title'),
    description: T('channels.desc'),
    alias: 'channelForm',
    extraElement: {
      name: T('channels'),
    },
  },
  {
    path: '/payment-management/pos',
    name: T('post.entegrations'),
    description: T('sidebar.settings2.desc').strippedString(),
    alias: 'posList',
  },
  {
    path: '/payment-management/pos/form',
    name: T('post.entegration.add.edit'),
    description: T('pos.entegrations.desc'),
    alias: 'posDetail',
    extraElement: {
      name: T('post.entegrations'),
    },
  },
  {
    path: '/payment-management/payment-option',
    description: T('payment.settings.desc'),
    name: T('payment.options'),
    alias: 'paymentOption',
  },
  {
    path: '/payment-management/payment-option/form',
    description: T('payment.settings.desc'),
    name: T('payment.option.desc'),
    alias: 'paymentOptionDetail',
    extraElement: {
      name: T('payment.options'),
    },
  },
  {
    path: '/sales-channel-settings/promotion-gateway',
    name: T('loyalty.entegration.settings'),
    description: T('loyalty.integration.desc'),
    alias: 'promotionGateways',
  },
  {
    path: '/sales-channel-settings/promotion-gateway/form',
    name: T('loyalty.integration.add.or.edit'),
    description: T('loyalty.integration.desc'),
    alias: 'promotionGateway',
    extraElement: {
      name: T('loyalty.entegration.settings'),
    },
  },
  {
    path: '/sales-channel-settings/shipping-option',
    name: T('shipping.options'),
    description: T('shipping.options.desc'),
    alias: 'shippingOption',
  },
  {
    path: '/sales-channel-settings/shipping-option/form',
    name: T('shipping.option1'),
    description: T('shipping.options.desc'),
    alias: 'shippingOptionDetail',
    extraElement: {
      name: T('shipping.options'),
    },
  },
  {
    path: '/payment-management/banks',
    name: T('banks'),
    description: T('sidebar.settings3.desc').strippedString(),
    alias: 'banks',
  },
  {
    path: '/payment-management/banks/form',
    name: T('add.edit.bank'),
    description: T('sidebar.settings3.desc').strippedString(),
    alias: 'bankDetail',
    extraElement: {
      name: T('banks'),
    },
  },
  {
    path: '/settings/shipping-company',
    name: T('shipping.company.settings'),
    description: T('shipping.company.settings.desc'),
    alias: 'shippingCompany',
  },
  {
    path: '/settings/shipping-company/form',
    name: T('shipping.company.add.or.edit'),
    description: T('shipping.company.settings.desc'),
    alias: 'shippingCompanyDetail',
    extraElement: {
      name: T('shipping.company.settings'),
    },
  },
  {
    path: '/settings/cancellation-reasons',
    name: T('cancel.and.refund.reasons'),
    description: T('cancel.and.refund.reasons.desc'),
    alias: 'cancellationReasonList',
  },
  {
    path: '/settings/cancellation-reasons/form',
    name: T('cancel.and.refund.add.or.edit'),
    description: T('cancel.and.refund.reasons.desc'),
    alias: 'cancellationReasonDetail',
    extraElement: {
      name: T('cancel.and.refund.reasons'),
    },
  },
  {
    path: '/sales-channel-settings/delivery-option',
    name: T('delivery.options'),
    description: T('delivery.options.desc').strippedString(),
    alias: 'deliveryOptions',
  },
  {
    path: '/sales-channel-settings/delivery-option/form',
    name: T('delivery.options.add.or.edit'),
    description: T('delivery.options.desc').strippedString(),
    alias: 'deliveryOptionDetail',
    extraElement: {
      name: T('delivery.options'),
    },
  },
  {
    path: '/sales-channel-settings/social-app',
    name: T('social.accounts'),
    description: T('social.accounts.desc.1'),
    alias: 'socialApps',
  },
  {
    path: '/sales-channel-settings/social-app/form',
    name: T('add.social.account'),
    description: T('social.accounts.desc.1'),
    alias: 'socialApp',
    extraElement: {
      name: T('social.accounts'),
    },
  },
  {
    path: '/order/cancellation-requests',
    name: T('cancellation.request.1'),
    description: T('cancellation.request.desc'),
    alias: 'cancellationRequests',
  },
  {
    path: '/sales-channel-settings/retail-stock-gateway',
    name: T('retail.stock.setting'),
    description: T('sidebar.settings12.desc').strippedString(),
    alias: 'retailStockGateway',
  },
  {
    path: '/sales-channel-settings/retail-stock-gateway/form',
    name: T('add.edit.retail.stock'),
    description: T('sidebar.settings12.desc').strippedString(),
    alias: 'retailStockGateway',
    extraElement: {
      name: T('retail.stock.setting'),
    },
  },
  {
    path: '/integrations',
    name: T('integrations'),
    description: T('integrations.desc'),
    alias: 'ingtegrations',
  },
  {
    path: '/integrations/mapping',
    name: T('sidebar.integration1'),
    description: T('mapping.desc'),
    alias: 'mapping',
  },
  {
    path: '/integrations/pre-products',
    name: T('pre.products'),
    description: T('pre.product.desc'),
    alias: 'preProducts',
  },
  {
    path: '/integrations/service-logs',
    name: T('service.logs'),
    description: T('service.logs.desc'),
    alias: 'serviceLogs',
  },
  {
    path: '/integrations/cargo-logs',
    name: T('cargo.logs'),
    description: T('cargo.logs.desc').strippedString(),
    alias: 'cargoLogs',
  },
  {
    path: '/integrations/location-mapping-logs',
    name: T('mapping.logs'),
    description: T('mapping.logs.desc'),
    alias: 'locationMappingLogs',
  },
  {
    path: '/integrations/service-logs/order-logs',
    name: T('service.order.logs'),
    description: T('service.order.logs.desc'),
    alias: 'orderLogs',
  },
  {
    path: '/integrations/service-logs/order-logs/:id',
    name: T('log.details'),
    description: T('service.order.logs.desc'),
    alias: 'orderLogDetail',
    extraElement: {
      name: T('service.order.logs'),
    },
  },
  {
    path: '/integrations/service-logs/product-logs',
    name: T('service.product.logs'),
    alias: 'productLogs',
  },
  {
    path: '/integrations/service-logs/product-logs/:id',
    name: T('log.details'),
    alias: 'productLogDetail',
    extraElement: {
      name: T('sservice.product.logs'),
    },
  },
  {
    path: '/integrations/service-logs/sku-logs',
    name: T('service.sku.logs'),
    description: T('service.sku.logs.desc'),
    alias: 'skuLogs',
  },
  {
    path: '/integrations/service-logs/sku-logs/:id',
    name: T('log.details'),
    description: T('service.sku.logs.desc'),
    alias: 'skuLogDetail',
    extraElement: {
      name: T('service.sku.logs'),
    },
  },
  {
    path: '/integrations/service-logs/price-logs',
    name: T('service.price.logs'),
    description: T('service.price.logs.desc'),
    alias: 'priceLogs',
  },
  {
    path: '/integrations/service-logs/price-logs/:id',
    name: T('log.details'),
    description: T('service.price.logs.desc'),
    alias: 'priceLogDetail',
    extraElement: {
      name: T('service.price.logs'),
    },
  },
  {
    path: '/integrations/service-logs/stock-logs',
    name: T('service.stock.logs'),
    description: T('service.stock.logs.desc'),
    alias: 'stockLogs',
  },
  {
    path: '/integrations/service-logs/stock-logs/:id',
    name: T('log.details'),
    description: T('service.stock.logs.desc'),
    alias: 'stockLogDetail',
    extraElement: {
      name: T('service.stock.logs'),
    },
  },
  {
    path: '/sales-channel-settings/loyalty-accounts',
    description: T('loyalty.accounts.desc'),
    name: T('loyalty.accounts'),
    alias: 'loyaltyAccounts',
  },
  {
    path: '/sales-channel-settings/loyalty-accounts/detail',
    name: T('loyalty.accounts.detail'),
    description: T('loyalty.accounts.detail.desc'),
    alias: 'loyaltyAccountsDetail',
  },
  {
    path: '/payment-management/card-types',
    name: T('card.types'),
    description: T('card.types.desc').strippedString(),
    alias: 'cardTypes',
  },
  {
    path: '/payment-management/card-types/form',
    name: T('add.edit.card.types'),
    description: T('card.types.desc').strippedString(),
    alias: 'cardTypeDetail',
    extraElement: {
      name: T('card.types'),
    },
  },
  {
    path: '/sales-channel-settings/facet-configuration',
    name: T('sidebar.settings10'),
    description: T('facet.configuration.desc'),
    alias: 'facetConf',
  },
  {
    path: '/sales-channel-settings/facet-configuration/form',
    name: T('add.edit.product.list.filter.settings'),
    description: T('facet.configuration.desc'),
    alias: 'facetConfDetail',
    extraElement: {
      name: T('sidebar.settings10'),
    },
  },
  {
    path: '/payment-management/card-setting',
    name: T('sidebar.settings5'),
    description: T('card.setting.desc').strippedString(),
    alias: 'cardSettingList',
  },
  {
    path: '/payment-management/card-setting/form',
    name: T('card.setting.breadcrumb'),
    description: T('card.setting.desc').strippedString(),
    alias: 'cardSettingList',
    extraElement: {
      name: T('sidebar.settings5'),
    },
  },
  {
    path: '/content-management/category-node-landing-pages',
    name: T('category.landing.list'),
    description: T('category.pages.desc'),
    alias: 'categoryNodeLandingPagesList',
  },
  {
    path: '/content-management/category-node-landing-pages/form',
    name: T('category.landing.list'),
    description: T('category.pages.desc'),
    alias: 'categoryNodeLandingPagesForm',
  },
  {
    path: '/content-management/retail-stores',
    name: T('stores'),
    description: T('store.desc'),
    alias: 'retailStoresList',
  },
  {
    path: '/content-management/retail-stores/form',
    name: T('store.add.edit'),
    description: T('store.desc'),
    alias: 'retailStoresForm',
    extraElement: {
      name: T('stores'),
    },
  },
  {
    path: '/sales-channel-settings/general-sort-option',
    name: T('general.sorting.options'),
    description: T('settings.sorting.desc').strippedString(),
    alias: 'generalSortOption',
  },
  {
    path: '/sales-channel-settings/general-sort-option/form',
    name: T('general.sort.option.add.or.edit'),
    description: T('settings.sorting.desc').strippedString(),
    alias: 'generalSortOptionDetail',
    extraElement: {
      name: T('general.sorting.options'),
    },
  },
  {
    path: '/settings/sorting-algorithm',
    name: T('sorting.algorithms'),
    description: T('sorting.algorithms.desc'),
    alias: 'sortingAlgorithms',
  },
  {
    path: '/settings/sorting-algorithm/form',
    name: T('add.edit.sorting.algorithms'),
    description: T('sorting.algorithms.desc'),
    alias: 'sortingAlgorithmDetail',
    extraElement: {
      name: T('sorting.algorithms'),
    },
  },
  {
    path: '/oms/settings/stock-location-engines',
    name: T('stock.location.engines'),
    description: T('stock.location.engines.desc'),
    alias: 'stockLocationEngineList',
  },
  {
    path: '/oms/settings/stock-location-engines/form',
    name: T('stock.location.engines.add.or.edit'),
    description: T('stock.location.engines.desc'),
    alias: 'stockLocationEngineDetail',
    extraElement: {
      name: T('stock.location.engines'),
    },
  },
  {
    path: '/payment-management',
    name: T('payment.management'),
    description: T('payment.management.desc'),
    alias: 'paymentManagement',
  },
  {
    path: '/sales-channel-settings',
    name: T('sidebar.channel-settings'),
    description: T('sales.channel.settings.desc'),
    alias: 'SalesChannelSettings',
  },
  {
    path: '/category-management',
    name: T('sidebar.category-management'),
    description: 'Kataloğa bağlı kategori ağacını bu sayfada yönetebilirsiniz.',
    alias: 'CategoryManagement',
  },
  {
    path: '/products',
    name: T('products'),
    description: T('catalog-control.1'),
    alias: 'SalesChannelProducts',
  },
  {
    path: '/settings/users',
    name: T('sidebar.users'),
    description: T('user.management.desc'),
    alias: 'userManagement',
  },
  {
    path: '/settings/users/form',
    name: T('add.edit.user'),
    description: T('sales.channel.settings.users.desc'),
    alias: 'userManagement',
    extraElement: {
      name: T('sidebar.users'),
    },
  },
  {
    path: '/settings/general-permission',
    description: T('permission.group.desc.1'),
    name: T('permission.group'),
    alias: 'permissionList',
  },
  {
    path: '/settings/general-permission/form',
    name: T('add.edit.permission.group'),
    description: T('permission.group.desc.1'),
    alias: 'permissionDetail',
    extraElement: {
      name: T('permission.group'),
    },
  },
  {
    path: '/settings/catalog-permission',
    name: T('catalog.permission.group'),
    description: T('catalog.permission.group.desc'),
    alias: 'catalogPermissionList',
  },
  {
    path: '/settings/catalog-permission/form',
    name: T('add.edit.catalog.permission.group'),
    description: T('add.edit.catalog.permission.group.desc'),
    alias: 'catalogPermissionDetail',
    extraElement: {
      name: T('catalog.permission.group'),
    },
  },
  {
    path: '/settings/channel-permission',
    name: T('channel.permissions.groups'),
    description: T('channel.permissions.groups.desc'),
    alias: 'channelPermissionList',
  },
  {
    path: '/settings/channel-permission/form',
    name: T('add.edit.channel.permissions.groups'),
    description: T('add.edit.channel.permissions.groups.desc'),
    alias: 'channelPermissionDetail',
    extraElement: {
      name: T('channel.permissions.groups'),
    },
  },
  {
    path: '/content-management/google-merchant',
    name: T('google.merchant.feed.adapters'),
    description: T('google.merchant.feed.adapters.desc').strippedString(),
    alias: 'googleMerchantFeedAdaptersList',
    extraElement: {
      name: T('google.merchant.feed.adapters'),
    },
  },
  {
    path: '/content-management/google-merchant/form',
    name: T('google.merchant.adapter.add.or.edit'),
    description: T('google.merchant.feed.adapters.desc').strippedString(),
    alias: 'googleMerchantFeedAdaptersDetail',
    extraElement: {
      name: T('google.merchant.adapter.add.or.edit'),
    },
  },
  {
    path: '/content-management/store-delivery-point',
    name: T('store.delivery.point'),
    description: T('store.delivery.point.desc'),
    alias: 'storeDeliveryPoint',
  },
  {
    path: '/content-management/store-delivery-point/manage-district/:id',
    name: T('store.delivery.point'),
    description: T('manage.district'),
    alias: 'storeDeliveryPointManageDistrict',
  },
  {
    path: '/content-management/store-delivery-hours',
    name: T('store.delivery.hours'),
    description: T('store.delivery.hours.desc'),
    alias: 'storeDeliveryHours',
  },
  {
    path: '/unified',
    name: T('sidebar.unified'),
    description: T('sidebar.unified.desc'),
    alias: 'unifiedLanding',
  },
  {
    path: '/unified/contracts',
    name: T('digital.contracts'),
    description: T('digital.contracts.desc'),
    alias: 'unifiedDigitalContracts',
  },
  {
    path: '/unified/contracts/detail',
    name: T('unified.digital.contract.add.edit'),
    description: T('digital.contracts.desc'),
    alias: 'unifiedDigitalContractDetail',
    extraElement: {
      name: T('digital.contracts'),
    },
  },
  {
    path: '/unified/reconciliations/detail',
    name: T('reconciliation.detail'),
    description: T('reconciliation.detail.desc'),
    alias: 'unifiedReconciliationDetail',
  },
  {
    path: '/unified/reconciliations',
    name: T('unified.reconciliations'),
    description: T('unified.reconciliations.desc'),
    alias: 'unifiedReconciliationList',
  },
  {
    path: '/unified/settings',
    name: T('unified.reconciliations.settings'),
    description: T('unified.reconciliations.settings.desc'),
    alias: 'unifiedReconciliationSettings',
  },
  {
    path: '/unified/settings/detail',
    name: T('unified.reconciliations.setting.add.edit'),
    description: T('unified.reconciliations.settings.desc'),
    alias: 'unifiedReconciliationSettings',
    extraElement: {
      name: T('unified.reconciliations.settings'),
    },
  },
  {
    path: '/unified/items',
    name: T('unified.reconciliations.items'),
    description: T('unified.reconciliations.items.inner.desc'),
    alias: 'unifiedReconciliationItems',
  },
  {
    path: '/oms/settings/channels',
    name: T('channels'),
    description: T('oms.channels.info'),
    alias: 'omsChannels',
  },
  //Fiyat Uygulaması İçin Geliştirilen Kısım
  {
    path: '/price-list-optimization',
    name: 'Fiyat Uygulaması', //T(""),
    description: 'Fiyat Uygulaması Açıklaması', // T(""),
    alias: 'priceListOptimization',
  },
  {
    path: '/price-list-optimization/price-list',
    name: 'Fiyat Uygulaması Listesi', //T(""),
    description: 'Fiyat Uygulaması Listesi Açıklaması', // T(""),
    alias: 'priceListOptimizationList',
  },
  {
    path: '/price-list-optimization/price-detail-list',
    name: 'Fiyat Uygulaması Detay Listesi', //T(""),
    description: 'Fiyat Uygulaması Detay Listesi Açıklaması', // T(""),
    alias: 'priceListOptimizationDetailList',
  },
  // Stock Uygulamasi
  {
    path: '/stock/stock-management',
    name: T('stock.management'),
    description: T('stock.management.desc'),
    alias: 'StockManagement',
  },
  {
    path: '/stock/stock-management/stock-list-rules',
    name: T('stockApp.stock.list.rules'),
    description: T('stockApp.stock.list.rules.desc'),
    alias: 'StockListRulesList',
  },
  {
    path: '/stock/stock-management/stock-list-rules/form',
    name: T('stockApp.add.edit').format(T('stockApp.stock.list.rule')),
    description: T('stockApp.detail.desc').format(
      T('stockApp.stock.list.rule')
    ),
    alias: 'StockListRuleForm',
    extraElement: {
      name: T('stockApp.stock.list.rules'),
    },
  },
  {
    path: '/stock/stock-management/stock-lists',
    name: T('stockApp.stock.lists'),
    description: T('stockApp.stock.lists.desc'),
    alias: 'StockListsList',
  },
  {
    path: '/stock/stock-management/stock-lists/form',
    name: T('stockApp.add.edit').format(T('stockApp.stock.list')),
    description: T('stockApp.detail.desc').format(T('stockApp.stock.list')),
    alias: 'StockListForm',
    extraElement: {
      name: T('stockApp.stock.lists'),
    },
  },
  {
    path: '/stock/stock-management/stock-providers',
    name: T('stockApp.stock.providers'),
    description: T('stockApp.stock.providers.desc'),
    alias: 'StockProvidersList',
  },
  {
    path: '/stock/stock-management/stock-providers/form',
    name: T('stockApp.add.edit').format(T('stockApp.stock.provider')),
    description: T('stockApp.detail.desc').format(T('stockApp.stock.provider')),
    alias: 'StockProviderForm',
    extraElement: {
      name: T('stockApp.stock.providers'),
    },
  },
  {
    path: '/stock/stock-management/stock-locations',
    name: T('stockApp.stock.locations'),
    description: T('stockApp.stock.locations.desc'),
    alias: 'StockLocationsList',
  },
  {
    path: '/stock/stock-management/stock-locations/form',
    name: T('stockApp.add.edit').format(T('stockApp.stock.location')),
    description: T('stockApp.detail.desc').format(T('stockApp.stock.location')),
    alias: 'StockLocationsForm',
    extraElement: {
      name: T('stockApp.stock.locations'),
    },
  },
  {
    path: '/stock/stock-management/stock',
    name: T('stockApp.stock'),
    description: T('stockApp.stock.desc'),
    alias: 'StockList',
  },
  {
    path: '/stock/stock-management/stock/form',
    name: T('stockApp.add.edit').format(T('stockApp.stock')),
    description: T('stockApp.detail.desc').format(T('stockApp.stock')),
    alias: 'StockForm',
    extraElement: {
      name: T('stockApp.stock'),
    },
  },
];
