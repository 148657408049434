import React from 'react';
import { isDefined } from '@utils';
import { T } from '@utils/languageProvider';

export const httpMethods = {
  GET: 'GET',
  POST: 'POST',
  OPTIONS: 'OPTIONS',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const responseTypes = {
  success: 'success',
  fail: 'fail',
};

export const languages = {
  tr: 'tr',
  en: 'en',
};

export const pages = {
  '/': { title: 'Dashboard', description: '' },
  '/profile': { title: 'Profile', description: 'profileDescription' },
};

export const filterTypes = {
  equals: (a, b) => a === b,
  contains: (a, b) =>
    isDefined(a) && isDefined(b)
      ? a.toString().indexOf(b.toString()) !== -1
      : false,
};

export const formLayoutTypes = {
  inline: 'inline',
  block: 'block',
  vertical: 'vertical',
  boxLayout: 'box',
};

export const validationTypes = {
  string: {
    type: 'string',
    message: T('string.validation.message'),
  },
  number: {
    validator(rule, value, callback, source, options) {
      let errors = [];
      if (value && isNaN(value)) {
        errors.push(new Error(T('number.validation.message')));
      }
      callback(errors);
    },
  },
  schema: {
    validator: async (rule, value, callback) => {
      let errors = [];
      try {
        if (value) JSON.parse(value);
      } catch (err) {
        errors.push(new Error(T('schema.validation.error')));
      } finally {
        callback(errors);
      }
    },
  },
  boolean: {
    type: 'boolean',
    message: T('boolean.validation.message'),
  },
  method: {
    type: 'method',
    message: T('method.validation.message'),
  },
  regexp: function (pattern, name) {
    return {
      type: 'enum',
      pattern,
      message: T('regexp.validation.message').format(pattern),
    };
  },
  integer: {
    type: 'integer',
    message: T('integer.validation.message'),
  },
  float: {
    type: 'float',
    message: T('float.validation.message'),
  },
  array: {
    type: 'array',
    message: T('array.validation.message'),
  },
  object: {
    type: 'object',
    message: T('object.validation.message'),
  },
  enum: function (enums, name) {
    return {
      type: 'enum',
      enum: enums,
      message: T('enum.validation.message').format(name),
    };
  },
  date: {
    type: 'date',
    message: T('date.validation.message'),
  },
  url: {
    type: 'url',
    message: T('url.validation.message'),
  },
  hex: {
    type: 'hex',
    message: T('hex.validation.message'),
  },
  email: {
    type: 'email',
    message: T('email.validation.message'),
  },
  required: function (name, type) {
    return {
      required: true,
      message:
        type === 'file'
          ? T('required.file').format(name)
          : T('required.message').format(name),
    };
  },
  min: function (number) {
    return {
      min: number,
      message: T('min.validation.message').format(number),
    };
  },
  max: function (number) {
    return {
      max: number,
      message: T('max.validation.message').format(number),
    };
  },
  size: function (min, max, size) {
    return {
      max: isDefined(max) ? max : null,
      min: isDefined(min) ? min : null,
      len: size,
      message: `The Input should between ${min || '-∞'} - ${max || '∞'}`,
    };
  },
  manual: function (customValidator) {
    return {
      validator(rule, value, callback, source, options) {
        var errors = [];
        errors = customValidator(value, rule);
        callback(errors);
      },
    };
  },
};

export const tagColors = {
  magenta: 'magenta',
  red: 'red',
  volcano: 'volcano',
  orange: 'orange',
  gold: 'gold',
  lime: 'lime',
  green: 'green',
  cyan: 'cyan',
  blue: 'blue',
  geekblue: 'geekblue',
  purple: 'purple',
};

export const packageStatusValues = {
  waiting: { value: '100', label: T('waiting'), id: 107 },
  preparing: { value: '200', label: T('preparing'), id: 108 },
  waiting_transfer_operation: {
    value: '300',
    label: T('waiting.transfer.operation'),
    id: 109,
  },
  ready_to_pack: { value: '400', label: T('ready.to.pack'), id: 110 },
  packed: { value: '500', label: T('packed'), id: 111 },
  waiting_cargo_label: {
    value: '510',
    label: T('waiting.cargo.label'),
    id: 112,
  },
  waiting_invoice_creation: {
    value: '520',
    label: T('waiting.invoice.creation'),
    id: 113,
  },
  ready_for_shipment: { value: '600', label: T('ready.for.shipment'), id: 13 },
  ready_for_customer_pickup: {
    value: '610',
    label: T('ready.for.customer.pickup'),
    id: 115,
  },
  shipped: { value: '700', label: T('shipped'), id: 116 },
  delivery_failed: { value: '710', label: T('delivery.failed'), id: 117 },
  delivered: { value: '800', label: T('delivered'), id: 118 },
  cancelled: { value: '900', label: T('cancelled'), id: 119 },
  defective_products: { value: '910', label: T('defective.products'), id: 120 },
  out_of_stock_products: {
    value: '920',
    label: T('outofstock.products'),
    id: 121,
  },
};

export const labelAligns = {
  left: 'left',
  right: 'right',
};

export const rowSelectionTypes = {
  single: 'radio',
  multiple: 'checkbox',
};

export const days = {
  1: T('monday'),
  2: T('tuesday'),
  3: T('wednesday'),
  4: T('thursday'),
  5: T('friday'),
  6: T('saturday'),
  7: T('sunday'),
};

export const buttonTypes = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  danger: 'danger',
  dashed: 'dashed',
};

export const primaryColors = {
  red: '#f97173',
  green: '#7fb6a1',
  blue: '#89b0f4',
  yellow: '#f8b033',
  gray: '#aaaeb7',
  pink: '#ff90aa',
  purple: '#a278c8',
  orange: '#ffa583',
};

export const secondaryColors = {
  light_red: '#fc9c9e',
  dark_red: '#c6484a',
  light_green: '#92d6bc',
  dark_green: '#508a73',
  light_blue: '#b0ccfc',
  dark_blue: '#6789c5',
  light_yellow: '#f9c97e',
  dark_yellow: '#d8880f',
  light_gray: '#cbd0db',
  dark_gray: '#7b7f8a',
};

export const colors = [
  primaryColors.gray,
  primaryColors.purple,
  primaryColors.yellow,
  secondaryColors.light_green,
  primaryColors.green,
  primaryColors.pink,
  secondaryColors.light_yellow,
  secondaryColors.dark_yellow,
  primaryColors.orange,
  primaryColors.blue,
  secondaryColors.light_red,
  secondaryColors.dark_blue,
  primaryColors.red,
  secondaryColors.dark_gray,
  secondaryColors.dark_red,
  secondaryColors.light_blue,
  secondaryColors.dark_green,
  secondaryColors.light_gray,
];

export const actionCommands = [
  {
    command: 'PKG_STATE_TRANSITION',
    label: T('oms.confirm.package'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'SPLIT_PACKAGE',
    label: T('oms.split.package'),
    reloadData: true,
    selectionRequired: true,
    type: buttonTypes.primary,
  },
  {
    command: 'RENEW_PACKAGE_PLAN',
    label: T('oms.renew.package'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'COMPLETE_PACKAGING',
    label: T('oms.complete.packaging'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'CHANNEL_BASED_COMPLETE_PACKAGING',
    label: T('oms.do.package'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'SHIP_THE_PACKAGE',
    label: T('oms.ship.the.package'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'SHIP_THE_PACKAGE_ADVANCED',
    label: T('oms.ship.the.package'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'TRANSFER_STATE_TRANSITION',
    label: T('oms.cancel.transfer'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'TRANSFER_OUT_OF_STOCK',
    label: T('oms.transfer.out.of.stock'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'RENEW_TRANSFER_ORDER',
    label: T('oms.renew.transfer.order'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'DETECTED_OUT_OF_STOCK_ITEM',
    label: T('oms.detected.out.of.stock.item'),
    reloadData: true,
    selectionRequired: true,
    type: buttonTypes.primary,
  },
  {
    command: 'COMPLETE_PACKAGING_WITHOUT_SHIPMENT',
    label: T('oms.complete.packaging.without.shipment'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'SHIP_THE_PACKAGE_ADVANCED',
    label: T('oms.ship.the.package.advanced'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
  {
    command: 'PKG_DEALLOCATE_RESERVATIONS',
    label: T('package.deallocated.erp'),
    reloadData: true,
    selectionRequired: false,
    type: buttonTypes.primary,
  },
];

export const catalogMenu = [
  { key: 'product-list', value: '0' },
  { key: 'price-list', value: '1' },
  { key: 'stock-list', value: '2' },
  { key: 'category-tree', value: '3' },
  { key: 'check-list', value: '4' },
  { key: 'priority-list', value: '5' },
  { key: 'channels', value: '6' },
  { key: 'settings', value: '7' },
];

export const filterEnum = {
  contains: 'contains',
  startsWith: 'startsWith',
};

export const orderCancelStatuses = [
  {
    pk: '0',
    value: 'waiting',
    label: T('waiting'),
  },
  {
    pk: '1',
    value: 'confirmation_waiting',
    label: T('confirmation.waiting'),
  },
  {
    pk: '2',
    value: 'confirmed',
    label: T('confirmed'),
  },
  {
    pk: '3',
    value: 'approved',
    label: T('approved'),
  },
  {
    pk: '4',
    value: 'rejected',
    label: 'Reddedilmiş',
  },
  {
    pk: '5',
    value: 'waiting_for_payment',
    label: T('waiting.for.payment'),
  },
  {
    pk: '6',
    value: 'manuel_refund_need',
    label: T('manuel.refund.need'),
  },
  {
    pk: '7',
    value: 'completed',
    label: T('completed'),
  },
];

export const orderStatuses = [
  {
    pk: '0',
    value: '50',
    label: T('waits.for.cancel'),
  },
  {
    pk: '1',
    value: '100',
    label: T('cancelled'),
  },
  {
    pk: '2',
    value: '200',
    label: T('waiting'),
  },
  {
    pk: '3',
    value: '300',
    label: T('waits.for.payment'),
  },
  {
    pk: '4',
    value: '350',
    label: T('confirmation.waiting'),
  },
  {
    pk: '5',
    value: '400',
    label: T('approved'),
  },
  {
    pk: '6',
    value: '450',
    label: T('preparing'),
  },
  {
    pk: '7',
    value: '500',
    label: T('shipped'),
  },
  {
    pk: '8',
    value: '520',
    label: T('in.store'),
  },
  {
    pk: '9',
    value: '540',
    label: T('delivery.failed'),
  },
  {
    pk: '10',
    value: '550',
    label: T('delivered'),
  },
  {
    pk: '11',
    value: '600',
    label: T('completed'),
  },
];

export const paymentOptionTypes = [
  {
    pk: '0',
    value: 'credit_card',
    label: T('credit.card'),
  },
  {
    pk: '1',
    value: 'funds_transfer',
    label: T('fund.transfer'),
  },
  {
    pk: '2',
    value: 'pay_on_delivery',
    label: T('pay.on.delivery'),
  },
  {
    pk: '3',
    value: 'bkm_express',
    label: T('bkm.express'),
  },
  {
    pk: '4',
    value: 'loyalty_money',
    label: T('loyalty.money'),
  },
  {
    pk: '5',
    value: 'cash_register',
    label: T('cash.register'),
  },
  {
    pk: '6',
    value: 'gpay',
    label: T('gpay'),
  },
  {
    pk: '7',
    value: 'redirection',
    label: T('redirection'),
  },
  {
    pk: '8',
    value: 'stored_card',
    label: T('stored.card'),
  },
];
export const orderCurrency = [
  {
    pk: '0',
    value: 'try',
    label: 'TL',
  },
  {
    pk: '1',
    value: 'eur',
    label: 'EUR',
  },
  {
    pk: '2',
    value: 'usd',
    label: 'USD',
  },
];
export const orderClientType = [
  {
    pk: '0',
    value: 'default',
    label: T('default.client.type'),
  },
  {
    pk: '1',
    value: 'ios',
    label: T('ios.client.type'),
  },
  {
    pk: '2',
    value: 'android',
    label: T('android.client.type'),
  },
  {
    pk: '3',
    value: 'instore',
    label: T('instore.client.type'),
  },
];

export const cancellationPlanStatuses = [
  {
    pk: '0',
    value: 'completed',
    label: T('cancellation.plan.completed'),
  },
  {
    pk: '1',
    value: 'waiting',
    label: T('waiting'),
  },
  {
    pk: '2',
    value: 'confirmed',
    label: T('confirmed'),
  },
  {
    pk: '3',
    value: 'confirmation_waiting',
    label: T('confirmation.waiting'),
  },
  {
    pk: '4',
    value: 'rejected',
    label: T('rejected'),
  },
  {
    pk: '5',
    value: 'cancelled',
    label: T('cancelled'),
  },
  {
    pk: '6',
    value: 'failed',
    label: T('failed'),
  },
];

export const gender = [
  {
    pk: '1',
    value: 'female',
    label: T('female'),
  },
  {
    pk: '2',
    value: 'male',
    label: T('male'),
  },
];

export const campaignConditionType = [
  {
    pk: '0',
    label: T('total.amount'),
    value: 'amount',
  },
  {
    pk: '1',
    label: T('total.quantity'),
    value: 'quantity',
  },
];

export const campaignBenefitType = [
  {
    pk: '0',
    label: T('amount.discount'),
    value: 'fixed',
  },
  {
    pk: '1',
    label: T('multibuy'),
    value: 'multibuy',
  },
  {
    pk: '2',
    label: T('percentage.discount'),
    value: 'percentage',
  },
  {
    pk: '3',
    label: T('shipping.free'),
    value: 'shipping_free',
  },
  {
    pk: '4',
    label: T('coupon'),
    value: 'coupon',
  },
  {
    pk: '5',
    label: T('voucher'),
    value: 'voucher',
  },
];

export const conditionPriceType = [
  {
    pk: '0',
    label: T('unit.price'),
    value: 'unit_price',
  },
  {
    pk: '1',
    label: T('discount.unit.price'),
    value: 'discount_unit_price',
  },
];

export const conditionConsumeType = [
  {
    pk: '0',
    label: T('consume.type.globally'),
    value: 'globally',
  },
  {
    pk: '1',
    label: T('consume.type.locally'),
    value: 'locally',
  },
  {
    pk: '2',
    label: T('consume.type.whatever'),
    value: 'whatever',
  },
];

export const yesNoOptions = [
  {
    pk: '0',
    value: 'True',
    label: T('yes'),
  },
  {
    pk: '1',
    value: 'False',
    label: T('no'),
  },
];

export const trueFalseOptions = [
  {
    pk: '0',
    value: 'true',
    label: T('yes'),
  },
  {
    pk: '1',
    value: 'false',
    label: T('no'),
  },
];

export const trueFalseBoolOptions = [
  {
    pk: '0',
    value: true,
    label: T('yes'),
  },
  {
    pk: '1',
    value: false,
    label: T('no'),
  },
];

export const activePassiveOptions = [
  {
    pk: '0',
    label: T('active'),
    value: 'active',
  },
  {
    pk: '1',
    label: T('passive'),
    value: 'passive',
  },
];

export const activePassiveStringBoolOptions = [
  {
    pk: '0',
    label: T('active'),
    value: 'True',
  },
  {
    pk: '1',
    label: T('passive'),
    value: 'False',
  },
];

export const activePassiveBoolOptions = [
  {
    pk: '0',
    label: T('active'),
    value: 'true',
  },
  {
    pk: '1',
    label: T('passive'),
    value: 'false',
  },
];

export const activePassiveBooleanOptions = [
  {
    pk: '0',
    label: T('active'),
    value: true,
  },
  {
    pk: '1',
    label: T('passive'),
    value: false,
  },
];

export const existencyOptions = [
  {
    pk: '0',
    value: 'True',
    label: T('exist'),
  },
  {
    pk: '1',
    value: 'False',
    label: T('not.exist'),
  },
];
export const existencyCrossOptions = [
  {
    pk: '0',
    value: 'False',
    label: T('exist'),
  },
  {
    pk: '1',
    value: 'True',
    label: T('not.exist'),
  },
];

export const datatableTitle = T('product.pool');

export const productTypeOptions = [
  {
    pk: '-1',
    value: '-1',
    label: T('product.type.preproduct'),
  },
  {
    pk: '0',
    value: '0',
    label: T('product.type.simple'),
  },
  {
    pk: '1',
    value: '1',
    label: T('product.type.variant'),
  },
  {
    pk: '2',
    value: '2',
    label: T('product.type.bundle'),
  },
  {
    pk: '3',
    value: '3',
    label: T('product.type.grouped'),
  },
  {
    pk: '-2',
    value: '-2',
    label: T('product.type.premiscellaneous'),
  },
  {
    pk: '4',
    value: '4',
    label: T('product.type.miscellaneous'),
  },
];

export const paymentOptions = [
  {
    pk: '0',
    value: 'credit_card',
    label: T('credit.card'),
  },
  {
    pk: '1',
    value: 'funds_transfer',
    label: T('fund.transfer'),
  },
  {
    pk: '2',
    value: 'pay_on_delivery',
    label: T('pay.on.delivery'),
  },
  {
    pk: '3',
    value: 'bkm_express',
    label: T('bkm.express'),
  },
  {
    pk: '4',
    value: 'loyalty_money',
    label: T('loyalty.money'),
  },
  {
    pk: '5',
    value: 'cash_register',
    label: T('cash.register'),
  },
];

export const sessionOfferTypes = [
  {
    pk: '0',
    value: 'giz_eticaret_promotion',
    label: T('giz.e.commerce.promotion'),
  },
  {
    pk: '1',
    value: 'ayakkabidunyasi',
    label: T('ayakkabidunyasi'),
  },
  {
    pk: '2',
    value: 'zubizu',
    label: T('zubizu'),
  },
  {
    pk: '3',
    value: 'adunyasi_loyalty_card',
    label: T('a.dunyasi.loyalty.card'),
  },
  {
    pk: '4',
    value: 'sephora',
    label: T('sephora'),
  },
];

export const cargoCompanies = [
  {
    value: 'aras',
    label: T('aras'),
    pk: '0',
  },
  {
    value: 'ups',
    label: T('ups'),
    pk: '1',
  },
  {
    value: 'ups_intl',
    label: T('ups.intl'),
    pk: '2',
  },
  {
    value: 'horoz',
    label: T('horoz'),
    pk: '3',
  },
  {
    value: 'asil',
    label: T('asil'),
    pk: '4',
  },
  {
    value: 'yurtici',
    label: T('yurtici'),
    pk: '5',
  },
  {
    value: 'mng',
    label: T('mng'),
    pk: '6',
  },
  {
    value: 'hepsi_express',
    label: T('hepsi.express'),
    pk: '7',
  },
  {
    value: 'aramex',
    label: T('aramex'),
    pk: '8',
  },
  {
    value: 'other',
    label: T('others'),
    pk: '9',
  },
  {
    value: 'ismail',
    label: T('ismail'),
    pk: '10',
  },
  {
    value: 'gls',
    label: 'GLS Logistic',
    pk: '11',
  },
  {
    value: 'hoopkapida',
    label: T('hoopkapida'),
    pk: '12',
  },
  {
    value: 'fancourier',
    label: T('fan.courier'),
    pk: '13',
  },
  {
    value: 'tmmexpress',
    label: T('tmm.express'),
    pk: '14',
  },
  {
    value: 'ptt',
    label: T('ptt'),
    pk: '15',
  },
  {
    value: 'surat',
    label: T('surat'),
    pk: '16',
  },
  {
    value: 'b2c_direct',
    label: T('b2c.direct'),
    pk: '17',
  },
  {
    value: 'novaposhta',
    label: T('novaposhta'),
    pk: '18',
  },
  {
    value: 'ay_cargo',
    label: T('ay.kargo'),
    pk: '19',
  },
  {
    value: 'cainiao',
    label: T('cainiao'),
    pk: '20',
  },
  {
    value: 'tyexpress',
    label: T('tyexpress'),
    pk: '21',
  },
  {
    value: 'jetizz',
    label: T('jetizz'),
    pk: '22',
  },
  {
    value: 'acs',
    label: T('acs'),
    pk: '23',
  },
  {
    value: 'packupp',
    label: T('packupp'),
    pk: '24',
  },
  {
    value: 'k_gelsin',
    label: T('k.gelsin'),
    pk: '25',
  },
  {
    value: 'birgunde',
    label: T('birgunde'),
    pk: '26',
  },
  {
    value: 'kargo_ist',
    label: T('kargo.ist'),
    pk: '27',
  },
];

export const cancellationType = [
  {
    pk: '0',
    value: 'cancel',
    label: T('cancel'),
  },
  {
    pk: '1',
    value: 'refund',
    label: T('refund'),
  },
];

export const deliveryOptionTypes = [
  {
    value: 'customer',
    label: T('customer'),
    pk: 'customer',
  },
  {
    value: 'retail_store',
    label: T('click.and.collect.1'),
    pk: 'retail_store',
  },
];

export const facetTypes = [
  {
    pk: '0',
    value: 'terms',
    label: T('facet.type.terms'),
  },
  {
    pk: '1',
    value: 'range',
    label: T('facet.type.range'),
  },
];

export const attributeTypes = [
  {
    pk: '0',
    value: 'global',
    label: T('global'),
  },
  {
    pk: '1',
    value: 'eav',
    label: T('eav'),
  },
];

export const widgetTypes = [
  {
    pk: '0',
    value: 'multi_select',
    label: T('multi.select'),
  },
  {
    pk: '1',
    value: 'category',
    label: T('category'),
  },
  {
    pk: '2',
    value: 'attribute_value',
    label: T('attribute.value'),
  },
  {
    pk: '3',
    value: 'visual_swatch',
    label: T('visual.swatch'),
  },
  {
    pk: '4',
    value: 'nested',
    label: T('nested'),
  },
];

export const cancellationReasons = [
  {
    pk: '0',
    value: '3',
    label: T('cancellation.reason.opt1'),
  },
  {
    pk: '1',
    value: '2',
    label: T('cancellation.reason.opt2'),
  },
  {
    pk: '2',
    value: '4',
    label: T('cancellation.reason.opt3'),
  },
  {
    pk: '3',
    value: '1',
    label: T('cancellation.reason.opt4'),
  },
  {
    pk: '4',
    value: '8',
    label: T('cancellation.reason.opt5'),
  },
  {
    pk: '5',
    value: '9',
    label: T('cancellation.reason.opt6'),
  },
  {
    pk: '6',
    value: '10',
    label: T('cancellation.reason.opt7'),
  },
  {
    pk: '7',
    value: '5',
    label: T('cancellation.reason.opt8'),
  },
];

export const retailStockGatewayTypes = [
  {
    value: 'ayakkabidunyasi',
    label: T('ayakkabidunyasi'),
    pk: '0',
  },
  {
    value: 'kigili',
    label: T('kigili'),
    pk: '1',
  },
  {
    value: 'desa',
    label: T('desa'),
    pk: '2',
  },
  {
    value: 'mns',
    label: T('mns'),
    pk: '3',
  },
  {
    value: 'lacoste',
    label: T('lacoste'),
    pk: '4',
  },
  {
    value: 'nebim',
    label: T('nebim'),
    pk: '5',
  },
  {
    value: 'gap',
    label: T('gap'),
    pk: '6',
  },
  {
    value: 'samsonite',
    label: T('samsonite'),
    pk: '7',
  },
  {
    value: 'mudo',
    label: T('mudo'),
    pk: '8',
  },
  {
    value: 'derimod',
    label: T('derimod'),
    pk: '9',
  },
  {
    value: 'enh',
    label: T('enh'),
    pk: '10',
  },
];

export const orderLogType = [
  {
    pk: '1',
    value: 'order_create',
    label: T('order.logtype.opt1'),
  },
  {
    pk: '2',
    value: 'order_single_status',
    label: T('order.logtype.opt2'),
  },
  {
    pk: '3',
    value: 'order_changed_status',
    label: T('order.logtype.opt3'),
  },
  {
    pk: '4',
    value: 'order_changed_single_status',
    label: T('order.logtype.opt4'),
  },
  {
    pk: '5',
    value: 'order_cancel',
    label: T('order.logtype.opt5'),
  },
];

export const serviceLogPathList = [
  {
    pk: '0',
    path: 'order-logs',
    value: 'order_single_status',
  },
  {
    pk: '1',
    path: 'price-logs',
    value: 'price_single ',
  },
  {
    pk: '2',
    path: 'product-logs',
    value: 'product_single',
  },
  {
    pk: '3',
    path: 'stock-logs',
    value: 'stock_single',
  },
];
export const transactionType = [
  {
    pk: '0',
    value: 'purchase',
    label: T('purchase'),
  },
  {
    pk: '1',
    value: 'purchase_three_d',
    label: T('purchase.3d'),
  },
  {
    pk: '2',
    value: 'void',
    label: T('cancel'),
  },
  {
    pk: '3',
    value: 'redirect',
    label: T('redirect'),
  },
];

export const isSucceeded = [
  {
    pk: '0',
    value: 'True',
    label: T('successful'),
  },
  {
    pk: '1',
    value: 'False',
    label: T('unsuccessful'),
  },
];

export const isSucceededColumn = [
  {
    pk: '0',
    value: true,
    label: T('successful'),
  },
  {
    pk: '1',
    value: false,
    label: T('unsuccessful'),
  },
];

export const isSuperUser = [
  {
    pk: '0',
    value: true,
    label: T('admin.staff'),
  },
  {
    pk: '1',
    value: false,
    label: T('staff'),
  },
];

export const providerOptions = [
  {
    pk: '0',
    value: 'facebook',
    label: T('facebook'),
  },
  {
    pk: '1',
    value: 'google',
    label: T('google'),
  },
  {
    pk: '2',
    value: 'apple',
    label: T('apple'),
  },
  {
    pk: '3',
    value: 'apple_mobile',
    label: T('apple.mobile'),
  },
];
export const stockLogType = [
  {
    pk: '0',
    value: 'stock_single',
    label: T('stock.single'),
  },
  {
    pk: '1',
    value: 'stock_changed',
    label: T('stock.changed'),
  },
  {
    pk: '2',
    value: 'stock_changed_single',
    label: T('stock.changed.single'),
  },
];
export const cardPaymentType = [
  {
    pk: '0',
    value: 'credit',
    label: T('credit.card'),
  },
  {
    pk: '1',
    value: 'debit',
    label: T('debit.card'),
  },
];

export const clientTypes = [
  {
    pk: '0',
    value: 'default',
    label: T('default.client.type'),
  },
  {
    pk: '1',
    value: 'ios',
    label: T('ios.client.type'),
  },
  {
    pk: '2',
    value: 'android',
    label: T('android.client.type'),
  },
  {
    pk: '3',
    value: 'instore',
    label: T('instore.client.type'),
  },
];

export const orderTransactionTypes = [
  {
    pk: '0',
    value: 'purchase',
    label: T('purchase'),
  },
  {
    pk: '1',
    value: 'refund',
    label: T('refunding'),
  },
  {
    pk: '2',
    value: 'cancel',
    label: T('cancel'),
  },
];
export const productLogType = [
  {
    pk: '0',
    value: 'product_single',
    label: T('product.single'),
  },
  {
    pk: '1',
    value: 'product_changed',
    label: T('product.changed'),
  },
  {
    pk: '2',
    value: 'product_changed_single',
    label: T('product.changed.single'),
  },
];
export const successStatusOptions = [
  {
    pk: '0',
    value: '0',
    label: T('unsuccessful'),
  },
  {
    pk: '1',
    value: '1',
    label: T('successful'),
  },
];
export const priceLogType = [
  {
    pk: '0',
    value: 'price_single',
    label: T('price.single'),
  },
  {
    pk: '1',
    value: 'price_changed',
    label: T('price.changed'),
  },
  {
    pk: '3',
    value: 'price_changed_single',
    label: T('price.changed.single'),
  },
];
export const skuLogType = [
  {
    pk: '0',
    value: 'product_single',
    label: T('product.single'),
  },
  {
    pk: '1',
    value: 'product_changed',
    label: T('product.changed'),
  },
  {
    pk: '0',
    value: 'product_changed_single',
    label: T('product.changed.single'),
  },
  {
    pk: '0',
    value: 'price_single',
    label: T('price.single'),
  },
  {
    pk: '0',
    value: 'price_changed',
    label: T('price.changed'),
  },
  {
    pk: '0',
    value: 'price_changed_single',
    label: T('price.changed.single'),
  },
  {
    pk: '0',
    value: 'stock_single',
    label: T('stock.single'),
  },
  {
    pk: '0',
    value: 'stock_changed',
    label: T('stock.changed'),
  },
];

export const storeDays = [
  {
    pk: 0,
    value: 0,
    label: T('monday'),
  },
  {
    pk: 1,
    value: 1,
    label: T('tuesday'),
  },
  {
    pk: 2,
    value: 2,
    label: T('wednesday'),
  },
  {
    pk: 3,
    value: 3,
    label: T('thursday'),
  },
  {
    pk: 4,
    value: 4,
    label: T('friday'),
  },
  {
    pk: 5,
    value: 5,
    label: T('saturday'),
  },
  {
    pk: 6,
    value: 6,
    label: T('sunday'),
  },
];
export const channelTypes = [
  {
    pk: 0,
    value: 'hepsiburada',
    label: T('hepsiburada'),
  },
  {
    pk: 1,
    value: 'gittigidiyor',
    label: T('gittigidiyor'),
  },
  {
    pk: 2,
    value: 'n11',
    label: T('n11'),
  },
  {
    pk: 3,
    value: 'web',
    label: T('web'),
  },
  {
    pk: 4,
    value: 'feed',
    label: T('feed'),
  },
  {
    pk: 5,
    value: 'trendyol',
    label: T('trendyol'),
  },
  {
    pk: 6,
    value: 'morhipo',
    label: T('morhipo'),
  },
  {
    pk: 7,
    value: 'morhipo V2',
    label: T('morhipo.v2'),
  },
  {
    pk: 8,
    value: 'amazon',
    label: T('amazon'),
  },
  {
    pk: 9,
    value: 'lidyana',
    label: T('lidyana'),
  },
  {
    pk: 10,
    value: 'allegro',
    label: T('allegro'),
  },
  {
    pk: 11,
    value: 'unified',
    label: T('unified'),
  },
  {
    pk: 12,
    value: 'aliexpress',
    label: T('aliexpress'),
  },
];

export const priceListOption = [
  {
    pk: '0',
    value: 'true',
    label: T('has.erp.connection'),
  },
  {
    pk: '1',
    value: 'false',
    label: T('erp.connection.no'),
  },
];

export const filterButtons = [
  {
    id: 1,
    name: T('product.type.simple'),
    key: 'product_type',
    value: '0',
    img: 'simple',
  },
  {
    id: 2,
    name: T('product.type.variant'),
    key: 'product_type',
    value: '1',
    img: 'variant',
  },
  {
    id: 3,
    name: T('product.type.grouped'),
    key: 'product_type',
    value: '3',
    img: 'grouped',
  },
  {
    id: 4,
    name: T('product.type.bundle'),
    key: 'product_type',
    value: '2',
    img: 'bundle',
  },
  {
    id: 5,
    name: T('product.type.miscellaneous'),
    key: 'product_type',
    value: '4',
    img: 'misc',
  },
];

export const customerTypes = [
  {
    pk: 0,
    value: 'guest',
    label: T('guest.member'),
  },
];

export const deliveryOption = [
  {
    pk: 0,
    value: 'customer',
    label: T('home.delivery'),
  },
  {
    pk: 1,
    value: 'retail_store',
    label: T('store.delivery'),
  },
];

export const acceptImage = ['.png', '.jpeg', '.jpg', '.bmp'];

export const reconciliationStatuses = [
  {
    value: 'waiting',
  },
  {
    value: 'started',
  },
  {
    value: 'finished',
  },
  {
    value: 'waiting_for_approval',
  },
  {
    value: 'sending_for_approval',
  },
  {
    value: 'waiting_for_modified',
  },
  {
    value: 'approved',
  },
  {
    value: 'completed',
  },
].map(({ value }, pk) => ({
  value,
  label: T('reconciliation.status.' + value),
  pk,
}));

export const googleMerchantAdapterTypes = [
  {
    pk: 0,
    value: 'base',
    label: 'base',
  },
  {
    pk: 1,
    value: 'kigili',
    label: 'kigili',
  },
  {
    pk: 2,
    value: 'mns',
    label: 'mns',
  },
  {
    pk: 3,
    value: 'mudo',
    label: 'mudo',
  },
  {
    pk: 4,
    value: 'gap',
    label: 'gap',
  },
  {
    pk: 5,
    value: 'lacoste',
    label: 'lacoste',
  },
  {
    pk: 6,
    value: 'desa',
    label: 'desa',
  },
  {
    pk: 7,
    value: 'sc_persona_click',
    label: 'sc_persona_click',
  },
  {
    pk: 8,
    value: 'sephora',
    label: 'sephora',
  },
  {
    pk: 9,
    value: 'coco',
    label: 'coco',
  },
  {
    pk: 10,
    value: 'a101',
    label: 'a101',
  },
];

export const outOfStockEnums = ['520', '920', 'out_of_stock'];

export const stockTypeOptions = [
  {
    pk: '0',
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    pk: '1',
    label: 'Standard',
    value: 'standard',
  },
];
