import React from "react";
import Block from "../Block";
import Anchor from "../Anchor";
import H3 from "../H3";
import Image from "../Image";
import { navigator } from "@common/navigation";

import logoImage from "@assets/images/OMNITRON.png";
import logoIcon from "@assets/images/logo_.png"

export default ({ collapsed }) => {
  return (
    <Block className="isoLogoWrapper">
      {collapsed ? (
        <Block>
          <H3>
            <Anchor onClick={navigateToDashboard}>
              <Image className="sidebar-logo--icon" src={logoIcon} />
            </Anchor>
          </H3>
        </Block>
      ) : (
        <H3>
          <Anchor className="logo-wrapper" onClick={navigateToDashboard}>
            <Image className="sidebar-logo--icon" src={logoIcon} />
            <Image className="sidebar-logo--image" src={logoImage} />
          </Anchor>
        </H3>
      )}
    </Block>
  );
};

function navigateToDashboard() {
  navigator.push("/");
}
