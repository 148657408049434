import React, { useEffect } from 'react';

import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';
import { dateFormatter, statusActivePassiveFormatter } from '@utils';
import DatatableWithFilter from '@components/DatatableWithFilter';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { setActionElements } from '@common/index';
import { stockLocationsUrl } from '@constants/serviceUrls';
import Button from '@components/Button';

const StockLocationsList = () => {
  useEffect(() => {
    setActionElements([
      <Button
        key="excelAdd"
        type="secondary"
        icon="icon-arti"
        onClick={() =>
          onNavigate('/stock/stock-management/stock-locations/form')
        }
      >
        {T('stockApp.new.add').format(T('stockApp.stock.location'))}
      </Button>,
    ]);
    return () => setActionElements([]);
  }, []);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'name',
      name: T('stockApp.stock.location'),
      active: true,
      fieldProps: {
        placeholder: T('stockApp.stock.location'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'code',
      name: T('stock.location.code'),
      active: true,
      fieldProps: {
        placeholder: T('stock.location.code'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'created_date__gte',
      name: T('created.date'),
      active: true,
      fieldProps: {
        placeholder: T('created.date'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'modified_date__lte',
      name: T('last.update'),
      active: true,
      fieldProps: {
        placeholder: T('last.update'),
      },
    },
  ].map((filter, index) => ({
    ...filter,
    pk: index + 1,
  }));

  const columns = [
    {
      dataIndex: 'id',
      title: 'id',
      visible: false,
      className: 'hide',
    },
    {
      dataIndex: 'name',
      title: T('stockApp.stock.location'),
    },
    {
      dataIndex: 'code',
      title: T('stock.location.code'),
    },
    {
      dataIndex: 'created_date',
      title: T('created.date'),
      render: (cellData) => dateFormatter(cellData, true),
    },
    {
      dataIndex: 'modified_date',
      title: T('last.update'),
      render: (cellData) => dateFormatter(cellData, true),
    },
  ].map((column, index) => ({
    ...column,
    key: index + 1,
  }));

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  const onRowClick = (row) => {
    onNavigate(`/stock/stock-management/stock-locations/form/${row.id}`);
  };

  return (
    <LayoutContentWrapper className="stock-locations-lists">
      <DatatableWithFilter
        filterProps={{ filterInputs }}
        datatableProps={{
          rowKey: 'id',
          doubleClickable: true,
          exportable: false,
          columns: columns,
          onRowClick: onRowClick,
          subtitle: T('stockApp.stock.locations'),
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(''),
          url: stockLocationsUrl,
        }}
      />
    </LayoutContentWrapper>
  );
};

export default StockLocationsList;
