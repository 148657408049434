import { 
  GET_PACKAGE_ORDERS_SUCCESS,
  GET_PACKAGE_DETAIL,
  CLEAR_STATE_PACKAGE_DETAIL
 } from "../actiontypes";

export const initialState = {
  packageOrders: [],
  packageDetail: {},
  packages: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PACKAGE_ORDERS_SUCCESS:
      const result = action.payload && action.payload.results;
      return {
        ...state,
        packageOrders: result
      };
    case GET_PACKAGE_DETAIL:
      const detailResult = action.payload && action.payload;
      return {
        ...state,
        packageDetail: detailResult
      };
    case CLEAR_STATE_PACKAGE_DETAIL:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
