import React from "react"
import { message as antdMessage } from 'antd';

import Span from "@components/Span";
import Intrinsic from "@components/Intrinsic";

import "./style.scss";

function message (props) {
  const { type, content, ...rest } = props;
  const messageContent = <Span>{ content } <Intrinsic className="icon-close pointer" onClick={()=>antdMessage.destroy()} /></Span>;

  antdMessage[type]({
    duration: 2,
    content: messageContent,
    ...rest
  })
}

export default message;
