import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Input } from 'antd';
import classnames from 'classnames';

import { T } from '@utils/languageProvider';
import { readResponseMessageKey } from '@constants/';
import { isDefinedAndNotEmpty, extractLanguageFromPathname } from '@utils/';
import { logResponseMessages, setAPILanguage, getAPILanguage } from '@common';
import { navigator } from '@common/navigation';
import {
  getOrderDetailByOrderNumberURL,
  getPackageDetailByPackageNumberURL,
  getTransferDetailByTransferNumberURL,
} from '@constants/serviceUrls';
import appActions, {
  handleSearch,
  getAPILanguages,
  toggleRightBar,
  changeReadReceipt,
} from '@resources/actions/app';

import Intrinsic from '../Intrinsic';
import Span from '@components/Span';
import Image from '@components/Image';
import Block from '@components/Block';
import { InputSearch } from '@components/UIElements/Input';
import Select, { SelectOption as Option } from '@components/UIElements/Select';

import TopbarUser from './topbarUser';
import TopbarWrapper from './topbarWrapper';

import { readStorageItem, writeStorageItem } from '@common/storage';
const { Header } = Layout;
const { toggleCollapsed } = appActions;
const InputGroup = Input.Group;

class Topbar extends Component {
  state = {
    selectOption: {},
    openMessageDrawer: false,
    languages: {},
    selectedLanguage: 'tr-tr',
    rightBarCollapsed: true,
    isAnimationStart: false,
  };

  componentDidMount() {
    // const { getAPILanguages } = this.props;
    // getAPILanguages().then(result=>{
    //   const languagesList = result.languages;
    //   const defaultLanguage = result.default_language
    //   this.setState(()=>{
    //     if(!(languagesList[getAPILanguage()])){
    //       navigator.lastChannelLang = defaultLanguage;
    //     }
    //     return {
    //       languages: languagesList,
    //       selectedLanguage: navigator.lastChannelLang
    //     }
    //   })
    // });
    this.handleStartCollapseAnimation();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isInOms !== this.props.isInOms) {
      this.setState({ selectOption: {} });
    }
    if (prevState.rightBarCollapsed && !this.state.rightBarCollapsed) {
      this.setState({ isAnimationStart: false });
      this.props.changeReadReceipt(true);
      writeStorageItem(readResponseMessageKey, JSON.stringify(true));
    }
    if (this.state.rightBarCollapsed && !this.state.isAnimationStart) {
      this.handleStartCollapseAnimation();
    }
  }

  handleStartCollapseAnimation() {
    const responseMessages =
      JSON.parse(readStorageItem('responseMessages')) || [];
    const readResponseMessages =
      JSON.parse(readStorageItem(readResponseMessageKey)) || false;
    if (responseMessages.length > 0 && !readResponseMessages) {
      this.setState({ isAnimationStart: true });
    }
  }

  onSearch = (value) => {
    const { selectOption } = this.state;
    const { handleSearch, isInOms } = this.props;
    let requestUrl, navigateUrl;

    if (isInOms) {
      switch (selectOption.value) {
        case 'Sipariş':
          requestUrl = getOrderDetailByOrderNumberURL;
          navigateUrl = '/oms/orders/order-detail/{0}';
          break;
        case 'Transfer':
          requestUrl = getTransferDetailByTransferNumberURL;
          navigateUrl = '/oms/transfer-orders/transfer-detail/{0}';
          break;
        case 'SKU':
          navigateUrl = `/oms/orders/{0}`;
          break;
        default:
          requestUrl = getPackageDetailByPackageNumberURL;
          navigateUrl = '/oms/package-orders/package-detail/{0}';
      }
    } else {
      switch (selectOption.value) {
        case 'Sipariş':
          navigateUrl = '/order/orders/{0}';
          break;
        case 'Müşteri':
          navigateUrl = '/customers/{0}';
          break;
        default:
          navigateUrl = '/products-and-categories/products/product-pool/{0}';
      }
    }
    if (requestUrl) {
      handleSearch(requestUrl, value)
        .then((result) => {
          if (
            result &&
            result.results.length > 0 &&
            (result.results[0].id || result.results[0].pk)
          ) {
            const url = navigateUrl.replace(
              '{0}',
              result.results[0].id || result.results[0].pk
            );
            this.onNavigate(url);
          } else {
            logResponseMessages({
              type: 'warning',
              message: T('could.not.found.vairable').format(selectOption.value),
            });
          }
        })
        .catch(() => {
          logResponseMessages({
            type: 'warning',
            message: T('could.not.found.vairable').format(selectOption.value),
          });
        });
    } else {
      const url = navigateUrl.replace('{0}', value);
      this.onNavigate(url);
    }
  };

  onNavigate = (url) => {
    navigator.push(url);
  };

  handleChange = (value) => {
    this.setState({
      selectOption: value,
    });
  };

  onChangeToggleRightBar = () => {
    const { toggleRightBar, collapsed, toggleCollapsed, rightBarCollapsed } =
      this.props;
    this.setState({ rightBarCollapsed: !rightBarCollapsed }, () => {
      if (!collapsed && rightBarCollapsed) {
        toggleCollapsed();
        toggleRightBar(true);
      } else {
        toggleRightBar(rightBarCollapsed);
      }
    });
  };

  topbarOptions = [
    {
      type: 'oms',
      title: T('package'),
      value: 'Paket',
      key: 'packageOMS',
    },
    {
      type: 'oms',
      title: T('transfer'),
      value: 'Transfer',
      key: 'transferOMS',
    },
    {
      type: 'oms',
      title: T('order'),
      value: 'Sipariş',
      key: 'orderOMS',
    },
    {
      type: 'oms',
      title: T('sku'),
      value: 'SKU',
      key: 'skuOMS',
    },
    {
      type: 'omnitron',
      title: T('product'),
      value: 'Ürün',
      key: 'product',
    },
    {
      type: 'omnitron',
      title: T('order'),
      value: 'Sipariş',
      key: 'order',
    },
    {
      type: 'omnitron',
      title: T('customer'),
      value: 'Müşteri',
      key: 'customer',
    },
  ];

  onChangeToggleSideBar = () => {
    const { toggleCollapsed, toggleRightBar, rightBarCollapsed } = this.props;
    if (!rightBarCollapsed) {
      toggleRightBar(false);
    }
    toggleCollapsed();
  };

  languageOptionFormatter = (obj) => {
    if (obj) {
      return Object.keys(obj).map((key, index) => ({
        pk: index,
        value: key,
        label: obj[key],
      }));
    }
  };

  onLocaleLanguageChange = (languageId) => {
    setAPILanguage(languageId);
    navigator.channelLanguage = languageId;
    window.location.reload();
  };

  topbarOptionRender = (type) => {
    return this.topbarOptions.map(
      (option) =>
        option.type === type && (
          <Option key={option.key} value={option.value}>
            {option.title}
          </Option>
        )
    );
  };

  renderBrandLogo = () => {
    const brandShortname = window.location.hostname.split('.')[0];
    let image = '';
    try {
      image = require(`@assets/images/brand-logos/${brandShortname}.png`);
    } catch {}
    return image ? <Image src={image} className="brand-logo-image" /> : '';
  };

  render() {
    const { customizedTheme, locale, rightBarCollapsed, isInOms, readReceipt } =
      this.props;
    const { isAnimationStart, selectOption, languages, selectedLanguage } =
      this.state;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: rightBarCollapsed ? '100%' : 'calc(100% - 300px)',
      height: 70,
    };
    const languageOptions = this.languageOptionFormatter(
      isDefinedAndNotEmpty(languages) && languages
    );
    const defaultLanguage = selectedLanguage;
    const option = this.topbarOptionRender(isInOms ? 'oms' : 'omnitron');
    const selectDefaultValue = isInOms
      ? { value: 'Paket', children: T('package') }
      : { value: 'Ürün', children: T('product') };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={classnames('isomorphicTopbar', { collapsed })}
        >
          <Block className="isoLeft" onClick={this.onChangeToggleSideBar}>
            <Intrinsic
              className="icon-outdent"
              style={{ color: customizedTheme.textColor, fontSize: 16 }}
            />
          </Block>
          <Block className="isoBrandLogo">{this.renderBrandLogo()}</Block>
          {/* <Block className="isoCenter topbar-center" key={isInOms}>
            <InputGroup compact>
              <Select
                defaultValue={selectDefaultValue.value}
                className="page-type-select"
                onChange={(_value, selectedOption) => this.handleChange(selectedOption.props)}
              >
                {option}
              </Select>
              <InputSearch
                id="InputTopbarSearch"
                className="page-type-search"
                onBlur={this.props.onBlur}
                placeholder={T('enter.number.variable').format( selectOption.children || selectDefaultValue.children)}
                onSearch={value => this.onSearch(value)}
                size="default"
              />
            </InputGroup>
          </Block> */}
          <Block className="isoRight topbar-right">
            {/* {navigator.lastChannelLang && languageOptions && (
              <Block className="locales">
                <Span className="locales--text">{T('topbar.locales')}</Span>
                <Select
                  key={selectedLanguage}
                  className="locales--select"
                  onChange={(languageId) =>
                    this.onLocaleLanguageChange(languageId)
                  }
                  defaultValue={defaultLanguage}
                >
                  {languageOptions.map((item) => {
                    return (
                      <Option key={item.pk} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Block>
            )} */}
            <Block
              onClick={() => this.setState({ selectedItem: 'user' })}
              className="isoUser"
            >
              <TopbarUser locale={locale} />
            </Block>
            <Block className="icon-outdent-rotated topbar--toggleBar">
              <Intrinsic
                className={classnames([
                  'icon-outdent',
                  { 'icon-outdent-animation': isAnimationStart },
                ])}
                style={{ color: customizedTheme.textColor, fontSize: 16 }}
                onClick={this.onChangeToggleRightBar}
              />
            </Block>
          </Block>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.appReducer,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    rightBarCollapsed: state.appReducer.rightBarCollapsed,
    isInOms: state.omsReducer.isInOms,
    readReceipt: state.appReducer.readReceipt,
  }),
  {
    toggleCollapsed,
    changeReadReceipt,
    handleSearch,
    toggleRightBar,
    getAPILanguages,
  }
)(Topbar);
