import {
  GET_UNIFIED_SOURCES,
  GET_UNIFIED_SOURCE_DETAIL,
  RESET_UNIFIED_SOURCE_FORM,
  GET_UNIFIED_RECONCILIATION_DETAIL,
  GET_RECONCILIATIONS
} from "../actiontypes";

const initialState = {
  dataSources: [],
  detailForm: null,
  reconciliationDetail: null,
  reconciliations: []
};

export default function (state = initialState, action) {
  const result = action.payload;

  switch (action.type) {
    case GET_UNIFIED_SOURCES:
      return { ...state, dataSources: result };
    case GET_UNIFIED_SOURCE_DETAIL:
      return { ...state, detailForm: result };
    case GET_UNIFIED_RECONCILIATION_DETAIL:
      return { ...state, reconciliationDetail: result };
    case GET_RECONCILIATIONS:
      return { ...state, reconciliations: result };
    case RESET_UNIFIED_SOURCE_FORM:
      return initialState;
    default:
      return state;
  }
}
