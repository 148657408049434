import React, { PureComponent } from "react";
import { Col, Row } from "antd";

import Input from "../UIElements/Input";
import Select, { SelectOption as Option } from "../UIElements/Select";

class InputWithInputSelect extends PureComponent {
  state = {};
  createOptions(options) {
    if (!options || !options.length) return options;
    return options.map(option => (
      <Option key={option.key} value={option.value}>
        {option.label}
      </Option>
    ));
  }
  onKeyPress = (e, keyIndex) => {
    const { keys, onChange } = this.props;
    if (e.key === "Enter")
      this.setState(
        {
          [keys[keyIndex]]: e.target.value
        },
        () => {
          if (onChange)
            onChange({
              [keys[0]]: this.state[keys[0]],
              [keys[1]]: this.state[keys[1]],
              [keys[2]]: this.state[keys[2]]
            });
        }
      );
  };
  onChangeSelect = value => {
    const { keys, onChange } = this.props;
    this.setState(
      {
        [keys[2]]: value
      },
      () => {
        if (onChange)
          onChange({
            [keys[0]]: this.state[keys[0]],
            [keys[1]]: this.state[keys[1]],
            [keys[2]]: this.state[keys[2]]
          });
      }
    );
  };
  render() {
    const {
      options,
      inputProps: { seperator }
    } = this.props;
    return (
      <>
        <Row gutter={10}>
          <Col span={seperator ? 11 : 12}>
            <Input onKeyPress={e => this.onKeyPress(e, 0)} />
          </Col>
          {seperator && <Col span={2}>{seperator}</Col>}
          <Col span={seperator ? 11 : 12}>
            <Input onKeyPress={e => this.onKeyPress(e, 1)} />
          </Col>
        </Row>
        <Select labelInValue onChange={this.onChangeSelect}>
          {this.createOptions(options)}
        </Select>
      </>
    );
  }
}
export default InputWithInputSelect;
