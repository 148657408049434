import { GET_MAPPING_ATTRIBUTES, GET_PRE_ATTRIBUTES } from "../actiontypes";

const initialState = {
  attributes: [],
  preAttributes: []
};

export default function (state = initialState, action) {
  const result = action.payload;

  switch (action.type) {
    case GET_MAPPING_ATTRIBUTES:
      return { ...state, attributes: result };
    case GET_PRE_ATTRIBUTES:
      return { ...state, preAttributes: result };
    default:
      return state;
  }
}
