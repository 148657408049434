import {
  CLEAR_STATE_COLLECTION,
  GET_COLLECTION,
} from "../actiontypes";

export const initialState = {
  collection : {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COLLECTION:
      const collectionResult = action.payload && action.payload;
      return {
        ...state,
        collection: collectionResult
      };
    case CLEAR_STATE_COLLECTION:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
