import React, {Component} from "react";
import {connect} from "react-redux";

import {
  permissionGroupURL,
} from "@constants/serviceUrls";
import {
  deletePermissionGroup,
} from "@resources/actions/setting";
import {setActionElements, checkAuth} from "@common";
import {navigator} from "@common/navigation";
import {httpMethods} from "@constants/commontypes";
import { T } from "@utils/languageProvider";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import Button from "@components/Button";
import {DatatableBox} from "@components/Datatable";

function onNavigate(url) {
  navigator.push(url);
}

class PermissionList extends Component {

  columns = [
    {
      key: 1,
      dataIndex: "name",
      title: T("group.name")
    },
    {
      key: 2,
      dataIndex: "description",
      title: T("description"),
    },
  ];

  actionButton = [
    checkAuth("permission-group-remove") &&
    {
      id: 0,
      loop: true,
      confirm: true,
      reloadData: true,
      url: permissionGroupURL + "{0}",
      selectionKey: "pk",
      selectionRequired: true,
      httpType: httpMethods.DELETE,
      successMessage: T("delete.success").format(T("selected.permission.group")),
      label: T("selected.permission.group.remove"),
      title: T("delete.selected.permissions"),
      content: T("permissions.list1")
    },
  ];

  rowSelection = {};

  componentDidMount() {
    checkAuth("general-permission-create") && setActionElements([
        <Button type = "secondary"
                icon="icon-arti"
                onClick={() => onNavigate(`/settings/general-permission/form`)}>
          {T("add.new.permission.group")}
        </Button>
    ]);
  }

  componentWillUnmount() {
    setActionElements();
  }

  onRowClick = (row) => {
    onNavigate(`/settings/general-permission/form/${row.pk}`);
  };

  render() {
    return (
      <LayoutContentWrapper>
        <DatatableBox
          exportable = {false}
          rowKey="pk"
          columns={this.columns}
          url={permissionGroupURL}
          onRowClick={this.onRowClick}
          rowSelection={this.rowSelection}
          actionButtons={this.actionButton}
          subtitle={T("permission.group")}
          locale={{emptyText: T("result.not.found")}}
          isAuthorized={true}
          authActionName="general-permission-update"
          paginationText={T("show.variable").format(T("permission.group.user"))}
        />
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,{
    deletePermissionGroup
  }
)(PermissionList);