import { sendRequest } from '@common/network';
import { httpMethods } from '@constants/commontypes';
import {
  GET_STOCK_LIST_DETAIL_SUCCESS,
  GET_STOCK_LIST_RULES_SUCCESS,
  GET_STOCK_LOCATION_DETAIL_SUCCESS,
  GET_STOCK_DETAIL_SUCCESS,
  GET_STOCK_PROVIDERS_SUCCESS,
  CLEAR_STOCK_DETAIL,
} from '../actiontypes';
import {
  stockListsURL,
  stockListRulesURL,
  providersURL,
  stockLocationsUrl,
  stocksUrl,
} from '@constants/serviceUrls';
import { createURL } from '@utils/';
import { T } from '@utils/languageProvider';

export function getStockListDetail(id) {
  return async () => {
    return sendRequest({
      url: id ? createURL(stockListsURL, id) : stockListsURL,
      onSuccess: (result) => {
        return {
          type: GET_STOCK_LIST_DETAIL_SUCCESS,
          payload: result,
        };
      },
    });
  };
}
export function saveStockList(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(stockListsURL, id) : stockListsURL,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('store'))
        : T('add.success').format(T('store')),
      params,
    });
  };
}
export function getStockListRules(id) {
  return async () => {
    return sendRequest({
      url: id ? createURL(stockListRulesURL, id) : stockListRulesURL,
      params: {
        limit: 1000,
      },
      onSuccess: (result) => {
        return {
          type: GET_STOCK_LIST_RULES_SUCCESS,
          payload: id ? result : result.results,
        };
      },
    });
  };
}
export function saveStockListRules(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(stockListRulesURL, id) : stockListRulesURL,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('new.rule'))
        : T('add.success').format(T('new.rule')),
      params,
    });
  };
}
export function getStockProviders(id) {
  return async () => {
    return sendRequest({
      url: id ? createURL(providersURL, id) : providersURL,
      onSuccess: (result) => {
        return {
          type: GET_STOCK_PROVIDERS_SUCCESS,
          payload: id ? result : result.results,
        };
      },
    });
  };
}
export function saveStockProviders(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(providersURL, id) : providersURL,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('stockApp.stock.list'))
        : T('add.success').format(T('stockApp.stock.list')),
      params,
    });
  };
}
export function getStockLocationDetail(id) {
  return async () => {
    return sendRequest({
      url: createURL(stockLocationsUrl, [id]),
      onSuccess: (result) => {
        return {
          type: GET_STOCK_LOCATION_DETAIL_SUCCESS,
          payload: result,
        };
      },
    });
  };
}
export function saveStockLocation(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(stockLocationsUrl, [id]) : stockLocationsUrl,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('stockApp.stock.location'))
        : T('add.success').format(T('stockApp.stock.location')),
      params,
    });
  };
}
export function getStockDetail(id) {
  return async () => {
    return sendRequest({
      url: createURL(stocksUrl, [id]),
      onSuccess: (result) => {
        return {
          type: GET_STOCK_DETAIL_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function clearStockDetail() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_STOCK_DETAIL,
    });
  };
}

export function saveStock(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(stocksUrl, [id]) : stocksUrl,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('stock'))
        : T('add.success').format(T('stock')),
      params,
    });
  };
}
