import React, {Component} from "react";
import {connect} from "react-redux";

import {
  savePermissionGroup,
  getCatalogGroupDetail,
  saveCatalogPermissionGroup,
  clearSettingState
} from "@resources/actions/setting";
import {setActionElements} from "@common";
import {catalogURL} from "@constants/serviceUrls";
import {inputTypes} from "@components/DynamicForm/constants";
import {formLayoutTypes, validationTypes} from "@constants/commontypes";
import { T } from "@utils/languageProvider";
import { navigator } from "@common/navigation";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import { DynamicFormWithBox } from "@components/DynamicForm";


const DynamicForm = DynamicFormWithBox("catalog-permission");

class CatalogPermissionDetail extends Component {

  componentDidMount() {
    const {match: {params: {id}}, getCatalogGroupDetail} = this.props;
    setActionElements([]);
    id && getCatalogGroupDetail(id);
  }

  componentWillUnmount() {
    const {clearSettingState} = this.props;
    clearSettingState()
  }

  catalogMapper = (catalog) =>{
   return catalog.map(item => (item.pk.toString()))
  };

  getAttributeInputs = () => {
    const {
      catalogGroupDetail
    } = this.props;
    return [
      {
        pk: 1,
        name: T("group.name"),
        key: "name",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("group.name"))]
        },
        default_value: catalogGroupDetail.group && catalogGroupDetail.group.name && catalogGroupDetail.group.name,
        fieldProps: {
          placeholder: T("enter.variable").format(T("group.name"))
        }
      },
      {
        pk: 2,
        name: T("catalog.permission"),
        key: "catalogs",
        data_type: {value: inputTypes.remoteselect.alias},
        active: true,
        url: catalogURL,
        objectKey: "results",
        valueKeyName: "pk",
        labelKeyName: "name",
        multiSelect: true,
        fieldOptions: {
          rules: [validationTypes.required("catalogs")]
        },
        default_value: catalogGroupDetail.catalogs && this.catalogMapper(catalogGroupDetail.catalogs),
        fieldProps: {
          placeholder: T("pick.catalog")
        }
      }
    ];
  };


  onSubmit = (value, values) => {
    const {
      match: {params: {id}},
      savePermissionGroup,
      catalogGroupDetail,
      saveCatalogPermissionGroup
    } = this.props;

    const groupPk = catalogGroupDetail && catalogGroupDetail.group && catalogGroupDetail.group.pk;

    const groupParams = id ? values : {name : values.name} ;

    savePermissionGroup(groupPk , groupParams).then( result =>{

      const catalogParams = {
        catalogs : values.catalogs,
        group : groupPk ? groupPk : result.pk
      };
      saveCatalogPermissionGroup(id , catalogParams)
      .then(() => navigator.push('/settings/catalog-permission/'))
    });
  };

  render() {
    const {
      match: {params: {id}},
    } = this.props;

    const formInputs = this.getAttributeInputs();

    return (
      <LayoutContentWrapper>
          <DynamicForm
            title={T(id ?"edit.catalog.group" : "add.catalog.group")}
            subtitle= {T('catalogperm.crud.subtitle')}
            inputs={formInputs}
            onSubmit={this.onSubmit}
            objectKey="visible_values"
            layoutType={formLayoutTypes.boxLayout}
          />
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    catalogGroupDetail: state.settingReducer.catalogGroupDetail
  };
}

export default connect(
  mapStateToProps,
  {
    clearSettingState,
    savePermissionGroup,
    getCatalogGroupDetail,
    saveCatalogPermissionGroup
  }
)(CatalogPermissionDetail);