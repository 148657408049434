import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AutoComplete, { AutoCompleteOption } from "../UIElements/AutoComplete";
import { sendRequest } from "@common/network";
import { createURL } from "@utils";
import { getObjectFromString } from "@utils";
import "./style.scss";

class AutoCompleteContainer extends PureComponent {
  state = {
    dataSource: [],
    loading: false
  };

  handleSearch = value => {
    const { url, objectKey, related, relatedKey, labelKeyName } = this.props;
    const relatedValue = typeof related === "object" ? related.key : related;
    sendRequest({
      params: relatedValue ? { [relatedKey]: relatedValue } : {},
      url: createURL(url, [], { [labelKeyName]: value }),
      onBegin: () => this.setState({ loading: true, dataSource: [] }),
      onSuccess: result =>
        this.setState({
          dataSource: getObjectFromString(objectKey, result)
          // dataSource: getObjectFromString(objectKey, result).filter(
          //   item => item[valueKeyName].indexOf(value) !== -1
          // )
        }),
      onFinally: () => this.setState({ loading: false })
    });
  };

  createAutoCompleteOptions = () => {
    const { dataSource } = this.state;
    const { keyName, valueKeyName, labelKeyName } = this.props;
    return dataSource.map((item, index) => (
      <AutoCompleteOption
        key={
          (keyName ? getObjectFromString(keyName, item) : item[valueKeyName]) ||
          index
        }
        value={getObjectFromString(valueKeyName, item).toString()}
      >
        {getObjectFromString(labelKeyName, item).toString() ||
          getObjectFromString(valueKeyName, item).toString()}
      </AutoCompleteOption>
    ));
  };

  render() {
    const {
      onChange,
      value,
      disabled,
      relatedKey,
      related,
      ...otherProps
    } = this.props;
    const disabledProp =
      disabled === true ? true : relatedKey ? !related : false;
    const options = this.createAutoCompleteOptions();
    return (
      <AutoComplete
        className="autocomplete-search"
        {...otherProps}
        onSelect={onChange}
        onSearch={this.handleSearch}
        disabled={disabledProp}
      >
        {options}
      </AutoComplete>
    );
  }
}

AutoCompleteContainer.defaultProps = {
  valueKeyName: "value",
  labelKeyName: "label",
  objectKey: "visible_values"
};

AutoCompleteContainer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  related: PropTypes.string,
  relatedKey: PropTypes.string,
  objectKey: PropTypes.string,
  valueKeyName: PropTypes.string,
  labelKeyName: PropTypes.string,
  keyName: PropTypes.string,
  disabled: PropTypes.bool
};

export default AutoCompleteContainer;
