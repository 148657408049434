import React from "react";
import classnames from "classnames";

import "./style.scss";

const AntRadiobox = Component => props => {
  const className = classnames(props.className, "antRadiobox");
  return <Component {...props} className={className} />;
};

export default AntRadiobox;
