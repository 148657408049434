import React from "react";
import Spin from "../UIElements/spin"
import Block from "../Block"

export default WrapperComponent => ({ loading, children, ...props }) => {
    const loader = (
        <Spin>
            <Block className="fullContain" />
        </Spin>
    );
    return (
        <WrapperComponent {...props}>
            {loading ? loader : children}
        </WrapperComponent>
    )
}