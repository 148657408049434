import React, { memo } from "react";
import PropTypes from "prop-types";

import { navigator } from "@common/navigation";

function onNavigate(url) {
  navigator.push(url);
}

const Anchor = memo(({ children, href, wrapHref=true,className = "", ...otherProps }) => {
  if (href){
   if (wrapHref){
     otherProps = {
       ...otherProps,
       onClick: () => {
         onNavigate(href);
       }
     };
   }
   else{
     otherProps={
       ...otherProps,
       href
     }
   }
  }
  className = "pointer ".concat(className);
  return (
    <a {...otherProps} className={className}>
      {children}
    </a>
  );
});

Anchor.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default Anchor;
