import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './authReducer';
import appReducer from './appReducer';
import channelReducer from './channelReducer';
import productReducer from './productReducer';
import catalogReducer from './catalogReducer';
import stockReducer from './stockReducer';
import packageReducer from './packageReducer';
import orderReducer from './orderReducer';
import scenarioReducer from './scenarioReducer';
import transferReducer from './transferReducer';
import settingReducer from './settingReducer';
import reportReducer from './reportReducer';
import ThemeSwitcher from './themeReducer';
import LanguageSwitcher from './languageReducer';
import marketingReducer from './marketingReducer';
import customerReducer from './customerReducer';
import collectionReducer from './collectionReducer';
import baseorderReducer from './baseorderReducer';
import widgetReducer from './widgetReducer';
import posReducer from './posReducer';
import campaignReducer from './campaignReducer';
import loyaltyReducer from './loyaltyReducer';
import contentManagementReducer from './contentManagementReducer';
import navigationManagerReducer from './navigationManagerReducer';
import priorityListReducer from './priorityListReducer';
import sortingAlgorithmsReducer from './sortingAlgorithmsReducer';
import integrationsReducer from './integrationsReducer';
import paymentManagementReducer from './paymentManagementReducer';
import salesChannelSettingsReducer from './salesChannelSettingsReducer';
import mappingReducer from './mappingReducer';
import omsReducer from './omsReducer';
import unifiedReducer from './unifiedReducer';
import webhookReducer from './webhookReducer';
import priceOptimizationReducer from './priceOptimizationReducer';
import stockAppReducer from './stockAppReducer';

export default combineReducers({
  authReducer,
  appReducer,
  channelReducer,
  productReducer,
  stockReducer,
  catalogReducer,
  transferReducer,
  packageReducer,
  orderReducer,
  baseorderReducer,
  marketingReducer,
  collectionReducer,
  scenarioReducer,
  customerReducer,
  widgetReducer,
  posReducer,
  campaignReducer,
  loyaltyReducer,
  settingReducer,
  reportReducer,
  form: reduxForm,
  ThemeSwitcher,
  LanguageSwitcher,
  contentManagementReducer,
  integrationsReducer,
  priorityListReducer,
  sortingAlgorithmsReducer,
  navigationManagerReducer,
  paymentManagementReducer,
  salesChannelSettingsReducer,
  mappingReducer,
  omsReducer,
  unifiedReducer,
  webhookReducer,
  priceOptimizationReducer,
  stockAppReducer,
});
