import React, { useContext } from "react";
import { Link as RRLink } from "react-router-dom";

import ChannelPkContext from "@containers/MasterPage/channelPkContext";
import { navigator } from "@common/navigation";

const Link = ({ to, ...rest }) => {
  const channelPk = useContext(ChannelPkContext);
  const platformLang = navigator.lastPlatformLang;
  const channelLang = navigator.lastChannelLang;
  const channelPath = `/${platformLang}/${channelLang}/channel/${channelPk}`;

  return <RRLink {...rest} to={`${channelPath}${to}`} />
}

export default Link;