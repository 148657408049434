import React from "react"; // Bu importu kaldırmayalım.
import { T } from "@utils/languageProvider";

/*
  alias -> o anki path'in alias'ı (routes içinden bulunabilir)
  "alias": { 
    select: "seçici özellik",
    content: component ve string olabilir,
    title: component ve string olabilir,
  }
*/
export default {
  "products-and-categories/product-pool": [
    {
      selector: '[data-tour="product-dropdown-button"]',
      content: T("guide.steps.product.pool.dropdown")
    },
    {
      selector: '[data-tour="filter-form-wrapper"]',
      content: T("guide.steps.product.pool.filter")
    },
    {
      selector: '[data-tour="action-combo"]',
      content: T("guide.steps.product.pool.combo")
    }
  ],
  "productSpecs": [
    {
      selector: '[data-tour="add-new-property"]',
      content: T("guide.steps.product.specs.property")
    },
    {
      selector: '[data-tour="filter-form-wrapper"]',
      content: T("guide.steps.product.specs.filter")
    },
    {
      selector: '.ant-table-tbody>tr:first-child',
      content: T("guide.steps.product.specs.table")
    }
  ],
  "prices": [
    {
      selector: '[data-tour="add-price-list"]',
      content: T("guide.steps.prices.list")
    },
    {
      selector: '[data-tour="filter-form-wrapper"]',
      content: T("guide.steps.prices.filter")

    },
    {
      selector: '.ant-table-tbody>tr:first-child',
      content: T("guide.steps.prices.table")
    }
  ],
  "stocks": [
    {
      selector: '[data-tour="add-stock-list"]',
      content: T("guide.steps.stocks.list")
    },
    {
      selector: '[data-tour="filter-form-wrapper"]',
      content: T("guide.steps.stocks.filter")
    },
    {
      selector: '.ant-table-tbody>tr:first-child',
      content: T("guide.steps.stocks.table")
    }
  ],
  "catalogFeeds": [
    {
      selector: '[data-tour="add-catalog-feed"]',
      content: T("guide.steps.catalog.feeds.add")
    },
    {
      selector: '[data-tour="action-combo"]',
      content: T("guide.steps.catalog.feeds.action")
    },
    {
      selector: '.ant-table-tbody>tr:first-child',
      content: T("guide.steps.catalog.feeds.table")
    }
  ],
  "product-collections": [
    {
      selector: '[data-tour="add-collection-button"]',
      content: T("guide.steps.product.collections.add")
    },
    {
      selector: '[data-tour="action-combo"]',
      content: T("guide.steps.product.collections.action")
    },
    {
      selector: '[data-tour="filter-form-wrapper"]',
      content: T("guide.steps.product.collections.filter")
    },
    {
      selector: '.ant-table-tbody>tr:first-child',
      content: T("guide.steps.product.collections.table")
    }
  ]
};
