import {
  CLEAR_SETTING_STATE,
  GET_SHIPPING_COMPANY_DETAIL,
  GET_CANCELLATION_REASON_DETAIL,
  GET_CARGO_DETAIL,
  CLEAR_CARGO_DETAIL_STATE,
  CLEAR_ENGINE_DETAIL_STATE,
  GET_ENGINE_DETAIL,
  GET_USER_FORM_DETAIL,
  GET_CATALOG_GROUPS,
  GET_CHANNEL_GROUPS,
  GET_PERMISSION_GROUPS_SUCCESS,
  GET_PERMISSION_GROUP_DETAIL_BY_USER,
  GET_CATALOG_GROUPS_DETAIL_SUCCESS,
  GET_PERMISSION_DETAIL_LIST_SUCCESS,
  GET_PERMISSION_DETAIL_SUCCESS,
  GET_CHANNEL_GROUPS_DETAIL_SUCCESS,
  GET_GOOGLE_MERCHANT_ATTRIBUTE,
  GET_GOOGLE_MERCHANT_FEED_ADAPTER
} from "../actiontypes";
export const initialState = {
  shippingCompanyDetail:{},
  cancellationReasonDetail:{},
  catalogGroupDetail: {},
  channelGroupDetail:{},
  permissionDetailList: {},
  userFormDetail: {},
  channelGroups: {},
  catalogGroups: {},
  permissionDetail: {},
  permissionDetailByUser: {},
  permissionGroups: {},
  cargoDetail:{},
  engineDetail:{},
  googleMerchant:{},
  googleMerchantFeedAdapter:{}
};

export default function(state = initialState, action) {
  const result = action.payload
  switch (action.type) {
    case GET_SHIPPING_COMPANY_DETAIL:
      return {
        ...state,
        shippingCompanyDetail: result
      };
    case GET_CANCELLATION_REASON_DETAIL:
      return {
        ...state,
        cancellationReasonDetail: result
      };
    case GET_CHANNEL_GROUPS_DETAIL_SUCCESS:
      return{
        ...state,
        channelGroupDetail:result
      };
    case GET_CARGO_DETAIL:
      return {
        ...state,
        cargoDetail: result
      };
    case GET_ENGINE_DETAIL:
      const engineResult = action.payload;
      return {
        ...state,
        engineDetail: engineResult
      };
    case CLEAR_CARGO_DETAIL_STATE:
      return { ...state, cargoDetail: {} };
    case CLEAR_ENGINE_DETAIL_STATE:
      return { ...state, engineDetail: {} };
    case CLEAR_SETTING_STATE:
      return {
        ...initialState
      };
      case GET_CATALOG_GROUPS_DETAIL_SUCCESS:
      return{
        ...state,
        catalogGroupDetail:result
      };
    case GET_PERMISSION_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        permissionDetailList: result
      };

    case GET_PERMISSION_DETAIL_SUCCESS:
      return {
        ...state,
        permissionDetail: result
      };
    case GET_CHANNEL_GROUPS:
      return{
        ...state,
        channelGroups:result
      }
      case GET_USER_FORM_DETAIL:
        return{
          ...state,
          userFormDetail:result
        }
    case GET_CATALOG_GROUPS:
      return{
        ...state,
        catalogGroups:result
      }
    case GET_PERMISSION_GROUP_DETAIL_BY_USER:
      return {
        ...state,
        permissionDetailByUser : result
      }  
    case GET_PERMISSION_GROUPS_SUCCESS:
      return{
        ...state,
        permissionGroups : result
      }
    case GET_GOOGLE_MERCHANT_ATTRIBUTE:
      return{
        ...state,
        googleMerchant : result.results
      }
    case GET_GOOGLE_MERCHANT_FEED_ADAPTER:
      return{
        ...state,
        googleMerchantFeedAdapter : result
      }


    default:
      return state;
  }
}
