import { language } from "@common/theme";

import { writeStorageItem, readStorageItem } from "@common/storage";
import { platformLanguage } from "@constants/";

import turkishLang from "@assets/images/flag/turkey.svg";
import englishLang from "@assets/images/flag/uk.svg";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "turkish",
      locale: "tr",
      text: "Türkçe",
      icon: turkishLang
    },
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang
    }
  ]
};

export function getCurrentLanguage(lang) {
  if (lang !== config.defaultLanguage) {
    const activeLanguage = config.options.find(
      ({ languageId, locale }) => languageId === lang || locale === lang
    );

    activeLanguage && writeStorageItem(platformLanguage, JSON.stringify(activeLanguage));
  }

  const platformLang = readStorageItem(platformLanguage) && JSON.parse(readStorageItem(platformLanguage));
  return platformLang || config.options[0];
}
export default config;
