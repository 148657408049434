import React, { memo } from "react";
import PropTypes from "prop-types";

const H3 = memo(({ children, ...otherProps }) => {
  return <h3 {...otherProps}>{children}</h3>;
});

H3.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  htmlFor: PropTypes.string
};

export default H3;
