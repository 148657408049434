import {httpMethods} from "@constants/commontypes";
import { sendRequest } from "@common/network";
import {createURL, addQueryToURL} from "@utils";

import {
  GET_CATALOGS,
  GET_CATALOG_DETAIL,
  GET_CATEGORY_TREE,
  CLEAR_STATE_CATALOG,
  GET_CATEGORY_NODE,
  GET_CATALOG_ALL_DETAIL,
  GET_CACHED_ATTRIBUTE
} from "../actiontypes";
import {
  contentTypesURL,
  getCatalogsURL,
  getCatalogURL,
  getPriceList,
  getStockList,
  categoryTreeURL,
  getContentTypesURL,
  getErrorReportURL,
  getTreeDataURL,
  getCatalogDetailURL,
  getCategoryMove,
  getCategorySchema,
  postCatalogItemsURL,
  catalogURL,
  cachedAttributeURL
} from "@constants/serviceUrls";
import { T } from "@utils/languageProvider";

export function getCatalogs(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: getCatalogsURL,
      onSuccess: result => {
        return {
          type: GET_CATALOGS,
          payload: result
        };
      }
    });
  };
}

export function getCatalogDetail(id) {
  return async () => {
    return sendRequest({
      url: getCatalogURL.replace("{0}",id),
      onSuccess: result => {
        return {
          type: GET_CATALOG_DETAIL,
          payload: result
        };
      }
    });
  };
}

export function getCatalogAllDetail(id) {
  return async () => {
    return sendRequest({
      url: getCatalogDetailURL.replace("{0}",id),
      onSuccess: result => {
        return {
          type: GET_CATALOG_ALL_DETAIL,
          payload: result
        };
      }
    });
  };
}

export function getPriceLists() {
  return async () => {
    return sendRequest({
      url: getPriceList,
    });
  };
}

export function getStockLists() {
  return async () => {
    return sendRequest({
      url: getStockList,
    });
  };
}

export function postPriceList(id , params = {}) {
  return async () => {
    return sendRequest({
      url: getCatalogURL.replace("{0}",id),
      method : httpMethods.PATCH,
      successMessage: T("connect.success").format(T("price.list")),
      params : params
    });
  };
}

export function postStockList(id , params = {}) {
  return async () => {
    return sendRequest({
      url: getCatalogURL.replace("{0}",id),
      method : httpMethods.PATCH,
      successMessage: T("save.success").format(T("stock.list")),
      params : params
    });
  };
}

export function getContentTypes() {
  return async () =>{
    return sendRequest({
      url : getContentTypesURL
    })
  }
}

export function getContentTypesByParams(queryObject){
  return async () => {
    return sendRequest({
      url: addQueryToURL(contentTypesURL,queryObject)
    })
  }
}

export function getErrorReport(id,contentTypeID) {
  return async () =>{
    return sendRequest({
      url : getErrorReportURL.replace("{0}",id).replace("{1}", contentTypeID)
    })
  }
}

export function getTreeData(catalogID) {
  return async () =>{
    return sendRequest({
      url : getTreeDataURL.replace("{0}",catalogID),
      onSuccess: (result) =>{
        return {
          type: GET_CATEGORY_TREE,
          payload:result
        }
      }
    })
  }
}

export function saveCategoryTree(params) {
  return async () =>{
    return sendRequest({
      url : categoryTreeURL,
      method : httpMethods.POST,
      params
    })
  }
}

export function saveCatalogCategoryTree(id , params) {
  return async () =>{
    return sendRequest({
      url : createURL(catalogURL , [id]),
      method : httpMethods.PATCH,
      params
    })
  }
}

export function getCategoryNodeSchema() {
  return async () =>{
    return sendRequest({
      url : getCategorySchema,
      onSuccess: (result) =>{
        return {
          type: GET_CATEGORY_NODE,
          payload:result
        }
      }
    })
  }
}

export function categoryTreeMoveUp(params = {}) {
  return async () =>{
    return sendRequest({
      url : getCategoryMove,
      params:{...params},
      method:httpMethods.POST,
    })
  }
}

export function addCatalogItem(params){
  return async () => {
    return sendRequest({
      url: postCatalogItemsURL,
      method: httpMethods.POST,
      successMessage: T("add.success").format(T("selected.product")),
      params
    })
  }
}

export function getCachedAttribute(){
  return async () =>{
    return sendRequest({
      url : addQueryToURL(cachedAttributeURL, {limit: 99999, sort: 'name'}),
      onSuccess: (result) =>{
        return {
          type: GET_CACHED_ATTRIBUTE,
          payload:result
        }
      }
    })
  }
}

export function deleteCatalogItem(id){
  return async () => {
    return sendRequest({
      url: createURL(postCatalogItemsURL,[id]),
      method: httpMethods.DELETE,
      successMessage: T("remove.success").format(T("selected.product"))
    })
  }
}

export function clearState() {
  return async dispatch => {
    dispatch({
      type: CLEAR_STATE_CATALOG
    });
  };
}