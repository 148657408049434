import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';

import {
  getRandom,
  isDefined,
  isDefinedAndNotEmpty,
  extractChannelPkFromPathname,
  extractLanguageFromPathname,
} from '@utils';
import routes from '@constants/routes';
import guideSteps from '@constants/guideSteps';
import { navigator } from '@common/navigation';
import { setupActionElements, setupSpecialBreadCrumb } from '@common';
import { siteConfig } from '@common/theme';

import Span from '../Span';
import Anchor from '../Anchor';
import Block from '@components/Block';
import GuideButton from '@components/GuideButton';
import ChannelPkContext from '@containers/MasterPage/channelPkContext';

import './style.scss';

class BreadcrumbContainer extends PureComponent {
  static contextType = ChannelPkContext;

  constructor(props) {
    super(props);
    this.state = {
      actionElements: props.actionElements || [],
      pageClassName: '',
    };
    this.currentElementID = 0;
    setupActionElements(this.setupActionElements);
    setupSpecialBreadCrumb(this.setupSpecialBreadcrumbs);
  }

  setupActionElements = (elements) => {
    this.setState({
      actionElements:
        elements &&
        elements.map((element) => ({
          ...element,
          key: this.currentElementID++,
        })),
    });
  };

  setupSpecialBreadcrumbs = (breadcrumb, pageClassName) => {
    this.setState({
      specialBreadCrumb: breadcrumb,
      pageClassName: pageClassName,
    });
  };

  onNavigate = (url) => {
    let withChannel = false;
    const historyPathname = this.props.location.pathname;
    if (historyPathname.split('/').includes('channel')) {
      withChannel = true;
    }

    navigator.push(url, withChannel);
  };

  findLatestBreadCrumb = () => {
    const { pathWithoutLanguage } = extractLanguageFromPathname(
      this.props.location.pathname
    );
    const { pathWithoutChannel: currentPath } = extractChannelPkFromPathname(
      pathWithoutLanguage
    );
    const paths = this.props.location.pathname
      .split('/')
      .filter((path) => path);
    for (let index = paths.length - 1; index >= 0; index--) {
      const element = '/'.concat(paths.slice(0, index + 1).join('/'));
      const currentRoute = routes.find((route) => {
        let path = `${route.path}`;
        if (
          path.search(':id') &&
          paths[0] === 'products-and-categories' &&
          path.replace(':id', paths[2]) === element
        ) {
          return route;
        } else {
          return route.path === element;
        }
      });

      if (currentRoute)
        return {
          route: currentRoute,
          isLatest:
            index === paths.length - 1
              ? true
              : !routes.find(
                  (route) =>
                    route.path ===
                    '/'.concat(paths.slice(0, index + 2).join('/'))
                ),
        };
    }
  };

  pageNameRenderer = ({ name, description, alias, extraElement }) => {
    const steps = guideSteps[alias];
    return (
      <Span key={getRandom()} className="breadcrumb">
        <Span>
          {(extraElement && extraElement.name) || name}{' '}
          {steps && <GuideButton steps={steps} />}
        </Span>
        <Block className="breadcrumb-description">
          {(extraElement && extraElement.description) || description}
        </Block>
      </Span>
    );
  };

  getPageNameDescription = () => {
    const { specialBreadCrumb } = this.state;
    const latestElement = this.findLatestBreadCrumb();

    if (isDefined(latestElement)) {
      const {
        route: { name, description, alias, extraElement } = { name: '' },
      } = latestElement;
      document.title = `${siteConfig.siteName} - ${
        name || (extraElement && extraElement.name) || ''
      }`;
      if (specialBreadCrumb) {
        return this.pageNameRenderer(specialBreadCrumb);
      }
      return this.pageNameRenderer({ name, alias, description, extraElement });
    }
  };

  getBreadCrumbs = () => {
    const { splitter } = this.props;
    const { specialBreadCrumb } = this.state;
    const breadCrumbs = [];
    const { pathWithoutLanguage } = extractLanguageFromPathname(
      this.props.location.pathname
    );
    const { pathWithoutChannel: currentPath } = extractChannelPkFromPathname(
      pathWithoutLanguage
    );
    const paths = this.props.location.pathname
      .split('/')
      .filter((path) => path);
    const { route: latestBreadCrumb, isLatest } =
      this.findLatestBreadCrumb() || {};
    for (let index = 0; index < paths.length; index++) {
      const element = '/'.concat(paths.slice(0, index + 1).join('/'));
      const currentRoute = routes.find((route) => {
        let path = `${route.path}`;
        if (
          path.search(':id') &&
          paths[0] === 'products-and-categories' &&
          path.replace(':id', paths[2]) === element
        ) {
          return route;
        } else {
          return route.path === element;
        }
      });
      const latestElement = currentRoute === latestBreadCrumb;
      const firstElement = index === 0;
      if (currentRoute && isDefinedAndNotEmpty(currentRoute.name))
        breadCrumbs.push(
          <Span key={element.concat(index)} className="breadcrumb">
            {!firstElement && <Span className="splitter">{splitter}</Span>}
            {latestElement && isLatest && !specialBreadCrumb ? (
              <Span className="last-element">{currentRoute.name}</Span>
            ) : (
              <Anchor
                onClick={() =>
                  this.onNavigate(currentRoute.currentPath || currentRoute.path)
                }
              >
                {currentRoute.name}
              </Anchor>
            )}
          </Span>
        );
    }
    if (specialBreadCrumb)
      breadCrumbs.push(
        <Span key={getRandom()} className={'breadcrumb'}>
          <Span className={`splitter`}>{splitter}</Span>
          <Span className="last-element">{specialBreadCrumb.name}</Span>
        </Span>
      );
    return breadCrumbs;
  };

  render() {
    const { actionElements, pageClassName } = this.state;
    const breadCrumbLastElement = this.getPageNameDescription();
    const breadCrumbs = this.getBreadCrumbs();

    return (
      <Block className={`breadcrumb-area ${pageClassName}`}>
        <Block>
          <Row>
            <Col
              className="breadcrumb-wrapper"
              span={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              {breadCrumbs}
            </Col>
          </Row>
        </Block>
        <Block className="page-name-area">
          <Block className="br-area">{breadCrumbLastElement}</Block>
          <Block className="actions-area">{actionElements}</Block>
        </Block>
      </Block>
    );
  }
}

BreadcrumbContainer.defaultProps = {
  splitter: '/',
};

export default withRouter(BreadcrumbContainer);
