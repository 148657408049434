import {sendRequest} from "@common/network";
import { allChannelsURL,channelsURL,channelTaskURL, getLanguageURL } from "@constants/serviceUrls";
import { httpMethods } from "@constants/commontypes";
import {
  GET_ALL_CHANNELS, GET_CONTEXT_CHANNEL,
  GET_CHANNELS_BY_PARAMS,
  GET_CHANNEL_DETAIL_SUCCESS,
  ADD_TO_ROUTE_STACK,
  GET_API_LANGUAGES_SUCCESS,
  CHANGE_TOGGLE_RIGHT_BAR,
  CHANGE_READ_RECEIPT_RIGHT_BAR
} from "../actiontypes";
import { createURL } from "@utils";
import { T } from "@utils/languageProvider";

export function getView(width) {
    let newView = "MobileView";
    if (width > 1220) {
      newView = "DesktopView";
    } else if (width > 767) {
      newView = "TabView";
    }
    return newView;
  }
  const actions = {
    COLLPSE_CHANGE: "COLLPSE_CHANGE",
    COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
    CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
    TOGGLE_ALL: "TOGGLE_ALL",
    CHANGE_CURRENT: "CHANGE_CURRENT",
    CLEAR_MENU: "CLEAR_MENU",
    CHANGE_READ_RECEIPT_RIGHT_BAR: "CHANGE_READ_RECEIPT_RIGHT_BAR",
    toggleCollapsed: () => ({
      type: actions.COLLPSE_CHANGE
    }),
    toggleAll: (width, height) => {
      const view = getView(width);
      const collapsed = view !== "DesktopView";
      return {
        type: actions.TOGGLE_ALL,
        collapsed,
        view,
        height
      };
    },
    toggleOpenDrawer: () => ({
      type: actions.COLLPSE_OPEN_DRAWER
    }),
    changeReadReceipt: () => ({
        type: actions.CHANGE_READ_RECEIPT_RIGHT_BAR
    }),
    changeOpenKeys: openKeys => ({
      type: actions.CHANGE_OPEN_KEYS,
      openKeys
    }),
    changeCurrent: current => ({
      type: actions.CHANGE_CURRENT,
      current
    }),
    clearMenu: () => ({ type: actions.CLEAR_MENU })
  };
  export default actions;

export function handleSearch(url,value) {
  return async () => {
    return sendRequest({
      url: url.replace("{0}",value),
    });
  };
}

export function toggleRightBar(payload){
  return {
    type : CHANGE_TOGGLE_RIGHT_BAR,
    payload
  }
}

export function changeReadReceipt(payload){
  return {
    type : CHANGE_READ_RECEIPT_RIGHT_BAR,
    payload
  }
}
export function getAllChannels() {
  return async () => {
    return sendRequest({
      url: allChannelsURL,
      onSuccess: result => {
        return {
          type: GET_ALL_CHANNELS,
          payload: result
        };
      }
    });
  };
}

export function getChannelsByParams(params = {}){
  return async () => {
    return sendRequest({
      params,
      url:channelsURL,
      onSuccess:result => {
        return{
          type: GET_CHANNELS_BY_PARAMS,
          payload: result
        }
      }
    })
  }
}

export function getChannelDetail(id){
  return async () => {
    return sendRequest({
      url : createURL(channelsURL,[id]),
      onSuccess : result => {
        return {
          type: GET_CHANNEL_DETAIL_SUCCESS,
          payload: result
        }
      }
    })
  }
}

export function executeChannelTask(params={}){
  return async () => {
    return sendRequest({
      params,
      url:channelTaskURL,
      method : httpMethods.POST
    })
  }
}

export function getContextChannel() {
  return {
    type: GET_CONTEXT_CHANNEL
  }
}

export function saveContextChannel(id,params){
  return async () => {
    return sendRequest({
      params,
      url:id ? createURL(channelsURL,[id]) : channelsURL,
      method : id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("save.success").format(T("sidebar.channel-settings")) : T("add.success").format(T("sale.channel"))
    })
  }
}

export function addToRouteStack(route) {
  return {
    type: ADD_TO_ROUTE_STACK,
    payload: route
  }
}

export function getAPILanguages(){
  return async () => {
    return sendRequest({
      url: getLanguageURL,
      onSuccess : result => {
        return {
          type : GET_API_LANGUAGES_SUCCESS,
          payload : result
        }
      }
    })
  }
}