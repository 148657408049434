import React from "react";
import classnames from "classnames";

import "./style.scss";

const AntInputNumber = Component => props => {
  const className = classnames(props.className, "ant-input-number-component");
  return <Component {...props} className={className} />;
};

export default AntInputNumber;
