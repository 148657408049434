import {
  GET_WIDGET_TYPE_DETAIL_SUCCESS,
  SAVE_WIDGET_TYPE_DETAIL_SUCCESS,
  CLEAR_STATE_SPECIAL_PAGE,
  GET_SPECIAL_PAGE_DETAIL,
  GET_SCHEMA_TYPE_DETAIL_SUCCESS,
  GET_CONTENT_TYPES_SUCCESS,
  GET_FORM_SUBJECT_DETAIL_SUCCESS,
  GET_LANDING_PAGE_TYPES,
  GET_RETAIL_STORE_DETAIL_SUCCESS,
  GET_RETAIL_STORES_DETAIL_SUCCESS,
  GET_RETAIL_STORES_COUNTRIES_SUCCESS,
  GET_RETAIL_STORES_CITIES_SUCCESS,
  GET_RETAIL_STORES_TOWNSHIPS_SUCCESS,
  GET_RETAIL_STORES_DISTRICTS_SUCCESS,
  GET_RETAIL_STORES_TYPE_SUCCESS,
  GET_REMOTE_SCHEDULES_SUCCESS,
  GET_REMOTE_SCHEDULE_RULES_SUCCESS,
  GET_SITES_SUCCESS,
  GET_STATIC_PAGE_DETAIL_SUCCESS,
  GET_LANDING_PAGE_ATTRIBUTE_SUCCESS,
  GET_CATEGORY_NODE_LANDING_PAGES_SUCCESS,
  CLEAR_CATEGORY_NODE_LANDING_PAGES,
  GET_CATEGORY_NODE_SUCCESS,
  DATA_SOURCE_PREPARED_SUCCESS,
  GET_LANDING_PAGES_SUCCESS,
  CLEAR_LANDING_PAGES
} from "../actiontypes";

const initialState = {
  widgetDetail: {},
  specialPageDetail: {},
  schemaTypeDetail : {},
  contentTypes : [],
  formSubjectDetail: {},
  landingPageType : {},
  retailStoresDetail: {},
  retailStoresCountries: [],
  retailStoresCities: {},
  retailStoresTownships: {},
  retailStoresDistricts: {},
  retailStoresType:{},
  sites : {},
  staticPageDetail : {},
  landingPageAttribute: {},
  categoryNodeLandingPages: {},
  categoryNode:{},
  channelsWithSites : {},
  landingPage:{},
  schedules: [],
  scheduleRules: []
};

export default function (state = initialState, action) {
  const result = action.payload && action.payload;
  switch (action.type) {
    case SAVE_WIDGET_TYPE_DETAIL_SUCCESS:
    case GET_WIDGET_TYPE_DETAIL_SUCCESS:
      const widgetDetailResult = action.payload || {};

      return { ...state, widgetDetail: widgetDetailResult };
    case GET_SPECIAL_PAGE_DETAIL:
      return {
        ...state,
        specialPageDetail: result
      };
    case DATA_SOURCE_PREPARED_SUCCESS:
      return {
        ...state,
        channelsWithSites : result
      }
    case GET_CONTENT_TYPES_SUCCESS:
      return {
        ...state,
        contentTypes : result
      }
    case GET_SCHEMA_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        schemaTypeDetail : result
      }
    case GET_FORM_SUBJECT_DETAIL_SUCCESS:
      return {
        ...state,
        formSubjectDetail: result
      };
    case GET_SITES_SUCCESS:
      return{
         ...state,
         sites : result
      }
    case GET_STATIC_PAGE_DETAIL_SUCCESS:
      return{
        ...state,
        staticPageDetail : result
      }
    case GET_LANDING_PAGE_TYPES:
      return{
        ...state,
        landingPageTypes: result
      };
    case GET_RETAIL_STORE_DETAIL_SUCCESS:
      return {
        ...state,
        retailStoreDetail: result
      }
    case GET_RETAIL_STORES_DETAIL_SUCCESS:
      return {
        ...state,
        retailStoresDetail: result
      }
    case GET_RETAIL_STORES_COUNTRIES_SUCCESS:
      return{
        ...state,
        retailStoresCountries: result
      }
    case GET_RETAIL_STORES_CITIES_SUCCESS:
      return {
        ...state,
        retailStoresCities: result
      }
    case GET_RETAIL_STORES_TOWNSHIPS_SUCCESS:
      return {
        ...state,
        retailStoresTownships: result
      }  
    case GET_RETAIL_STORES_DISTRICTS_SUCCESS:
      return {
        ...state,
        retailStoresDistricts: result
      } 
    case GET_RETAIL_STORES_TYPE_SUCCESS:
      return {
        ...state,
        retailStoresType: result
      }
    case GET_REMOTE_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: result.results || initialState.schedules
      }
    case GET_REMOTE_SCHEDULE_RULES_SUCCESS:
      return {
        ...state,
        scheduleRules: result.results || initialState.scheduleRules
      }
    case GET_LANDING_PAGE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        landingPageAttribute: result
      }
    case GET_CATEGORY_NODE_LANDING_PAGES_SUCCESS:
      return {
        ...state,
        categoryNodeLandingPages: result
      }
    case GET_CATEGORY_NODE_SUCCESS:
      return{
        ...state,
        categoryNode:result
      }
    case GET_LANDING_PAGES_SUCCESS:
      return{
        ...state,
        landingPage:result
      }
      case CLEAR_LANDING_PAGES:
        return{
          ...initialState
        }  
    case CLEAR_CATEGORY_NODE_LANDING_PAGES:
      return {
        ...initialState
      }
    case CLEAR_STATE_SPECIAL_PAGE:
      return {
        ...initialState
      };                  
    default:
      return state;
  }
}