import {
  GET_LOYALTY_ACCOUNT_DETAIL_SUCCESS,
  CLEAR_SALES_CHANNEL_SETTINGS_STATE,
  GET_SOCIAL_APP_SUCCESS,
  SAVE_SOCIAL_APP_SUCCESS,
  GET_DELIVERY_OPTION_SUCCESS,
  SAVE_DELIVERY_OPTION_SUCCESS,
  CLEAR_CARGO_DELIVERY_MANAGENET_STATE,
  GET_SHIPPING_OPTION_DETAIL,
  GET_FACET_CONF_DETAIL_SUCCESS,
  FETCH_SORTING_OPTION_DETAIL,
  FETCH_SORTING_ALGORITHMS,
  SHOW_SORTING_ALGORITHMS,
  SAVE_SORTING_OPTIONS,
  CLEAR_SORTING_OPTION_DETAIL,
  CLEAR_SORTING_FILTER_SETTINGS_STATE,
  GET_RETAIL_STOCK_GATEWAY
} from "../actiontypes";

export const initialState = {
  loyaltyAccountDetail: {},
  socialAppDetail: {},
  deliveryOption: {},
  shippingOptionDetail: {},
  facetConfDetail:{},
  genericForm: {},
  sortingAlgorithms: [],
  showSortingForm: false,
  retailStockGatewayDetail: {}
};

export default function(state = initialState, action) {
  const result = action.payload
  switch (action.type) {

    case GET_FACET_CONF_DETAIL_SUCCESS:
      return {
        ...state,
        facetConfDetail: result
      };

    case FETCH_SORTING_OPTION_DETAIL:
      const genericForm = action.payload;
      return { ...state, genericForm, showSortingForm: true }

    case FETCH_SORTING_ALGORITHMS:
      const sortingAlgorithms = action.payload;
      return { ...state, sortingAlgorithms }

    case SHOW_SORTING_ALGORITHMS:
      return {
        ...state,
        showSortingForm: true
      }

    case SAVE_SORTING_OPTIONS:
      const data = action.payload;
      return { ...state, genericForm: data }

    case CLEAR_SORTING_OPTION_DETAIL:
      return initialState  

    case CLEAR_SORTING_FILTER_SETTINGS_STATE:
      return {
        ...initialState
      };
    case GET_LOYALTY_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        loyaltyAccountDetail: result
      };

    case GET_SOCIAL_APP_SUCCESS:
    case SAVE_SOCIAL_APP_SUCCESS:
      return {
        socialAppDetail: result || initialState.socialAppDetail
      };  

    case CLEAR_SALES_CHANNEL_SETTINGS_STATE:
      return {
        ...initialState
      };
    case GET_DELIVERY_OPTION_SUCCESS:
    case SAVE_DELIVERY_OPTION_SUCCESS:
      return {
        deliveryOption: result || initialState.deliveryOption
      };

    case GET_SHIPPING_OPTION_DETAIL:
      return {
         ...state,
         shippingOptionDetail: result
      }; 

    case CLEAR_CARGO_DELIVERY_MANAGENET_STATE:
      return {
        initialState
      };
    case GET_RETAIL_STOCK_GATEWAY:
      return {
        ...state,
        retailStockGatewayDetail: result
      };
    default:
      return state;
  }
}
