import React, { PureComponent } from "react";
import { Icon, Popconfirm, Pagination, Checkbox } from "antd";
import PropTypes from "prop-types";
import classnames from "classnames";
import { arrayMove } from "react-movable";

import { sendRequest } from "@common/network";
import { httpMethods, responseTypes } from "@constants/commontypes";
import {
  getRandom,
  serializeObjectWithDot,
  shallowEqual,
  createURL,
  arrayEqual,
  getObjectFromString,
  isDefined,
  isDefinedAndNotEmpty,
  setObjectFromString,
  addQueryToURL
} from "@utils";
import {
  defaultHorizontalScroll,
  defaultPagination,
  defaultVerticalScroll,
  defaultColumnProps,
  locale,
  paginationPosition,
  draggableColumn,
  pageSizeLimitForMultiplePagination
} from "./constants";
import { downloadURL } from "@constants/serviceUrls";
import getRenderer from "./renderers";
import formatData from "./formatters";
import { checkAuth, getContextChannelInfo } from "@common";
import { T } from "@utils/languageProvider";

import Table from "./antTable";
import Select, { SelectOption as Option } from "../UIElements/Select";
import Button from "../Button";
import Block from "../Block";
import Span from "../Span";
import Anchor from "../Anchor";
import Box from "@components/utility/box/index";
import { EditableFormRow, EditableCell, EditableContext } from "./editablecell";
import { DragableBodyRow, EditableDragableFormRow } from "./draggable";
import Modal from '@components/Modal';

import "./style.scss";

class Datatable extends PureComponent {
  constructor(props) {
    super(props);
    this.onDatatableAction = this.onDatatableAction.bind(this);
    const {
      pagination = {},
      rowSelection,
      horizontalScroll,
      horizontalScrollSize,
      verticalScroll,
      verticalScrollSize,
      locale: localeProps = {},
      dataSource,
    } = props;
    this.state = {
      selectedRowKeys: [],
      selectedRows: [],
      dataSource: dataSource || [],
      pagination:
        pagination === false
          ? false
          : {
              ...defaultPagination,
              ...pagination,
              defaultCurrent: props.startPage,
              current: props.startPage,
              defaultPageSize: props.startSize,
              pageSize: props.startSize,
              onShowSizeChange: this.onShowSizeChange,
              total: dataSource && dataSource.length,
              showLessItems: true,
              position:
                dataSource &&
                dataSource.length > pageSizeLimitForMultiplePagination &&
                (pagination.pageSize
                  ? pagination.pageSize >= pageSizeLimitForMultiplePagination
                  : defaultPagination.pageSize >=
                    pageSizeLimitForMultiplePagination)
                  ? paginationPosition.both
                  : paginationPosition.bottom
            },
      loading: false,
      editingKey: "",
      buttonLoading : false,
      confirmVisible: false
    };
    this.locale = { ...locale, ...localeProps };
    this.scroll =
      horizontalScroll || verticalScroll
        ? {
            x: horizontalScrollSize
              ? horizontalScrollSize
              : horizontalScroll
              ? defaultHorizontalScroll
              : null,
            y: verticalScrollSize
              ? verticalScrollSize
              : verticalScroll
              ? defaultVerticalScroll
              : null
          }
        : undefined;
  }

  handleDelete = (event,key) => {
    event.stopPropagation();
    const { rowKey, onChangeDataSource, onDeleteRow } = this.props;
    const dataSource = [...this.state.dataSource];
    let deletedItem = dataSource.find(item => item[rowKey] === key);
    this.setState(
      {
        dataSource: dataSource.filter(item => item[rowKey] !== key)
      },
      () => {
        if (onChangeDataSource) onChangeDataSource(this.state.dataSource);
        if (onDeleteRow) onDeleteRow(deletedItem);
      }
    );
  };

  createNewData = () => {
    const columnKeys = this.columns
      .filter(column => !column.systemProp)
      .map(column => column.dataIndex);
    let result = {};
    for (const key of columnKeys) {
      result = { ...result, [key]: "" };
    }
    result[this.props.rowKey] = getRandom();
    return serializeObjectWithDot(result);
  };

  handleSaveCell = row => {
    const { rowKey } = this.props;
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row[rowKey] === item[rowKey]);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    const { selectedRows } = this.state;
    let newRows = null;
    if (selectedRows && selectedRows.length) {
      const index = selectedRows.findIndex(
        item => item[rowKey] === row[rowKey]
      );
      newRows = [
        ...selectedRows.slice(0, index),
        row,
        ...selectedRows.slice(index + 1, selectedRows.length)
      ];
    }
    this.setState(
      prevState => ({
        dataSource: newData,
        selectedRows: newRows ? newRows : prevState.selectedRows
      }),
      () => {
        const { selectedRowKeys, selectedRows } = this.state;
        this.onRowSelection(selectedRowKeys, selectedRows);
      }
    );
  };

  handleAdd = () => {
    const { dataSource } = this.state;
    const newData = this.createNewData();
    this.setState({
      dataSource: [...dataSource, newData]
    });
  };

  isEditing = record => record[this.props.rowKey] === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  onRemoveRow = (pk) => {
    const { onRemoveRow } = this.props;
    onRemoveRow(pk)
  }

  handleSaveRow(form, key) {
    const { rowKey, onChangeDataSource, onChangeRow } = this.props;
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item[rowKey]);
      const resultAction = () => {
        if (onChangeDataSource) onChangeDataSource(this.state.dataSource);
        if (onChangeRow) onChangeRow(row);
      };
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.setState({ dataSource: newData, editingKey: "" }, resultAction);
      } else {
        newData.push(row);
        this.setState({ dataSource: newData, editingKey: "" }, resultAction);
      }
    });
  }

  edit(key) { 
    this.setState({ editingKey: key });
  }

  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      pagination:
        prevState.pagination === false
          ? false
          : { ...prevState.pagination, pageSize }
    }));
  };

  onRowSelection = (selectedRowKeys, selectedRows) => {
    const {
      rowSelection: { onRowSelection },
      rowKey
    } = this.props;
    this.setState(
      {
        selectedRowKeys: selectedRows.map(row => row[rowKey]),
        selectedRows
      },
      () => {
        if (onRowSelection) onRowSelection(selectedRowKeys, selectedRows);
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { reloaded , handleReloaded } = this.props;
    const hasItem = isDefined(prevState.pagination.total) && isDefined(this.state.pagination.total);
    if (hasItem) {
      if(prevState.pagination.total !== this.state.pagination.total && handleReloaded && !reloaded){
        handleReloaded(true);
      }
      if (reloaded){
        handleReloaded(false);
        this.reload();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { filter, formFilters, dataSource, remote, url, keepRowSelection } = this.props;
    if (
      !shallowEqual(filter, nextProps.filter) ||
      !arrayEqual(
        formFilters.map(formFilter => formFilter.key),
        nextProps.formFilters.map(formFilter => formFilter.key)
      ) ||
      url !== nextProps.url
    )
      {this.fetchData({
        urlParam: nextProps.url,
        filter: nextProps.filter,
        formFilters: nextProps.formFilters
      });
    }
    if (!remote && !shallowEqual(dataSource, nextProps.dataSource)){
      // keepRowSelection props'u dataSource verdiğimiz orderDetail'de
      // seçilen rowların datatable tarafında sıfırlanması problemini çözmek için geçici olarak eklenmiş bir props'tur.
      this.setState(prevState => ({
        dataSource: nextProps.dataSource,
        ... keepRowSelection ? {} :
        {
          selectedRowKeys: [],
          selectedRows: [],
        },
        pagination:
          prevState.pagination === false
            ? false
            : {
                ...prevState.pagination,
                total: dataSource ? dataSource.length : 0,
                current: nextProps.startPage,
                defaultCurrent: nextProps.startPage,
                pageSize: nextProps.startSize,
                defaultPageSize: nextProps.startSize,
                position:
                  dataSource &&
                  dataSource.length > pageSizeLimitForMultiplePagination &&
                  prevState.pagination.pageSize >=
                    pageSizeLimitForMultiplePagination
                    ? paginationPosition.both
                    : paginationPosition.bottom
              }
      }));
    }
  }

  componentDidMount() {
    const { filter, formFilters, startPage } = this.props;
    this.fetchData({
      filter: filter,
      formFilters: formFilters,
      page: startPage
    });
  }

  reload = () => {
    const { filter, formFilters } = this.props;
    this.fetchData({
      filter: filter,
      formFilters: formFilters
    });
  };

  addRow = (data) => {
    this.setState((prevState) => {
      return {
        dataSource: [
          ...prevState.dataSource,
          ... Array.isArray(data) ? data : [data]
        ]
      }
    });
  }

  removeRow = (index) => {
    this.setState((prevState) => {
      return {
        dataSource: [
          ...prevState.dataSource.slice(0, index),
          ...prevState.dataSource.slice(index + 1),
        ]
      }
    });
  }

  fetchData = ({
    page = 1,
    sortField,
    sortOrder,
    filter,
    formFilters,
    urlParam
  } = {}) => {
    const {
      url:urlProp,
      onChangeTotalCount,
      remote,
      sourceKey,
      onGetResponse,
      sourceNormalizer
    } = this.props;
    const url = urlParam || urlProp;
    const { pagination } = this.state;
    const pageSize = pagination === false ? null : pagination.pageSize,
      pageOrder = pagination === false ? null : page;
    if (!remote) return;
    this.setState({ loading: true });
    let formFile = null;
    const hasFormData = formFilters && formFilters.length > 0;
    if (hasFormData) {
      formFile = new FormData();
      formFile.append("filename", formFilters[0].file);
    }
    const queryObject = {
      ...filter,
      ...(pageSize ? { limit: pageSize } : {}),
      ...(pageOrder ? { page: pageOrder } : {}),
      ...(sortField ? { sort: sortOrder === "ascend" ? sortField : '-' + sortField } : {})
    };
    sendRequest({
      url: hasFormData ? createURL(url, [], queryObject) : url,
      params: hasFormData ? formFile : queryObject,
      method: hasFormData ? httpMethods.POST : httpMethods.GET,
      headers: hasFormData ? { "Override-Method": "GET" } : {},
      onSuccess: rawResult => {
        sourceNormalizer(rawResult, result => {
        if (isDefinedAndNotEmpty(result)) {
        if (onChangeTotalCount) onChangeTotalCount(result.count || result.results.length);
        if (onGetResponse) onGetResponse(responseTypes.success, result);
        this.setState(prevState => ({
          dataSource: getObjectFromString(sourceKey, result),
          next: result.next,
          loading: false,
          selectedRowKeys: [],
          selectedRows: [],
          pagination:
            prevState.pagination === false
              ? false
              : {
                  ...prevState.pagination,
                  total: result.count,
                  current: page,
                  position:
                    result.count > pageSizeLimitForMultiplePagination &&
                    prevState.pagination.pageSize >=
                      pageSizeLimitForMultiplePagination
                      ? paginationPosition.both
                      : paginationPosition.bottom
                }
        }));
        }
      });

      },
      onFail: error => {
        if (onGetResponse) onGetResponse(responseTypes.fail, error);
        this.setState({
          dataSource: [],
          loading: false,
          selectedRowKeys: [],
          selectedRows: []
        });
      }
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    const { filter } = this.props;
    pager.current = pagination.current;
    this.setState(
      prevState => ({
        sorter,
        pagination:
          prevState.pagination === false
            ? false
            : { ...prevState.pagination, current: pagination.current }
      }),
      () => {
        this.fetchData({
          filter,
          size: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order
        });
      }
    );
  };

  onChangeActionButton = (currentAction, isStaticAction, callback) => {
    this.setState(
      {
        [isStaticAction
          ? "currentAction"
          : "currentSelectionAction"]: currentAction
      },
      () => {
        if (callback && callback instanceof Function) callback();
      }
    );
  };

  onDatatableAction = isStaticAction => {
    const {
      currentAction: currentActionIDFromState,
      currentSelectionAction,
      selectedRowKeys = [],
      selectedRows = []
    } = this.state;
    const currentActionID = isStaticAction
      ? currentActionIDFromState
      : currentSelectionAction;
    const { actionButtons, rowKey } = this.props;
    const currentAction = actionButtons.find(
      button => button.id === currentActionID
    );
    if (
      !currentAction ||
      (currentAction.selectionRequired
        ? !selectedRowKeys || !selectedRowKeys.length
        : false)
    )
      return;
    const resultAction = currentAction.reloadData ? this.fetchData : null;
    const formatValue =
      selectedRowKeys.length > 1
        ? selectedRowKeys.join(",")
        : selectedRowKeys[0];

    if (currentAction.customAction) {
      currentAction.customAction(currentAction)
      this.onRowSelection(selectedRowKeys, selectedRows);
      return;
    }
    else if (currentAction.confirm) {

      Modal.confirm({
        title: currentAction.title,
        content: currentAction.content.format(selectedRowKeys.length),
        okText: T('yes'),
        cancelText: T('no'),
        icon:null,
        onOk: () => {
          doCurrentAction();
          this.setState({
            selectedRowKeys: [],
            selectedRows: []
          },() => {
            const { selectedRowKeys, selectedRows } = this.state;
            this.onRowSelection(selectedRowKeys, selectedRows);
          })
        },
        onCancel: () => {
          this.hideConfirmModal();
        }
      });
      return;
    } else {
      doCurrentAction();
      this.setState({
        selectedRowKeys: [],
        selectedRows: []
      },() => {
        const { selectedRowKeys, selectedRows } = this.state;
        this.onRowSelection(selectedRowKeys, selectedRows);
      })
    }

    function paramsMapper(params, iterator, iteratorParam) {
      return {
        ...params,
        [iteratorParam]: iterator
      };
    }

    function doCurrentAction() {
      let param = { ...currentAction.params };
      const selectedRowDatas = selectedRows.map(row =>
        currentAction.selectionKey
          ? getObjectFromString(currentAction.selectionKey, row)
          : row[rowKey]
      );
      param = setObjectFromString(
        param,
        currentAction.bodyKey,
        selectedRowDatas
      );
      if (currentAction.loop) {
        for (const iterator of selectedRowDatas) {
          if (currentAction.iteratorParam) {
            currentAction.params[currentAction.iteratorParam] = iterator;
          }
          sendRequest({
            url:
              httpMethods[currentAction.httpType] === httpMethods.GET ||
              httpMethods[currentAction.httpType] === httpMethods.PATCH ||
              httpMethods[currentAction.httpType] === httpMethods.DELETE
                ? currentAction.url.format(iterator)
                : currentAction.replaceUrlViaKey
                ? currentAction.url.format(iterator)
                : currentAction.url,
            method: httpMethods[currentAction.httpType],
            successMessage: currentAction.successMessage,
            params:
              httpMethods[currentAction.httpType] === httpMethods.GET
                ? currentAction.params
                : currentAction.params &&
                  paramsMapper(
                    currentAction.params,
                    iterator,
                    currentAction.iteratorParam
                  ),
            onBegin : () =>{
              if (currentAction.onBegin) currentAction.onBegin();
            },
            onSuccess: result => {
              if (currentAction.onSuccess) currentAction.onSuccess(result);
            },
            onFail: result => {
              if (currentAction.onFail) currentAction.onFail(result);
            },
            onFinally: () => {
              if (
                resultAction &&
                selectedRowDatas.indexOf(iterator) ===
                  selectedRowDatas.length - 1
              )
                resultAction();

              if (currentAction.onFinally) currentAction.onFinally();
            }
          });
        }
      } else {
        sendRequest({
          url:
            httpMethods[currentAction.httpType] === httpMethods.GET
              ? currentAction.url.format(formatValue)
              : currentAction.replaceUrlViaKey
              ? currentAction.url.format(formatValue)
              : currentAction.url,
          method: httpMethods[currentAction.httpType],
          successMessage: currentAction.successMessage,
          params:
            httpMethods[currentAction.httpType] === httpMethods.GET
              ? currentAction.params
              : param,
          onBegin: () => {
            if (currentAction.onBegin) currentAction.onBegin();
          },
          onSuccess: result => {
            if (currentAction.onSuccess) currentAction.onSuccess(result);
            if (resultAction) resultAction();
          },
          onFail: result => {
            if (currentAction.onFail) currentAction.onFail(result);
          },
          onFinally: () => {
            if (currentAction.onFinally) currentAction.onFinally();
          },
        });
      }
    }
  };

  actionElements = order => {
    const {
      currentSelectionAction: currentActionID,
      selectedRowKeys,
      pagination
    } = this.state;
    const { actionButtons, actionButtonText, buttonSpinner, alwaysShowPagination} = this.props;
    const buttons = actionButtons;//actionButtons.filter(button => button.selectionRequired);
    const currentAction = buttons.find(button => button.id === currentActionID);
    if (!buttons || !buttons.length) return null;
    const options = buttons.filter(Boolean).map((button, index) => (
      <Option
        key={order * buttons.length + index + 1}
        value={button.id}
        disabled={button.disabled}
      >
        {button.label}
      </Option>
    ));
    const isPaginationActive =
      pagination &&
      pagination.total > pagination.pageSize &&
      pagination.position === paginationPosition.both;
    const actionButtonClass = "table-operations";
    const isRowSelected = selectedRowKeys.length > 0

    return (
      <Block className={actionButtonClass}>
        <Block className="action-selection">
          <Select
            className="select"
            data-tour="action-combo"
            onChange={value => this.onChangeActionButton(value)}
            value={this.state.currentSelectionAction}
            placeholder={T("select.action")}
          >
            {options}
          </Select>
          <Button
            disabled={
              !currentAction ||
              (currentAction.selectionRequired
                ? !isRowSelected
                : false)
            }
            loading={buttonSpinner}
            onClick={() => this.onDatatableAction()}
            className="btn btn-primary"
          >
            {actionButtonText}
          </Button>
        </Block>

        {alwaysShowPagination && this.renderPagination()}
      </Block>
    );
  };

  renderPagination() {
    const { paginationText } = this.props
    return<>
        {this.state.pagination.pageSizeOptions && <Block className="page-sizer">
        <Select
          className="select custom-datatable-pager"
          defaultValue={this.state.pagination.pageSize}
          value={this.state.pagination.pageSize}
          onChange={value => {
            const pagination = {...this.state.pagination, pageSize: parseInt(value)};
            this.setState({ pagination })
            this.handleTableChange(pagination, undefined, this.state.sorter || {});
          }}
        >
          {this.state.pagination.pageSizeOptions.map((pageCount) => {
            return <Option key={pageCount} value={pageCount}>{pageCount}</Option>
          })}
        </Select>
            <span className="sizer-text">{paginationText}</span>
      </Block>}
      <Pagination {...this.state.pagination} onChange={(current) => {
        this.handleTableChange({...this.state.pagination, current}, undefined, this.state.sorter || {});
      }} simple/>
    </>;
  }

  actionButtons = () => {
    const { actionButtons, buttonDisable} = this.props;
    const { pagination } = this.state;
    const filteredButtons = actionButtons.filter(
      button => !button.selectionRequired
    );
    const buttonClass = actionButtons.map(item =>{
      return item.buttonClass
    });
    if (!filteredButtons || !filteredButtons.length) return null;
    const isPaginationActive =
      pagination &&
      pagination.total > pagination.pageSize &&
      pagination.position === paginationPosition.both;
    const actionButtonClass = isPaginationActive
      ? "button-operations"
      : "button-operations-default";
    const buttons = filteredButtons.map(button => (
      <Button
        className={buttonClass[0]}
        disabled={buttonDisable}
        key={button.id}
        onClick={() => {
          this.onChangeActionButton(button.id, true, () =>
            this.onDatatableAction(true)
          );
        }}
        type={button.type}
        disabled={button.disabled}
      >
        {button.label}
      </Button>
    ));

    return (
      <>
        <Block className={actionButtonClass}>{buttons}</Block>
        <Block className="clear" />
      </>
    );
  };

  hideConfirmModal = () => {
    this.setState({ confirmVisible: false });
  };

  onRow = (row, index) => {
    const { onRowClick, isAuthorized, authActionName } = this.props;
    if((isAuthorized && checkAuth(authActionName)) || !isAuthorized) {

      return {
        onClick: event => { 
          if (onRowClick) onRowClick(row, index, event, this.state.pagination);
        },
        onDoubleClick: () => {
          this.edit(row.pk);
        },
        index,
        moveRow: this.moveRow
      }
    }
    else return {};
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { dataSource } = this.state;

    this.setState(
      { dataSource: arrayMove(dataSource, dragIndex, hoverIndex) },
      () => {
        const { onDrag } = this.props;
        if (onDrag)
          onDrag(
            { ...dataSource[dragIndex], index: dragIndex },
            { ...dataSource[hoverIndex], index: hoverIndex }
          );
      }
    );
  };

  handlePopconfirm = (event) =>{
    event.stopPropagation();
  };

  handleCancel = (event) =>{
    event.stopPropagation();
  };

  makeColUppercase = (title) => typeof title === "string" ? title.upper() : title;

  getColumns = () => {
    const {
      draggable,
      columns,
      effaceable,
      saveable,
      rowKey,
      effaceableTitle,
      effaceableButtonText,
      deleteIcon,
      doubleClickable,
      onRemoveRow
    } = this.props;
    this.columns = [
      ...columns.map(column => ({
        ...defaultColumnProps,
        ...column,
        title: this.makeColUppercase(column.title)
      }))
    ];
    if (draggable) this.columns.unshift(draggableColumn);
    if (effaceable)
      this.columns.push({
        title: "",
        dataIndex: "effaceable",
        systemProp: true,
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm
              title={effaceableTitle ? effaceableTitle : T('sure.to.delete')}
              onConfirm={(event) => this.handleDelete(event,record[rowKey])}
              onCancel={this.handleCancel}
            >
              {isDefinedAndNotEmpty(deleteIcon) ? <Icon type = {deleteIcon}/> :
              <Anchor onClick={this.handlePopconfirm}>{effaceableButtonText ? effaceableButtonText : T('delete')}</Anchor>
            }
            </Popconfirm>
          ) : null
      });

    if (saveable)
      this.columns.push({
        title: onRemoveRow ?`${T('edit')} / ${T('delete')}`: T('edit'),
        dataIndex: "saveable",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <Block>
              {editable ? (
                <Span>
                  <EditableContext.Consumer>
                    {form => (
                      <Anchor
                        className="ant-table-row-save-btn"
                        onClick={() => this.handleSaveRow(form, record[rowKey])}
                        style={{ marginRight: 8 }}
                      >
                        {T("save")}
                      </Anchor>
                    )}
                  </EditableContext.Consumer>
                  <Popconfirm
                    title={T('sure.to.abort')}
                    onConfirm={() => this.cancel(record[rowKey])}
                  >
                    <Anchor className="ant-table-row-abort-btn">{T("abort")}</Anchor>
                  </Popconfirm>
                </Span>
              ) : (
                <>
                  <Anchor
                    disabled={editingKey !== ""}
                    onClick={() => this.edit(record[rowKey])}
                  >
                    {T("edit")}
                  </Anchor>
                  {onRemoveRow && <Popconfirm
                    title={T('sure.to.delete')}
                    onConfirm={() => this.onRemoveRow(record[rowKey])}
                  >
                    <Anchor className="delete-btn">{T("delete")}</Anchor>
                  </Popconfirm>}
                </>
              )}
            </Block>
          );
        }
      });
    return this.columns.map(col => {
      const column = { ...col };
      if (!column.render && (column.formatters || column.renderer)) {
        column.renderer = column.renderer || {};
        column.formatters = column.formatters || [];
        column.render = (cellData, rowData) =>
          getRenderer(
            column.renderer.type,
            formatData(column.formatters, cellData, rowData),
            rowData,
            column.renderer.props
          );
      }
      if (!column.editable) {
        return column;
      }
      return {
        ...column,
        onCell: record => ({
          record,
          inputType: column.inputType,
          editable: column.editable,
          dataIndex: column.dataIndex,
          title: column.title,
          options: column.options,
          url: column.url,
          valueKeyName: column.valueKeyName,
          labelKeyName: column.labelKeyName,
          objectKey: column.objectKey,
          checkCellValue: column.checkCellValue,
          handleSave: this.handleSaveCell,
          isEditing: this.isEditing(record),
          doubleClickable
        })
      };
    });
  };

  onChangeCheckbox = (e) => {
    const {checked} = e.target;
    const {leftCheckboxAction} = this.props;
    leftCheckboxAction(checked);
  };

  rowClassName = (record, index) => {
    const { rowClassName, onRowClick} = this.props;
    const  className = rowClassName && rowClassName(record, index); 
    return classnames([ className, { "pointer" : onRowClick }])
  }
  

  render() {
    const { pagination, loading, dataSource, selectedRowKeys } = this.state;
    const {
      onRowClick,
      draggable,
      customFooter,
      customHeader,
      showActionsBottom,
      loading: isLoading,
      subtitle,
      exportable,
      totalCount,
      leftCheckBox ,
      leftCheckboxText ,
      leftCheckboxDefaultCheck,
      alwaysShowPagination,
      exportButtonClick,
      rowClassName,
      ...otherProps
    } = this.props;
    const hasData = totalCount> 0;
    const columns = this.getColumns();
    const editable = this.columns.findIndex(column => column.editable) !== -1;
    const components = {
      body: {
        row:
          draggable && editable
            ? EditableDragableFormRow
            : draggable
            ? DragableBodyRow
            : EditableFormRow,
        cell: EditableCell
      }
    };
    const loadingValue = isDefined(isLoading) ? isLoading : loading;
    const actionElements = this.actionElements(1);
    const paginationBlock = (hasData && !actionElements) && this.renderPagination();
    const actionButtons = this.actionButtons();
    const isPaginationActive =
      pagination && dataSource;
    const countClass = subtitle ? "" : "count-box";
    const boxTitle = <>{subtitle} {!!totalCount && <span className="count-box-title">({totalCount})</span>}</>;
      const totalCountWithIcon = (isDefined(totalCount) && totalCount>0) ? (
        <Span className={countClass}>
          <Span className="count-text">{totalCount}</Span> {T("results.found")}
        </Span>
      ) : (
        ""
      )
    const isPaginationWithActions =  exportable || actionElements

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onRowSelection,
    };

    return (
      <Block
        className={
          isPaginationActive ? "pagination-active" : "pagination-disable"
        }
      >
        <Block className= {classnames(["table-head", { "pagination-with-actions" : isPaginationWithActions }])} >
          <Box
            title={boxTitle}
            subtitle={totalCountWithIcon}
            className="table-title"
            actions={
              exportable && <span className="download-action">
                <Button icon="file-text" onClick={() => exportButtonClick()}>{T('XLS')}</Button>
              </span>
            }
            checkbox={
            leftCheckBox &&
            <Checkbox
              defaultChecked={leftCheckboxDefaultCheck}
              onChange={this.onChangeCheckbox}>{leftCheckboxText}</Checkbox>}>
          </Box>
          <Block className="table-action-pagination">
            {customHeader}
            { hasData && actionElements }
            {alwaysShowPagination && <Block className="table-operations">
              {paginationBlock}
            </Block>}
          </Block>
        </Block>
        <Table
          data-tour="datatable"
          {...otherProps}
          rowClassName={this.rowClassName}
          components={components}
          onChange={this.handleTableChange}
          loading={loadingValue}
          dataSource={dataSource}
          rowSelection={this.props.rowSelection && rowSelection}
          selectedRowKeys={selectedRowKeys}
          scroll={this.scroll}
          pagination={pagination}
          locale={this.locale}
          columns={columns}
          onRow={this.onRow}
        />
        <Block className="table-action-pagination table-action-pagination-footer">
          { showActionsBottom && this.actionElements(2) }
          { (isPaginationActive || customFooter ) && <Block className="table-operations">
              {customFooter}
              {isPaginationActive && paginationBlock}
          </Block>}
        </Block>
      </Block>
    );
  }
}

Datatable.defaultProps = {
  formFilters: [],
  sourceKey: "results",
  actionButtonText: T('apply').toLocaleUpperCase(),
  remote: true,
  className: "isoSimpleTable",
  actionButtons: [],
  columns: [],
  isAuthorized: false,
  authActionName: "",
  keepRowSelection: false,
  startPage: 1,
  startSize: defaultPagination.pageSize,
  showActionsBottom: true,
  alwaysShowPagination: true,
  draggable: false,
  sourceNormalizer: (data, cb) => cb(data)
};

Datatable.propTypes = {
  horizontalScroll: PropTypes.bool,
  horizontalScrollSize: PropTypes.number,
  verticalScroll: PropTypes.bool,
  verticalScrollSize: PropTypes.number,
  locale: PropTypes.object,
  dataSource: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      dataIndex: PropTypes.string,
      title: PropTypes.any,
      sorter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      fixed: PropTypes.oneOf(["left", "right"]),
      render: PropTypes.func,
      editable: PropTypes.bool,
      inputType: PropTypes.string
    })
  ).isRequired,
  effaceable: PropTypes.bool,
  effaceableTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  effaceableButtonText:PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  saveable: PropTypes.bool,
  doubleClickable: PropTypes.bool,
  rowKey: PropTypes.string.isRequired,
  onChangeDataSource: PropTypes.func,
  rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filter: PropTypes.object,
  formFilters: PropTypes.array,
  url: PropTypes.string,
  onChangeTotalCount: PropTypes.func,
  sourceKey: PropTypes.string,
  actionButtons: PropTypes.array,
  buttonDisable: PropTypes.bool,
  actionButtonText: PropTypes.string,
  onRowClick: PropTypes.func,
  remote: PropTypes.bool,
  draggable: PropTypes.bool,
  onDrag: PropTypes.func,
  onGetResponse: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onChangeRow: PropTypes.func,
  deleteIcon : PropTypes.string,
  isAuthorized: PropTypes.bool,
  authActionName: PropTypes.string,
  keepRowSelection: PropTypes.bool,
  startPage: PropTypes.number,
  startSize: PropTypes.number,
  customFooter: PropTypes.any,
  customHeader: PropTypes.node,
  showActionsBottom: PropTypes.bool,
  alwaysShowPagination: PropTypes.bool,
  exportButtonClick: PropTypes.func,
  sourceNormalizer: PropTypes.func
};

export default Datatable;

//export const DragableDatatable = DragDropContext(HTML5Backend)(Datatable);

export class DatatableBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: props.dataSource ? props.dataSource.length : 0
    };
  }
  onChangeTotalCount = totalCount => {
    this.setState({
      totalCount
    });
  };

  reload = () => {
    this.datatable.reload();
  };

  addRow = (...args) => {
    this.datatable.addRow(...args);
  }

  removeRow = (...args) => {
    this.datatable.removeRow(...args);
  }

  componentDidUpdate(prevProps){
    if (prevProps.dataSource && prevProps.dataSource.length !== this.props.dataSource && this.props.dataSource.length){
      this.setState({
        totalCount : this.props.dataSource.length,
      });
    }
  }

  exportButtonClick = () => {
    
    const dt = this.datatable;
    const { url, type } = this.props;
    let fields=[];
    const pureUrl = url.includes('?') ? url.split('?')[0] : url
    const basketOfferId = url.includes('basket_offer=') ? url.split('=')[1] : ''
    dt.columns.forEach(item=>{
      fields.push(item.exportKey || item.dataIndex)
    });

    let queryOptions = {
      url: pureUrl,
      _fields: fields,
      ...dt.props.filter,
      format: 'xls',
      limit: 99999,
    }

    if (type === 'MultiCoupon') {
     queryOptions = {
      ...queryOptions,
      basket_offer: basketOfferId,
     }
    }

    window.open(addQueryToURL(`/channel/${getContextChannelInfo().pk}${downloadURL}`, queryOptions), "_blank");
}

  


  render() {
    const { totalCount } = this.state;
    const { rowSelection } = this.props;
    const DT = Datatable;
    return (
      <Box
        data-tour="datatable-box"
        className={classnames(["akinon-datatable", { "fix-with-padding" : !rowSelection }])} >
        <DT
          ref={dt => (this.datatable = dt)}
          {...this.props}
          totalCount={totalCount}
          onChangeTotalCount={this.onChangeTotalCount}
          exportButtonClick = {this.exportButtonClick}
        />
      </Box>
    );
  }
}


DatatableBox.defaultProps = {
  exportable: true,
  subtitle: "",
  paginationText: T('show.product')
};

DatatableBox.propTypes = {
  exportable: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
  paginationText: PropTypes.string
}
