import { arrayMove, arrayRemove } from "react-movable";

import {
  FETCH_PRIORITY_LIST,
  FETCH_CATEGORY_TREE,
  SET_PRIORITY_SELECTION,
  FETCH_COLLECTION_LIST,
  FETCH_SORTING_ALGORITHM_LIST,
  ADD_SORTING_ALGORITHM,
  REMOVE_SORTING_ALGORITHM,
  MOVE_SORTING_ALGORITHM,
  FETCH_SORTING_DETAIL,
  UPDATE_SORTING_ALGORITHMS,
  UPDATE_DATATABLE_SELECTIONS,
  ADD_STATIC_SORTING_ITEM,
  MOVE_STATIC_PRODUCT,
  FETCH_STATIC_PRODUCTS,
  REMOVE_STATIC_PRODUCT,
  SET_SORTABLE_PRODUCT_LIST
} from "../actiontypes";

const initialState = {
  categoryTree: [],
  selection: {
    type: null,
    pk: null,
    key: null,
    sort_option: null,
    path: null
  },
  collectionList: [],
  sortingAlgorithms: [],
  selectedAlgorithms: [],
  sortOptionDetails: null,
  datatableSelections: [],
  static_sorting_algorithm: null,
  staticProductList: [],
  sortableProductList: [],
  loading: false,
  channel: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRIORITY_LIST:
      return state;
    case FETCH_SORTING_DETAIL:
      const { sorting_algorithms, static_sorting_algorithm } = action.payload;
      return {
        ...state,
        selectedAlgorithms: sorting_algorithms,
        static_sorting_algorithm
      }
    case FETCH_CATEGORY_TREE:
      const { categoryTree, channel } = action.payload;

      return {
        ...state,
        categoryTree,
        channel
      }
    case FETCH_COLLECTION_LIST:
      const collectionList = action.payload;

      return {
        ...state,
        collectionList
      }
    case FETCH_SORTING_ALGORITHM_LIST:
      const sortingAlgorithms = action.payload;

      return {
        ...state,
        sortingAlgorithms
      }
    case SET_PRIORITY_SELECTION:
      const { selectionType, pk, key, sort_option, path } = action.payload;

      return { ...state, selection: { type: selectionType, pk, key, sort_option, path } }
    case ADD_SORTING_ALGORITHM:
      const newAlgorithm = action.payload;

      return { ...state, selectedAlgorithms: [...state.selectedAlgorithms, newAlgorithm] }
    case REMOVE_SORTING_ALGORITHM:
      const removeIndex = action.payload;

      return {
        ...state,
        selectedAlgorithms: arrayRemove(state.selectedAlgorithms, removeIndex)
      }
    case MOVE_SORTING_ALGORITHM:
      const { oldIndex: moveOldIndex, newIndex: moveNewIndex } = action.payload;

      return {
        ...state,
        selectedAlgorithms: arrayMove(state.selectedAlgorithms, moveOldIndex, moveNewIndex),
      }
    case UPDATE_SORTING_ALGORITHMS:
      return state;
    case UPDATE_DATATABLE_SELECTIONS:
      const datatableSelections = action.payload;

      return {
        ...state,
        datatableSelections
      }
    case ADD_STATIC_SORTING_ITEM:
      return state;
    case MOVE_STATIC_PRODUCT:
      const { oldIndex, newIndex } = action.payload;

      return {
        ...state,
        sortableProductList: arrayMove(state.sortableProductList, oldIndex, newIndex)
      }
    case REMOVE_STATIC_PRODUCT:
      const index = action.payload;

      return {
        ...state,
        sortableProductList: arrayRemove(state.sortableProductList, index)
      }
    case FETCH_STATIC_PRODUCTS:
      const staticProductList = action.payload;

      return {
        ...state,
        staticProductList
      }
    case SET_SORTABLE_PRODUCT_LIST:
      const sortableProductList = action.payload;

      return {
        ...state,
        sortableProductList: sortableProductList
      }
    default:
      return state;
  }
};  
