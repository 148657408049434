import React from "react";
import { connect } from "react-redux";

import 
{ 
  fetchSortingAlgorithmDetail,
  getCatalogs,
  fetchAttributes,
  clearSortingAlgorithmDetail
 } from "@resources/actions/sortingAlgorithm"
import { setActionElements } from "@common";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";

import GenericAlgorithmForm from "./genericForm";
import DynamicAlgorithmForm from "./dynamicForm";
class SortingAlgorithmDetail extends React.Component {
  state = {
    loading: true
  }

  fetchAlgorithmData = async (id) => {
    const { fetchSortingAlgorithmDetail } = this.props;

    await fetchSortingAlgorithmDetail(id);

    this.setState({ loading: false });
  }

  componentDidMount() {
    const {
      match: { params: { id } },
      getCatalogs,
      fetchAttributes
    } = this.props;

    (async () => {
      await getCatalogs();
      await fetchAttributes();

      if (id) { await this.fetchAlgorithmData(id); }
      else {
        this.setState({ loading: false });
      }
    })();

    setActionElements();
  }

  componentWillUnmount(){
    this.props.clearSortingAlgorithmDetail()
  }

  render() {

    return (
      <LayoutContentWrapper loading={this.state.loading} className= "algorithm-detail">
        <GenericAlgorithmForm />
        <DynamicAlgorithmForm />
      </LayoutContentWrapper>
    )
  }
}

export default connect(null, {
  getCatalogs,
  fetchAttributes,
  fetchSortingAlgorithmDetail,
  clearSortingAlgorithmDetail
})(SortingAlgorithmDetail);