import React from 'react';
import classnames from "classnames";

import BoxTitleWrapper from '@components/utility/boxTitle';
import { BoxWrapper } from './boxComponents';
import withLoader from "../../LoadingContainer"

const Box = props => {
    const {
    className,
    style,
    title,
    subtitle,
    actions,
    children,
    checkbox,
    leftSideColor,
    "data-tour": dataTour
  } = props;

  return <BoxWrapper
    className={`${className} isoBoxWrapper`}
    style={style}
    data-tour={dataTour}
  >
    <div className="box-header">
      <div className={classnames(["left-side", leftSideColor])}>
        <BoxTitleWrapper title={title} subtitle={subtitle} />
      </div>
      {actions && <div className="right-side">
        {checkbox && <span>{checkbox}</span>} {actions}
      </div>
      }
    </div>
    {children}
  </BoxWrapper>
}

export default withLoader(Box);