import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { navigator } from '@common/navigation';
import { sendRequest } from '@common/network';
import { isDefinedAndNotEmpty } from '@utils';
import { inputTypes } from '@components/DynamicForm/constants';
import {
  formLayoutTypes,
  httpMethods,
  validationTypes,
  activePassiveBooleanOptions,
} from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { providersURL } from '@constants/serviceUrls';
import { isDefined } from '@utils';

import { getStockLocationDetail, saveStockLocation } from '@resources/actions/stockApp';

const DynamicForm = DynamicFormWithBox('stock-locations-detail');

const StockLocationsDetail = (props) => {
  const { getStockLocationDetail, stockLocationDetail, saveStockLocation } = props;
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    id
      ? getStockLocationDetail(id).then(() => {
          setLoading(false);
        })
      : setLoading(false);
  }, [getStockLocationDetail, id]);

  const generateFormInputs = () => {
    const defaultValue = id && stockLocationDetail;

    return [
      {
        name: T('stockApp.stock.location'),
        key: 'name',
        data_type: { value: inputTypes.input.alias },
        default_value: defaultValue && defaultValue.name,
        fieldOptions: {
          rules: [validationTypes.required(T('stockApp.stock.location'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('stockApp.stock.location')),
        },
      },
      {
        name: T('stock.location.code'),
        key: 'code',
        data_type: { value: inputTypes.input.alias },
        default_value: defaultValue && defaultValue.code,
        fieldOptions: {
          rules: [validationTypes.required(T('stock.location.code'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('stock.location.code')),
        },
      },
      {
        name: T('stockApp.stock.provider'),
        key: 'provider',
        data_type: { value: inputTypes.remoteselect.alias },
        url: providersURL,
        valueKeyName: 'id',
        labelKeyName: 'name',
        objectKey: 'results',
        default_value: defaultValue?.provider && defaultValue.provider.toString(),
        fieldProps: {
          placeholder: T('enter.variable').format(T('stockApp.stock.provider')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('stockApp.stock.provider'))],
        },
      },
      {
        name: T('status'),
        key: 'is_active',
        data_type: { value: inputTypes.select.alias },
        visible_values: activePassiveBooleanOptions,
        fieldProps: {
          placeholder: T('status'),
        },
        default_value: defaultValue && defaultValue.is_active,
      },
    ].map((input, index) => ({
      ...input,
      pk: index + 1,
    }));
  };

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const onSubmit = (values) => {
    saveStockLocation(id, values).then(() => {
      onNavigate(`/stock/stock-management/stock-locations`);
    });
  };

  const inputFields = generateFormInputs();

  return (
    <LayoutContentWrapper className="stock-locations-form" loading={loading}>
      <DynamicForm
        title={T('stockApp.add.edit').format(T('stockApp.stock.location'))}
        subtitle={T('stockApp.detail.desc').format(T('stockApp.stock.location'))}
        onSubmit={onSubmit}
        // onChange={onChange}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
};

function mapStateToProps(state) {
  return {
    stockLocationDetail: state.stockAppReducer.stockLocationDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStockLocationDetail: (id) => dispatch(getStockLocationDetail(id)),
    saveStockLocation: (id, params) => dispatch(saveStockLocation(id, params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockLocationsDetail);
