import React from "react";

import { getSortingAlgorithms } from "@constants/serviceUrls";
import { T } from "@utils/languageProvider";
import { setActionElements } from "@common";
import { navigator } from "@common/navigation";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import { DatatableBox } from "@components/Datatable";
import Button from "@components/Button";

import "./style.scss";

class SortingAlgorithmList extends React.Component {

  onCreateClick = () => {
    navigator.push(`/settings/sorting-algorithm/form`);
  }

  componentDidMount() {
    setActionElements([
      <Button type = "secondary" onClick={() => this.onCreateClick()} icon="icon-arti">
        {T("new.sorting.algorithm")}
      </Button>
    ]);
  }

  columns = [
    {
      key: 1,
      dataIndex: "name",
      title: T("sorting.label")
    },
    {
      key: 2,
      dataIndex: "sorting_type",
      title: T("sorting.type"),
      render: (type) => <span className="sorting-type">{T(type)}</span>
    }
  ];

  onRowClick = (rowPk) => {
    navigator.push(`/settings/sorting-algorithm/form/${rowPk.pk}`);
  };

  render() {
    return <LayoutContentWrapper>
      <DatatableBox
        rowKey="pk"
        draggable={false}
        columns={this.columns}
        exportable={false}
        url={getSortingAlgorithms}
        subtitle={T("sorting.algorithms")}
        onChangeDataSource={this.onChangeDataSource}
        onRowClick={this.onRowClick}
      />
    </LayoutContentWrapper>
  }
}

export default SortingAlgorithmList;