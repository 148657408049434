import React from "react";
import classnames from "classnames";

import "./style.scss";

const BoxTitle = (props) => {
  const className = classnames(props.className, "boxTitle-h3");
  return <h3 {...props} className={className}>{props.children}</h3>;
};

const BoxSubTitle = (props) => {
  const className = classnames(props.className, "boxTitle-p");
  return <p {...props} className={className}>{props.children}</p>;
};

export { BoxTitle, BoxSubTitle };
