import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { navigator } from '@common/navigation';
import { inputTypes } from '@components/DynamicForm/constants';
import {
  formLayoutTypes,
  validationTypes,
  activePassiveBooleanOptions,
} from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';

import { getStockListDetail, getStockListRules, saveStockList } from '@resources/actions/stockApp';

const DynamicForm = DynamicFormWithBox('stock-list-detail');

const StockListsDetail = ({
  getStockListDetail,
  getStockListRules,
  stockListDetail,
  stockListRules,
  saveStockList,
}) => {
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    await Promise.all([getStockListDetail(id), getStockListRules()]).then(() => {
      setLoading(false);
    });
  };

  const onNavigate = (url) => {
    navigator.push(url);
  };
  const generateFormInputs = () => {
    const defaultValue = id && stockListDetail;

    return [
      {
        pk: 0,
        name: T('stock.list.name'),
        key: 'name',
        default_value: defaultValue?.name,
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('stock.list.name'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('stock.list.name')),
        },
      },
      {
        pk: 1,
        name: T('stock.list.code'),
        key: 'code',
        default_value: defaultValue?.code,
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('stock.list.code'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('stock.list.code')),
        },
      },
      {
        pk: 2,
        name: T('status'),
        key: 'is_active',
        data_type: { value: inputTypes.select.alias },
        visible_values: activePassiveBooleanOptions,
        fieldProps: {
          placeholder: T('status'),
        },
        default_value: defaultValue?.is_active,
      },
      {
        pk: 3,
        name: T('remote.id'),
        key: 'remote_id',
        default_value: defaultValue?.remote_id,
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('remote.id'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('remote.id')),
        },
      },
      {
        pk: 4,
        name: T('stockApp.stock.list.rules'),
        key: 'stock_list_rule',
        default_value: defaultValue?.stock_list_rule,
        data_type: { value: inputTypes.select.alias },
        visible_values: stockListRules?.map((item, i) => {
          return {
            pk: i,
            value: item.id,
            label: item.name,
          };
        }),
        fieldOptions: {
          rules: [validationTypes.required(T('stockApp.stock.list.rules'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('stockApp.stock.list.rules')),
        },
      },
    ];
  };

  const onSubmit = (values) => {
    saveStockList(id, values).then(() => {
      onNavigate(`/stock/stock-management/stock-lists`);
    });
  };

  const inputFields = generateFormInputs();

  return (
    <LayoutContentWrapper className="stock-list-form" loading={loading}>
      <DynamicForm
        title={T('stockApp.add.edit').format(T('stockApp.stock.list'))}
        subtitle={T('stockApp.detail.desc').format(T('stockApp.stock.list'))}
        onSubmit={onSubmit}
        // onChange={onChange}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    stockListDetail: state.stockAppReducer.stockListDetail,
    stockListRules: state.stockAppReducer.stockListRules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStockListDetail: (id) => dispatch(getStockListDetail(id)),
    getStockListRules: getStockListRules(),
    saveStockList: (id, params) => dispatch(saveStockList(id, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockListsDetail);
