import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { forceRerender, createAuthorizedPage } from "@utils";

import Landing from "./Landing";
import ShippingCompanyList from "./ShippingCompany/ShippingCompanyList";
import ShippingCompanyDetail from "./ShippingCompany/ShippingCompanyDetail";
import CancellationReasonList from "./CancellationReason/CancellationReasonList";
import CancellationReasonDetail from "./CancellationReason/CancellationReasonDetail";
import Channels from "./Channels/ChannelList";
import ChannelDetail from './Channels/ChannelDetail'
import SortingAlgorithms from "./SortingAlgorithm/AlgorithmList";
import SortingAlgorithmDetail from "./SortingAlgorithm/AlgorithmDetail";
import Users from "./User/Users/UserList";
import UserForm from "./User/Users/UserForm";
import PermissionList from "./User/UserPermissions/Permissions/PermissionList";
import PermissionDetail from "./User/UserPermissions/Permissions/PermissionDetail";
import CatalogPermissionList from "./User/UserPermissions/CatalogPermission/CatalogPermissionList";
import CatalogPermissionDetail from "./User/UserPermissions/CatalogPermission/CatalogPermissionDetail";
import ChannelPermissionList from "./User/UserPermissions/ChannelPermission/ChannelPermissionList";
import ChannelPermissionDetail from "./User/UserPermissions/ChannelPermission/ChannelPermissionDetail";

const AuthorizedChannels = createAuthorizedPage(Channels,"setting-channels" );
const AuthorizedChannelDetail = createAuthorizedPage(ChannelDetail,["setting-channels-create","setting-channels-update"] );
const AuthorizedCancellationReasonList = createAuthorizedPage(CancellationReasonList,"cancellation-reasons");
const AuthorizedCancellationReasonDetail = createAuthorizedPage(CancellationReasonDetail,["cancellation-reasons-create","cancellation-reasons-update"] );
const AuthorizedShippingCompanyList = createAuthorizedPage(ShippingCompanyList,"shipping-company" );
const AuthorizedShippingCompanyDetail = createAuthorizedPage(ShippingCompanyDetail,["shipping-company-create","shipping-company-update"] );
const AuthorizedUser = createAuthorizedPage(Users,["users","admin-v2-settings-users"])
const AuthorizedUserDetail = createAuthorizedPage(UserForm,["users-create","users-update"])
const AuthorizedPermissionList= createAuthorizedPage(PermissionList,"general-permission" );
const AuthorizedPermissionDetail= createAuthorizedPage(PermissionDetail,["general-permission-create","general-permission-update"] );
const AuthorizedCatalogPermissionList = createAuthorizedPage(CatalogPermissionList,"catalog-permission" );
const AuthorizedCatalogPermissionDetail = createAuthorizedPage(CatalogPermissionDetail,["catalog-permission-create","catalog-permission-update"] );
const AuthorizedChannelPermissionList = createAuthorizedPage(ChannelPermissionList,"channel-permission" );
const AuthorizedChannelPermissionDetail = createAuthorizedPage(ChannelPermissionDetail,["channel-permission-create","channel-permission-update"] );


export default class extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/settings" component={Landing} />
        <Route exact path="/settings/channels" component={AuthorizedChannels} />
        <Route path="/settings/channels/form/:id?/:row?" component={AuthorizedChannelDetail} />
        <Route exact path="/settings/cancellation-reasons" component={AuthorizedCancellationReasonList} />
        <Route path="/settings/cancellation-reasons/form/:id?" component={AuthorizedCancellationReasonDetail} />
        <Route exact path="/settings/shipping-company" component={AuthorizedShippingCompanyList} />
        <Route path="/settings/shipping-company/form/:id?" component={AuthorizedShippingCompanyDetail} />
        <Route exact path="/settings/sorting-algorithm" component={SortingAlgorithms} />
        <Route path="/settings/sorting-algorithm/form/:id?" render={forceRerender(SortingAlgorithmDetail)} />
        <Route exact path="/settings/users" component={AuthorizedUser} />
        <Route path="/settings/users/form/:id?" component={AuthorizedUserDetail}/>
        <Route exact path="/settings/general-permission" component={AuthorizedPermissionList}/>>
        <Route path="/settings/general-permission/form/:id?" component={AuthorizedPermissionDetail}/>
        <Route path="/settings/catalog-permission/form/:id?" component={AuthorizedCatalogPermissionDetail}/>
        <Route path="/settings/catalog-permission" component={AuthorizedCatalogPermissionList}/>
        <Route path="/settings/channel-permission/form/:id?" component={AuthorizedChannelPermissionDetail}/>
        <Route path="/settings/channel-permission" component={AuthorizedChannelPermissionList}/>
      </Switch>
    );
  }
}

