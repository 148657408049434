import React, { memo } from "react";
import PropTypes from "prop-types";

const Paragraph = memo(({ children, ...otherProps }) => {
  return <p {...otherProps}>{children}</p>;
});

Paragraph.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default Paragraph;
