import React, { Component } from "react"
import { Select } from 'antd';
import { AntSelect } from './selects';

const isoSelect = AntSelect(Select);
const SelectOption = Select.Option;

export default disabledTextSelect(isoSelect);
export { SelectOption };

function disabledTextSelect(WrappedSelect) {
   return class Select extends Component {
       disableInput() {
        //    const selects = document.getElementsByClassName(`ant-select-search__field`)
        //    for (let el of selects) {
        //        el.setAttribute(`maxlength`, 0)
        //    }
       }
       componentDidMount() {
           this.disableInput()
       }
       render() {
           return <WrappedSelect {...this.props} />
       }
   }
}