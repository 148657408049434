import { GET_SCENARIO_OPTIONS_SUCCESS, GET_SCENARIO_DETAIL_SUCCESS, GET_TRANSFER_SCENARIO_DETAIL_SUCCESS } from "../actiontypes";

export const initialState = {
  conditionOptions: [
    { label: "option1", value: "option1" },
    { label: "option2", value: "option2" },
    { label: "option3", value: "option3" }
  ],
  conditionTypes: [
    { label: "type1", value: "type1" },
    { label: "type2", value: "type2" },
    { label: "type3", value: "type3" }
  ],
  scenarioDetail: {},
  transferScenario: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SCENARIO_OPTIONS_SUCCESS:
      return {
        ...state,
        conditionTypes: action.payload.conditionTypes,
        conditionOptions: action.payload.conditionOptions
      };
    case GET_SCENARIO_DETAIL_SUCCESS:
      return {
        scenarioDetail: action.payload || state.scenarioDetail
      }
    case GET_TRANSFER_SCENARIO_DETAIL_SUCCESS:
      return {
        transferScenario: action.payload || state.transferScenario
      }
    default:
      return state;
  }
}
