import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import set from 'lodash/set';
import get from 'lodash/get';
import merge from 'lodash/merge';
import sample from 'lodash/sample';

import { filterTypes, colors } from '@constants/commontypes';
import {
  defaultDateFormat,
  defaultTimeFormat,
  defaultTimeFormatWithTimeZone,
  defaultTimeFormatWithSeconds,
} from '@constants';
import { T } from '@utils/languageProvider';
import { history, navigator } from '@common/navigation';
import { contextChannel } from '@constants/';

import Image from '@components/Image';
import Label from '@components/Label';
import route from '@components/Route';
import AuthorizationInfo from '@components/AuthorizationInfo';
import Intrinsic from '@components/Intrinsic';
import Span from '@components/Span';

import simpleProductImage from '@assets/images/simple-product.svg';
import variantProductImage from '@assets/images/variant-product.svg';
import groupProductImage from '@assets/images/group-product.svg';
import bundleProductImage from '@assets/images/bundle-product.svg';
import miscProductImage from '@assets/images/misc-product.svg';
import noImage from '@assets/images/noimage.png';
import { readStorageItem, writeStorageItem } from '@common/storage';

export function isDefined(obj) {
  return obj !== undefined && obj !== null;
}

export function isDefinedAndNotEmpty(obj) {
  if (typeof obj === 'string')
    return obj !== undefined && obj !== null && obj !== '';
  else if (Array.isArray(obj))
    return obj !== undefined && obj !== null && obj.length;
  else return isDefined(obj);
}

export function createURL(url, routes, queryObject) {
  return addQueryToURL(
    addRoutesToURL(
      url,
      typeof routes === 'string' ? routes.split('/') : routes
    ),
    queryObject
  );
}

export function addRoutesToURL(url = '', routes) {
  if (!routes) return url;
  if (routes instanceof Array) {
    url = url.length
      ? url[url.length - 1] === '/'
        ? url
        : url.concat('/')
      : url;
    for (let index = 0; index < routes.length; index++) {
      const element = routes[index];
      if (isDefinedAndNotEmpty(element))
        url = url.concat(`${encodeURIComponent(element)}/`);
    }
    return url;
  }
  if (typeof routes === 'object') {
    url = '/'.concat(url);
    for (const key in routes) {
      if (routes.hasOwnProperty(key)) {
        const element = routes[key];
        if (isDefinedAndNotEmpty(element))
          url = url.concat(`${encodeURIComponent(element)}/`);
      }
    }
    return url;
  }
  return url;
}

export function addQueryToURL(url = '', queryObject = {}) {
  let queryAdded = false;
  for (const key in queryObject) {
    if (queryObject.hasOwnProperty(key)) {
      const element = queryObject[key];
      if (isDefinedAndNotEmpty(element)) {
        if (!queryAdded) {
          url = url.concat('?');
        }
        url = url.concat(
          `${queryAdded ? '&' : ''}${encodeURIComponent(
            key
          )}=${encodeURIComponent(element)}`
        );
        queryAdded = true;
      }
    }
  }
  return url;
}

export function serializeObjectWithDot(obj) {
  if (!obj || typeof obj !== 'object') return obj;
  let serializedObject = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const element = obj[key];
      const isDotted = key.indexOf('.') !== -1;
      if (isDotted) set(serializedObject, key, element);
      else serializedObject[key] = element;
    }
  }
  return serializedObject;
}

export function concatQueryToURL(url, queryString) {
  return queryString ? url.concat('&', queryString) : url;
}

export function getObjectFromString(valuePath, obj) {
  return get(obj, valuePath);
}

export function setObjectFromString(obj, valuePath, value) {
  return set(obj, valuePath, value);
}

export function filterByObject(data, filterObject, filterOptions) {
  if (!data || !filterObject) return data;
  return data.filter((item) => objectFilter(item, filterObject, filterOptions));
}

function objectFilter(item, filterObject, filterOptions) {
  for (const key in filterObject) {
    if (filterObject.hasOwnProperty(key)) {
      const element = filterObject[key];
      if (
        (item === undefined && element === undefined) ||
        (item === null && element === null)
      )
        continue;
      if (!item.hasOwnProperty(key)) {
        return false;
      }
      if (typeof element === 'object') {
        if (!objectFilter(item[key], element)) return false;
      } else {
        let filter = filterTypes.equals;
        if (filterOptions) {
          filter = filterOptions.find((filter) => filter.name === key);
          filter =
            filter && filter.filterType
              ? filter.filterType
              : filterTypes.equals;
        }
        if (!filter(item[key], element)) return false;
      }
    }
  }
  return true;
}

export function mergeObjects(firstObj, secondObj) {
  return merge(firstObj, secondObj);
}

export function getSmallImageSource(src) {
  if (!src) return '';
  let extensionPointer = src.lastIndexOf('.');
  return src
    .substring(0, extensionPointer)
    .concat('_size50x50')
    .concat(src.substring(extensionPointer, src.length));
}

export function getRandom(max = 10000) {
  return (new Date().getTime() * Math.random()) % (max + 1);
}

export function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export function arrayEqual(arrA, arrB) {
  if (arrA === arrB) {
    return true;
  }

  if (!(arrA instanceof Array) || !(arrB instanceof Array)) {
    return false;
  }
  if (arrA.length !== arrB.length) {
    return false;
  }
  for (let i = 0; i < arrA.length; i++) {
    // eslint-disable-next-line
    if (arrA[i] != arrB[i]) {
      return false;
    }
  }

  return true;
}

export function dateFormatter(dateObject = new Date(), timeFormatting = false) {
  if (!isDefinedAndNotEmpty(dateObject)) return '';
  if (timeFormatting)
    return moment(dateObject).format(
      defaultDateFormat + ' ' + defaultTimeFormat
    );
  return moment(dateObject).format(defaultDateFormat);
}

export function renderDateObject(dateObject = new Date()) {
  if (!isDefinedAndNotEmpty(dateObject)) return '';
  return moment(dateObject);
}

export function timeFormatter(time = '') {
  return moment(time, defaultTimeFormatWithTimeZone).format(
    defaultTimeFormatWithSeconds
  );
}

export function convertTimeToUtc(
  time,
  defaultFormat = defaultTimeFormat,
  convertFormat = defaultTimeFormat
) {
  return moment.utc(moment(time, defaultFormat)).format(convertFormat);
}

export function convertTimeToLocal(
  time,
  defaultFormat = defaultTimeFormat,
  convertFormat = defaultTimeFormat
) {
  return moment.utc(time, defaultFormat).local().format(convertFormat);
}

export function packageStatusColorRender(index, count) {
  if (count > 0) {
    if (colors[index]) {
      return colors[index];
    }
    return sample(colors);
  }
  return '#232b3b';
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function isColorLight(color) {
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  if (hsp > 127.5) {
    return true;
  }
  return false;
}

export function getSmallImageFormatter(imgSrc, { name, parent }) {
  const hasParent = isDefined(parent) && (
    <Intrinsic className="icon-arrow-product" />
  );
  const cellSrc = getSmallImageSource(imgSrc);
  return cellSrc ? (
    <div className="image-wrapper">
      {hasParent}
      <Image className="cell-image" src={cellSrc} />
    </div>
  ) : (
    <div className="image-wrapper">
      {hasParent}
      <Image className="cell-image" src={noImage} />
    </div>
  );
}

export const productIconClasses = [
  { title: T('simple.product'), image: simpleProductImage },
  { title: T('variant.product'), image: variantProductImage },
  { title: T('bundle.product'), image: bundleProductImage },
  { title: T('grouped.product'), image: groupProductImage },
  { title: T('miscellaneous.product'), image: miscProductImage },
];

export const getProductTypeFormatter = (cellData) => {
  if (parseInt(cellData) >= 0) {
    return (
      <div className="product-type-box">
        <Tooltip title={productIconClasses[parseInt(cellData)].title}>
          <Image src={productIconClasses[parseInt(cellData)].image} />
        </Tooltip>
      </div>
    );
  }
};

export function hex2RGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function getAllUrlParams(url) {
  let queryString = decodeURIComponent(
    url ? url.split('?')[1] : window.location.search.slice(1)
  );
  let obj = {};

  if (queryString) {
    queryString = queryString.split('#')[0];
    let arr = queryString.split('&');
    for (let i = 0; i < arr.length; i++) {
      let a = arr[i].split('=');
      let paramName = a[0];
      let paramValue = typeof a[1] === 'undefined' ? true : a[1];
      paramName = paramName.toLowerCase();

      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      if (paramName.match(/\[(\d+)?\]$/)) {
        let key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];
        if (paramName.match(/\[\d+\]$/)) {
          let index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

export function isEmptyFn(f) {
  return (
    typeof f === 'function' &&
    /^function[^{]*[{]\s*[}]\s*$/.test(Function.prototype.toString.call(f))
  );
}

export function booleanFormatter(cellData) {
  return <Label>{cellData ? T('yes') : T('no')}</Label>;
}

export function yesNoFormatter(cellData) {
  return <Label>{cellData ? T('yes') : T('no')}</Label>;
}

export function statusBoolExistinceFormatter(cellData) {
  return <Label>{cellData ? T('exist') : T('not.exist')}</Label>;
}

export function cancellationTypeFormatter(cellData) {
  return <Label>{cellData === 'cancel' ? T('cancel') : T('return')}</Label>;
}

export function statusRenderer(statusArr, cellData) {
  const status = statusArr.find((item) => item.value === cellData);
  return status && status.label;
}

export function inputRenderer(staticInputs, id, formData) {
  return id
    ? [
        ...staticInputs.map((input) => {
          const inputValue = formData && formData[input.key];
          if (inputValue instanceof Array) {
            return {
              ...input,
              default_value: input.default_value || inputValue,
            };
          } else if (inputValue instanceof Object) {
            return {
              ...input,
              default_value:
                input.default_value ||
                JSON.stringify(isDefined(inputValue) ? inputValue : '{}'),
            };
          } else if (inputValue instanceof Boolean) {
            return {
              ...input,
              default_value:
                input.default_value ||
                (isDefined(inputValue) ? 'True' : 'False'),
            };
          } else {
            return {
              ...input,
              default_value:
                input.default_value ||
                (isDefined(inputValue) ? `${inputValue}` : ''),
            };
          }
        }),
      ]
    : staticInputs;
}

export function createAuthorizedPage(component, actionName, unAuthorizedURL) {
  return route({
    component: component,
    actionName: actionName,
    unAuthorizedRender: <AuthorizationInfo />,
    unAuthorizedURL: unAuthorizedURL,
  });
}

/**
 * Forces router to rerender when destination path and target path renders same component
 * @param {component} Component
 */
export const forceRerender = (Component) => (props) =>
  <Component {...props} key={props.location.pathname} />;

export const redirectToSignInPage = (callback) => {
  const { pathWithoutLanguage } = extractLanguageFromPathname(
    history.location.pathname
  );
  const { channelPk, pathWithoutChannel } =
    extractChannelPkFromPathname(pathWithoutLanguage);
  if (channelPk) {
    writeStorageItem(contextChannel, channelPk);
    navigator.channelPk = channelPk;
  }

  if (
    history.location &&
    (history.location.pathname == '/forgotpassword' ||
      history.location.pathname.indexOf('/auth/resetPassword') > -1)
  ) {
    history.push({ pathname: history.location.pathname });
  } else if (history.location && history.location.pathname != '/stock/signin') {
    history.push({
      pathname: '/stock/signin',
    });
  } else history.push({ pathname: '/stock/signin' });

  if (callback && typeof callback === 'function') {
    callback();
  }
};

export const getEntries = (obj) =>
  Object.keys(obj).map((key) => [key, obj[key]]);

export const extractChannelPkFromPathname = (pathname) => {
  const splittedPath = pathname.split('/');
  const [, , channel, ...pathSplittedWithoutChannel] = splittedPath;
  const channelPk = Number(channel) || readStorageItem(contextChannel);
  const pathWithoutChannel = splittedPath.includes('channel')
    ? `/${pathSplittedWithoutChannel.join('/')}`
    : pathname;

  return { channelPk, pathWithoutChannel };
};

export const extractLanguageFromPathname = (pathname) => {
  const splittedPath = pathname.split('/');
  const [, platformLang, channelLang, ...pathSplittedWithoutLanguage] =
    splittedPath;
  const pathWithoutLanguage = `/${pathSplittedWithoutLanguage.join('/')}`;
  const lang = { platform: platformLang, channel: channelLang };
  return { lang, pathWithoutLanguage };
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function fileBase64(file) {
  if (file) {
    const { name, uid } = file;
    const result = await getBase64(file);
    return {
      name,
      uid,
      status: 'done',
      thumbUrl: result,
    };
  }
}

export function unifiedIconRenderer(cellData) {
  return (
    <Tooltip title={cellData ? T('unified.product') : T('not.unified.product')}>
      <Intrinsic
        className={classNames(['icon-unified header-icon', { red: cellData }])}
      />
    </Tooltip>
  );
}

export function latentNameRenderer(cellData) {
  return <Tooltip title={cellData}>{cellData}</Tooltip>;
}

export function statusActivePassiveFormatter(cellData) {
  return (
    <Label className={cellData ? 'green' : 'red'}>
      {T(cellData ? 'active' : 'passive')}
    </Label>
  );
}
export function statusActivePassiveStringFormatter(cellData) {
  return (
    <Label className={cellData === 'active' ? 'green' : 'red'}>
      {T(cellData)}
    </Label>
  );
}

export function statusUsedPassiveFormatter(cellData) {
  return (
    <Label className={cellData === 'used' ? 'green' : 'red'}>
      {T(cellData === 'used' ? 'active' : 'passive')}
    </Label>
  );
}

export const Deferred = () => {
  let resolve;
  let reject;

  return {
    promise: new Promise((...args) => {
      [resolve, reject] = args;
    }),
    resolve,
    reject,
  };
};

export function emptyValueRenderer(value) {
  return isDefinedAndNotEmpty(value) ? value : '-';
}

// source: https://stackoverflow.com/a/30810322
function fallbackCopyTextToClipboard(text) {
  let textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

// source: https://stackoverflow.com/a/30810322
export function copyTextToClipboard(text, cb = () => {}) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      cb(text);
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const extractId = ({
  match: {
    params: { id },
  },
}) => id;

export function readImgAsUrl(delegate, event) {
  const reader = new FileReader();
  reader.onloadend = function () {
    delegate(reader.result);
  };
  if (event.target.files[0]) {
    reader.readAsDataURL(event.target.files[0]);
    return reader.result;
  }
}

export const requiredLabelRenderer = (label) => {
  return (
    <>
      {' '}
      {label} <Span className="red">*</Span>
    </>
  );
};

export const fullNameRenderer = (customer) => {
  return (
    customer &&
    `${customer.first_name && customer.first_name} ${
      customer.last_name && customer.last_name
    }`
  );
};

export const dateValueFormatter = (
  value = new Date(),
  dateFormat,
  dateValueFormat
) => {
  if (!isDefinedAndNotEmpty(value)) return '';
  return moment(value, dateFormat).format(dateValueFormat);
};
