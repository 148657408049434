import React from 'react';
import Card from "./card"
import { navigator } from "@common/navigation"
import basicStyle from "@common/theme/basicStyle";
import { Col, Row } from 'antd';

const cardList = ({ navigationList = [], withChannel = false }) => {
    const { rowStyle, colStyle } = basicStyle;
    const navigate = (link) => navigator.push(link, withChannel);
    const items = navigationList.filter(Boolean).map(({ link, ...otherProps }, index) => (
        <Col key={link.concat(".").concat(index)} sm={12} xs={24} lg={8} md={12}  style={colStyle}>
            <Card  {...otherProps} onClick={() => navigate(link)} />
        </Col>
    ));

    return (
        <Row style={rowStyle} gutter={0} justify="start">
            {items}
        </Row>
    );
}

export default cardList;
