import React from 'react'

import Block from "@components/Block";
import Paragraph from "@components/Paragraph";
import Span from "@components/Span";
import Intrinsic from "@components/Intrinsic";

import './style.scss'

const ResponseMessages = ( {type , message, date} ) => {

  const messageTypes = {
    // ActionText'ler örnek olarak yazıldığı için translate yapılmamıştır.
    // Gerçekleşen action ile ilgili tüm sayfalarda succes ve error message'ları
    // 'nın düzenlemesi işi yapıldıktan sonra burası refactor edilecektir.
    success : {
      icon : 'icon-warning_arrow',
      actionText : 'Aksiyon Gerçekleşti'
    },
    error : {
      icon : 'icon-close',
      actionText : 'Bir Hata Gerçekleşti'
    },
    warning : {
      icon : 'icon-warning_tri',
      actionText: 'Bir uyarı var'
    }
  } 
  return (
    <Block className = {`response-message-${type}`}>
        <Paragraph>{message.non_field_errors || message}</Paragraph>
        <Block className="date-message">
        <Intrinsic className="icon-clock" />
        <Span>{date}</Span>
        </Block>
        <Block className = "action-message">
          <Span>{messageTypes[type].actionText}</Span>
          <Intrinsic className={messageTypes[type]['icon']}/>
        </Block>
    </Block>
  )
}

export default ResponseMessages