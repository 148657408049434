import axios from 'axios';
import qs from 'qs';

import { httpMethods } from '@constants/commontypes';
import { isDefined } from '@utils';
import { store } from '@resources/store';
import {
  baseURLSet,
  checkAuthorizationURL,
  loginURL,
} from '@constants/serviceUrls';
import { T } from '@utils/languageProvider';
import { authorizationRequest } from '@resources/actions/auth';
import { logResponseMessages, getAPILanguage } from './index';
import { redirectToSignInPage } from '@utils/';
import { tokenHeaderKEY } from '@constants/';
import { readStorageItem } from './storage';

let instance = null;
let baseURL = baseURLSet.default;
const defaultResponse = {};
const defaultTimeout = 30000;
const disableCheckAuthorizationURLs = [checkAuthorizationURL];

export function setupNetwork() {
  instance = axios.create({
    paramsSerializer: function (params) {
      return qs.stringify(params, { indices: false });
    },
  });
  instance.interceptors.request.use(
    (config) => {
      config.headers.common = {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: ' application/json',
        'Accept-Language': getAPILanguage() || 'tr-tr',
        ...config.headers.common,
      };
      if (!isDefined(config.timeout)) config.timeout = defaultTimeout;
      const token = readStorageItem('tkn');
      if (token)
        config.headers.common = {
          ...config.headers.common,
          Authorization: `${tokenHeaderKEY} ${token}`,
        };
      else
        config.headers.common = {
          ...config.headers.common,
        };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return parseBody(response);
    },
    (error) => {
      if (error.response) {
        return parseError(error.response, error.request);
      } else {
        return Promise.reject(error);
      }
    }
  );
}
const isModifyRequest = (method) => {
  const methods = [
    httpMethods.POST,
    httpMethods.PUT,
    httpMethods.PATCH,
    httpMethods.DELETE,
  ];
  return methods.indexOf(method) !== -1;
};
export function sendRequest({
  onBegin,
  onSuccess,
  onFail,
  onFinally,
  method = httpMethods.GET,
  params,
  headers,
  url,
  successMessage,
  useDefaultBaseURL,
} = {}) {
  if (!url) return;
  if (onBegin && onBegin instanceof Function) {
    const action = onBegin();
    if (action) store.dispatch(action);
  }
  const preURL = useDefaultBaseURL ? baseURLSet.default : baseURL;
  return new Promise((resolve, reject) => {
    call({ url: preURL.concat(url), params, method, headers })
      .then((result) => {
        if (onSuccess && onSuccess instanceof Function) {
          const action = onSuccess(result);
          if (action) store.dispatch(action);
        }
        //Login Ekranında Notification gösterimi kapatıldı.
        if (isModifyRequest(method.toUpperCase()) && url !== loginURL) {
          logResponseMessages({
            type: 'success',
            message: successMessage || T('message.success'),
          });
        }
        resolve(result);
      })
      .catch((error) => {
        if (onFail && onFail instanceof Function) {
          const action = onFail(error);
          if (action) store.dispatch(action);
        }
        reject(error);
      })
      .then(() => {
        if (onFinally && onFinally instanceof Function) {
          const action = onFinally();
          if (action) store.dispatch(action);
        }
      });
  });
}

function call({ method = httpMethods.GET, params = {}, url, headers = {} }) {
  switch (method) {
    case httpMethods.GET:
      return instance.get(url, { params, headers });
    case httpMethods.POST:
      return instance.post(url, params, { headers });
    case httpMethods.PUT:
      return instance.put(url, params, { headers });
    case httpMethods.PATCH:
      return instance.patch(url, params, { headers });
    case httpMethods.OPTIONS:
      return instance.options(url, params, { headers });
    case httpMethods.DELETE:
      return instance.delete(url, { data: params }, { headers });
    default:
      return defaultResponse;
  }
}

function parseError(
  { messages, status, data } = {},
  { responseURL = '' } = {}
) {
  if (
    disableCheckAuthorizationURLs.find(
      (url) => responseURL.indexOf(url) === -1
    ) &&
    (status === 401 || status === 403)
  ) {
    //authorizationRequest().catch(redirectToSignInPage);
  } else if (data) {
    if (responseURL.indexOf(loginURL) === -1) {
      if (typeof data === 'object') {
        Object.keys(data).forEach((key) => {
          let message = {
            type: 'error',
            message: Array.isArray(data[key]) ? data[key][0] : data[key],
          };
          message.message = `${key}: ${message.message}`;
          message.status = status;
          logResponseMessages(message);
        });
      } else {
        const message = { type: 'error', message: data, status };
        logResponseMessages(message);
      }
    }

    return Promise.reject({
      messages: data instanceof Array ? data : [data],
    });
  } else {
    const msg =
      (data.error && data.error.non_field_errors) || T('process.failed');
    logResponseMessages({ type: 'error', message: msg });
    return Promise.reject({
      messages: [msg],
    });
  }
}

function parseBody(response) {
  if (response.status >= 200 && response.status < 300) {
    if (
      response.config.method.toUpperCase() === httpMethods.POST ||
      response.config.method.toUpperCase() === httpMethods.PUT ||
      response.config.method.toUpperCase() === httpMethods.PATCH ||
      response.config.method.toUpperCase() === httpMethods.DELETE
    ) {
      return response.data;
    }
    return response.data;
  } else {
    return parseError(response.data.messages);
  }
}

export let network = instance;
