import React, { Component } from "react";
import {connect} from "react-redux";

import { isDefinedAndNotEmpty,inputRenderer, fileBase64 } from "@utils";
import {
  getUserFormDetail,
  saveUserFormDetail,
  getCatalogGroups,
  getChannelGroups,
  assignUsers,
  getPermissionGroups,
  getPermissionGroupDetailByUser,
  userUploadAvatar,
  clearSettingState
} from "@resources/actions/setting";
import { inputTypes } from "@components/DynamicForm/constants";
import { setActionElements, setSpecialBreadcrumb } from "@common";
import {
  formLayoutTypes,
  validationTypes
} from "@constants/commontypes";
import { T } from "@utils/languageProvider";

import { DynamicFormWithBox } from "@components/DynamicForm";
import LayoutContentWrapper from "@components/utility/layoutWrapper/index";

const DynamicForm = DynamicFormWithBox("user-form");

class UserForm extends Component {

  state = {
    loading:false,
    rules:false,
    avatar : [],
    base64: [],
    isLoading:true
  }

  componentWillUnmount() {
    const {clearSettingState} = this.props;
    clearSettingState();
  }

  componentDidMount() {
    setSpecialBreadcrumb();
    setActionElements([]);
    const {
      match: { params: { id } = {} },
      getUserFormDetail,
      getCatalogGroups,
      getChannelGroups,
      getPermissionGroupDetailByUser,
      getPermissionGroups
      
    } = this.props;
    Promise.all([
      id && getUserFormDetail(id),
      getCatalogGroups(),
      getChannelGroups(),
      id && getPermissionGroupDetailByUser({users : id}),
      getPermissionGroups()
    ])
    .then((result) => {
      this.setState({
        isLoading : false, 
        base64: !(result[0] && result[0].userprofile) ? [] : [{uid:1, url:result[0].userprofile.avatar}]
      })
    })

  }
  generateFormInputs = () => {
    const {userFormDetail,channelGroups,catalogGroups, permissionDetailByUser, permissionGroups} = this.props
    const { base64 } = this.state
    return [
      {
        pk: 0,
        name: T("user.name"),
        key: "username",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("user.name"))]
        },
        fieldProps: {
          placeholder: T("enter.variable").format(T("user.name"))
        }
      },
      {
        pk: 1,
        name: T("first.name"),
        key: "first_name",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("first.name"))]
        },
        fieldProps: {
          placeholder: T("enter.variable").format(T("first.name"))
        }
      },
      {
        pk: 2,
        name: T("last.name"),
        key: "last_name",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("last.name"))]
        },
        fieldProps: {
          placeholder: T("enter.variable").format(T("last.name"))
        }
      },
      {
        pk: 3,
        name: T("email"),
        key: "email",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("email"))]
        },
        fieldProps: {
          placeholder: T("enter.variable").format(T("email"))
        }
      },
      {
        pk: 4,
        name: T("general.auth.group"),
        key: "authPk",
        data_type: {value: inputTypes.remoteselect.alias},
        dataSource: permissionGroups,
        keyName: "name",
        valueKeyName : "pk",
        labelKeyName : "name",
        objectKey : "results",
        default_value: permissionDetailByUser && permissionDetailByUser.results && permissionDetailByUser.results[0] && permissionDetailByUser.results[0].pk.toString(),
        fieldProps: {
          placeholder: T("select.variable").format(T("general.auth.group"))
        }
      },
      {
        pk: 5,
        name: T("catalog.auth.group"),
        key: "group1",
        data_type: {value: inputTypes.remoteselect.alias},
        dataSource : catalogGroups,
        keyName:"group.name",
        valueKeyName:"group.pk",
        labelKeyName:"group.name",
        objectKey : "results",
        multiSelect: true,
        default_value : userFormDetail && userFormDetail.groups && this.mapGroups(userFormDetail.groups , catalogGroups),
        fieldProps: {
          placeholder: T("select.variable").format(T("catalog.auth.group"))
        },
        fieldOptions: {
          rules: [validationTypes.required(T("catalog.auth.group"))]
        }
      },
      {
        pk: 6,
        name: T("channel.auth.group"),
        key: "group2",
        data_type: {value: inputTypes.remoteselect.alias},
        dataSource : channelGroups,
        keyName:"group.name",
        valueKeyName:"group.pk",
        labelKeyName:"group.name",
        objectKey : "results",
        multiSelect: true,
        default_value : userFormDetail && userFormDetail.groups && this.mapGroups(userFormDetail.groups , channelGroups),
        fieldProps: {
          placeholder: T("select.variable").format(T("channel.auth.group"))
        },
        fieldOptions: {
          rules: [validationTypes.required(T("channel.auth.group"))]
        }
      },
      {
        pk: 7,
        name: T("phone"),
        key: "phone",
        data_type: {value: inputTypes.input.alias},
        fieldProps:{
          placeholder : "Örn: 05XXXXXXXXX"
        },
        default_value : (userFormDetail && userFormDetail.userprofile) && userFormDetail.userprofile.phone_number
      },
      {
        pk: 8,
        name: T("pick.file"),
        key: "avatar",
        data_type: {value: inputTypes.file.alias},
        fieldProps: {
          fileList:base64,
          beforeUpload:this.handleChangeImage
        },
      },
      {
        pk: 9,
        name: T("password"),
        key: "password",
        data_type: {value: inputTypes.password.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("password"))]
        },
        fieldProps: {
          placeholder: T("enter.variable").format(T("password"))
        }
      },
      {
        pk: 10,
        name: T("super.user"),
        key: "is_superuser",
        data_type: {value: inputTypes.checkbox.alias},
      },
      {
        pk: 11,
        name: T("staff"),
        key: "is_staff",
        data_type: {value: inputTypes.checkbox.alias},
      },
      {
        pk: 12,
        name:T("is.active"),
        key: "is_active",
        data_type: {value: inputTypes.checkbox.alias},
      }
    ];
  }

  mapGroups = (groups, completeGroupData) => {
    const { results } = completeGroupData;
    let matchedGroupIDs = [];
    if(results) {
      results.map(item => {
        let matchedID = groups.find(userItem => userItem == item.group.pk);
        matchedID && matchedGroupIDs.push(matchedID.toString());
      });
      return matchedGroupIDs;
    }
    else return [];
  }

  valueMapper = (values) => {
    const {auth_group,avatar,phone,group1,group2,...restOfValues} = values;
    const groupNum1 = group1 ? group1.map(Number) : [];
    const groupNum2 = group2 ? group2.map(Number) : [];

    return {
      ...restOfValues,
      userprofile:{phone_number : phone, avatar : null},
      groups : [...groupNum1,...groupNum2]
    }
  }

  onSubmit = values => {
    const {
      match: { params: { id } = {} },
      saveUserFormDetail,
      assignUsers,
    } = this.props;
    const {avatar} = this.state
    const param = this.valueMapper(values);

    saveUserFormDetail(id, param).then(result => {
      isDefinedAndNotEmpty(avatar) && this.sendSpecialFormImage(id || result.pk);
      values.authPk && assignUsers({users:[result.pk]}, values.authPk);
    })
  };

  handleChangeImage = file => {
    fileBase64(file).then((result)=>{
      this.setState({base64:[result]})
    })
    this.setState({avatar:[file]})
    return false;
  };

  sendSpecialFormImage = (id) => {
    const {avatar} = this.state;

    const { userUploadAvatar } = this.props;
    let bodyFormData = new FormData()
    
    bodyFormData.append('userprofile.avatar',avatar[0]);

    userUploadAvatar(bodyFormData,id)
  };

  inputRender = () => {
    const {
      match: { params: { id } = {} },
      userFormDetail
    } = this.props;
    const staticInputs = this.generateFormInputs();
    return inputRenderer(staticInputs,id,userFormDetail)
  }

  render() {
    const {
      match: { params: { id } = {} }
    } = this.props;
    const {loading, isLoading} = this.state;
    const formInputs = this.inputRender()

    return (
      <LayoutContentWrapper loading = {isLoading}>
        <DynamicForm
          title={T("add.edit.user")}
          subtitle={T("add.edit.user.desc")}
          inputs={formInputs}
          submitting={loading}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          layoutType={formLayoutTypes.boxLayout}
        />
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state){
  return{
    userFormDetail : state.settingReducer.userFormDetail,
    channelGroups : state.settingReducer.channelGroups,
    catalogGroups : state.settingReducer.catalogGroups,
    permissionDetail : state.settingReducer.permissionDetail,
    permissionGroups : state.settingReducer.permissionGroups,
    permissionDetailByUser : state.settingReducer.permissionDetailByUser
  }
}

export default connect(mapStateToProps,
  {
    getUserFormDetail,
    getChannelGroups,
    getCatalogGroups,
    saveUserFormDetail,
    assignUsers,
    userUploadAvatar,
    getPermissionGroups,
    clearSettingState,
    getPermissionGroupDetailByUser
  }
)(UserForm)