import React from "react";
import classnames from "classnames";

import "./style.scss";

const WidgetWrapper = props => {
  const className = classnames(props.className, "widgetWrapper");
  return <div {...props} className={className}>{props.children}</div>
}

export { WidgetWrapper };
