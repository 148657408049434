import {
  GET_BASEORDER_DETAIL_SUCCESS, 
  CLEAR_STATE_BASEORDER_DETAIL,
  MAKE_REFUND_ORDER_ITEM_SUCCESS,
  REFUND_ORDER_CARGO_SUCCESS,
  GET_CANCELLATION_REASONS_SUCCESS,
  GET_ORDER_AGREEMENTS_SUCCESS,
  GET_CANCELLATION_REQUESTS_BY_ID_SUCCESS
} from "../actiontypes";

const initialState = {
  orderDetail: {},
  cancellationReasons: [],
  orderAgreements: [],
  cancellationRequestsById: []
};

export default function (state = initialState, action) {
  const result = action.payload;
  switch (action.type) {
    case GET_BASEORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: result || initialState.orderDetail
      };
    case CLEAR_STATE_BASEORDER_DETAIL:
      return {
        ...initialState
      };
    case MAKE_REFUND_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        orderDetail: result || initialState.orderDetail
      }
    case REFUND_ORDER_CARGO_SUCCESS:
      return {
        ...state
      }
    case GET_CANCELLATION_REASONS_SUCCESS:
      return {
        ...state,
        cancellationReasons: result.results || initialState.cancellationReasons
      }
    case GET_ORDER_AGREEMENTS_SUCCESS:
      return {
        ...state,
        orderAgreements: result.results || initialState.orderAgreements
      }
    case GET_CANCELLATION_REQUESTS_BY_ID_SUCCESS:
      return {
        ...state,
        cancellationRequestsById : [...state.cancellationRequestsById,result.results[0]]
      }
    default:
      return state;
  }
}
