import { arrayMove, arrayRemove } from "react-movable";

import {
  FETCH_ALGORITHM_DETAIL,
  GET_CATALOGS,
  SAVE_ALGORITHM_DETAIL,
  FETCH_ATTRIBUTES,
  ADD_DYNAMIC_SORT_ALGORITHM,
  REMOVE_DYNAMIC_SORT_ALGORITHM,
  MOVE_DYNAMIC_SORT_ALGORITHM,
  UPDATE_DYNAMIC_SORT_ALGORITHM,
  CHANGE_SORTING_TYPE,
  CLEAR_SORTING_ALGORITHM_DETAIL
} from "../actiontypes";

const initialState = {
  formData: {},
  catalogs: [],
  result: {},
  attributes: null,
  dynamicFormItems: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALGORITHM_DETAIL:
      const formData = action.payload;
      const newState = {
        ...state,
        ...formData
      };

      return newState;

    case GET_CATALOGS:
      const { results } = action.payload;

      return {
        ...state,
        catalogs: results,
      }

    case SAVE_ALGORITHM_DETAIL:
      const result = action.payload;

      return {
        ...state,
        result,
      }

    case FETCH_ATTRIBUTES:
      const attributes = action.payload;

      return {
        ...state,
        attributes: attributes
      }

    case ADD_DYNAMIC_SORT_ALGORITHM:
      const data = action.payload;

      return {
        ...state,
        dynamicFormItems: [...state.dynamicFormItems, data]
      }

    case REMOVE_DYNAMIC_SORT_ALGORITHM:
      const removeIndex = action.payload;

      return {
        ...state,
        dynamicFormItems: arrayRemove(state.dynamicFormItems, removeIndex)
      }

    case MOVE_DYNAMIC_SORT_ALGORITHM:
      const { destinationIndex, targetIndex } = action.payload;

      return {
        ...state,
        dynamicFormItems: arrayMove(state.dynamicFormItems, destinationIndex, targetIndex),
      }


    case UPDATE_DYNAMIC_SORT_ALGORITHM:
      const { field, value, updateIndex } = action.payload;
      const { dynamicFormItems } = state;

      return {
        ...state,
        dynamicFormItems: [
          ...dynamicFormItems.slice(0, updateIndex),
          { ...dynamicFormItems[updateIndex], [field]: value },
          ...dynamicFormItems.slice(updateIndex + 1),
        ]
      }

    case CHANGE_SORTING_TYPE:
      return {
        ...state,
        formData: {
          ...state.formData,
          sorting_type: action.payload
        }
      }

    case CLEAR_SORTING_ALGORITHM_DETAIL:
      return initialState
    default:
      return state;
  }
}