import React from "react";
import throttle from "lodash/throttle";

import { sendRequest } from "@common/network";
import { attributeValueSearchURL } from "@constants/serviceUrls";
import { addQueryToURL } from "@utils";

import Select, { SelectOption } from "@components/UIElements/Select";

import "./style.scss";

class CreatableRemoteSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      searchData: []
    }
  }

  handleSelectChange = (value) => {
    this.setState({ value }, () => {
      this.props.onChange(value);
    });
  }

  fetchOptions = throttle((value, callback) => {
    sendRequest({
      url: addQueryToURL(attributeValueSearchURL, {
        attribute__key: this.props.attributeKey, limit: 10, q: value
      }),
      onSuccess: ({ results }) => {
        const data = results.map(item => item.name);
        callback(data);
      }
    })
  }, 2000);

  renderOptions = () => {
    return this.state.searchData.map((item, index) => <SelectOption key={index} value={item}>{item}</SelectOption>)
  }

  handleSearch = (value) => {
    const fetchFn = () => {
      this.fetchOptions(value, (data) => {
        this.setState({ searchData: [value, ...data] });
      });
    };

    this.setState({ searchData: [value] }, fetchFn);
  }

  render() {
    return <Select
      showSearch
      onSearch={this.handleSearch}
      onChange={this.handleSelectChange}
      value={this.state.value}
      disabled={this.props.disabled}
      notFoundContent={null}
      className="createable-remote-select"
    >
      {this.renderOptions()}
    </Select>
  }
}

CreatableRemoteSelect.defaultProps = {
  initialValue: [""],
  maxTags: 0,
  onChange: () => { },
  disabled: false,
  attributeKey: ""
}

export default CreatableRemoteSelect;