import {
  GET_OPTIMIZATION_PRICE_LIST_REQUEST,
  GET_OPTIMIZATION_PRICE_LIST_SUCCESS,
  GET_OPTIMIZATION_PRICE_LIST_FAILURE,
  GET_OPTIMIZATION_PRODUCT_PRICE_REQUEST,
  GET_OPTIMIZATION_PRODUCT_PRICE_SUCCESS,
  GET_OPTIMIZATION_PRODUCT_PRICE_FAILURE,
  GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_REQUEST,
  GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_SUCCESS,
  GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_FAILURE,
} from '../actiontypes';

const initialState = {
  loading: false,
  pricelist: [],
  productprice: [],
  scheduledproductprice: [],
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    // pricelist
    case GET_OPTIMIZATION_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_OPTIMIZATION_PRICE_LIST_SUCCESS:
      return {
        loading: false,
        pricelist: action.payload,
        error: '',
      };
    case GET_OPTIMIZATION_PRICE_LIST_FAILURE:
      return {
        loading: false,
        pricelist: [],
        error: action.payload,
      };
    // productprice
    case GET_OPTIMIZATION_PRODUCT_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_OPTIMIZATION_PRODUCT_PRICE_SUCCESS:
      return {
        loading: false,
        productprice:
          action.payload.length > 1 ? action.payload : [action.payload],
        error: '',
      };
    case GET_OPTIMIZATION_PRODUCT_PRICE_FAILURE:
      return {
        loading: false,
        productprice: [],
        error: action.payload,
      };
    // scheduledproductprice
    case GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_SUCCESS:
      return {
        loading: false,
        scheduledproductprice:
          action.payload.length > 1 ? action.payload : [action.payload],
        error: '',
      };
    case GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_FAILURE:
      return {
        loading: false,
        scheduledproductprice: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
