import React, { memo } from "react";
import PropTypes from "prop-types";
import Box from "@components/utility/box/index";
import { Icon } from "antd";

import "./style.scss";
import { T } from "@utils/languageProvider";

const AuthorizationInfo = memo(({ title, content }) => {
  return (
    <Box title={title} className="authorization-info-box">
      <Icon type="stop" className="authorization-info--icon"></Icon>
      {content}
    </Box>
  );
});

AuthorizationInfo.defaultProps = {
  title: T("you.dont.have.authorization"),
  content: T("you.dont.have.authorization.to.see.this.page.on.platform.contact.your.administrator")
}

AuthorizationInfo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string
};

export default AuthorizationInfo;
