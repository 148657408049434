import React, { isValidElement } from "react";

import H3 from "@components/H3";
import Span from "@components/Span";

const renderContent = (Component, content, props) =>
  isValidElement(content) ? content : <Component {...props} children={content} />;

export const transformSteps = steps =>
  steps.map(item => {
    const { content, title, ...rest } = item;

    return {
      ...rest,
      content: (
        <>
          {title && renderContent(H3, title, { className: "step-title" })}
          {renderContent(Span, content, { className: "step-content" })}
        </>
      )
    };
  });
