import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { navigator } from '@common/navigation';
import { inputTypes } from '@components/DynamicForm/constants';
import {
  formLayoutTypes,
  validationTypes,
  activePassiveBooleanOptions,
} from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import {
  getStockListRules,
  saveStockListRules,
} from '@resources/actions/stockApp';

const DynamicForm = DynamicFormWithBox('stock-list-rules-detail');

const StockListRulesDetail = ({
  getStockListRules,
  saveStockListRules,
  stockListRules,
}) => {
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    id
      ? getStockListRules(id).then(() => {
          setLoading(false);
        })
      : setLoading(false);
  }, [getStockListRules, id]);

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const generateFormInputs = () => {
    const defaultValue = id && stockListRules;
    return [
      {
        pk: 0,
        name: T('rule.name'),
        key: 'name',
        default_value: defaultValue?.name,
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required('Kural Adi')],
        },
        fieldProps: {
          placeholder: T('enter.variable').format('Kural Adi'),
        },
      },
      {
        pk: 1,
        name: T('new.rule'),
        key: 'rule',
        default_value:
          defaultValue?.rule && JSON.stringify(defaultValue.rule || {}),
        data_type: { value: inputTypes.textarea.alias },
        fieldOptions: {
          rules: [
            validationTypes.schema,
            validationTypes.required(T('rule.type')),
          ],
        },
        fieldProps: {
          placeholder: T('enter.variable').format('Kural'),
          className: 'conf-area',
        },
      },
      {
        pk: 3,
        name: T('rule.type'),
        key: 'rule_type',
        data_type: { value: inputTypes.select.alias },
        fieldProps: {
          options: [
            { value: 'prog', label: 'Prog' },
            { value: 'sql', label: 'Sql' },
            { value: 'raw_sql', label: 'Raw Sql' },
          ],
          placeholder: T('select.variable').format(T('rule.type')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('rule.type'))],
        },
        default_value: defaultValue && defaultValue.rule_type,
      },
      {
        pk: 4,
        name: T('status'),
        key: 'is_active',
        active: true,
        data_type: { value: inputTypes.select.alias },
        visible_values: activePassiveBooleanOptions,
        fieldProps: {
          placeholder: T('status'),
        },
        default_value: defaultValue && defaultValue.is_active,
      },
    ];
  };

  const onSubmit = (values) => {
    const { rule, ...rest } = values;
    saveStockListRules(id, {
      rule: rule && JSON.parse(rule),
      ...rest,
    }).then(() => {
      onNavigate(`/stock/stock-management/stock-list-rules`);
    });
  };

  const inputFields = generateFormInputs();
  return (
    <LayoutContentWrapper className="stock-list-rules-form" loading={loading}>
      <DynamicForm
        title={T('stockApp.add.edit').format(T('stockApp.stock.list.rule'))}
        subtitle={T('stockApp.detail.desc').format(
          T('stockApp.stock.list.rule')
        )}
        onSubmit={onSubmit}
        // onChange={onChange}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    stockListRules: state.stockAppReducer.stockListRules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStockListRules: (id) => dispatch(getStockListRules(id)),
    saveStockListRules: (id, params) =>
      dispatch(saveStockListRules(id, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockListRulesDetail);
