import React, {Component} from "react";
import {connect} from "react-redux";

import {setActionElements, checkAuth} from "@common";
import {navigator} from "@common/navigation";
import {
  getShippingCompanies,
} from "@constants/serviceUrls";
import { T } from "@utils/languageProvider";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import {DatatableBox} from "@components/Datatable";
import Button from "@components/Button";

function onNavigate(url) {
  navigator.push(url);
}

class ShippingCompanyList extends Component {

  componentDidMount() {
    checkAuth("shipping-company-create") && setActionElements([
        <Button type = "secondary"
                icon="icon-arti"
                onClick={() => onNavigate(`/settings/shipping-company/form`)}>
          {T("add.new.shipping.company")}
        </Button>
    ]);
  }

  componentWillUnmount() {
    setActionElements();
  }

  onRowClick = (row) => {
    onNavigate(`/settings/shipping-company/form/${row.pk}`);
  };

  columns = [
    {
      key: 1,
      dataIndex: "name",
      title: T("name1"),
    },
    {
      key: 2,
      dataIndex: "erp_code",
      title: T("erp.code"),
    },
    {
      key: 3,
      dataIndex: "shipping_company",
      title: T("shipping.company1"),
    }
  ];

  render() {
    return (
      <LayoutContentWrapper>
        <>
          <DatatableBox
            rowKey="pk"
            exportable={false}
            columns={this.columns}
            url={getShippingCompanies}
            onRowClick={this.onRowClick}
            subtitle={T("shipping.company.settings")}
            locale={{emptyText: T("result.not.found")}}
            paginationText={T("show.variable").format(T("shipping.company.setting"))} 
            isAuthorized
            authActionName= "shipping-company-update"
          />
        </>
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps
)(ShippingCompanyList);