import React, { PureComponent } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { defaultDateFormat } from "@constants";
import { T } from "@utils/languageProvider";

import Datepicker from "@components/UIElements/DatePicker";

import "./style.scss";

class DateRangePicker extends PureComponent {

  constructor(props) {
    super(props);
    let now = moment(new Date());
    this.state = {
      startValue: props.startDate || now,
      endValue: props.endDate || now,
      endOpen: false
    };
  }

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  onChange = (start, end) => {
    this.setState(prevState => ({
      startValue: start || prevState.startValue,
      endValue: end || prevState.endValue
    }), () => {
      const { startValue, endValue } = this.state;
      this.props.onChange({ start: startValue, end: endValue });
    });
  }

  onStartChange = (value) => {
    const { endValue } = this.state;
    if (value > endValue) {
      this.onChange(value, value)
    }
    else {
      this.onChange(value, null);
    }
  };

  onEndChange = (value) => {
    this.onChange(null, value);
  }

  handleEndOpenChange = (endOpen) => {
    this.setState({ endOpen });
  }

  render() {
    const { startValue, endValue, endOpen } = this.state;
    return (
      <div className="daterange-container">
        <Datepicker
          format={defaultDateFormat}
          value={startValue}
          placeholder={T('startdate')}
          onChange={this.onStartChange}
          onOpenChange={this.handleStartOpenChange}
          allowClear={false}
        />
        <Datepicker
          disabledDate={this.disabledEndDate}
          format={defaultDateFormat}
          value={endValue}
          placeholder={T('enddate')}
          onChange={this.onEndChange}
          open={endOpen}
          onOpenChange={this.handleEndOpenChange}
          allowClear={false}
        />
      </div>
    );
  }
}

DateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate : PropTypes.object,
  onChange : PropTypes.func
};

export default DateRangePicker;