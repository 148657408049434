import React, {Component} from "react";
import {connect} from "react-redux";

import {
  savePermissionGroup,
  getChannelGroupDetail,
  saveChannelPermissionGroup,
  clearSettingState
} from "@resources/actions/setting";
import {setActionElements} from "@common";
import {allChannelsURL} from "@constants/serviceUrls";
import {inputTypes} from "@components/DynamicForm/constants";
import {formLayoutTypes, validationTypes} from "@constants/commontypes";
import { T } from "@utils/languageProvider";
import { navigator } from "@common/navigation";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import { DynamicFormWithBox } from "@components/DynamicForm";

const DynamicForm = DynamicFormWithBox("channel-permission");

class ChannelPermissionDetail extends Component {

  componentDidMount() {
    const {match: {params: {id}}, getChannelGroupDetail} = this.props;
    setActionElements([]);
    id && getChannelGroupDetail(id);
  }

  componentWillUnmount() {
    const {clearSettingState} = this.props;
    clearSettingState()
  }

  channelMapper = (channel) =>{
    return channel.map(item => (item.pk.toString()))
  };

  getAttributeInputs = () => {
    const {
      channelGroupDetail
    } = this.props;
    return [
      {
        pk: 1,
        name: T("group.name"),
        key: "name",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("group.name"))]
        },
        default_value: channelGroupDetail.group && channelGroupDetail.group.name && channelGroupDetail.group.name,
        fieldProps: {
          placeholder: T("enter.variable").format(T("group.name"))
        }
      },
      {
        pk: 2,
        name: T("channel.permission"),
        key: "channels",
        data_type: {value: inputTypes.remoteselect.alias},
        active: true,
        url: allChannelsURL,
        objectKey: "results",
        valueKeyName: "pk",
        labelKeyName: "name",
        multiSelect: true,
        fieldOptions: {
          rules: [validationTypes.required(T("channel.permission"))]
        },
        default_value: channelGroupDetail.channels && this.channelMapper(channelGroupDetail.channels),
        fieldProps: {
          placeholder: T("channel.choose")
        }
      }
    ];
  };


  onSubmit = (value, values) => {
    const {
      match: {params: {id}},
      savePermissionGroup,
      channelGroupDetail,
      saveChannelPermissionGroup
    } = this.props;

    const groupPk = channelGroupDetail && channelGroupDetail.group && channelGroupDetail.group.pk;

    const groupParams = id ? values : {name : values.name} ;

    savePermissionGroup(groupPk , groupParams).then( result =>{

      const channelParams = {
        channels : values.channels,
        group : groupPk ? groupPk : result.pk
      };

      saveChannelPermissionGroup(id , channelParams)
      .then(() => navigator.push('/settings/channel-permission/'))
    });
  };

  render() {
    const {
      match: {params: {id}},
    } = this.props;

    const formInputs = this.getAttributeInputs();

    return (
      <LayoutContentWrapper>
          <DynamicForm
            title={ T(id ?"edit.channel.group" : "add.channel.group")}
            subtitle= { T('channelperm.crud.subtitle')}
            inputs={formInputs}
            onSubmit={this.onSubmit}
            objectKey="visible_values"
            layoutType={formLayoutTypes.boxLayout}
          />
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    channelGroupDetail: state.settingReducer.channelGroupDetail
  };
}

export default connect(
  mapStateToProps,
  {
    clearSettingState,
    savePermissionGroup,
    getChannelGroupDetail,
    saveChannelPermissionGroup
  }
)(ChannelPermissionDetail);