import React, {Component} from "react";
import {connect} from "react-redux";

import {
  statusActivePassiveFormatter,
  booleanFormatter, 
  cancellationTypeFormatter,
  latentNameRenderer
} from "@utils";
import {setActionElements, checkAuth} from "@common";
import {navigator} from "@common/navigation";
import {
  getCancellationReason,
} from "@constants/serviceUrls";
import {activePassiveBoolOptions, yesNoOptions, cancellationType} from "@constants/commontypes";
import {inputTypes} from "@components/DynamicForm/constants";
import { T } from "@utils/languageProvider";

import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import DatatableWithFilter from "@components/DatatableWithFilter";
import Button from "@components/Button";

function onNavigate(url) {
  navigator.push(url);
}

class CancellationReasonList extends Component {

  componentDidMount() {
    checkAuth("cancellation-reasons-create") && setActionElements([
        <Button type = "secondary"
                icon="icon-arti"
                onClick={() => onNavigate("/settings/cancellation-reasons/form")}>
            {T("add.cancellation.reason")} 
        </Button>
    ]);
  }

  componentWillUnmount() {
    setActionElements();
  }

  onRowClick = (row) => {
    onNavigate(`/settings/cancellation-reasons/form/${row.pk}`);
  };

  filterInputs = [
    {
      data_type: {value: inputTypes.input.alias},
      key: "attributes__subject",
      name: T("title"),
      pk: "s0",
      active: true,
      fieldProps : {
        placeholder : T('title')
      }
    },
    {
      data_type: {value: inputTypes.select.alias},
      key: "extra_information_needed",
      name: T("extra_information"),
      pk: "s1",
      active: true,
      visible_values: yesNoOptions,
      fieldProps : {
        placeholder : T('extra_information')
      }
    },
    {
      data_type: {value: inputTypes.select.alias},
      key: "cancellation_type",
      name: T("cancellation.type"),
      pk: "s2",
      active: true,
      multiSelect: true,
      visible_values: cancellationType,
      fieldProps : {
        placeholder : T('cancellation.type')
      }
    },
    {
      data_type: {value: inputTypes.select.alias},
      key: "is_active",
      name: T("status"),
      pk: "s3",
      active: true,
      visible_values: activePassiveBoolOptions,
      fieldProps : {
        placeholder : T('status')
      }
    },
  ];

  columns = [
    {
      key: 1,
      dataIndex: "subject",
      title: T("title"),
      render: cellData => latentNameRenderer(cellData)
    },
    {
      key: 2,
      dataIndex: "extra_information_needed",
      title: T("extra_information"),
      render: (cellData) => booleanFormatter(cellData)
    },
    {
      key: 3,
      dataIndex: "cancellation_type",
      title: T("process"),
      render: (cellData) => cancellationTypeFormatter(cellData)
    },
    {
      key: 4,
      dataIndex: "is_active",
      title: T("status"),
      render: statusActivePassiveFormatter
    }
  ];

  render() {
    return (
      <LayoutContentWrapper>
        <DatatableWithFilter
          filterProps={{
            filterInputs: this.filterInputs,
          }}
          datatableProps={{
            subtitle:T("cancel.refund.reasons"),
            rowKey: "pk",
            exportable: false,
            columns: this.columns,
            url: getCancellationReason,
            onRowClick: this.onRowClick,
            locale: {emptyText: T("result.not.found")},
            paginationText: T("show.variable").format(T("cancel.refund.reason")),
            authActionName: "cancellation-reasons-update",
            isAuthorized: true
          }}
        />
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps
)(CancellationReasonList);