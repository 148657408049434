import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import withLoader from "../LoadingContainer";

const Block = forwardRef(({ children, ...otherProps }, ref) => (
  <div ref={ref} {...otherProps}>
    {children}
  </div>
));

Block.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default withLoader(Block);
export const ScrollableBlock = Block;
