import React, { Component } from 'react';
import { connect } from 'react-redux';

import { logout } from '@resources/actions/auth';
import { T } from '@utils/languageProvider';

import Popover from '@components/UIElements/Popover';
import TopbarDropdownWrapper from './topbarDropdown';
import Span from '@components/Span';
import Image from '@components/Image';
import Anchor from '@components/Anchor';
import { history } from '@common/navigation';

import defaultPicture from '@assets/images/user.svg';

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  onLogout = () => {
    this.props.logout();
    history.push('/stock/signin');
  };

  render() {
    const { userData: { first_name, last_name } = {} } = this.props;
    const name = `${first_name} ${last_name}`;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {/* <Anchor className="isoDropdownLink" href="/active-user">
          {T('profile')}
        </Anchor>
        <Anchor className="isoDropdownLink" href="/language-settings">
          {T('language.settings')}
        </Anchor> */}
        {/*<Anchor className="isoDropdownLink" href="# ">
          {T('help')}
    </Anchor>*/}
        <Anchor className="isoDropdownLink" onClick={this.onLogout}>
          {T('sign.out')}
        </Anchor>
      </TopbarDropdownWrapper>
    );

    return (
      <React.Fragment>
        <Popover
          content={content}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          arrowPointAtCenter={true}
          placement="bottomLeft"
          className="topbar-user"
        >
          <Span className="isoImgWrapper">
            <Image alt="user" src={defaultPicture} />
            {/* CDN'deki resim çalışmıyor! <Image alt={name} src={avatar} /> */}
            <Span className="userActivity online" />
          </Span>
        </Popover>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.authReducer.userData,
  };
}

export default connect(mapStateToProps, { logout })(TopbarUser);
