import React, { Component } from 'react';
import {Icon} from 'antd'

import { StickerWidgetWrapper } from './stickerWidgetWrapper';
import Block from "../Block"
import H3 from "../H3"
import Span from "../Span"
import Image from "../Image"
import Intrinsic from "../Intrinsic"


export default class extends Component {
  render() {
    const { fontColor = "#293245", iconColor = "#ffffff", bgColor, width, icon, title, text, image, intrinsic } = this.props;

    const textColor = {
      color: fontColor
    };
    const widgetStyle = {
      width: width
    };
    const iconWrapper ={
      backgroundColor: bgColor,
    }
    const iconStyle = {
      color: iconColor
    };

    return (
      <StickerWidgetWrapper className="isoStickerWidget" style={widgetStyle}>
        <Block className="isoIconWrapper"  style={iconWrapper}>
          {icon && <Icon style={iconStyle} type={icon} />}
          {intrinsic && <Intrinsic className={intrinsic} style={iconStyle}/>}
          {image && <Image src={image} alt={title} />}
        </Block>

        <Block className="isoContentWrapper white">
          <H3 className="isoStatNumber">
            {title}
          </H3>
          <Span className="isoLabel" style={textColor} dangerouslySetInnerHTML={{ __html: text }}/>

        </Block>
      </StickerWidgetWrapper>
    );
  }
}
