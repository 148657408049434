import { getDefaultPath } from "@utils/urlSync";
import actions, { getView } from "../actions/app";
import { 
  GET_ALL_CHANNELS, 
  GET_CONTEXT_CHANNEL, 
  GET_CHANNELS_BY_PARAMS,
  GET_CHANNEL_DETAIL_SUCCESS,
  ADD_TO_ROUTE_STACK,
  GET_API_LANGUAGES_SUCCESS,
  CHANGE_TOGGLE_RIGHT_BAR,
  CHANGE_READ_RECEIPT_RIGHT_BAR
 } from "../actiontypes";
import { readStorageItem, writeStorageItem } from "@common/storage";
import { readResponseMessageKey } from "@constants/";

const preKeys = getDefaultPath();
const readReceipt = JSON.parse(readStorageItem(readResponseMessageKey)) || false;

const initState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  channels: [],
  contextChannel: {},
  channelDetail : {},
  channelsByParams : {},
  routes: [],
  rightBarCollapsed: true,
  readReceipt
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current
      };
    case CHANGE_TOGGLE_RIGHT_BAR:
      return{
        ...state,
        rightBarCollapsed : !action.payload
      }
    case CHANGE_READ_RECEIPT_RIGHT_BAR:
      return {
        ...state,
        readReceipt: action.payload
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: []
      };
    case GET_ALL_CHANNELS: 
      const channelList = action.payload && action.payload.results;
      let contextChnl = channelList.find(item=> item.pk == readStorageItem('contextChannel'));
      let firstChannel = channelList && channelList[0];
      if(!contextChnl){
        writeStorageItem('channelType',(firstChannel && firstChannel.channel_type));
      }
      return {
        ...state,
        channels: channelList,
        contextChannel: contextChnl || firstChannel
      }
    case GET_CONTEXT_CHANNEL:
      const {channels} = state;
      let contextChn = channels.find(item=> item.pk == readStorageItem('contextChannel'));
      let firstChn = channels && channels[0];
      if(!contextChn){
        writeStorageItem('channelType',(firstChn && firstChn.channel_type));
      }
      return {
        ...state,
        contextChannel: contextChn || firstChn
      }
    case GET_CHANNELS_BY_PARAMS:
      return{
        ...state,
        channelsByParams : action.payload
      }
    case GET_CHANNEL_DETAIL_SUCCESS:
      return {
        ...state,
        channelDetail: action.payload
      }
    case ADD_TO_ROUTE_STACK:
      const newRoute = action.payload;
      const routeCount = state.routes.length;
      const routes = routeCount ? [...state.routes.slice(routeCount - 1), newRoute] : [newRoute];
      return { ...state, routes }

    case GET_API_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload && action.payload.languages,
        defaultAPILanguage: action.payload && action.payload.default_language
      }
      
    default:
      return state;
  }
  return state;
}
