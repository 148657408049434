import React from "react";
import { Route } from "react-router-dom";
import { navigator } from "@common/navigation";
import { checkAuth } from "@common";
import { redirectToSignInPage } from "@utils/";

export default ({
  component: WrappedComponent,
  actionName: actionNameFromWrapper,
  unAuthorizedProps: unAuthorizedPropsFromWrapper,
  unAuthorizedURL: unAuthorizedURLFromWrapper,
  unAuthorizedAction: unAuthorizedActionFromWrapper,
  unAuthorizedRender: unAuthorizedRenderFromWrapper
}) => ({
  actionName = actionNameFromWrapper,
  unAuthorizedProps = unAuthorizedPropsFromWrapper,
  unAuthorizedURL = unAuthorizedURLFromWrapper,
  unAuthorizedAction = unAuthorizedActionFromWrapper,
  unAuthorizedRender = unAuthorizedRenderFromWrapper,
  ...props
}) => {
  const isAuthorized = checkAuth(actionName);
  if (!isAuthorized) {
    if(!unAuthorizedRender) unAuthorizeAction(unAuthorizedAction, unAuthorizedURL);
    return unAuthorizedRender || null;
  }
  return (
    <Route
      {...props}
      render={renderProps => {
        return (
          <WrappedComponent
            {...renderProps}
            {...(isAuthorized ? {} : unAuthorizedProps)}
          />
        );
      }}
    />
  );
};

function unAuthorizeAction(action, url) {
  if (action && action instanceof Function) action();
  else {
    if (url) {
      navigator.push(url);
    } else {
      redirectToSignInPage();
    }
  }
}
