import { getCurrentLanguage } from "@components/LanguageSwitcher/config";

String.prototype.format = function() {
  let str = this;
  for (let arg in arguments) {
    str = str.replace("{" + arg + "}", arguments[arg]);
  }
  return str;
}

String.prototype.upper = function(lang) {
  return this.toLocaleUpperCase(lang || (getCurrentLanguage() && getCurrentLanguage().locale) || 'tr');
}
String.prototype.lower = function(lang) {
  return this.toLocaleLowerCase(lang || (getCurrentLanguage() && getCurrentLanguage().locale) || 'tr');
}

String.prototype.toFixed = function() {
  let str = this;
  return parseFloat(str).toFixed(arguments);
}

Array.prototype.isPresent = function() {
  let arr = this;
  return Array.isArray(arr) && arr.length > 0 
}

String.prototype.strippedString = function () {
  let str = this;
  return str.replace(/(<([^>]+)>)/ig,"");
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    } 
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

