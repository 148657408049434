import antdTR from 'antd/lib/locale-provider/tr_TR';
import appLocaleData from 'react-intl/locale-data/tr';
import trMessages from '../locales/tr_TR.json';

const TRLang = {
  messages: {
    ...trMessages,
  },
  antd: antdTR,
  locale: 'tr-TR',
  data: appLocaleData,
};
export default TRLang;
