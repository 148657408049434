import {
  GET_SKU_LOG_SUCCESS,
  GET_SERVICE_LOG_DETAIL_SUCCESS,
  GET_SERVICE_LOG_ID_SUCCESS,
  GET_MAPPING_ERROR_LOG_SUCCESS,
  CLEAR_STATE_INTEGRATIONS, DELETE_MAPPING_ERROR_LOG
} from "../actiontypes";

const initialState = {
  skuLogData: [],
  serviceLogsDetail: [],
  serviceLogsID: [],
  mappingReports: []
};

export default function (state = initialState, action) {
  const result = action.payload;
  switch (action.type) {
    case GET_SKU_LOG_SUCCESS:
      return {
        ...state,
        skuLogData: result || initialState.skuLogData
      };
    case GET_SERVICE_LOG_DETAIL_SUCCESS:
      return {
        ...state,
        serviceLogsDetail: result || initialState.serviceLogsDetail
      };
    case GET_SERVICE_LOG_ID_SUCCESS:
      return {
        ...state,
        serviceLogsID: result || initialState.serviceLogsID
      };
    case CLEAR_STATE_INTEGRATIONS:
      return {
        ...initialState
      }
    case GET_MAPPING_ERROR_LOG_SUCCESS:
      return {
        ...state,
        mappingReports: result || initialState.mappingReports
      };
    case DELETE_MAPPING_ERROR_LOG:
      return {
        ...initialState
      };
    default:
      return state;
  }
}