import {
  GET_ORDER_DETAIL, GET_PACKAGES, 
  CLEAR_STATE_ORDER_DETAIL,
  GET_CUSTOMERS_SUCCESS
} from "../actiontypes";

const initialState = {
  orderDetail: {},
  packages:{},
  customers: []
};

export default function (state = initialState, action) {
  const result = action.payload;
  switch (action.type) {
    case GET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: result || initialState.orderDetail
      };
    case GET_PACKAGES:
      return {
        ...state,
        packages: result || initialState.packages
      };
    case CLEAR_STATE_ORDER_DETAIL:
      return {
        ...initialState
      };
    case GET_CUSTOMERS_SUCCESS:
      const results = result && result.results;
      return {
        ...state,
        customers: results || initialState.customers
      };

    default:
      return state;
  }
}
