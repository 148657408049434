import React from "react";
import { Popover } from 'antd';
import classnames from "classnames";

import "./style.scss";

const PopoverWrapper = Component => props => {
  const className = classnames(props.className, "popoverWrapper");
  return <Component {...props} className={className} />;
};

export default PopoverWrapper(Popover);