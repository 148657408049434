import React, {Component} from "react";
import {connect} from "react-redux";

import {
  cargoCompanies,
  formLayoutTypes,
  validationTypes
} from "@constants/commontypes";
import {isDefined} from "@utils";
import {setActionElements} from "@common";
import {inputTypes} from "@components/DynamicForm/constants";
import {
  clearSettingState,
  getShippingCompanyDetail,
  postShippingCompanyDetail
} from "@resources/actions/setting";
import { navigator } from "@common/navigation";
import { T } from "@utils/languageProvider";

import { DynamicFormWithBox } from "@components/DynamicForm";
import LayoutWrapper from "@components/utility/layoutWrapper/index";

const DynamicForm = DynamicFormWithBox("shipping-company-form");

class ShippingCompanyDetail extends Component {

  state = {
    loading: false,
  };

  componentWillUnmount() {
    const {clearSettingState} = this.props;
    clearSettingState();
  }

  componentDidMount() {
    setActionElements();
    const {match: {params: {id}} , getShippingCompanyDetail} = this.props;
    if (id) {
      getShippingCompanyDetail(id)
    }
  }

  getAttributeInputs = () => {
    return [
      {
        pk: 1,
        name: T("name1"),
        key: "name",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("name1"))]
        },
        fieldProps: {
          placeholder: T("enter.variable").format(T("cargo.company.name"))
        }
      },
      {
        pk: 2,
        name: T("erp.code"),
        key: "erp_code",
        data_type: {value: inputTypes.input.alias},
        fieldOptions: {
          rules: [validationTypes.required(T("erp.code"))]
        },
        fieldProps: {
          placeholder: T("enter.variable").format(T("erp.code"))
        }
      },
      {
        pk: 3,
        name: T("shipping.company"),
        key: "shipping_company",
        data_type: {value: inputTypes.select.alias},
        visible_values: cargoCompanies,
        fieldOptions: {
          rules: [validationTypes.required(T("shipping.company"))]
        },
        fieldProps: {
          placeholder: T("select.variable").format(T("shipping.company"))
        }
      }
    ];
  };

  onSubmit = (values) => {
    const {
      match: {params: {id}},
      postShippingCompanyDetail
    } = this.props;

    postShippingCompanyDetail(id,values).then(() => navigator.push('/settings/shipping-company/'))
  };

  inputRenderer = () =>{
    const {
      match:{params:{id}},
      shippingCompanyDetail
    } = this.props;

    const staticInputs = this.getAttributeInputs();
    return id ? [...staticInputs.map(input => {
      if (typeof shippingCompanyDetail[input.key] == "object") {
        return {
          ...input,
          default_value: JSON.stringify(isDefined(shippingCompanyDetail[input.key]) ? shippingCompanyDetail[input.key] : {})
        }
      } else if (typeof shippingCompanyDetail[input.key] == "boolean") {
        return {
          ...input,
          default_value: isDefined(shippingCompanyDetail[input.key]) ? "True" : "False"
        }
      } else {
        return {
          ...input,
          default_value: isDefined(shippingCompanyDetail[input.key]) && `${shippingCompanyDetail[input.key]}`
        }
      }
    })] : staticInputs
  };


  render() {
    const {
      loading,
    } = this.state;

    const formInputs = this.inputRenderer();

    return (
      <LayoutWrapper>
          <DynamicForm
            title={T("shipping.company.add.or.edit")}
            subtitle={T('shipping.company.add.or.edit.desc')}
            inputs={formInputs}
            submitting={loading}
            onSubmit={this.onSubmit}
            objectKey="visible_values"
            layoutType={formLayoutTypes.boxLayout}
          />
      </LayoutWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    shippingCompanyDetail : state.settingReducer.shippingCompanyDetail,
  };
}

export default connect(
  mapStateToProps,
  {
    getShippingCompanyDetail,
    postShippingCompanyDetail,
    clearSettingState
  }
)(ShippingCompanyDetail);
