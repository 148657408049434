import { sendRequest } from "@common/network";
import { httpMethods } from "@constants/commontypes";
import { addQueryToURL, createURL } from "@utils";
import {
  sortingAlgorithms,
  catalogURL,
  getAttributesURL,
} from "@constants/serviceUrls";
import { T } from "@utils/languageProvider";
import { propertyPrefix, staticProperties } from "@containers/Settings/SortingAlgorithm/AlgorithmDetail/constants";

import {
  FETCH_ALGORITHM_DETAIL,
  SAVE_ALGORITHM_DETAIL,
  GET_CATALOGS,
  FETCH_ATTRIBUTES,
  ADD_DYNAMIC_SORT_ALGORITHM,
  REMOVE_DYNAMIC_SORT_ALGORITHM,
  UPDATE_DYNAMIC_SORT_ALGORITHM,
  MOVE_DYNAMIC_SORT_ALGORITHM,
  CHANGE_SORTING_TYPE,
  CLEAR_SORTING_ALGORITHM_DETAIL
} from "../actiontypes";

function normalizeAlgorithmDetail(result) {
  const normalizedResult = result;

  // dinamik sıralama için ruleset ve config normalizasyonu gerekli değil
  if (result.sorting_type === "dynamic") return result;

  if (normalizedResult.hasOwnProperty("config")) {
    for (const configKey in normalizedResult.config) {
      normalizedResult[`config__${configKey}`] = normalizedResult.config[configKey];
    }
  }

  // harici sıralama tipi için sadece bir tane ruleset dönüyor
  if (normalizedResult.hasOwnProperty("ruleset") && normalizedResult.ruleset.length === 1) {
    const [ruleset] = normalizedResult.ruleset;

    for (const ruleKey in ruleset) {
      normalizedResult[`ruleset__${ruleKey}`] = ruleset[ruleKey];
    }
  }

  return normalizedResult;
}

export const fetchSortingAlgorithmDetail = (id) => {
  return async () => sendRequest({
    url: createURL(sortingAlgorithms, [id]),
    onSuccess: result => {
      const normalizedResult = { formData: normalizeAlgorithmDetail(result) }

      if (result.sorting_type === "dynamic") {
        normalizedResult["dynamicFormItems"] = result.ruleset.map((rule) => {
          const [firstExp] = rule.exp;
          const { field, value: attribute = "", lookup } = firstExp;
          const property = field.startsWith(propertyPrefix) ? field.slice(propertyPrefix.length) : field; 
          const sorting = rule["rule_type"] === "liquer" ? lookup : rule.order;

          return { property, sorting, attribute, original_attribute: field, _id: Math.random() }
        });
      }

      return {
        type: FETCH_ALGORITHM_DETAIL,
        payload: normalizedResult
      }
    }
  })
}

export const clearSortingAlgorithmDetail = () => {
  return {
    type: CLEAR_SORTING_ALGORITHM_DETAIL
  }
}

export const getCatalogs = () => {
  return async () => {
    return sendRequest({
      url: catalogURL,
      onSuccess: result => {
        return {
          type: GET_CATALOGS,
          payload: result
        };
      }
    });
  };
}

export const saveSortingAlgorithm = (data, id) => {

  return async () => sendRequest({
    url: createURL(sortingAlgorithms, [id]),
    params: data,
    method: id ? httpMethods.PATCH : httpMethods.POST,
    successMessage: id ? T("update.success").format(T("general.sort.setting")) : T("add.success").format(T("general.sort.setting")),
    onSuccess: result => {
      return {
        type: SAVE_ALGORITHM_DETAIL,
        payload: result
      }
    }
  });
}

export const fetchAttributes = () => {
  return async () => sendRequest({
    url: addQueryToURL(getAttributesURL, { limit: 9999 }),
    onSuccess: result => {
      return {
        type: FETCH_ATTRIBUTES,
        payload: [...staticProperties, ...result.results]
      }
    }
  });
}

export const addDynamicSortAlgorithm = (data) => {
  return {
    type: ADD_DYNAMIC_SORT_ALGORITHM,
    payload: data
  }
}

export const removeDynamicSortAlgorithm = (index) => {
  return {
    type: REMOVE_DYNAMIC_SORT_ALGORITHM,
    payload: index
  }
}

export const moveDynamicSortAlgorithm = ({ oldIndex, newIndex }) => {
  return {
    type: MOVE_DYNAMIC_SORT_ALGORITHM,
    payload: { destinationIndex: oldIndex, targetIndex: newIndex }
  }
}

export const updateDynamicSortAlgorithm = (field, value, updateIndex) => {
  return {
    type: UPDATE_DYNAMIC_SORT_ALGORITHM,
    payload: { field, value, updateIndex }
  }
}

export const changeSortingType = (sorting_type) => {
  return {
    type: CHANGE_SORTING_TYPE,
    payload: sorting_type,
  }
}