import React, { Component } from "react";
import {Row, Col, Upload} from "antd";
import {connect} from "react-redux";
import jwtDecode from "jwt-decode";

import { getCatalogs } from "@resources/actions/catalog";
import { saveContextChannel, executeChannelTask } from "@resources/actions/app";
import { getAllChannels } from "@resources/actions/app";
import {
  postChannelIntegrationBulkUpdate,
  getChannelIntegrationBulkStatus
} from "@resources/actions/setting";
import { navigator } from "@common/navigation";
import { prepareDataSource, saveSites } from "@resources/actions/contentManagement";
import { setActionElements } from "@common";
import {
  validationTypes,
  channelTypes,
  activePassiveBoolOptions
} from "@constants/commontypes";
import { T } from "@utils/languageProvider";
import { Deferred } from "@utils";
import Paragraph from "@components/Paragraph";

import Box from '@components/utility/box/index';
import Form from "@components/UIElements/form";
import Button from "@components/Button"
import Block from "@components/Block";
import Span from "@components/Span";
import H3 from "@components/H3";
import Select, { SelectOption as Option }  from "@components/UIElements/Select"
import Input, { Textarea } from "@components/UIElements/Input";
import LayoutContentWrapper from "@components/utility/layoutWrapper/index";
import Modal from "@components/Modal";

import './style.scss'

const maskToken = (token, sliceLen = 6) => {
  const firstPart = token.slice(0, sliceLen);
  const lastPart = token.slice(token.length - sliceLen);

  return firstPart + "*".repeat(20) +lastPart;
}

const UnifiedInfoRow = ({ label, value }) => {
  return (
    <Block className="unified-info-row">
      <Block className="label">{T(label)}</Block>
      <Block className="value">{value}</Block>
    </Block>
  );
};

const UnifiedInfo = ({data}) => {
  const { token, maturity, name, commission } = data;

  return <Block className="unified-info-container">
    <UnifiedInfoRow label="unified.digital.contract.key" value={token}/>
    <UnifiedInfoRow label="unified.partner.name" value={name}/>
    <UnifiedInfoRow label="unified.commision.rate" value={commission}/>
    <UnifiedInfoRow label="unified.maturity" value={maturity}/>
  </Block>
}

class ChannelDetail extends Component {

  bulkIntegrationInterval = null;

  state = {
    loading:true,
    addingMode: true,
    isUnifiedChannel: false,
    showUnifiedConfirmation: false
  }

  componentDidMount() {
    const { match: { params: { id }} ,getCatalogs, getAllChannels, prepareDataSource } = this.props;
    setActionElements();
    Promise.all([getCatalogs(),getAllChannels()])
    .then(() => prepareDataSource(() => this.setState(
    { loading: false, ...id ? { addingMode : false} : {} })))
  }

  actions = {
    web: {
      send_product_price: T('update.price.info'),
      send_product_stock: T('update.stock.info'),
      send_category_node: T('update.category.tree')
    },
    hepsiburada: {
      send_inventory: T('update.price.stock.info'),
      get_orders: T('update.orders')
    },
    n11: {},
    feed: {}
  }
  
  taskMapping = {
    web: [
      "send_product_price",
      "send_product_stock",
      "send_category_node",
    ]
    ,
    hepsiburada:[
      "send_inventory",
      "get_orders"
    ],
    n11: [],
    feed: []
  }

  updateAllTaskMapping = {
    web: "send_all",
    hepsiburada: "update_all"
  }

  priceAndStockUpdateBlock = [
    {
      title: T("update.bulk.price.version"),
      model: "ProductPrice"
    },
    {
      title: T("update.bulk.stock.version"),
      model: "ProductStock"
    },
    {
      title: T("update.bulk.product.version"),
      model: "Product"
    }
  ]

  handleUpdate = (channel,taskName) => {
    const { executeChannelTask } = this.props;
    if(!taskName) return;
    const params = {
      channel_id: channel.pk,
      task_name: taskName
    };
    executeChannelTask(params);
  }

  onChannelTypeChange = (value) => {
    this.setState(() => {
      return { isUnifiedChannel: value === "unified" }
    });
  }

  unifiedConfirmationPromise = Deferred();

  handleSubmit = async (e) => {
    e.preventDefault()
    const {
    match : { params : { row }}, 
    saveContextChannel,
    channelsWithSites,
    saveSites,
    form : { validateFields }} = this.props;
    const { isUnifiedChannel, addingMode } = this.state;

    const values = await validateFields();

    if (isUnifiedChannel) {
      values["channel_type"] = "distributed";
      this.unifiedInfo = {
        token: maskToken(values.conf.token),
        ...jwtDecode(values.conf.token)
      };
      this.setState({ showUnifiedConfirmation: true });
      
    }

    if(addingMode){
      this.unifiedConfirmationPromise.promise.then(() => {
        saveContextChannel(null,values)
        .then(() => navigator.push('/settings/channels/'));
      })
      if (!isUnifiedChannel) {
        this.unifiedConfirmationPromise.resolve();
      }
    }
    else{
      const channel = channelsWithSites.find((_ , index) => index === Number(row))
  
      const {conf,site_name,site_domain, ...restOfValues} = values;
      let params = restOfValues;
      const {conf : channelConf} = channel;
      try {
        if(conf !== JSON.stringify(channelConf)){
          params = {
            conf : JSON.parse(conf),
            ...restOfValues
          }
        }
      }
      catch(err){

      }

      saveContextChannel(channel.pk,params).then(() => {
        if(site_name && site_domain){
          const sitePk = channel.site ? channel.site.pk : null
          const siteParams = {
            name : site_name,
            domain : site_domain
          }
          saveSites(channel.pk,sitePk,siteParams)
        }
        navigator.push('/settings/channels/')
      })
    }
  }

  hideUnifiedConfirmation = () => {

    this.setState(() => {
      return {
        showUnifiedConfirmation: false
      }
    });
  }

  checkIntegrationCacheKey = cacheKey => {
    const { getChannelIntegrationBulkStatus } = this.props;

    getChannelIntegrationBulkStatus(cacheKey)
      .then((result) => {
        if (result && !!result.completed && this.bulkIntegrationInterval) {
          clearInterval(this.bulkIntegrationInterval);
          this.setState({priceAndStockDisable: false});
        }
      }).catch(() => {
        if (this.bulkIntegrationInterval) {
          clearInterval(this.bulkIntegrationInterval);
          this.setState({priceAndStockDisable: false});
        }
      });
  }

  handlePriceAndStockUpdate = (channel, model, file = null) => {
    const { postChannelIntegrationBulkUpdate } = this.props;

    const formData = new FormData();
    formData.append("model", model);
    file && formData.append("filename", file);

    postChannelIntegrationBulkUpdate(channel, formData)
      .then((result) => {
        if (result && result.cache_key) {
          this.bulkIntegrationInterval = setInterval(() => this.checkIntegrationCacheKey(result.cache_key), 2500);
          this.setState({priceAndStockDisable: true});
        }
      });

    return false;
  }

  renderPriceAndStockUpdate() {
    const { match: { params: { id }}, location : {state : {type = {}} = {}} } = this.props;
    const { priceAndStockDisable } = this.state;

    return <Block className="price-stock-update-wrapper">
      {this.priceAndStockUpdateBlock.map((item,index) => {
        return <Block key={index} className = "channels-box-item">
          <Span className = "action-name-text">{item.title}</Span>
          <Block className="action-update-btn-wrapper">
            <Upload
              className="action-update-btn"
              fileList={[]}
              accept=".csv,xls,xlsx"
              beforeUpload={(file) => this.handlePriceAndStockUpdate(id, item.model, file)}
              multiple={false}
            >
              <Button type="secondary"
                      disabled={type === "catalog" || priceAndStockDisable}
                      icon="icon-arti">
                {T("update.uploading.files")}
              </Button>
            </Upload>
            <Button type="primary"
              className="action-update-btn"
              disabled={type === "catalog" || priceAndStockDisable}
              onClick={() => this.handlePriceAndStockUpdate(id, item.model)}
            >
              {T("update.all")}
            </Button>
            </Block>
        </Block>
      })}
    </Block>;
  }

  render() {
    const { loading, addingMode, isUnifiedChannel, showUnifiedConfirmation } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { match : { params : { row }}, location : {state : {type = {}} = {}},catalogs, channelsWithSites } = this.props;
    if(!channelsWithSites) return null;
    const channel = channelsWithSites.length && channelsWithSites.find(( _ ,index) => index === Number(row))
    const actionTypes = this.taskMapping[channel && channel.channel_type] || []
    const showTokenInput = addingMode && isUnifiedChannel;
    return (
      <><LayoutContentWrapper loading = {loading}>
        <Box className = "box-primary form-box" title={T('channel.detail.title')}
        subtitle = {T('settings.channels.detail.desc')}>
          <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} className = "channel-detail"
            onSubmit = {this.handleSubmit}>
            <Form.Item label={T('channel.name')}>
              {getFieldDecorator('name', {
                initialValue : channel && channel.name, rules: [validationTypes.required(T('channel.name'))]
                })(<Input placeholder={T('insert.channel.name')} disabled={type === "catalog"}/>)}
            </Form.Item>
          {addingMode && 
            <Form.Item label={T('channel.type')}>
                {getFieldDecorator('channel_type',{
                  initialValue : channel && channel.channel_type ,rules: [validationTypes.required(T('channel.type'))]
                })(
                <Select placeholder={T('pick.channel.type')} onChange={this.onChannelTypeChange} disabled={type === "catalog"}>
                  {
                    channelTypes.map( item => {
                      return <Option key={item.pk} value={item.value} >{item.label} </Option>
                    })
                  }
                </Select>)
                
                }
            </Form.Item>
            }
            { showTokenInput && <Form.Item label={T('unified.digital.contract.key')}>
              {getFieldDecorator('conf.token', {
                 rules: [validationTypes.required(T('unified.digital.contract.key'))]
                })(
                  <Input placeholder={T("enter.variable").format(T('unified.digital.contract.key'))} disabled={type === "catalog"}/>
                )}
              </Form.Item>
            }
            <Form.Item label={T('catalog')}>
              {getFieldDecorator('catalog',{
                initialValue : channel && channel.catalog, rules: [validationTypes.required(T('catalog'))]
              })(
                  <Select placeholder={T('pick.catalog')} disabled={type === "catalog"}>
                    {
                      (catalogs && catalogs.results && catalogs.results.length > 0) && 
                      catalogs.results.map(item => {
                        return <Option key={item.name} value={item.pk} >{item.name} </Option>
                      })
                    }
                  </Select>)
              }
            </Form.Item>
            <Form.Item label={T('status')}>
              {getFieldDecorator('is_active', {initialValue: channel && channel.is_active.toString()})(<Select>
                {activePassiveBoolOptions.map(option=> {
                    return <Option key={option.pk} value={option.value}>{option.label}</Option>
                  })
                }
              </Select>)}
            </Form.Item>
              {!addingMode &&  
              <Form.Item label={T('config')} >
                  {getFieldDecorator('conf', { initialValue : channel && JSON.stringify(channel.conf)})
                  (<Textarea disabled={type === "catalog"} rows = {4}/>)
                  }
                </Form.Item>
               }
                {(!addingMode && channel && channel.channel_type === "web") ? 
                <>
                  <Form.Item label={T('site.name')}>
                      {getFieldDecorator('site_name',{ initialValue : channel && channel.site && channel.site.name})(<Input />)}
                  </Form.Item>
                  <Form.Item label={T('domain.name')}>
                      {getFieldDecorator('site_domain', { initialValue :  channel && channel.site && channel.site.domain})(<Input />)}
                  </Form.Item>
                  </> : null
            }
          {channel && actionTypes.map((action, index) => (
              <Block key={index} className = "channels-box-item">
                <Span className = "action-name-text">{this.actions[channel.channel_type][action]}</Span>
                  <Button
                    type="primary"
                    className = "action-update-btn"
                    disabled={type === "catalog"}
                    onClick={() => this.handleUpdate(channel,action)}
                  >
                    {T("update")}
                  </Button>
              </Block>
            )) 
          }
          {channel && this.renderPriceAndStockUpdate()}
          {channel && this.updateAllTaskMapping[channel.channel_type] && 
              <Block className = "update-all">
                  <Button
                    className = "update-all-btn"
                    type="primary"
                    disabled={type === "catalog"}
                    onClick={() => this.handleUpdate(channel,this.updateAllTaskMapping[channel.channel_type])}
                  >
                    {T("update.all")}
                  </Button>
              </Block>}
          <Block className="submit-btn-wrapper">
            <Row>
              <Col offset={5}>
                <Button 
                  type="primary" 
                  htmlType="submit"  
                  className="submit-btn"
                  disabled={type === "catalog"}>
                  {T('save')}
                </Button>
              </Col>
            </Row>
          </Block>
         </Form>
        </Box>
      </LayoutContentWrapper>
      <Modal
          visible={showUnifiedConfirmation}
          onCancel={this.hideUnifiedConfirmation}
          closable
          title={false}
          className="unified-contract-modal"
          centered
          footer={false}>
            <Block className="unified-contract-overview">
              <H3>{T("unified.contract.confirmation")}</H3>
              <Paragraph>{T("unified.contract.share.description")}</Paragraph>
              <UnifiedInfo data={this.unifiedInfo} />
            </Block>
            <Block className="modal-actions">
              <Button onClick={() => {
                  this.unifiedConfirmationPromise.resolve();
                }}>
                  {T("confirm")}
              </Button>
              <Button onClick={() => {
                this.hideUnifiedConfirmation();
              }}>
                {T("deny")}
              </Button>
            </Block>
        </Modal>
      </>
    );
  }
}

const ChannelDetailForm = Form.create({ name:'channelDetail' })(ChannelDetail);

function mapStateToProps(state){
  return{
    catalogs: state.catalogReducer.catalogs,
    channelsWithSites : state.contentManagementReducer.channelsWithSites,
    channels: state.appReducer.channels,
    sites: state.contentManagementReducer.sites
  }
}

export default connect(mapStateToProps,
  {
    getCatalogs,
    saveContextChannel,
    executeChannelTask,
    getAllChannels,
    prepareDataSource,
    saveSites,
    postChannelIntegrationBulkUpdate,
    getChannelIntegrationBulkStatus
  }
)(ChannelDetailForm)