import React, { Component } from "react";

import { T } from "@utils/languageProvider";
import { checkAuth } from '@common/'

import NavigationCardList from "@components/NavigationCard/list";
import LayoutContentWrapper from "@components/utility/layoutWrapper/index";


export default class extends Component {
    navigationList = [
        checkAuth('setting-channels') && 
        {
            link: "/settings/channels",
            title: T('sidebar.channels'),
            text: T('sidebar.channels.desc'),
            color: "#788195",
            intrinsic: "icon-satiskanalllari"
        },
        checkAuth('shipping-company') && 
        {
            link: "/settings/shipping-company",
            title: T('sidebar.settings9'),
            text: T('sidebar.settings9.desc'),
            color: "#788195",
            intrinsic: "icon-kargosirketleri"
        },
        checkAuth('cancellation-reasons') && 
        {
            link: "/settings/cancellation-reasons",
            title: T('cancel.refund.reasons'),
            text: T('cancel.refund.reasons.subtitle'),
            color: "#788195",
            intrinsic: "icon-iptaliadetalepleri"
        },
        ... checkAuth(['users','admin-v2-settings-users']) ? [
            {
                link: "/settings/users",
                title: T("users"),
                text: T("users.1"),
                color: "#788195",
                intrinsic:"icon-musteriler"
              },
              checkAuth('general-permission') && 
              {
                link: "/settings/general-permission",
                title: T("general.permission.group"),
                text: T("general.permission.group.1"),
                color: "#788195",
                intrinsic: "icon-sosyalhesaplar"
              },
              checkAuth('catalog-permission') && 
              {
                link: "/settings/catalog-permission",
                title: T("catalog.permission.group"),
                text: T("catalog.permission.group.1"),
                color: "#788195",
                intrinsic: "icon-sosyalhesaplar"
              },
              checkAuth('channel-permission') && 
              {
                link: "/settings/channel-permission",
                title: T("channel.permissions.groups"),
                text: T("channel.permission.group.1"),
                color: "#788195",
                intrinsic: "icon-sosyalhesaplar"
              }
        ] : []
          
    ].filter(Boolean);

    render() {
        return (
            <LayoutContentWrapper className="module-landing">
                <NavigationCardList navigationList={this.navigationList} />
            </LayoutContentWrapper>
        );
    }
}
/*
general-sort-option.png
*/