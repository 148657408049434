import React from 'react';

import { T } from '@utils/languageProvider';
import NavigationCardList from '@components/NavigationCard/list';
import LayoutContentWrapper from '@components/utility/layoutWrapper';

const StockManagementLanding = () => {
  const getNavigationList = () => {
    return [
      {
        link: '/stock/stock-management/stock-list-rules',
        title: T('stockApp.stock.list.rules'),
        text: T('stockApp.stock.list.rules.desc'),
        color: '#ffa154',
        intrinsic: 'icon-pos-terminal',
      },
      {
        link: '/stock/stock-management/stock-lists',
        title: T('stockApp.stock.lists'),
        text: T('stockApp.stock.lists.desc'),
        color: '#ffa154',
        intrinsic: 'icon-pos-terminal',
      },
      {
        link: '/stock/stock-management/stock-providers',
        title: T('stockApp.stock.providers'),
        text: T('stockApp.stock.providers.desc'),
        color: '#ffa154',
        intrinsic: 'icon-pos-terminal',
      },
      {
        link: '/stock/stock-management/stock-locations',
        title: T('stockApp.stock.locations'),
        text: T('stockApp.stock.locations.desc'),
        color: '#ffa154',
        intrinsic: 'icon-pos-terminal',
      },
      {
        link: '/stock/stock-management/stock',
        title: T('stockApp.stock'),
        text: T('stockApp.stock.desc'),
        color: '#ffa154',
        intrinsic: 'icon-pos-terminal',
      },
    ];
  };
  return (
    <LayoutContentWrapper className="module-landing">
      <NavigationCardList navigationList={getNavigationList()} />
    </LayoutContentWrapper>
  );
};

export default StockManagementLanding;
