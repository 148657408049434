import {sendRequest} from "@common/network";
import {addQueryToURL, createURL} from "@utils";
import { T } from "@utils/languageProvider";
import {httpMethods} from "@constants/commontypes";

import {
  CLEAR_SETTING_STATE,
  GET_SHIPPING_COMPANY_DETAIL,
  GET_CANCELLATION_REASON_DETAIL,
  CLEAR_CARGO_DETAIL_STATE,
  GET_CARGO_DETAIL,
  GET_ENGINE_DETAIL,
  CLEAR_ENGINE_DETAIL_STATE,
  SAVE_ENGINE_DETAIL,
  GET_CATALOG_GROUPS_DETAIL_SUCCESS,
  GET_CHANNEL_GROUPS_DETAIL_SUCCESS,
  GET_PERMISSION_DETAIL_SUCCESS,
  GET_PERMISSION_DETAIL_LIST_SUCCESS,
  GET_USER_FORM_DETAIL,
  GET_CATALOG_GROUPS,
  GET_CHANNEL_GROUPS,
  GET_PERMISSION_GROUPS_SUCCESS,
  GET_PERMISSION_GROUP_DETAIL_BY_USER,
  GET_GOOGLE_MERCHANT_ATTRIBUTE,
  GET_GOOGLE_MERCHANT_FEED_ADAPTER
} from "../actiontypes";
import {
  getShippingCompanies,
  getCancellationReason,
  usersURL,
  getOmsCargoDetail,
  stockLocationEnginesURL,
  activeUserURL,
  groupsURL,
  channelGroupsURL,
  catalogGroupsURL,
  permissionGroupURL,
  getPermissionsURL,
  googleMerchantURL,
  googleMerchantFeedAdapterURL,
  fillStockEngineURL,
  channelsURL
} from "@constants/serviceUrls";

export function getOmsCargoDetails(id) {
  return async () => {
    return sendRequest({
      url: getOmsCargoDetail.replace("{0}",id),
      onSuccess: result => {
        return {
          type: GET_CARGO_DETAIL,
          payload: result
        };
      }
    });
  };
}

export function getShippingCompanyDetail(id) {
  return async () => {
    return sendRequest({
      url: createURL(getShippingCompanies,[id]),
      onSuccess: result => {
        return {
          type: GET_SHIPPING_COMPANY_DETAIL,
          payload: result
        };
      }
    });
  };
}

export function postShippingCompanyDetail(id , params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(getShippingCompanies,[id]) : getShippingCompanies,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("cargo.company")) : T("add.success").format(T("cargo.company")),
      params:  id ? {...params, pk: id} : {...params}
    });
  };
}

export function getCancellationReasonDetail(id) {
  return async () => {
    return sendRequest({
      url: createURL(getCancellationReason,[id]),
      onSuccess: result => {
        return {
          type: GET_CANCELLATION_REASON_DETAIL,
          payload: result
        };
      }
    });
  };
}

export function postCancellationReasonDetail(id , params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(getCancellationReason,[id]) : getCancellationReason,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("cancel.refund.reason")) : T("add.success").format(T("cancel.refund.reason")),
      params:  id ? {...params, pk: id} : {...params}
    });
  };
}

export function getEngineDetails(id) {
  return async () => {
    return sendRequest({
      url: createURL(stockLocationEnginesURL, [id]),
      onSuccess: result => {
        return {
          type: GET_ENGINE_DETAIL,
          payload: result
        };
      }
    });
  };
}

export function patchActiveUser(params){
  return async () => {
    return sendRequest({
      url : activeUserURL,
      method : httpMethods.PATCH,
      params
    })
  }
}

export function changeUserPassword(params) {
  return async () => {
    return sendRequest({
      url : createURL(activeUserURL,['change_password']),
      method : httpMethods.PATCH,
      params
    })
  }
}

export function clearCargoState() {
  return async dispatch => {
    dispatch({
      type: CLEAR_CARGO_DETAIL_STATE
    });
  };
}

export function clearEngineState() {
  return async dispatch => {
    dispatch({
      type: CLEAR_ENGINE_DETAIL_STATE
    });
  };
}

export function saveEngineDetail(id, values) {

  function valueMapper(values) {
    return {
      ...values,
      conf: JSON.parse(values.conf)
    }
  }

  return async () => {
    return sendRequest({
      url: id
        ? createURL(stockLocationEnginesURL, [id])
        : stockLocationEnginesURL,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("add.success").format(T("stock.location.engine")) : T("add.success").format(T("stock.location.engine")),
      params: valueMapper(values),
      onSuccess: result => {
        return {
          type: SAVE_ENGINE_DETAIL,
          payload: result
        };
      }
    });
  }
}

export function fillStockEngine(params) {
  return async () => {
    return sendRequest({
      params,
      url: fillStockEngineURL,
      method: httpMethods.POST,
    });
  };
}

export function userUploadAvatar(params = {}, id) {
  return async () => {
    return sendRequest({
      params,
      url: createURL(usersURL, [id]) ,
      method: httpMethods.PATCH,
    });
  };
}

export function clearSettingState() {
  return async dispatch => {
    dispatch({
      type: CLEAR_SETTING_STATE
    });
  };
}
export function savePermissionGroup(id , params){
  return async () => {
    return sendRequest({
      url:  id ? createURL(groupsURL , [id]) : groupsURL,
      method: id ? httpMethods.PUT : httpMethods.POST,
      params,
    })
  }
}

export function getCatalogGroupDetail(id){
  return async () => {
    return sendRequest({
      url: createURL(catalogGroupsURL , [id , "detailed"]),
      onSuccess: result => {
        return {
          type : GET_CATALOG_GROUPS_DETAIL_SUCCESS,
          payload: result
        }
      }
    })
  }
}

export function saveCatalogPermissionGroup(id , params){
  return async () => {
    return sendRequest({
      url:  id ? createURL(catalogGroupsURL , [id]) : catalogGroupsURL ,
      method: id ? httpMethods.PUT : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("catalog.permission.group")) : T("add.success").format(T("catalog.permission.group")),
      params
    })
  }
}
export function savePermissionGroupDetail(id, params) {
  return async () => {
    return sendRequest({
      url: id ?  createURL(permissionGroupURL,[id]) : permissionGroupURL,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("general.permission.group")) : T("add.success").format(T("general.permission.group")),
      params: params
    });
  };
}

export function getChannelGroupDetail(id){
  return async () => {
    return sendRequest({
      url: createURL(channelGroupsURL , [id , "detailed"]),
      onSuccess: result => {
        return {
          type : GET_CHANNEL_GROUPS_DETAIL_SUCCESS,
          payload: result
        }
      }})
    }
  }

  export function saveChannelPermissionGroup(id , params){
    return async () => {
      return sendRequest({
        url:  id ? createURL(channelGroupsURL , [id]) : channelGroupsURL ,
        method: id ? httpMethods.PUT : httpMethods.POST,
        successMessage: id ? T("update.success").format(T("channel.permission.group")) : T("add.success").format(T("channel.permission.group")),
        params
      })
    }
  }

  export function getPermissionDetailList(queryObject) {
    return async () => {
      return sendRequest({
        url: addQueryToURL(getPermissionsURL,queryObject),
        onSuccess: result =>{
          return{
            type : GET_PERMISSION_DETAIL_LIST_SUCCESS,
            payload: result
          };
        }
      });
    };
  }
  
export function getPermissionGroupDetail(id) {
  return async () => {
    return sendRequest({
      url:  id ? createURL(permissionGroupURL , [id]) : permissionGroupURL,
      onSuccess: result => {
        return{
          type : GET_PERMISSION_DETAIL_SUCCESS,
          payload: result
        }
      }
    });
  };
}

export function deletePermissionGroup(id) {
  return async () => {
    return sendRequest({
      url: createURL(permissionGroupURL, [id]),
      method: httpMethods.DELETE,
      successMessage: T("delete.success").format(T("selected.permission.group")),
    });
  };
}

export function getUserFormDetail(id){
  return async () => {
    return sendRequest(
      {
      url: createURL(usersURL, [id]),
      onSuccess:result => {
        return {
          type : GET_USER_FORM_DETAIL,
          payload:result
        };
      }
    });
  };
}

export function saveUserFormDetail(id , params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(usersURL,[id]) : usersURL,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id ? T("update.success").format(T("user")) : T("add.success").format(T("user")),
      params:  id ? {...params, pk: id} : {...params}
    });
  };
}

export function getCatalogGroups(){
  return async () => {
    return sendRequest({
      url:createURL(catalogGroupsURL,["detailed"]),
      onSuccess:result => {
        return {
          type : GET_CATALOG_GROUPS,
          payload:result
        }
      }
    })
  }
}


export function getChannelGroups(){
  return async () => {
    return sendRequest({
      url:createURL(channelGroupsURL,["detailed"]),
      onSuccess:result => {
        return {
          type : GET_CHANNEL_GROUPS,
          payload:result
        };
      }
    });
  };
}

export function assignUsers(params,id){
  return async () => {
    return sendRequest({
      url:createURL(permissionGroupURL,[id,"assign-users"]),
      method : httpMethods.PATCH,
      successMessage: T("update.success").format(T("user")),
      params : {...params}
    })
  }
}

export function getPermissionGroups(){
  return async () => {
    return sendRequest({
      url: permissionGroupURL,
      onSuccess : result => {
        return{
          type: GET_PERMISSION_GROUPS_SUCCESS,
          payload: result
        }
      }
    })
  }
}

export function getPermissionGroupDetailByUser(queryObject){
  return async () => {
    return sendRequest({
      url: addQueryToURL(permissionGroupURL,queryObject),
      onSuccess : result => {
        return {
          type: GET_PERMISSION_GROUP_DETAIL_BY_USER,
          payload: result
        }
      }
    })
  }
}

export function getGoogleMerchantAttribute(channel,queryObject) {
  return async () => {
    return sendRequest({
      url: addQueryToURL(googleMerchantURL,queryObject).format(channel),
      onSuccess : result => {
        return {
          type: GET_GOOGLE_MERCHANT_ATTRIBUTE,
          payload: result
        }
      }
    })
  }
}

export function patchGoogleMerchantAttribute(channel, id, params) {
  return async () => {
    return sendRequest({
      url: createURL(googleMerchantURL, [id]).format(channel),
      method : httpMethods.PATCH,
      params : {...params}
    })
  }
}

export function deleteGoogleMerchantAttribute(channel,id) {
  return async () => {
    return sendRequest({
      url: createURL(googleMerchantURL, [id]).format(channel),
      method : httpMethods.DELETE,
    })
  }
}

export function postGoogleMerchantAttribute(channel,params) {
  return async () => {
    return sendRequest({
      url:googleMerchantURL.format(channel),
      method : httpMethods.POST,
      params : {...params}
    })
  }
}

export function getGoogleMerchantFeedAdapter(channel,id) {
  return async () => {
    return sendRequest({
      url: createURL(googleMerchantFeedAdapterURL,[id]).format(channel),
      onSuccess : result => {
        return {
          type: GET_GOOGLE_MERCHANT_FEED_ADAPTER,
          payload: result
        }
      }
    })
  }
}

export function saveGoogleMerchantFeedAdapter(channel,id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(googleMerchantFeedAdapterURL,[id]).format(channel): googleMerchantFeedAdapterURL.format(channel),
      method : id ? httpMethods.PATCH : httpMethods.POST,
      params: {...params}
    })
  }
}

export function postChannelIntegrationBulkUpdate(channel, params) {
  return async () => {
    return sendRequest({
      url : createURL(channelsURL, [channel, "bulk_update_integration_action"]),
      method: httpMethods.POST,
      params
    })
  }
}

export function getChannelIntegrationBulkStatus(cacheKey) {
  return async () => {
    return sendRequest({
      url : createURL(channelsURL, [cacheKey, "bulk_update_integration_action_status"]),
      method: httpMethods.GET
    })
  }
}