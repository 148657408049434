import {
  GET_WEBHOOK_SUCCESS,
  CLEAR_WEBHOOK_STATE,
  GET_PACKAGE_WEBHOOK_SUCCESS,
  CLEAR_PACKAGE_WEBHOOK_STATE,
  GET_TRANSFER_WEBHOOK_SUCCESS, 
  CLEAR_TRANSFER_WEBHOOK_STATE
} from "../actiontypes";

export const initialState = {
  webhook: {},
  packageWebHook: {},
  transferWebHook: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_WEBHOOK_SUCCESS:
      return {
        webhook: action.payload || state.webhook
      };
    case CLEAR_WEBHOOK_STATE:
      return { webhook: {} };
    case GET_PACKAGE_WEBHOOK_SUCCESS:
      return {
        packageWebHook: action.payload || state.packageWebHook
      }
    case CLEAR_PACKAGE_WEBHOOK_STATE:
      return {
        packageWebHook: {}
      }
    case GET_TRANSFER_WEBHOOK_SUCCESS:
      return {
        transferWebHook: action.payload || state.transferWebHook
      }
    case CLEAR_TRANSFER_WEBHOOK_STATE:
      return {
        transferWebHook: {}
      }
    default:
      return state;
  }
}