import React from "react";
import classnames from "classnames";

import "./style.scss";

const InputWrapper = Component => props => {
  const className = classnames(props.className, "inputWrapper");
  return <Component {...props} className={className} />;
};

const InputGroupWrapper = Component => props => {
  const className = classnames(props.className, "inputGroupWrapper");
  return <Component {...props} className={className} />;
};

const TextAreaWrapper = Component => props => {
  const className = classnames(props.className, "textAreaWrapper");
  return <Component {...props} className={className} />;
};

const InputSearchWrapper = Component => props => {
  const className = classnames(props.className, "inputSearchWrapper");
  return <Component {...props} className={className} />;
};

export { InputWrapper, InputGroupWrapper, InputSearchWrapper, TextAreaWrapper };
