import {
  GET_PRODUCT_COLLECTION,
  GET_URL_REDIRECT_SUCCESS,
  CLEAR_STATE_URL_REDIRECT,
  SAVE_URL_REDIRECT_SUCCESS,
  GET_URL_DETAIL,
  GET_URL_DETAILS_SUCCESS,
  GET_URL_GENERATOR_CONFIG_SUCCESS,
  GET_SEO_SETTINGS_SUCCESS,
  GET_SEO_FORM_SUCCESS,
  CLEAR_URL_SETTINGS,
  GET_BULK_APPLY_CACHE_KEY,
  GET_CATEGORY_NODE_URL_CONFIGS
} from "../actiontypes";

export const initialState = {
  productCollection: {},
  urlRedirect: {},
  url: {},
  urlDetail: {},
  urlDetails : {},
  urlGeneratorConfig : {},
  urlSeoSettings : {},
  urlSeoFormSettings: {},
  cacheKey: "",
  categoryNode: {}
};

export default function (state = initialState, action) {
  const result = action.payload && action.payload;
  switch (action.type) {
    case GET_PRODUCT_COLLECTION:
      return {
        ...state,
        productCollection: result
      };
    case GET_URL_REDIRECT_SUCCESS:
    case SAVE_URL_REDIRECT_SUCCESS:
      return{
        ...state,
        urlRedirect: action.payload || initialState.serviceLogsID
      }
    case CLEAR_STATE_URL_REDIRECT:
      return{
        ...state,
        urlRedirect: initialState.urlRedirect
      }  
    case GET_URL_DETAIL:
      return{
        ...state,
        urlDetail:result
      }
    case GET_URL_DETAILS_SUCCESS : 
      return{
        ...state,
        urlDetails: result
      }
    case GET_URL_GENERATOR_CONFIG_SUCCESS:
      return{
        ...state,
        urlGeneratorConfig : result
      }
    case GET_SEO_SETTINGS_SUCCESS:
      return{
        ...state,
        urlSeoSettings : result
      }
    case GET_SEO_FORM_SUCCESS:
      return{
        ...state,
        urlSeoFormSettings : result
      }
    case CLEAR_URL_SETTINGS:
      return{
        ...state,
        urlDetail : initialState.urlDetail
      }
    case GET_BULK_APPLY_CACHE_KEY: 
      return {
        ...state,
        cacheKey: result || initialState.cacheKey
      }
    case GET_CATEGORY_NODE_URL_CONFIGS:
      return {
        ...state,
        categoryNode: result || initialState.categoryNode
      }    
    default:
      return state;
  }
}
