import React, { Component } from "react";
import { Upload, Icon} from "antd";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { T } from "@utils/languageProvider";

import Intrinsic from "@components/Intrinsic";
import Span from "@components/Span";
import Image from "@components/Image";
import Block from "@components/Block";
import Modal from "@components/Modal";

import "./style.scss"

const { Dragger } = Upload;

class FileInput extends Component {
  onInputImported = (file, fileList) => {
    const { onChange , singleAction } = this.props;
    if (onChange)  onChange(singleAction ? fileList[fileList.length - 1 ] : fileList);
    return false;
  };

  render() {
    const {
      accept,
      disabled,
      listType,
      multiple,
      showUploadList,
      onPreview,
      onRemove,
      defaultFileList,
      children
    } = this.props;
    return (
      <Upload
        beforeUpload={this.onInputImported}
        accept={accept}
        disabled={disabled}
        listType={listType}
        defaultFileList={defaultFileList}
        multiple={multiple}
        showUploadList={showUploadList}
        onPreview={onPreview}
        onRemove={onRemove}
      >
        {children}
      </Upload>
    );
  }
}

export default FileInput;


const SortableItem = SortableElement(({value}) => (
  <li tabIndex={0} className="file-drag-item">{value}</li>
));

const SortableList = SortableContainer(({items}) => {
  return (
    <ul className="file-drag-wrapper">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

export class DraggerFileInput extends Component {

  state={
    modalVisible:false,
    preview:null
  }

  closeModal = () =>{
    this.setState({
      modalVisible:false,
    },()=>(this.setState({preview:null})))
  }

  closePreview=()=>{
    this.setState({
      preview:null
    })
  }
  
  onPreviewModal=(preview)=>{
    this.setState({
      modalVisible:true,
      preview
    })
  }

  onSortable=(moverInfo)=>{
    const { onFileSortable } = this.props 
    return onFileSortable && onFileSortable(moverInfo)
  }

  itemList = (fileList, type) => {
    const {onRemove, onPreview} = this.props
    const files = fileList && fileList.map((fileItem, index)=>{
      const url = fileItem.thumbUrl || fileItem.url
      return <Block className="file-item image-item" key={index}>
        {
        type === "video" 
          ? (
            <video key={url}>
              <source src={url}/>
            </video>
          )
          : (
            <Image src={url}/>
          )
          }
        <Span className="image-item-action">
          <Icon type="eye" onClick={()=>{
            onPreview 
            ? onPreview(fileItem)
            : this.onPreviewModal(fileItem)
          }}/>
          {onRemove && <Icon type="delete" 
          onClick={()=>onRemove(fileItem)}
          />}
        </Span>
      </Block>
    })
    return files;
  }

  imagesRenderer=(fileList, type)=>{
    const fileItem = this.itemList(fileList, type);
    const lastFileIndex = fileItem.length-1;
    if(fileList.length > 0){
      return (
        <>
          {fileItem[lastFileIndex]}
          {fileList.length > 1  
          && <Block className="file-item remaining-images" onClick={()=>this.onPreviewModal(null)}>
            {`+ ${fileList.length - 1}`}
          </Block>}
        </>
      )
    }
  }



  render() {
    const {
      children,
      fileList,
      limit,
      type,
      ...restProps
    } = this.props;
    const { modalVisible, preview} = this.state;
    const url = preview && (preview.thumbUrl || preview.url);
    const item = this.itemList(fileList,type);

    return (
      <> 
      <Block className="dragger-wrapper">
        <Block className="file-images-wrapper">
          {this.imagesRenderer(fileList, type)}
        </Block>
        <Dragger {...restProps} fileList={false} className="dragger-file-input">
          { children || <Span className="dragger-file-input-desc" >{T("dragger.desc")} </Span> }
        </Dragger>
        <Modal
          visible={modalVisible}
          onCancel={this.closeModal}
          closable
          title={false}
          className="dragger-modal"
          centered
          footer={false}>
          <Block className="file-images-wrapper">
             { preview 
             ?<Block className="preview-wrapper" >
                {fileList.length > 1  && <Intrinsic className="icon-chevron_up" onClick={this.closePreview}/>} 
                <Block className="preview-image">
                {
                  type === "video" 
                    ? (
                      <video  controls>
                        <source src={url}/>
                      </video>
                    )
                    : (
                      <Image src={url}/>
                    )
                }
                </Block>
               </Block> 
              :<SortableList
                  items={item}
                  axis="xy" 
                  distance={7}
                  onSortEnd={this.onSortable}
                  />}
          </Block>
        </Modal>
      </Block> 
      </>
    )
  }
}

