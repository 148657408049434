import * as Sentry from "@sentry/browser";

const url = window.location.hostname;

export function sentrySetup() {
  if (url === "localhost") {
    return false
  }
  else {
    Sentry.init({
      dsn: "https://e7b5f29d83e1482ebc01345cca62452a@sentry.io/1514378",
    });

    Sentry.configureScope((scope) => {
      scope.setTag("url", url);
    });
  }
}