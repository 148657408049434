import React from "react";
import classnames from "classnames";

import "./style.scss";

const LayoutContentWrapper = React.forwardRef((props, ref) => {
  const className = classnames(props.className, "layoutContentWrapper");
  return <div {...props} className={className} ref={ref}>{props.children}</div>;
});

export { LayoutContentWrapper };
