import React from "react";
import classnames from "classnames";

import "./style.scss";

const Buttons = Component => props => {
  const className = classnames(props.className, "styled-buttons");
  return <Component {...props} className={className} />;
};

const RadioButtons = Component => props => {
  const className = classnames(props.className, "styled-radio-buttons");
  return <Component {...props} className={className} />;
};

const ButtonsGroup = Component => props => {
  const className = classnames(props.className, "styled-buttons-group");
  return <Component {...props} className={className} />;
};

const GhostButtons = Component => props => {
  const className = classnames(props.className, "styled-ghost-buttons");
  return <Component {...props} className={className} />;
};

export { Buttons, ButtonsGroup, RadioButtons, GhostButtons };
