import { T } from "@utils/languageProvider/index.js";

export const visibilityTypes = [
  { label: T("active"), value: "true", pk: "0" },
  { label: T("passive"), value: "false", pk: "1" },
];

export const sortingTypes = [
  { label: T("dynamic"), value: "dynamic" },
  { label: T("external"), value: "external" },
];

export const requestTypes = [
  { label: T("metabase"), value: "metabase" },
  { label: T("stream"), value: "stream" },
];

export const sortingOptions = [
  { label: T("sorting.ascending"), value: "asc" },
  { label: T("sorting.descending"), value: "desc" },
].map(option => ({ ...option, rule_type: "sorter" }));

export const extendedSortOptions = [
  ...sortingOptions,
  { label: T("sorting.lt"), value: "lt" },
  { label: T("sorting.lte"), value: "lte" },
  { label: T("sorting.exact"), value: "exact" },
  { label: T("sorting.gte"), value: "gte" },
  { label: T("sorting.gt"), value: "gt" },
].map(option => ({ ...option, rule_type: "liquer" }));

export const elasticSearchModes = [
  { label: T("elastic.mode.min"), value: "min" },
  { label: T("elastic.mode.max"), value: "max" },
  { label: T("elastic.mode.sum"), value: "sum" },
  { label: T("elastic.mode.avg"), value: "avg" },
  { label: T("elastic.mode.median"), value: "median" },
];

export const propertyPrefix = "attributes__";
export const staticProperties = [
  { key: "price", name: T("price") },
  { key: "stock", name: T("stok") },
  { key: "created_date", name: T("created.date") },
];