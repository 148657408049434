import React from "react";
import classnames from "classnames";

import "./style.scss";

const TopbarDropdownWrapper = props => {
  const className = classnames(props.className, "topbarDropdownWrapper");
  return <div {...props} className={className}>{props.children}</div>;
};

export default TopbarDropdownWrapper;
