import { sendRequest } from "@common/network";
import { httpMethods } from "@constants/commontypes";

import {
  FETCH_CHANNEL_SUCCESS,
  FETCH_CHANNEL_OVERALL_SUCCESS,
  FETCH_CHANNEL_CATEGORY_SUCCESS,
  FETCH_CHANNEL_PRODUCT_SUCCESS,
  FETCH_CHANNEL_CITY_SUCCESS,
  FETCH_CHANNEL_CHART_SUCCESS,
  GET_OMS_TOTAL_PACKAGE_SUCCESS,
  GET_OMS_TOTAL_TRANSFER_SUCCESS,
  GET_OMS_TOTAL_COUNT_SUCCESS,
  GET_OMS_WAITING_PACKAGE_SUCCESS,
  GET_OMS_SHIPPED_PACKAGE_SUCCESS
} from "../actiontypes";
import {
  channelURL,
  channelOverallURL,
  channelTopCategoriesURL,
  channelTopProductsURL,
  channelTopCitiesURL,
  channelChartURL,
  getOmsTotalCountURL,
  getOmsShippingPackagesURL,
  getOmsShippedPackageURL,
  getOmsWaitingPackageURL,
  getOmsTotalPackageURL,
  getOmsTotalTransferURL,
  getOmsPackagePerHourURL
} from "@constants/serviceUrls";

export function getChannelInfo(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: channelURL,
      method: httpMethods.GET,
      onSuccess: result => {
        return {
          type: FETCH_CHANNEL_SUCCESS,
          payload: result
        };
      }
    });
  };
}

export function getChannelOverallInfo(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: channelOverallURL,
      method: httpMethods.GET,
      onSuccess: result => {
        return {
          type: FETCH_CHANNEL_OVERALL_SUCCESS,
          payload: result
        };
      }
    });
  };
}

export function getChannelTopProducts(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: channelTopProductsURL,
      method: httpMethods.GET,
      onSuccess: result => {
        return {
          type: FETCH_CHANNEL_PRODUCT_SUCCESS,
          payload: result
        };
      }
    });
  };
}

export function getChannelTopCategories(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: channelTopCategoriesURL,
      method: httpMethods.GET,
      onSuccess: result => {
        return {
          type: FETCH_CHANNEL_CATEGORY_SUCCESS,
          payload: result
        };
      }
    });
  };
}

export function getChannelTopCities(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: channelTopCitiesURL,
      method: httpMethods.GET,
      onSuccess: result => {
        return {
          type: FETCH_CHANNEL_CITY_SUCCESS,
          payload: result
        };
      }
    });
  };
}

export function getChannelChartInfo(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: channelChartURL,
      method: httpMethods.GET,
      onSuccess: result => {
        return {
          type: FETCH_CHANNEL_CHART_SUCCESS,
          payload: result
        };
      }
    });
  };
}


export function getOmsPackageCountsPerHour(params) {
  return async () => {
    return sendRequest({
      params,
      url: getOmsPackagePerHourURL
    });
  };
}

export function getOmsTotalCount(params) {
  return async () => {
    return sendRequest({
      params,
      url: getOmsTotalCountURL,
      onSuccess : result => {
        return{
          type: GET_OMS_TOTAL_COUNT_SUCCESS,
          payload: result
        }
      }
    });
  };
}

export function getOmsShippingPackages(params) {
  return async () => {
    return sendRequest({
      params,
      url: getOmsShippingPackagesURL
    });
  };
}

export function getOmsShippedPackage(params) {
  return async () => {
    return sendRequest({
      params,
      url: getOmsShippedPackageURL,
      onSuccess: result => {
        return {
          type : GET_OMS_SHIPPED_PACKAGE_SUCCESS,
          payload: result
        }
      }
    });
  };
}

export function getOmsWaitingPackage(params) {
  return async () => {
    return sendRequest({
      params,
      url: getOmsWaitingPackageURL,
      onSuccess : result => {
        return {
          type: GET_OMS_WAITING_PACKAGE_SUCCESS,
          payload: result
        }
      }
    });
  };
}

export function getOmsTotalPackage(params) {
  return async () => {
    return sendRequest({
      params,
      url: getOmsTotalPackageURL,
      onSuccess : result => {
        return{
          type: GET_OMS_TOTAL_PACKAGE_SUCCESS,
          payload: result
        }
      }
    });
  };
}

export function getOmsTotalTransfer(params) {
  return async () => {
    return sendRequest({
      params,
      url: getOmsTotalTransferURL,
      onSuccess : result => {
        return {
          type: GET_OMS_TOTAL_TRANSFER_SUCCESS,
          payload: result
        }
      }
    });
  };
}

