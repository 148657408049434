import {
  CLEAR_STATE_CUSTOMER_DETAIL,
  CLEAR_STATE_USER_DETAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  BASKET_OFFERS_BY_CHANNEL_USER_SUCCESS,
  ADDRESSES_BY_CUSTOMER_SUCCESS,
  USER_SCHEMA_TYPE_BY_CHANNEL_SUCCESS,
  USER_DETAIL_SUCCESS,
  ADDRESSES_BY_USER_SUCCESS,
  ORDER_DETAIL_EMAIL_SUCCESS,
  GET_CUSTOMER_ORDER_INFO
} from "../actiontypes";

const initialState = {
  customerDetail: {},
  basketOffers:[],
  addresses: [],
  userSchema: {},
  userDetail: {},
  orderInfo: {}
};

export default function (state = initialState, action) {
  const result = action.payload;
  switch (action.type) {
    case CLEAR_STATE_CUSTOMER_DETAIL:
      return {
        ...initialState
      };
    case GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        customerDetail: result || initialState.customerDetail
      };
    case BASKET_OFFERS_BY_CHANNEL_USER_SUCCESS:
      return {
        ...state,
        basketOffers: (result && result.results) || initialState.basketOffers
      };
    case ADDRESSES_BY_CUSTOMER_SUCCESS:
    case ADDRESSES_BY_USER_SUCCESS:
      const results = result && result.results;
      return {
        ...state,
        addresses: results || initialState.addresses
      };
    case USER_SCHEMA_TYPE_BY_CHANNEL_SUCCESS:
      return {
        ...state,
        userSchema: result || initialState.userSchema
      };
    case ORDER_DETAIL_EMAIL_SUCCESS:
      return {
        ...state,
        orderDetail: (result && result.results) || initialState.orderDetail
      };
    case USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: result || initialState.userDetail
      };
    case GET_CUSTOMER_ORDER_INFO:  
      return{
        ...state,
        orderInfo: result || initialState.orderInfo
      }
    case CLEAR_STATE_USER_DETAIL:
      return {
        customerDetail: {},
        addresses: [],
        orderDetail: [],
        userDetail: {}
      };
    default:
      return state;
  }
}
