import React from "react"
import { connect } from "react-redux";
import { compose } from "redux";
import { List } from "react-movable";
import { Form , Icon} from "antd";
import throttle from "lodash/throttle";
import classNames from "classnames";

import { T } from "@utils/languageProvider/index.js";
import { validationTypes } from "@constants/commontypes";

import Select, { SelectOption } from "@components/UIElements/Select";
import Span from "@components/Span"
import Box from "@components/utility/box/index";
import { default as IntrinsicIcon } from "@components/Intrinsic"

import {
  addDynamicSortAlgorithm,
  moveDynamicSortAlgorithm,
  removeDynamicSortAlgorithm,
  updateDynamicSortAlgorithm
} from "@resources/actions/sortingAlgorithm";

import { extendedSortOptions } from "./constants";
import CreatableRemoteSelect from "./createable_remote_select";

import "./style.scss";

const AddItem = (props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.addDynamicSortAlgorithm({ ...values, _id: Math.random() });
        props.form.resetFields();
      }
    });
  }

  return <Form className="add-form">
    <Form.Item label={T("select.property")}>
      {getFieldDecorator('property', {
        rules: [{ required: true, message: T("algorithms.select.property") }],
      })(
        <Select>
          {props.attributes.map(attribute => {
            return <SelectOption key={attribute.key}>
              {attribute.key} ({attribute.name})
            </SelectOption>
          })}
        </Select>
      )}
    </Form.Item>
    <Form.Item label={T("sort.order")}>
      {getFieldDecorator('sorting', {
        rules: [{ required: true, message: T("algorithms.select.sorting") }],
      })(
        <Select>
          {extendedSortOptions.map(option => {
            return <SelectOption key={option.value} value={option.value}>{option.label}</SelectOption>
          })}
        </Select>
      )}
    </Form.Item>
    <Form.Item label={T("sorting.value")}>
      {getFieldDecorator('attribute', {
        rules: [{ required: true, message: T("algorithms.select.attribute") }],
      })(
        <CreatableRemoteSelect
          placeholder={T("search.property")}
          onChange={value => {
            setFieldsValue({ attribute: value });
          }}
          attributeKey={props.form.getFieldValue('property')}
          disabled={["asc", "desc"].some(sortType => sortType === props.form.getFieldValue('sorting'))}
        />,
      )}
    </Form.Item>
    <Form.Item className="submit-btn-wrapper">
          <IntrinsicIcon className="icon-plus"
          onClick={handleFormSubmit}/>
        <Span>{T("add")}</Span>
    </Form.Item>
  </Form>
}

const WrappedAddItem = compose(
  connect((state) => {
    return {
      attributes: state.sortingAlgorithmsReducer.attributes,
      dynamicFormItems: state.sortingAlgorithmsReducer.dynamicFormItems,
    }
  }, {
    addDynamicSortAlgorithm,
  }),
  Form.create({}),
)(AddItem);

const DynamicListItem = (props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  return <Form className="add-form">
    <Form.Item label= {T("added.property")} >
      {getFieldDecorator('property', {
        rules: [validationTypes.required(T("algorithms.select.property"))],
        initialValue: props.property,
      })(
        <Select>
          {props.attributes.map(attribute => {
            return <SelectOption key={attribute.key}>
              {attribute.name}
            </SelectOption>
          })}
        </Select>
      )}
    </Form.Item>
    <Form.Item label={T("sort.order")} >
      {getFieldDecorator('sorting', {
        rules: [validationTypes.required(T("algorithms.select.sorting"))],
        initialValue: props.sorting,
      })(
        <Select values>
          {extendedSortOptions.map(({ value, label }) => {
            return <SelectOption key={value}>{label}</SelectOption>
          })}
        </Select>
      )}
    </Form.Item>
    <Form.Item label= {T("sorting.value")}>
      {getFieldDecorator('attribute', {
        initialValue: props.attribute,
        rules: [validationTypes.required(T("algorithms.select.attribute"))],
      })(
        <CreatableRemoteSelect
          placeholder={T("search.property")}
          onChange={value => {
            setFieldsValue({ attribute: value });
          }}
          attributeKey={props.form.getFieldValue('property')}
          disabled={["asc", "desc"].some(sortType => sortType === props.form.getFieldValue('sorting'))}
        />,
      )}
    </Form.Item>
    <Form.Item className="submit-btn-wrapper">
        <IntrinsicIcon className= "icon-minus" onClick={props.removeSortingAlgorithm}/>
      <Span>{T("remove")}</Span>
    </Form.Item>
  </Form>
}

const WrappedDynamicList = compose(
  connect((state) => {
    return {
      dynamicFormItems: state.sortingAlgorithmsReducer.dynamicFormItems,
      attributes: state.sortingAlgorithmsReducer.attributes
    }
  }, {
    addDynamicSortAlgorithm,
    updateItem: throttle(updateDynamicSortAlgorithm, 1000),
  }),
  Form.create({
    onValuesChange: (props, changedValues) => {
      const { updateItem, index } = props;
      const [fieldKey] = Object.keys(changedValues);
      const value = changedValues[fieldKey];

      updateItem(fieldKey, value, index);
    }
  }),
)(DynamicListItem);

class DynamicForm extends React.Component {
  render() {
    const { formData: { sorting_type } } = this.props;
    const activated = sorting_type === "dynamic";

    if (!activated) return null;

    return <Box
      title={T("dynamic.sorting.options")}
      subtitle={T("dynamic.sorting.options.desc")}
      className = "box-primary form-box"
      >
      <WrappedAddItem />
      <List
        lockVertically
        values={this.props.dynamicFormItems}
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, index, isDragged }) => {
          return <li {...props} className={classNames(["sortable-dynamic-form-item", { "dragging": isDragged }])}>
            <Icon type="drag" data-movable-handle
              className={classNames(["drag-handle", { "dragged": isDragged }])}
            />
            <WrappedDynamicList key={value._id} index={index} {...value} removeSortingAlgorithm={() => {
              this.props.removeDynamicSortAlgorithm(index)
            }} />
          </li>
        }}
        onChange={this.props.moveDynamicSortAlgorithm}
      />
    </Box>
  }
}


function mapStateToProps(state) {
  const { formData, attributes, dynamicFormItems } = state.sortingAlgorithmsReducer;
  return { formData, attributes, dynamicFormItems }
}

export default connect(mapStateToProps, { moveDynamicSortAlgorithm, removeDynamicSortAlgorithm })(DynamicForm);
