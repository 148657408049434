import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { navigator } from '@common/navigation';
import {
  formLayoutTypes,
  validationTypes,
  activePassiveBooleanOptions,
  stockTypeOptions,
} from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { providersURL, stockLocationsUrl } from '@constants/serviceUrls';
import {
  getStockDetail,
  saveStock,
  clearStockDetail,
} from '@resources/actions/stockApp';
import {
  defaultDateTimeValueFormat,
  dateFormatWithTimeZone,
  defaultTimeFormat,
} from '@constants/';
import { inputTypes } from '@components/DynamicForm/constants';

import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { addQueryToURL } from '@utils/';

const DynamicForm = DynamicFormWithBox('stock-detail');

const StockDetail = (props) => {
  const { getStockDetail, stockDetail, saveStock, clearStockDetail } = props;
  const params = useParams();
  const { id } = params;

  const inputDisabled = stockDetail.stock_type === 'standard' ? true : false;
  const [loading, setLoading] = useState(true);
  const [stockType, setStockType] = useState();
  const [stockProvider, setStockProvider] = useState();

  useEffect(() => {
    id
      ? getStockDetail(id).then(() => {
          setLoading(false);
        })
      : setLoading(false);

    return () => {
      clearStockDetail();
    };
  }, []);

  useEffect(() => {
    if (id) {
      setStockType(stockDetail.stock_type);
      setStockProvider(stockDetail.provider);
    }
  }, [stockDetail]);

  const generateFormInputs = () => {
    const defaultValue = id && stockDetail;
    return [
      {
        name: T('sku'),
        key: 'sku',
        data_type: { value: inputTypes.input.alias },
        default_value: defaultValue && defaultValue.sku,
        fieldOptions: {
          rules: [validationTypes.required(T('sku'))],
        },
        fieldProps: {
          disabled: inputDisabled,
          placeholder: T('enter.variable').format(T('sku')),
        },
      },
      {
        name: T('stock.type'),
        key: 'stock_type',
        data_type: { value: inputTypes.select.alias },
        visible_values: stockTypeOptions,
        default_value: defaultValue && defaultValue.stock_type,
        fieldProps: {
          disabled: inputDisabled,
          placeholder: T('stock.type'),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('stock.type'))],
        },
      },
      {
        name: T('stockApp.stock.provider'),
        key: 'provider',
        data_type: { value: inputTypes.remoteselect.alias },
        url: providersURL,
        valueKeyName: 'code',
        labelKeyName: 'name',
        objectKey: 'results',
        default_value:
          defaultValue &&
          defaultValue.provider &&
          defaultValue.provider.toString(),
        fieldProps: {
          disabled: inputDisabled,
          placeholder: T('enter.variable').format(T('stockApp.stock.provider')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('stockApp.stock.provider'))],
        },
      },
      {
        name: T('location'),
        key: 'location',
        data_type: { value: inputTypes.remoteselect.alias },
        url: addQueryToURL(stockLocationsUrl, { provider: stockProvider }),
        valueKeyName: 'code',
        labelKeyName: 'name',
        objectKey: 'results',
        default_value:
          defaultValue &&
          defaultValue.location &&
          defaultValue.location.toString(),
        fieldProps: {
          disabled: inputDisabled || !stockProvider,
          placeholder: T('enter.variable').format(T('location')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('location'))],
        },
      },
      {
        name: T('status'),
        key: 'is_active',
        data_type: { value: inputTypes.select.alias },
        visible_values: activePassiveBooleanOptions,
        fieldProps: {
          disabled: inputDisabled,
          placeholder: T('status'),
        },
        default_value: defaultValue && defaultValue.is_active,
      },
      {
        name: T('stock.quantity'),
        key: 'stock',
        data_type: { value: inputTypes.input.alias },
        default_value:
          defaultValue &&
          typeof defaultValue.stock === 'number' &&
          defaultValue.stock.toString(),
        fieldOptions: {
          rules: [validationTypes.required(T('stock.quantity'))],
        },
        fieldProps: {
          disabled: inputDisabled,
          placeholder: T('enter.variable').format(T('stock.quantity')),
        },
      },
    ].map((input, index) => ({
      ...input,
      pk: index + 1,
    }));
  };

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const onSubmit = (values) => {
    const { period, ...rest } = values;
    const isPeriod = {
      lower: period && period[0].format(),
      upper: period && period[1].format(),
    };
    saveStock(id, {
      period: stockType === 'scheduled' ? isPeriod : null,
      ...rest,
    }).then(() => {
      onNavigate('/stock/stock-management/stock');
    });
  };

  const onChange = (values) => {
    setStockType(values.stock_type);
    setStockProvider(values.provider);
  };

  const inputFields = generateFormInputs();
  const periodItem = {
    name: T('period'),
    key: 'period',
    data_type: { value: inputTypes.dateRangePicker.alias },

    default_value: stockDetail?.period && [
      moment(stockDetail?.period?.lower, defaultDateTimeValueFormat),
      moment(stockDetail?.period?.upper, defaultDateTimeValueFormat),
    ],
    fieldOptions: {
      disabled: inputDisabled,
      rules: [validationTypes.required(T('period'))],
    },
    fieldProps: {
      showTime: {
        hideDisabledOptions: true,
        defaultValue: [
          moment().format(defaultTimeFormat),
          moment('00:00', defaultTimeFormat),
        ],
      },
    },
  };
  (stockType || stockDetail?.stock_type) === 'scheduled' &&
    inputFields.splice(2, 0, periodItem);

  return (
    <LayoutContentWrapper className="stock-form" loading={loading}>
      <DynamicForm
        title={T('stockApp.add.edit').format(T('stockApp.stock'))}
        subtitle={T('stockApp.detail.desc').format(T('stockApp.stock'))}
        onSubmit={onSubmit}
        onChange={onChange}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
        disabled={inputDisabled}
      />
    </LayoutContentWrapper>
  );
};

function mapStateToProps(state) {
  return {
    stockDetail: state.stockAppReducer.stockDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStockDetail: (id) => dispatch(getStockDetail(id)),
    saveStock: (id, params) => dispatch(saveStock(id, params)),
    clearStockDetail: () => dispatch(clearStockDetail()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockDetail);
