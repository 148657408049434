import React, { useEffect } from 'react';

import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';
import { dateFormatter, statusActivePassiveFormatter } from '@utils';
import DatatableWithFilter from '@components/DatatableWithFilter';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { setActionElements } from '@common/index';
import { stocksUrl } from '@constants/serviceUrls';
import Button from '@components/Button';
import { activePassiveBooleanOptions } from '@constants/commontypes';

const StockList = () => {
  useEffect(() => {
    setActionElements([
      <Button
        key="excelAdd"
        type="secondary"
        icon="icon-arti"
        onClick={() => onNavigate('/stock/stock-management/stock/form')}
      >
        {T('stockApp.new.add').format(T('stockApp.stock'))}
      </Button>,
    ]);
    return () => setActionElements([]);
  }, []);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'sku',
      name: T('sku'),
      active: true,
      fieldProps: {
        placeholder: T('sku'),
      },
    },
    {
      data_type: { value: inputTypes.select.alias },
      key: 'is_active',
      name: T('status'),
      active: true,
      visible_values: activePassiveBooleanOptions,
      fieldProps: {
        placeholder: T('status'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'provider',
      name: T('stockApp.stock.provider'),
      active: true,
      fieldProps: {
        placeholder: T('stockApp.stock.provider'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'location',
      name: T('location'),
      active: true,
      fieldProps: {
        placeholder: T('location'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'stock__gte',
      name: T('min.stock'),
      active: true,
      fieldProps: {
        placeholder: T('min.stock'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'stock__lte',
      name: T('max.stock'),
      active: true,
      fieldProps: {
        placeholder: T('max.stock'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'period__startswith__gte',
      name: T('startdate'),
      active: true,
      fieldProps: {
        placeholder: T('startdate'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'period__endswith__lte',
      name: T('enddate'),
      active: true,
      fieldProps: {
        placeholder: T('enddate'),
      },
    },
  ].map((filter, index) => ({
    ...filter,
    pk: index + 1,
  }));

  const columns = [
    {
      dataIndex: 'id',
      title: 'id',
      visible: false,
      className: 'hide',
    },
    {
      dataIndex: 'sku',
      title: T('sku'),
      formatters: [{ type: '' }],
    },
    {
      dataIndex: 'is_active',
      title: T('status'),
      render: statusActivePassiveFormatter,
    },
    {
      dataIndex: 'provider',
      title: T('stockApp.stock.provider'),
      formatters: [{ type: '' }],
    },
    {
      dataIndex: 'location',
      title: T('location'),
    },
    {
      dataIndex: 'stock',
      title: T('stock'),
    },
    {
      dataIndex: 'reserved_stock',
      title: T('reserved.stock'),
    },
    {
      dataIndex: 'stock_type',
      title: T('stock.type'),
    },
    {
      dataIndex: 'period[lower]',
      title: T('startdate'),
      render: dateFormatter,
    },
    {
      dataIndex: 'period.upper',
      title: T('enddate'),
      render: dateFormatter,
    },
  ].map((column, index) => ({
    ...column,
    key: index + 1,
  }));

  const onNavigate = (url) => {
    navigator.push(url, false);
  };
  const onRowClick = (row) => {
    onNavigate(`/stock/stock-management/stock/form/${row.id}`);
  };

  return (
    <LayoutContentWrapper className="stock-lists">
      <DatatableWithFilter
        filterProps={{ filterInputs }}
        datatableProps={{
          rowKey: 'id',
          doubleClickable: true,
          exportable: false,
          columns: columns,
          onRowClick: onRowClick,
          subtitle: T('stockApp.stock'),
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(''),
          url: stocksUrl,
        }}
      />
    </LayoutContentWrapper>
  );
};

export default StockList;
