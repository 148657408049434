import {
  FETCH_CHANNEL_SUCCESS,
  FETCH_CHANNEL_OVERALL_SUCCESS,
  FETCH_CHANNEL_CATEGORY_SUCCESS,
  FETCH_CHANNEL_PRODUCT_SUCCESS,
  FETCH_CHANNEL_CITY_SUCCESS,
  FETCH_CHANNEL_CHART_SUCCESS,
  GET_OMS_TOTAL_TRANSFER_SUCCESS,
  GET_OMS_TOTAL_PACKAGE_SUCCESS,
  GET_OMS_TOTAL_COUNT_SUCCESS,
  GET_OMS_WAITING_PACKAGE_SUCCESS,
  GET_OMS_SHIPPED_PACKAGE_SUCCESS
} from "../actiontypes";

export const initialState = {
  channel: {},
  overall_data: {},
  top_categories: [],
  top_products: [],
  top_cities: [],
  chart: [],
  omsTotalPackage : [],
  omsTotalTransfer : [],
  omsTotalCount : {},
  omsWaitingPackage : {},
  omsShippedPackage : {}
};

export default function (state = initialState, action) {
  const result = action.payload && action.payload.results;
  switch (action.type) {
    case FETCH_CHANNEL_SUCCESS:
      return {
        ...state,
        channel: action.payload || initialState.channel
      };

    case FETCH_CHANNEL_OVERALL_SUCCESS:

      const overall = action.payload || initialState.overall_data;
      return {
        ...state,
        overall_data: overall,
        overall_array: Object.entries(overall).map(([key, value]) => {
            return { key, value };
        }).filter((item) => { return (item.key != 'turnover' && item.key != 'currency_type' && item.key != 'updated_at')})
      };
    case FETCH_CHANNEL_CATEGORY_SUCCESS:
      return {
        ...state,
        top_categories: action.payload || initialState.top_categories
      };
    case FETCH_CHANNEL_PRODUCT_SUCCESS:
      return {
        ...state,
        top_products: action.payload || initialState.top_products
      };
    case FETCH_CHANNEL_CITY_SUCCESS:
      return {
        ...state,
        top_cities: action.payload || initialState.top_cities
      };
    case GET_OMS_TOTAL_PACKAGE_SUCCESS:
      return{
        ...state,
        omsTotalPackage: result
      }
    case GET_OMS_TOTAL_TRANSFER_SUCCESS:{
      return {
        ...state,
        omsTotalTransfer : result
      }
    }
    case GET_OMS_TOTAL_COUNT_SUCCESS: {
      return {
        ...state,
        omsTotalCount : action.payload
      }
    }
    case GET_OMS_WAITING_PACKAGE_SUCCESS: {
      return {
        ...state,
        omsWaitingPackage : action.payload
      }
    }
    case GET_OMS_SHIPPED_PACKAGE_SUCCESS: {
      return{
        ...state,
        omsShippedPackage : action.payload
      }
    }
    case FETCH_CHANNEL_CHART_SUCCESS:
      return {
        ...state,
        chart: action.payload || initialState.chart
      };
    default:
      return state;
  }
}
