import {
  GET_PRODUCT_REPORT,
  CLEAR_REPORT_STATE
} from "../actiontypes";

export const initialState = {
  productReport:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_REPORT:
      const result = action.payload && action.payload;
      return {
        ...state,
        productReport: result
      };
    case CLEAR_REPORT_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
