import {
  CLEAR_BASKET_OFFER_DETAIL,
  GET_REMOTE_BANK_SUCCESS,
  GET_BASKET_OFFER_DETAIL_SUCCESS,
  GET_REMOTE_CARD_TYPE_SUCCESS
} from "../actiontypes";

export const initialState = {
  remoteBanks: {},
  remoteCardTypes:{},
  basketOfferDetail : {}
};

export default function (state = initialState, action) {
  const result = action.payload && action.payload;
  switch (action.type) {
    case GET_BASKET_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        basketOfferDetail: result || initialState.basketOfferDetail
      };
    case GET_REMOTE_BANK_SUCCESS:
      return {
        ...state,
        remoteBanks: result || initialState.remoteBanks
      };
    case GET_REMOTE_CARD_TYPE_SUCCESS:
      return {
        ...state,
        remoteCardTypes: result || initialState.remoteCardTypes
      };
    case CLEAR_BASKET_OFFER_DETAIL:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
