import React from "react";
import classnames from "classnames";

import "./style.scss";

const AntDatePicker = Component => props => {
  const className = classnames(props.className, "antDatePicker");
  return <Component {...props} className={className} />;
};

export default AntDatePicker;
