import React from "react";
import classnames from "classnames";

import "./style.scss";

const AntSelect = Component => props => {
  const className = classnames(props.className, "antSelect");
  return <Component {...props} className={className} />;
};

const AntSelectOption = Component => props => {
  const className = classnames(props.className, "antSelectOption");
  return <Component {...props} className={className} />;
};

export { AntSelect, AntSelectOption };
