import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { navigator } from '@common/navigation';
import { isDefinedAndNotEmpty } from '@utils';
import { inputTypes } from '@components/DynamicForm/constants';
import {
  formLayoutTypes,
  validationTypes,
  activePassiveBooleanOptions,
} from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';

import {
  getStockProviders,
  saveStockProviders,
} from '@resources/actions/stockApp';

const DynamicForm = DynamicFormWithBox('stock-providers-detail');

const StockProvidersDetail = ({
  getStockProviders,
  saveStockProviders,
  stockProviders,
}) => {
  const params = useParams();
  const { id } = params;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    id
      ? getStockProviders(id).then(() => {
          setLoading(false);
        })
      : setLoading(false);
  }, []);

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const generateFormInputs = () => {
    const defaultValue = id && stockProviders;
    return [
      {
        pk: 0,
        name: T('stockApp.stock.provider'),
        key: 'name',
        default_value: defaultValue && defaultValue.name,
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('stockApp.stock.provider'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('stockApp.stock.provider')),
        },
      },
      {
        pk: 0,
        name: T('stock.provider.code'),
        key: 'code',
        default_value: defaultValue && defaultValue.code,
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('stock.provider.code'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('stock.provider.code')),
        },
      },
      {
        pk: 4,
        name: T('status'),
        key: 'is_active',
        active: true,
        data_type: { value: inputTypes.select.alias },
        visible_values: activePassiveBooleanOptions,
        fieldProps: {
          placeholder: T('status'),
        },
        default_value: defaultValue && defaultValue.is_active,
      },
    ];
  };

  const onSubmit = (values) => {
    saveStockProviders(id, values).then(() => {
      onNavigate(`/stock/stock-management/stock-providers`);
    });
  };

  const inputFields = generateFormInputs();

  return (
    <LayoutContentWrapper
      className="stock-providers-detail-form"
      loading={loading}
    >
      <DynamicForm
        title={T('stockApp.add.edit').format(T('stockApp.stock.provider'))}
        subtitle={T('stockApp.detail.desc').format(
          T('stockApp.stock.provider')
        )}
        onSubmit={onSubmit}
        // onChange={onChange}
        inputs={inputFields}
        // submitting={loading}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    stockProviders: state.stockAppReducer.stockProviders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStockProviders: (id) => dispatch(getStockProviders(id)),
    saveStockProviders: (id, params) =>
      dispatch(saveStockProviders(id, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockProvidersDetail);
