import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import {
  httpMethods,
  labelAligns,
  formLayoutTypes,
} from '@constants/commontypes';
import {
  isDefined,
  shallowEqual,
  getObjectFromString,
  isDefinedAndNotEmpty,
  serializeObjectWithDot,
} from '@utils';
import { T } from '@utils/languageProvider';
import { sendRequest } from '@common/network';
import {
  formLayout,
  inputSpace,
  colBlockType,
  filterLayout,
  formItemLayout,
  colVerticalType,
  boxFormItemLayout,
  colHorizontalType,
  formItemLayoutBlock,
  formItemLayoutVertical,
} from './constants';
import {
  getError,
  getInputType,
  formatDefaultValue,
  getValueByInputType,
} from './helpers';

import Box from '@components/utility/box/index';
import Form from '../UIElements/form';
import Button from '../UIElements/ButtonGroups';
import PlaceHolderContainer from './placeholderContainer';

import './style.scss';

const FormItem = Form.Item;

class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.formObject = {};
    const { getFormUtils, form, inputs } = props;
    this.state = { inputs: [], submitted: false };
    this.updateFormObject(inputs, true);

    if (getFormUtils && typeof getFormUtils === 'function') {
      getFormUtils({
        ...form,
        validateFields: (callback = () => {}) => {
          this.setState(
            () => ({ submitted: true }),
            () => {
              form.validateFields(callback);
            }
          );
        },
      });
    }
  }

  componentDidMount() {
    const { url, httpMethod, objectKey } = this.props;
    if (url) this.getFormFields(url, httpMethod, objectKey);
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props.inputs, prevProps.inputs)) {
      this.clearDeletedValues();
      this.props.form.validateFields();
    }
  }
  // Not: burasi hataya sebep oldugu icin simdilik comment'e alindi ve UNSAFE eklendi. Daha sonra tekrar kontrol edilecek.
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (!shallowEqual(this.props.inputs, nextProps.inputs))
  //     this.updateFormObject(nextProps.inputs);
  // }

  updateFormObject = (inputs = [], fromConstructor) => {
    let value = { ...this.formObject };

    for (const element of inputs) {
      if (isDefined(element.default_value))
        value = {
          ...value,
          [element.key]: formatDefaultValue(element.default_value),
        };
    }
    this.setFormObject(value, !fromConstructor);
  };

  getFormFields = (url, httpMethod, objectKey) => {
    sendRequest({
      url,
      method: httpMethod,
      onSuccess: (result) => {
        this.setState({
          inputs: objectKey ? getObjectFromString(objectKey, result) : result,
        });
      },
    });
  };

  clearDeletedValues = () => {
    let changed = false;
    let result = {};
    for (const key in this.formObject) {
      if (this.formObject.hasOwnProperty(key)) {
        const element = this.formObject[key];
        if (
          this.props.inputs.findIndex(
            (input) =>
              input.key === key ||
              (input.children &&
                input.children.findIndex((child) => child.key === key) !== -1)
          ) !== -1
        )
          result = { ...result, [key]: element };
        else changed = true;
      }
    }
    this.setFormObject({ ...result });
    if (changed) this.onChange();
  };

  calculateFormValues = (formObject) => {
    let result = {};
    for (const key in formObject) {
      if (formObject.hasOwnProperty(key)) {
        const element = formObject[key];
        if (isDefinedAndNotEmpty(element))
          result = { ...result, [key]: element };
      }
    }
    return result;
  };

  clearRelatedValue = (
    formObject = {},
    newValue = {},
    inputs = [],
    exceptions = []
  ) => {
    let exception = null;
    const relatedInputs = inputs
      .filter((input) => input.relatedTo)
      .filter((input) => exceptions.indexOf(input.relatedTo) === -1);
    for (const relatedInput of relatedInputs) {
      if (
        formObject[relatedInput.relatedTo] === newValue[relatedInput.relatedTo]
      )
        continue;
      const relateds = inputs.filter(
        (input) => input.relatedTo === relatedInput.relatedTo
      );
      for (const related of relateds) {
        newValue = { ...newValue, [related.key]: null };
      }
      exception = relatedInput.relatedTo;
      break;
    }
    if (exception) {
      return this.clearRelatedValue(formObject, newValue, inputs, [
        ...exceptions,
        exception,
      ]);
    } else {
      return newValue;
    }
  };

  setFormObject = (newValue, stateIsReady) => {
    const { inputs } = this.props;

    if (!shallowEqual(this.formObject, newValue)) {
      this.formObject =
        this.clearRelatedValue(this.formObject, newValue, inputs) || {};
      if (stateIsReady) this.forceUpdate();
    }
  };

  onChange = (input, dataField, e, type, label) => {
    const { onChange } = this.props;

    if (e !== undefined) {
      const value = getValueByInputType(e, type, input);
      this.setFormObject({
        ...this.formObject,
        [dataField]: value,
      });
    }
    if (onChange) {
      const values = this.calculateFormValues(this.formObject);
      onChange(values);
    }
  };

  onKeyPress = (input, dataField, e, type, label) => {
    if (e.key === 'Enter') this.onChange(input, dataField, e, type, label);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    this.setState(
      () => ({ submitted: true }),
      () => {
        this.props.form.validateFields((err) => {
          if (!err && onSubmit) {
            const value = this.calculateFormValues(this.formObject);
            onSubmit(value, serializeObjectWithDot(this.formObject));
          }
        });
      }
    );
  };

  onBlur(e) {
    e.preventDefault();
  }

  reset = (e) => {
    e.preventDefault();
    this.props.form.resetFields();
  };

  setInitialValueForRemotes = (key) => {
    this.props.form.setFieldsValue({
      [key]: getObjectFromString(key, this.formObject),
    });
  };

  createEvents = (type, field, code, label, getChangeWithEnterPress) => {
    const inputType = getInputType(type);
    const workWithKeyPress =
      inputType.canWorkWithKeyPress && getChangeWithEnterPress;
    const onChange = workWithKeyPress
      ? null
      : (e) => this.onChange(field, code, e, inputType, label);
    const onKeyPress = workWithKeyPress
      ? (e) => this.onKeyPress(field, code, e, inputType, label)
      : null;
    return { inputType, onChange, onKeyPress };
  };

  createDynamicInput = ({
    label,
    options,
    fieldProps,
    url,
    valueKeyName,
    labelKeyName,
    dataSource,
    multiSelect,
    relatedTo,
    relatedKey,
    keyValueSelect,
    onChange,
    onKeyPress,
    inputType,
    objectKey,
    id,
    noLabel,
    noLabelCheck,
    placeholder,
    keys,
    inputProps,
  }) => {
    const labelInValue = fieldProps && fieldProps.labelInValue;
    const DynamicInput = inputType.creator(
      {
        label,
        options,
        ...fieldProps,
        ...(inputType.remote
          ? {
              valueKeyName,
              labelKeyName,
              dataSource,
              objectKey,
              relatedKey,
              url,
              URLKey: id,
            }
          : {}),
        ...(inputType.canMultiple
          ? { mode: multiSelect ? 'multiple' : 'default' }
          : {}),
        ...(keyValueSelect && inputType.canKeyValueResult
          ? {
              ...(typeof labelInValue !== 'undefined'
                ? { labelInValue }
                : { labelInValue: true }),
            }
          : {}),
        ...(relatedTo
          ? { related: getObjectFromString(relatedTo, this.formObject) }
          : {}),
        ...(inputType.noLabel ? { noLabel } : {}),
        ...(inputType.needKeys ? { keys } : {}),
        ...(inputType.needInputProps ? { inputProps } : {}),
      },
      {
        onChange,
        onKeyPress,
      }
    );
    const DynamicInputContainer = placeholder ? (
      <PlaceHolderContainer placeholder={placeholder}>
        {DynamicInput}
      </PlaceHolderContainer>
    ) : (
      DynamicInput
    );
    return DynamicInputContainer;
  };

  createFormItem = ({
    inputType,
    noLabel,
    label,
    formItemProps,
    hasError,
    validateStatus,
    error,
    code,
    fieldOptions,
    normalize,
    initialValue,
    getFieldDecorator,
    DynamicInputContainer,
    multiSelect,
    formItemLayoutType,
  }) => {
    const colLayoutFormItem =
      formItemLayoutType === formLayoutTypes.boxLayout
        ? boxFormItemLayout
        : formItemLayoutType === formLayoutTypes.inline
        ? formItemLayout
        : formItemLayoutType === formLayoutTypes.block
        ? formItemLayoutBlock
        : formItemLayoutVertical;
    let initValue = initialValue
      ? inputType.canMultiple && multiSelect
        ? Array.isArray(initialValue)
          ? initialValue
          : [initialValue]
        : initialValue
      : typeof initialValue === 'boolean'
      ? initialValue
      : undefined;
    const child = (
      <FormItem
        className={`ant-col-md-24 ant-col-sm-24 ant-col-xs-24`}
        label={inputType.noLabel && noLabel ? null : label}
        {...colLayoutFormItem}
        {...formItemProps}
        validateStatus={hasError ? validateStatus : ''}
        help={hasError ? error : ''}
      >
        {getFieldDecorator(code, {
          ...fieldOptions,
          initialValue: initValue,
          valuePropName: inputType.valuePropName,
          normalize,
        })(DynamicInputContainer)}
      </FormItem>
    );
    return child;
  };

  renderField = (field = {}) => {
    const {
      pk: id,
      data_type: { value: type } = {},
      default_value: initialValue,
      placeholder,
      name: label,
      key: code,
      objectKey,
      visible_values: options,
      fieldOptions = {},
      fieldProps,
      formItemProps = {},
      url,
      valueKeyName,
      activeField = true,
      labelKeyName,
      dataSource,
      multiSelect,
      relatedTo,
      relatedKey,
      noLabel,
      noLabelCheck,
      normalize,
      keys,
      inputProps,
    } = field;
    const {
      form: { getFieldDecorator, getFieldError, isFieldTouched },
      errorOnFocus,
      layoutType,
      getChangeWithEnterPress,
      keyValueSelect,
    } = this.props;
    const { inputType, onChange, onKeyPress } = this.createEvents(
      type,
      field,
      code,
      label,
      getChangeWithEnterPress
    );
    const DynamicInputContainer = this.createDynamicInput({
      label,
      options,
      fieldProps,
      url,
      valueKeyName,
      labelKeyName,
      dataSource,
      multiSelect,
      relatedTo,
      relatedKey,
      keyValueSelect,
      onChange,
      onKeyPress,
      placeholder,
      id,
      noLabel,
      noLabelCheck,
      objectKey,
      inputType,
      keys,
      inputProps,
    });
    const { hasError, validateStatus, error } = getError(
      isFieldTouched,
      getFieldError,
      code,
      errorOnFocus,
      this.state.submitted
    );
    const formItemLayoutType = layoutType;
    const child = this.createFormItem({
      inputType,
      noLabel,
      noLabelCheck,
      label,
      formItemLayoutType,
      formItemProps,
      hasError,
      validateStatus,
      error,
      code,
      fieldOptions,
      initialValue,
      getFieldDecorator,
      normalize,
      DynamicInputContainer,
      multiSelect,
    });
    return activeField && child;
  };

  renderInput = (field = {}, index) => {
    const isInline = this.props.layoutType === formLayoutTypes.inline;
    const isBlock = this.props.layoutType === formLayoutTypes.block;
    if (field.children)
      return (
        <Row key={field.pk}>
          {field.children.map((item) => {
            const colLayout = isInline
              ? colHorizontalType
              : isBlock
              ? colBlockType
              : colVerticalType;
            return (
              <Col className={`filter-input`} {...colLayout} key={item.pk}>
                {this.renderField(item)}
              </Col>
            );
          })}
        </Row>
      );
    const colLayout = isInline
      ? colHorizontalType
      : isBlock
      ? colBlockType
      : colVerticalType;
    return (
      <Col className={`filter-input`} {...colLayout} key={field.pk}>
        {this.renderField(field)}
      </Col>
    );
  };

  renderInputs = (inputs = [], language) => {
    return inputs.map((input, index) => this.renderInput(input, index));
  };

  render() {
    const {
      language,
      onCancel,
      inputs,
      hasSubmitButton,
      layoutType,
      submitting,
      cancelling,
      labelAlign,
      buttonClassName,
      isInlineButton,
      cancelButtonType,
      cancelButtonText,
      submitButtonText,
      submitButtonType,
      disabled,
    } = this.props;
    const formInputs = this.renderInputs(inputs, language);
    const isInline = layoutType === formLayoutTypes.inline,
      isVertical = layoutType === formLayoutTypes.vertical,
      colLayout = isInline ? filterLayout : formLayout;
    if (!formInputs.length) return null;
    // const hasError = hasErrors(getFieldsError());
    const formButtonClass = isInlineButton
      ? 'form-button-inline'
      : 'form-button-block';
    return (
      <Col className="dynamic-form" {...colLayout}>
        <Form
          onSubmit={this.onSubmit}
          labelAlign={isInline ? labelAligns.left : labelAlign}
        >
          {isInline || isVertical ? (
            <Row className="form-wrapper" gutter={{ ...inputSpace }}>
              {formInputs}
            </Row>
          ) : (
            formInputs
          )}
          {(hasSubmitButton || onCancel) && (
            <Row
              className={`${
                !isInlineButton && 'ant-col-md-24 ant-col-sm-24 ant-col-xs-24'
              } form-wrapper ${formButtonClass}`}
            >
              <Col offset={5}>
                <FormItem>
                  {hasSubmitButton && (
                    <Button
                      type={submitButtonType}
                      htmlType="submit"
                      disabled={disabled}
                      loading={submitting}
                      className={buttonClassName}
                    >
                      {submitButtonText}
                    </Button>
                  )}
                  {onCancel && (
                    <Button
                      type={cancelButtonType}
                      className="cancel-button"
                      onClick={onCancel}
                      loading={cancelling}
                    >
                      {cancelButtonText}
                    </Button>
                  )}
                </FormItem>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    );
  }
}

DynamicForm.defaultProps = {
  hasSubmitButton: true,
  layoutType: formLayoutTypes.block,
  buttonClassName: '',
  isInlineButton: false,
  cancelButtonText: T('clean'),
  cancelButtonType: 'default',
  submitButtonText: T('submit'),
  submitButtonType: 'primary',
  errorOnFocus: true,
  getChangeWithEnterPress: false,
  multiSelect: false,
  submitting: false,
  cancelling: false,
  keyValueSelect: false,
  labelAlign: labelAligns.right,
  httpMethod: httpMethods.OPTIONS,
};

DynamicForm.propTypes = {
  getChangeWithEnterPress: PropTypes.bool,
  language: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  getFormUtils: PropTypes.func,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      data_type: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      default_value: PropTypes.any,
      placeholder: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      key: PropTypes.string.isRequired,
      objectKey: PropTypes.string,
      timezone: PropTypes.bool,
      visible_values: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          label: PropTypes.string,
          pk: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          value: PropTypes.any.isRequired,
        })
      ),
      fieldOptions: PropTypes.object,
      fieldProps: PropTypes.object,
      formItemProps: PropTypes.object,
      url: PropTypes.string,
      valueKeyName: PropTypes.string,
      labelKeyName: PropTypes.string,
      dataSource: PropTypes.object,
      multiSelect: PropTypes.bool,
      relatedTo: PropTypes.string,
      relatedKey: PropTypes.string,
    })
  ).isRequired,
  hasSubmitButton: PropTypes.bool,
  cancelButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancelButtonType: PropTypes.string,
  submitButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitButtonType: PropTypes.string,
  keyValueSelect: PropTypes.bool,
  url: PropTypes.string,
  submitting: PropTypes.bool,
  cancelling: PropTypes.bool,
  httpMethod: PropTypes.string,
  labelAlign: PropTypes.oneOf([labelAligns.left, labelAligns.right]),
  objectKey: PropTypes.string,
  buttonClassName: PropTypes.string,
  isInlineButton: PropTypes.bool,
};

export default function (name) {
  return Form.create({ name })(DynamicForm);
}

class DynamicFormBox extends Component {
  render() {
    const { title, subtitle, type, ...restOf } = this.props;
    const DynamicFormWithBox = DynamicForm;
    return (
      <Box title={title} subtitle={subtitle} className={`box-${type} form-box`}>
        <DynamicFormWithBox {...restOf} />
      </Box>
    );
  }
}

DynamicFormBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
};

DynamicFormBox.defaultProps = {
  type: 'primary',
};

export function DynamicFormWithBox(name) {
  return Form.create({ name })(DynamicFormBox);
}
