import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
// import logger from 'redux-logger';

import configureMockStore from 'redux-mock-store';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store =
  process.env.NODE_ENV === 'test'
    ? configureMockStore([reduxThunk])({
        authReducer: {},
      })
    : createStore(reducers, {}, composeEnhancers(applyMiddleware(reduxThunk)));
