import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo
} from "react";
import { Icon as AntdIcon, Tooltip } from "antd";
import Tour from "reactour";

import Block from "@components/Block";
import Span from "@components/Span";
import Icon from "@components/Intrinsic";

import { T } from "@utils/languageProvider";

import { transformSteps } from "./utils";
import "./style.scss";

const GuideButton = forwardRef((props, ref) => {
  const [tourState, setTourState] = useState(false);
  const steps = useMemo(() => transformSteps(props.steps), [props.steps]);

  const close = () => {
    setTourState(false);
  };
  const open = () => {
    setTourState(true);
  };

  useImperativeHandle(ref, () => ({
    close,
    open
  }));

  return (
    <>
      <Span className="guide-trigger-button">
        <Tooltip title={T("guide.button.info")}><Icon className="icon-info" onClick={open} /></Tooltip>
      </Span>
      <Tour
        isOpen={tourState}
        onRequestClose={close}
        className="tour-modal-wrapper"
        showCloseButton={false}
        highlightedMaskClassName="active-tour-element"
        showNavigationNumber={false}
        startAt={0}
        {...props}
        ref={ref}
        steps={steps}
      >
        <AntdIcon type="close" className="guide-close-button" onClick={close} />
        <Block className="guide-modal-icon">
          <Icon className="icon-info" />
        </Block>
      </Tour>
    </>
  );
});

export default GuideButton;
