import "./prototype";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as sentryConfig from './services/sentry';
import 'antd/dist/antd.css';
import './assets/styles/main.scss';

sentryConfig.sentrySetup();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
