import Enlang from "./entries/en-US";
import TrLang from "./entries/tr-TR";
import { addLocaleData } from "react-intl";
import config, {
  getCurrentLanguage
} from "@components/LanguageSwitcher/config";

const AppLocale = {
  en: Enlang,
  tr: TrLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.tr.data);

export default AppLocale;

export const T = function(key) {
  const languageKey = getCurrentLanguage(config.defaultLanguage || "turkish");
  return AppLocale[languageKey.locale].messages[key];
};
