import React, { PureComponent } from "react";

import { inputTypes } from "../DynamicForm/constants";
import { defaultDateFormat } from "@constants";

import Input from "../UIElements/Input";
import Select, { SelectOption as Option } from "../UIElements/Select";
import Datepicker from "../UIElements/DatePicker";

class InputWithSelect extends PureComponent {
  state = {};
  createOptions(options) {
    if (!options || !options.length) return options;
    return options.map(option => (
      <Option key={option.key} value={option.value}>
        {option.label}
      </Option>
    ));
  }
  onKeyPress = e => {
    if (e.key === "Enter") this.onChange(e.target.value);
  };
  onChange = value => {
    const { keys, onChange } = this.props;
    this.setState(
      {
        [keys[0]]: value
      },
      () => {
        if (onChange)
          onChange({
            [keys[0]]: this.state[keys[0]],
            [keys[1]]: this.state[keys[1]]
          });
      }
    );
  };
  onChangeSelect = value => {
    const { keys, onChange } = this.props;
    this.setState(
      {
        [keys[1]]: value
      },
      () => {
        if (onChange)
          onChange({
            [keys[0]]: this.state[keys[0]],
            [keys[1]]: this.state[keys[1]]
          });
      }
    );
  };
  createInput = () => {
    const { inputProps = {} } = this.props;
    switch (inputProps.inputType) {
      case inputTypes.datepicker.alias:
        return (
          <Datepicker
            format={inputProps.dateFormat || defaultDateFormat}
            onChange={e => this.onChange(e)}
          />
        );
      default:
        return <Input onKeyPress={this.onKeyPress} />;
    }
  };
  render() {
    const { options } = this.props;
    return (
      <>
        {this.createInput()}
        <Select labelInValue onChange={this.onChangeSelect}>
          {this.createOptions(options)}
        </Select>
      </>
    );
  }
}
export default InputWithSelect;