import {
  CLEAR_STATE_TRANSFER_DETAIL,
  GET_TRANSFER_ORDERS_SUCCESS,
  GET_TRANSFER_DETAIL,
  GET_TRANSFERS
} from "../actiontypes";

export const initialState = {
  transferOrders: [],
  transferDetail: {},
  transfers: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSFER_ORDERS_SUCCESS:
      const result = action.payload && action.payload.results;
      return {
        ...state, 
        transferOrders: result
      };
    case GET_TRANSFER_DETAIL:
      const transferDetail = action.payload && action.payload;
      return {
        ...state,
        transferDetail: transferDetail || initialState.transferDetail
      };
    case GET_TRANSFERS:
      const transfers = action.payload && action.payload;
      return {
        ...state,
        transfers: transfers || initialState.transfers
      };
    case CLEAR_STATE_TRANSFER_DETAIL:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
