export const SET_TOKEN_FROM_STORAGE = 'SET_TOKEN_FROM_STORAGE';

export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_ROUTE = 'CHANGE_ROUTE';
export const GET_API_LANGUAGES_SUCCESS = 'GET_API_LANGUAGES_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CONFIRM_PASSWORD_SUCCESS = 'CONFIRM_PASSWORD_SUCCESS';

export const GET_ALL_CHANNELS = 'GET_ALL_CHANNELS';
export const GET_CONTEXT_CHANNEL = 'GET_CONTEXT_CHANNEL';

export const GET_REMOTE_LANGUAGE = 'GET_REMOTE_LANGUAGE';
export const CLEAR_REMOTE_LANGUAGE = 'CLEAR_REMOTE_LANGUAGE';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const FETCH_USER_FINAL = 'FETCH_USER_FINAL';

export const CHECK_AUTHORIZATION_SUCCESS = 'CHECK_AUTHORIZATION_SUCCESS';
export const CHECK_AUTHORIZATION_FAIL = 'CHECK_AUTHORIZATION_FAIL';
export const CHECK_AUTHORIZATION_FINAL = 'CHECK_AUTHORIZATION_FINAL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FETCH_CHANNEL_SUCCESS = 'FETCH_CHANNEL_SUCCESS';
export const FETCH_CHANNEL_OVERALL_SUCCESS = 'FETCH_CHANNEL_OVERALL_SUCCESS';
export const FETCH_CHANNEL_CATEGORY_SUCCESS = 'FETCH_CHANNEL_CATEGORY_SUCCESS';
export const FETCH_CHANNEL_PRODUCT_SUCCESS = 'FETCH_CHANNEL_PRODUCT_SUCCESS';
export const FETCH_CHANNEL_CITY_SUCCESS = 'FETCH_CHANNEL_CITY_SUCCESS';
export const FETCH_CHANNEL_CHART_SUCCESS = 'FETCH_CHANNEL_CHART_SUCCESS';

export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const SAVE_PRODUCT_DETAIL_SUCCESS = 'SAVE_PRODUCT_DETAIL_SUCCESS';

export const GET_CATALOGS = 'GET_CATALOGS';
export const GET_CATALOG_DETAIL = 'GET_CATALOG_DETAIL';
export const GET_CATALOG_ALL_DETAIL = 'GET_CATALOG_ALL_DETAIL';
export const GET_CATALOG_FEED_DETAIL = 'GET_CATALOG_FEED_DETAIL';
export const GET_CATEGORY_TREE = 'GET_CATEGORY_TREE';
export const GET_CATEGORY_NODE = 'GET_CATEGORY_NODE';
export const CLEAR_STATE_CATALOG = 'CLEAR_STATE_CATALOG';

export const CLEAR_STATE_SPECIAL_PAGE = 'CLEAR_STATE_SPECIAL_PAGE';
export const GET_SPECIAL_PAGE_DETAIL = 'GET_SPECIAL_PAGE_DETAIL';

export const GET_PRODUCT_COLLECTION = 'GET_PRODUCT_COLLECTION';

export const GET_COLLECTION = 'GET_COLLECTION';
export const CLEAR_STATE_COLLECTION = 'CLEAR_STATE_COLLECTION';

export const GET_ATTRIBUTE_DETAIL = 'GET_ATTRIBUTE_DETAIL';
export const GET_ATTRIBUTES = 'GET_ATTRIBUTES';
export const GET_PRODUCT_SPECIFICATION_DATATABLE =
  'GET_PRODUCT_SPECIFICATION_DATATABLE';
export const GET_OPTIONAL_ATTRIBUTE_DETAIL = 'GET_OPTIONAL_ATTRIBUTE_DETAIL';
export const CLEAR_PRODUCT_DETAIL_STATE = 'CLEAR_PRODUCT_DETAIL_STATE';
export const GET_SPECIFICATION_SET_DETAIL = 'GET_SPECIFICATION_SET_DETAIL';
export const GET_ALL_SPECS_SUCCESS = 'GET_ALL_SPECS_SUCCESS';
export const GET_SELECTED_SPECS_SUCCESS = 'GET_SELECTED_SPECS_SUCCESS';
export const GET_FORM_SUBJECT_DETAIL_SUCCESS =
  'GET_FORM_SUBJECT_DETAIL_SUCCESS';

export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FINALLY = 'GET_PERMISSIONS_FINALLY';
export const GET_PERMISSION_GROUPS_SUCCESS = 'GET_PERMISSION_GROUPS_SUCCESS';
export const GET_PERMISSION_GROUP_DETAIL_BY_USER =
  'GET_PERMISSION_GROUP_DETAIL_BY_USER';

export const GET_REMOTE_BANK_SUCCESS = 'GET_REMOTE_BANK_SUCCESS';
export const GET_REMOTE_CARD_TYPE_SUCCESS = 'GET_REMOTE_CARD_TYPE_SUCCESS';
export const GET_BASKET_OFFER_DETAIL_SUCCESS =
  'GET_BASKET_OFFER_DETAIL_SUCCESS';
export const CLEAR_BASKET_OFFER_DETAIL = 'CLEAR_BASKET_OFFER_DETAIL';

export const GET_STOCK_LOCATION_MOVEMENTS_SUCCESS =
  'GET_STOCK_LOCATION_MOVEMENTS_SUCCESS';
export const GET_STOCK_LOCATION_MOVEMENT_DETAIL_SUCCESS =
  'GET_STOCK_LOCATION_MOVEMENT_DETAIL_SUCCESS';
export const GET_STOCK_LOCATION_DETAIL = 'GET_STOCK_LOCATION_DETAIL';
export const GET_STOCK_LOCATIONS_SUCCESS = 'GET_STOCK_LOCATIONS_SUCCESS';
export const GET_STOCK_LOCATION = 'GET_STOCK_LOCATION';
export const GET_STOCK_LOCATION_PACKAGES = 'GET_STOCK_LOCATION_PACKAGES';
export const PACKAGE_STATUS_LIST_SUCCESS = 'PACKAGE_STATUS_LIST_SUCCESS';
export const GET_TRANSFER_LOCATION_MOVEMENTS_SUCCESS =
  'GET_TRANSFER_LOCATION_MOVEMENTS_SUCCESS';
export const TRANSFER_STATUS_LIST_SUCCESS = 'TRANSFER_STATUS_LIST_SUCCESS';
export const GET_TRANSFER_LOCATION_MOVEMENT_DETAIL_SUCCESS =
  'GET_TRANSFER_LOCATION_MOVEMENT_DETAIL_SUCCESS';
export const GET_TRANSFER_LOCATION_DETAIL = 'GET_TRANSFER_LOCATION_DETAIL';

export const CLEAR_STATE_LOCATION = 'CLEAR_STATE_LOCATION';

export const GET_TRANSFERS = 'GET_TRANSFERS';
export const GET_TRANSFER_ORDERS_SUCCESS = 'GET_TRANSFER_ORDERS_SUCCESS';
export const GET_TRANSFER_DETAIL = 'GET_TRANSFER_DETAIL';

export const CLEAR_STATE_TRANSFER_DETAIL = 'CLEAR_STATE_TRANSFER_DETAIL';

export const GET_CARGO_DETAIL = 'GET_CARGO_DETAIL';
export const CLEAR_CARGO_DETAIL_STATE = 'CLEAR_CARGO_DETAIL_STATE';

export const GET_PRODUCT_REPORT = 'GET_PRODUCT_REPORT';
export const CLEAR_REPORT_STATE = 'CLEAR_REPORT_STATE';

export const GET_SCENARIO_SUCCESS = 'GET_SCENARIO_SUCCESS';
export const GET_SCENARIO_OPTIONS_SUCCESS = 'GET_SCENARIO_OPTIONS_SUCCESS';
export const SET_PRIORITY_SUCCESS = 'SET_PRIORITY_SUCCESS';
export const GET_SCENARIO_DETAIL_SUCCESS = 'GET_SCENARIO_DETAIL_SUCCESS';
export const GET_TRANSFER_SCENARIO_DETAIL_SUCCESS =
  'GET_TRANSFER_SCENARIO_DETAIL_SUCCESS';

export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_PACKAGES = 'GET_PACKAGES';

export const CLEAR_STATE_ORDER_DETAIL = 'CLEAR_STATE_ORDER_DETAIL';

export const GET_PACKAGE_ORDERS_SUCCESS = 'GET_PACKAGE_ORDERS_SUCCESS';
export const GET_PACKAGE_DETAIL = 'GET_PACKAGE_DETAIL';
export const CLEAR_STATE_PACKAGE_DETAIL = 'CLEAR_STATE_PACKAGE_DETAIL';

export const CLEAR_SETTING_STATE = 'CLEAR_SETTING_STATE';
export const GET_PAYMENT_OPTION_DETAIL = 'GET_PAYMENT_OPTION_DETAIL';
export const GET_SHIPPING_OPTION_DETAIL = 'GET_SHIPPING_OPTION_DETAIL';

export const GET_SHIPPING_COMPANY_DETAIL = 'GET_SHIPPING_COMPANY_DETAIL';
export const GET_CANCELLATION_REASON_DETAIL = 'GET_CANCELLATION_REASON_DETAIL';
export const GET_RETAIL_STOCK_GATEWAY = 'GET_RETAIL_STOCK_GATEWAY';
export const GET_FACET_CONF_DETAIL_SUCCESS = 'GET_FACET_CONF_DETAIL_SUCCESS';

export const GET_BANK_DETAIL = 'GET_BANK_DETAIL';
export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT';
export const GET_BANK_ACCOUNT_DETAILED = 'GET_BANK_ACCOUNT_DETAILED';
export const GET_BANK_FORM_INPUT = 'GET_BANK_FORM_INPUT';

export const GET_CARD_TYPE_DETAIL_SUCCESS = 'GET_CARD_TYPE_DETAIL_SUCCESS';
export const GET_CARD_TYPE_INPUT_SUCCESS = 'GET_CARD_TYPE_INPUT_SUCCESS';

export const GET_LOYALTY_ACCOUNT_DETAIL_SUCCESS =
  'GET_LOYALTY_ACCOUNT_DETAIL_SUCCESS';

export const GET_PRODUCT_ATTRIBUTE_SETS_DETAIL_SUCCESS =
  'GET_PRODUCT_ATTRIBUTE_SETS_DETAIL_SUCCESS';

export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const BASKET_OFFERS_BY_CHANNEL_USER_SUCCESS =
  'BASKET_OFFERS_BY_CHANNEL_USER_SUCCESS';
export const ADDRESSES_BY_CUSTOMER_SUCCESS = 'ADDRESSES_BY_CUSTOMER_SUCCESS';
export const USER_SCHEMA_TYPE_BY_CHANNEL_SUCCESS =
  'USER_SCHEMA_TYPE_BY_CHANNEL_SUCCESS';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const CLEAR_STATE_CUSTOMER_DETAIL = 'CLEAR_STATE_CUSTOMER_DETAIL';
export const CLEAR_STATE_USER_DETAIL = 'CLEAR_STATE_USER_DETAIL';
export const ADDRESSES_BY_USER_SUCCESS = 'ADDRESSES_BY_USER_SUCCESS';
export const ORDER_DETAIL_EMAIL_SUCCESS = 'ORDER_DETAIL_EMAIL_SUCCESS';

export const GET_CATALOG_GROUPS_DETAIL_SUCCESS =
  'GET_CATALOG_GROUPS_DETAIL_SUCCESS';
export const GET_CHANNEL_GROUPS_DETAIL_SUCCESS =
  'GET_CHANNEL_GROUPS_DETAIL_SUCCESS';

export const GET_WIDGET_DETAIL_SUCCESS = 'GET_WIDGET_DETAIL_SUCCESS';
export const GET_WIDGET_TYPES_SUCCESS = 'GET_WIDGET_TYPES_SUCCESS';
export const GET_WIDGET_TYPE_DETAIL_SUCCESS = 'GET_WIDGET_TYPE_DETAIL_SUCCESS';
export const SAVE_WIDGET_TYPE_DETAIL_SUCCESS =
  'SAVE_WIDGET_TYPE_DETAIL_SUCCESS';
export const CLEAR_WIDGET_DETAIL_STATE = 'CLEAR_WIDGET_DETAIL_STATE';
export const GET_COLLECTION_WIDGET_DETAIL_SUCCESS =
  'GET_COLLECTION_WIDGET_DETAIL_SUCCESS';
export const GET_PRODUCT_COLLECTION_LIST_SUCCESS =
  'GET_PRODUCT_COLLECTION_LIST_SUCCESS';
export const CLEAR_WIDGET_STATE = 'CLEAR_WIDGET_STATE';

export const GET_BASEORDER_DETAIL_SUCCESS = 'GET_BASEORDER_DETAIL_SUCCESS';
export const CLEAR_STATE_BASEORDER_DETAIL = 'CLEAR_STATE_BASEORDER_DETAIL';
export const MAKE_REFUND_ORDER_ITEM_SUCCESS = 'MAKE_REFUND_ORDER_ITEM_SUCCESS';
export const REFUND_ORDER_CARGO_SUCCESS = 'REFUND_ORDER_CARGO_SUCCESS';
export const GET_CANCELLATION_REASONS_SUCCESS =
  'GET_CANCELLATION_REASONS_SUCCESS';
export const GET_ORDER_AGREEMENTS_SUCCESS = 'GET_ORDER_AGREEMENTS_SUCCESS';

export const GET_POS_DETAIL_SUCCESS = 'GET_POS_DETAIL_SUCCESS';
export const GET_POS_SCHEMA_SUCCESS = 'GET_POS_SCHEMA_SUCCESS';
export const SAVE_POS_DETAIL_SUCCESS = 'SAVE_POS_DETAIL_SUCCESS';
export const GET_ALL_POS_SUCCESS = 'GET_ALL_POS_SUCCESS';
export const CLEAR_POS_DETAIL_STATE = 'CLEAR_POS_DETAIL_STATE';

export const GET_PROMOTION_GATEWAY_SUCCESS = 'GET_PROMOTION_GATEWAY_SUCCESS';
export const SAVE_PROMOTION_GATEWAY_SUCCESS = 'SAVE_PROMOTION_GATEWAY_SUCCESS';
export const CLEAR_PROMOTION_GATEWAY_STATE = 'CLEAR_PROMOTION_GATEWAY_STATE';

export const GET_SKU_LOG_SUCCESS = 'GET_SKU_LOG_SUCCESS';
export const GET_MAPPING_ERROR_LOG_SUCCESS = 'GET_MAPPING_ERROR_LOG_SUCCESS';
export const UPDATE_MAPPING_ERROR_LOG = 'UPDATE_MAPPING_ERROR_LOG';
export const DELETE_MAPPING_ERROR_LOG = 'DELETE_MAPPING_ERROR_LOG';
export const GET_SERVICE_LOG_DETAIL_SUCCESS = 'GET_SERVICE_LOG_DETAIL_SUCCESS';
export const GET_SERVICE_LOG_ID_SUCCESS = 'GET_SERVICE_LOG_ID_SUCCESS';
export const CLEAR_STATE_INTEGRATIONS = 'CLEAR_STATE_INTEGRATIONS';

export const GET_PERMISSION_DETAIL_LIST_SUCCESS =
  'GET_PERMISSION_DETAIL_LIST_SUCCESS';
export const GET_PERMISSION_DETAIL_SUCCESS = 'GET_PERMISSION_DETAIL_SUCCESS';

export const GET_DELIVERY_OPTION_SUCCESS = 'GET_DELIVERY_OPTIONS_SUCCESS';
export const SAVE_DELIVERY_OPTION_SUCCESS = 'GET_DELIVERY_OPTION_SUCCESS';

export const GET_USER_FORM_DETAIL = 'GET_USER_FORM_DETAIL';
export const GET_CHANNEL_GROUPS = 'GET_CHANNEL_GROUPS';
export const GET_CATALOG_GROUPS = 'GET_CATALOG_GROUPS';
export const GET_SOCIAL_APP_SUCCESS = 'GET_SOCIAL_APP_SUCCESS';
export const SAVE_SOCIAL_APP_SUCCESS = 'SAVE_SOCIAL_APP_SUCCESS';
export const GET_CHANNELS_BY_PARAMS = 'GET_CHANNELS_BY_PARAMS';
export const GET_CHANNEL_DETAIL_SUCCESS = 'GET_CHANNEL_DETAIL_SUCCESS';

export const GET_ALL_BANK_SUCCESS = 'GET_ALL_BANK_SUCCESS';
export const GET_ALL_CARD_TYPE_SUCCESS = 'GET_ALL_CARD_TYPE_SUCCESS';
export const GET_CARD_DETAIL_SUCCESS = 'GET_CARD_DETAIL_SUCCESS';
export const GET_COMMISSION_DETAIL_SUCCESS = 'GET_COMMISSION_DETAIL_SUCCESS';
export const GET_CARD_BIN_DETAIL_SUCCESS = 'GET_CARD_BIN_DETAIL_SUCCESS';

export const FETCH_NAVIGATION_DATA = 'FETCH_NAVIGATION_DATA';
export const ADD_NAVIGATION_DATA = 'ADD_NAVIGATION_DATA';
export const WRITE_NAVIGATION_DATA = 'WRITE_NAVIGATION_DATA';
export const DELETE_NAVIGATION_DATA = 'DELETE_NAVIGATION_DATA';
export const CHANGE_NAVIGATION_ACTION = 'CHANGE_NAVIGATION_ACTION';
export const FETCH_NAVIGATION_SCHEMA = 'FETCH_NAVIGATION_SCHEMA';
export const MOVE_NAVIGATION_DATA = 'MOVE_NAVIGATION_DATA';
export const UPDATE_NAVIGATION_DATA = 'UPDATE_NAVIGATION_DATA';
export const SAVE_NAVIGATION_SCHEMA = 'SAVE_NAVIGATION_SCHEMA';
export const SAVE_NAVIGATION_NODE = 'SAVE_NAVIGATION_NODE';
export const UPDATE_NAVIGATION_SCHEMA = 'UPDATE_NAVIGATION_SCHEMA';
export const MANAGE_NAVIGATION_SCHEMA_VISIBILITY =
  'MANAGE_NAVIGATION_SCHEMA_VISIBILITY';

export const GET_SCHEMA_TYPE_DETAIL_SUCCESS = 'GET_SCHEMA_TYPE_DETAIL_SUCCESS';
export const GET_CONTENT_TYPES_SUCCESS = 'GET_CONTENT_TYPES_SUCCESS';

export const GET_URL_REDIRECT_SUCCESS = 'GET_URL_REDIRECT_SUCCESS';
export const CLEAR_STATE_URL_REDIRECT = 'CLEAR_STATE_URL_REDIRECT';
export const SAVE_URL_REDIRECT_SUCCESS = 'SAVE_URL_REDIRECT_SUCCESS';

export const GET_URL_DETAIL = 'GET_URL_DETAIL';
export const CLEAR_URL_SETTINGS = 'CLEAR_URL_SETTINGS';

export const GET_LANDING_PAGE_TYPES = 'GET_LANDING_PAGE_TYPES';
export const GET_RETAIL_STORE_DETAIL_SUCCESS =
  'GET_RETAIL_STORE_DETAIL_SUCCESS';
export const GET_RETAIL_STORES_DETAIL_SUCCESS =
  'GET_RETAIL_STORES_DETAIL_SUCCESS';
export const GET_RETAIL_STORES_COUNTRIES_SUCCESS =
  'GET_RETAIL_STORES_COUNTRIES_SUCCESS';
export const GET_RETAIL_STORES_CITIES_SUCCESS =
  'GET_RETAIL_STORES_CITIES_SUCCESS';
export const GET_RETAIL_STORES_TOWNSHIPS_SUCCESS =
  'GET_RETAIL_STORES_TOWNSHIPS_SUCCESS';
export const GET_RETAIL_STORES_DISTRICTS_SUCCESS =
  'GET_RETAIL_STORES_DISTRICTS_SUCCESS';
export const GET_RETAIL_STORES_TYPE_SUCCESS = 'GET_RETAIL_STORES_TYPE_SUCCESS';

export const GET_REMOTE_SCHEDULES_SUCCESS = 'GET_REMOTE_SCHEDULES_SUCCESS';
export const GET_REMOTE_SCHEDULE_RULES_SUCCESS =
  'GET_REMOTE_SCHEDULE_RULES_SUCCESS';

export const GET_URL_DETAILS_SUCCESS = 'GET_URL_DETAILS_SUCCESS';
export const GET_URL_GENERATOR_CONFIG_SUCCESS =
  'GET_URL_GENERATOR_CONFIG_SUCCESS';
export const GET_SEO_SETTINGS_SUCCESS = 'GET_SEO_SETTINGS_SUCCESS';
export const GET_SEO_FORM_SUCCESS = 'GET_SEO_FORM_SUCCESS';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';

export const FETCH_SORTING_OPTION_DETAIL = 'FETCH_SORTING_OPTION_DETAIL';
export const FETCH_SORTING_ALGORITHMS = 'FETCH_SORTING_ALGORITHMS';
export const SAVE_SORTING_OPTIONS = 'SAVE_SORTING_OPTIONS';
export const SAVE_SORTING_ALGORITHMS = 'SAVE_SORTING_ALGORITHMS';
export const SHOW_SORTING_ALGORITHMS = 'SHOW_SORTING_ALGORITHMS';
export const CLEAR_SORTING_ALGORITHM_DETAIL = 'CLEAR_SORTING_ALGORITHM_DETAIL';
export const CLEAR_SORTING_OPTION_DETAIL = 'CLEAR_SORTING_OPTION_DETAIL';
export const GET_STATIC_PAGE_DETAIL_SUCCESS = 'GET_STATIC_PAGE_DETAIL_SUCCESS';

// priority list
export const FETCH_PRIORITY_LIST = 'FETCH_PRIORITY_LIST';
export const FETCH_CATEGORY_TREE = 'FETCH_CATEGORY_TREE';
export const FETCH_COLLECTION_LIST = 'FETCH_COLLECTION_LIST';
export const SET_PRIORITY_SELECTION = 'SET_PRIORITY_SELECTION';
export const FETCH_SORTING_ALGORITHM_LIST = 'FETCH_SORTING_ALGORITHM_LIST';
export const FETCH_SORTING_DETAIL = 'FETCH_SORTING_DETAIL';
export const SET_SORTABLE_PRODUCT_LIST = 'SET_SORTABLE_PRODUCT_LIST';
export const SAVE_SORTABLE_PRODUCT_LIST = 'SAVE_SORTABLE_PRODUCT_LIST';
export const REMOVE_SORTABLE_PRODUCT = 'REMOVE_SORTABLE_PRODUCT';

export const ADD_SORTING_ALGORITHM = 'ADD_SORTING_ALGORITHM';
export const REMOVE_SORTING_ALGORITHM = 'REMOVE_SORTING_ALGORITHM';
export const MOVE_SORTING_ALGORITHM = 'MOVE_SORTING_ALGORITHM';
export const UPDATE_SORTING_ALGORITHMS = 'UPDATE_SORTING_ALGORITHMS';
export const UPDATE_DATATABLE_SELECTIONS = 'UPDATE_DATATABLE_SELECTIONS';
export const ADD_STATIC_SORTING_ITEM = 'ADD_STATIC_SORTING_ITEM';
export const MOVE_STATIC_PRODUCT = 'MOVE_STATIC_PRODUCT';
export const FETCH_STATIC_PRODUCTS = 'FETCH_STATIC_PRODUCTS';
export const REMOVE_STATIC_PRODUCT = 'REMOVE_STATIC_PRODUCT';
export const FETCH_PRODUCT_CHECK_LIST = 'FETCH_PRODUCT_CHECK_LIST';
export const GET_LANDING_PAGE_ATTRIBUTE_SUCCESS =
  'GET_LANDING_PAGE_ATTRIBUTE_SUCCESS';

export const FETCH_ALGORITHM_DETAIL = 'FETCH_ALGORITHM_DETAIL';
export const SAVE_ALGORITHM_DETAIL = 'SAVE_ALGORITHM_DETAIL';
export const FETCH_ATTRIBUTES = 'FETCH_ATTRIBUTES';

export const ADD_DYNAMIC_SORT_ALGORITHM = 'ADD_DYNAMIC_SORT_ALGORITHM';
export const REMOVE_DYNAMIC_SORT_ALGORITHM = 'REMOVE_DYNAMIC_SORT_ALGORITHM';
export const MOVE_DYNAMIC_SORT_ALGORITHM = 'MOVE_DYNAMIC_SORT_ALGORITHM';
export const UPDATE_DYNAMIC_SORT_ALGORITHM = 'UPDATE_DYNAMIC_SORT_ALGORITHM';
export const CHANGE_SORTING_TYPE = 'CHANGE_SORTING_TYPE';
export const GET_OMS_CHANNELS_SUCCESS = 'GET_OMS_CHANNELS_SUCCESS';
export const GET_CATEGORY_NODE_LANDING_PAGES_SUCCESS =
  'GET_CATEGORY_NODE_LANDING_PAGES_SUCCESS';
export const CLEAR_CATEGORY_NODE_LANDING_PAGES =
  'CLEAR_CATEGORY_NODE_LANDING_PAGES';
export const GET_CATEGORY_NODE_SUCCESS = 'GET_CATEGORY_NODE_SUCCESS';

export const CLEAR_ENGINE_DETAIL_STATE = 'CLEAR_ENGINE_DETAIL_STATE';
export const GET_ENGINE_DETAIL = 'GET_ENGINE_DETAIL';
export const SAVE_ENGINE_DETAIL = 'SAVE_ENGINE_DETAIL';

export const REMOVE_SEO_ITEM = 'REMOVE_SEO_ITEM';
export const ADD_TO_ROUTE_STACK = 'ADD_TO_ROUTE_STACK';

export const GET_CONTENT_TYPE = 'GET_CONTENT_TYPE';
export const GET_CATALOG_FIELD = 'GET_CATALOG_FIELD';
export const UPDATE_FIELD_STORE = 'UPDATE_FIELD_STORE';
export const GENERATE_SUB_FIELD = 'GENERATE_SUB_FIELD';
export const SET_MAIN_FEED_FIELD = 'SET_MAIN_FEED_FIELD';
export const REMOVE_SUB_FEED_FIELD = 'REMOVE_SUB_FEED_FIELD';
export const UPDATE_SUB_FEED_FIELD = 'UPDATE_SUB_FEED_FIELD';
export const ADD_CHILD_FEED_FIELD = 'ADD_CHILD_FEED_FIELD';
export const UPDATE_CHILD_FEED_FIELD = 'UPDATE_CHILD_FEED_FIELD';
export const REMOVE_CHILD_FEED_FIELD = 'REMOVE_CHILD_FEED_FIELD';
export const RESET_CHILD_FEED_FIELD = 'RESET_CHILD_FEED_FIELD';
export const SAVE_PRODUCT_SPEC_SET = 'SAVE_PRODUCT_SPEC_SET';
export const INITIALIZE_FEED_DATA = 'INITIALIZE_FEED_DATA';

export const DATA_SOURCE_PREPARED_SUCCESS = 'DATA_SOURCE_PREPARED_SUCCESS';
export const PATCH_CUSTOMER_DETAIL_SUCCESS = 'PATCH_CUSTOMER_DETAIL_SUCCESS';

export const CLEAR_CARGO_DELIVERY_MANAGENET_STATE =
  'CLEAR_CARGO_DELIVERY_MANAGENET_STATE';
export const CLEAR_SORTING_FILTER_SETTINGS_STATE =
  'CLEAR_SORTING_FILTER_SETTINGS_STATE';
export const CLEAR_PAYMENT_MANAGEMENT_STATE = 'CLEAR_PAYMENT_MANAGEMENT_STATE';
export const CLEAR_SALES_CHANNEL_SETTINGS_STATE =
  'CLEAR_SALES_CHANNEL_SETTINGS_STATE';
export const CHANGE_TOGGLE_RIGHT_BAR = 'CHANGE_TOGGLE_RIGHT_BAR';

export const GET_OMS_TOTAL_PACKAGE_SUCCESS = 'GET_OMS_TOTAL_PACKAGE_SUCCESS';
export const GET_OMS_TOTAL_TRANSFER_SUCCESS = 'GET_OMS_TOTAL_TRANSFER_SUCCESS';
export const GET_OMS_TOTAL_COUNT_SUCCESS = 'GET_OMS_TOTAL_COUNT_SUCCESS';
export const GET_OMS_WAITING_PACKAGE_SUCCESS =
  'GET_OMS_WAITING_PACKAGE_SUCCESS';
export const GET_OMS_SHIPPED_PACKAGE_SUCCESS =
  'GET_OMS_SHIPPED_PACKAGE_SUCCESS';

export const GET_MAPPING_ATTRIBUTES = 'GET_MAPPING_ATTRIBUTES';
export const REMOVE_MAPPING = 'REMOVE_MAPPING';
export const GET_PRE_ATTRIBUTES = 'GET_MAPPING_ATTRIBUTES';
export const SAVE_MAPPING_ITEM = 'SAVE_MAPPING_ITEM';
export const UPDATE_MAPPING_ITEM = 'UPDATE_MAPPING_ITEM';

export const IS_IN_OMS = 'IS_IN_OMS';

export const GET_CANCELLATION_REQUESTS_BY_ID_SUCCESS =
  'GET_CANCELLATION_REQUESTS_BY_ID_SUCCESS';

export const GET_UNIFIED_SOURCES = 'GET_UNIFIED_SOURCES';
export const GET_UNIFIED_SOURCE_DETAIL = 'GET_UNIFIED_SOURCE_DETAIL';
export const GET_UNIFIED_RECONCILIATION_DETAIL =
  'GET_UNIFIED_RECONCILIATION_DETAIL';
export const RESET_UNIFIED_SOURCE_FORM = 'RESET_UNIFIED_SOURCE_FORM';
export const SAVE_UNIFIED_SOURCE = 'SAVE_UNIFIED_SOURCE';
export const GET_RECONCILIATIONS = 'GET_RECONCILIATIONS';

export const GET_GOOGLE_MERCHANT_ATTRIBUTE = 'GET_GOOGLE_MERCHANT_ATTRIBUTE';
export const GET_GOOGLE_MERCHANT_FEED_ADAPTER =
  'GET_GOOGLE_MERCHANT_FEED_ADAPTER';

export const GET_PRODUCT_VIDEO = 'GET_PRODUCT_VIDEO';

export const GET_LANDING_PAGES_SUCCESS = 'GET_LANDING_PAGES_SUCCESS';
export const CLEAR_LANDING_PAGES = 'CLEAR_LANDING_PAGES';

export const CHANGE_READ_RECEIPT_RIGHT_BAR = 'CHANGE_READ_RECEIPT_RIGHT_BAR';

export const GET_PRODUCT_IMAGE = 'GET_PRODUCT_IMAGE';

export const GET_CACHED_ATTRIBUTE = 'GET_CACHED_ATTRIBUTE';

export const GET_WEBHOOK_SUCCESS = 'GET_WEBHOOK_SUCCESS';
export const SAVE_WEBHOOK_SUCCESS = 'SAVE_WEBHOOK_SUCCESS';
export const CLEAR_WEBHOOK_STATE = 'CLEAR_WEBHOOK_STATE';
export const GET_PACKAGE_WEBHOOK_SUCCESS = 'GET_PACKAGE_WEBHOOK_SUCCESS';
export const CLEAR_PACKAGE_WEBHOOK_STATE = 'CLEAR_PACKAGE_WEBHOOK_STATE';
export const CLEAR_TRANSFER_WEBHOOK_STATE = 'CLEAR_TRANSFER_WEBHOOK_STATE';
export const GET_TRANSFER_WEBHOOK_SUCCESS = 'GET_TRANSFER_WEBHOOK_SUCCESS';

export const GET_BULK_APPLY_CACHE_KEY = 'GET_BULK_APPLY_CACHE_KEY';
export const GET_BULK_APPLY_PROGRESS = 'GET_BULK_APPLY_PROGRESS';

export const GET_CATEGORY_NODE_URL_CONFIGS = 'GET_CATEGORY_NODE_URL_CONFIGS';

export const GET_PRE_PRODUCT_ATTRIBUTE = 'GET_PRE_PRODUCT_ATTRIBUTE';
export const GET_CUSTOMER_ORDER_INFO = 'GET_CUSTOMER_ORDER_INFO';

export const GET_OPTIMIZATION_PRICE_LIST_REQUEST =
  'GET_OPTIMIZATION_PRICE_LIST_REQUEST';
export const GET_OPTIMIZATION_PRICE_LIST_SUCCESS =
  'GET_OPTIMIZATION_PRICE_LIST_SUCCESS';
export const GET_OPTIMIZATION_PRICE_LIST_FAILURE =
  'GET_OPTIMIZATION_PRICE_LIST_FAILURE';

export const GET_OPTIMIZATION_PRODUCT_PRICE_REQUEST =
  'GET_OPTIMIZATION_PRODUCT_PRICE_REQUEST';
export const GET_OPTIMIZATION_PRODUCT_PRICE_SUCCESS =
  'GET_OPTIMIZATION_PRODUCT_PRICE_SUCCESS';
export const GET_OPTIMIZATION_PRODUCT_PRICE_FAILURE =
  'GET_OPTIMIZATION_PRODUCT_PRICE_FAILURE';

export const GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_REQUEST =
  'GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_REQUEST';
export const GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_SUCCESS =
  'GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_SUCCESS';
export const GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_FAILURE =
  'GET_OPTIMIZATION_SCHEDULED_PRODUCT_PRICE_FAILURE';

export const GET_STOCK_LIST_DETAIL_SUCCESS = 'GET_STOCK_LIST_DETAIL_SUCCESS';
export const GET_STOCK_LIST_RULES_SUCCESS = 'GET_STOCK_LIST_RULES_SUCCESS';
export const GET_STOCK_PROVIDERS_SUCCESS = 'GET_STOCK_PROVIDERS_SUCCESS';
export const GET_STOCK_LOCATION_DETAIL_SUCCESS =
  'GET_STOCK_LOCATION_DETAIL_SUCCESS';

export const GET_STOCK_DETAIL_SUCCESS = 'GET_STOCK_DETAIL_SUCCESS';
export const CLEAR_STOCK_DETAIL = 'CLEAR_STOCK_DETAIL';
