import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';

import appActions from '@resources/actions/app';
import Sidebar from '@components/Sidebar/Sidebar';
import RightbarWarningMessage from '@components/RightbarWarningMessage';
import Topbar from '@components/Topbar';
import {
  extractChannelPkFromPathname,
  extractLanguageFromPathname,
} from '@utils/';

import { siteConfig } from '@common/theme';
import { createAuthorizedPage } from '@utils';
import { navigator } from '@common/navigation';
import actions from '@resources/actions/language';
import config from '@components/LanguageSwitcher/config';
import { readStorageItem } from '@common/storage';
import { setAPILanguage } from '@common';
import { platformLanguage } from '@constants/';

import { AppLocale } from '../../App';
import AppHolder from './appHolder';
import Dashboard from '../Dashboard';
import BreadCrumb from '@components/BreadCrumb';
import Settings from '../Settings';

import StockManagement from '../StockManagement';

import ChannelPkContext from './channelPkContext';

import './global.css';

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
const { changeLanguage } = actions;

const AuthorizedDashboard = createAuthorizedPage(Dashboard, [
  'dashboard',
  'admin-v2-dashboard',
]);

const AuthorizedSettings = createAuthorizedPage(Settings, [
  'settings',
  'admin-v2-settings',
]);

export class App extends Component {
  componentDidMount() {
    const platformLanguage = this.getInitialPlatformLanguage();
    changeLanguage(platformLanguage);
    navigator.platformLanguage = platformLanguage;
    const channelLanguage = this.getInitialChannelLanguage();
    navigator.channelLanguage = channelLanguage;
  }

  getInitialChannelLanguage = () => {
    const { defaultAPILanguage } = this.props;
    const { lang, pathWithoutLanguage } = extractLanguageFromPathname(
      this.props.location.pathname
    );
    const { channel } = lang;

    if (channel && channel !== 'null') {
      setAPILanguage(channel);
      return channel;
    }

    return defaultAPILanguage;
  };

  getInitialPlatformLanguage = () => {
    const { lang, pathWithoutLanguage } = extractLanguageFromPathname(
      this.props.location.pathname
    );
    const { platform } = lang;

    if (
      platform &&
      config.options.some((language) => language.locale === platform)
    ) {
      return platform;
    }

    const storagePlatformLang = readStorageItem(platformLanguage);
    if (storagePlatformLang && storagePlatformLang.locale) {
      return storagePlatformLang.locale;
    }

    return config.options[0].locale;
  };

  render() {
    const { pathWithoutLanguage, lang } = extractLanguageFromPathname(
      this.props.location.pathname
    );
    const { pathWithoutChannel, channelPk } =
      extractChannelPkFromPathname(pathWithoutLanguage);
    const { url } = this.props.match;
    const { locale, selectedTheme, height } = this.props;
    const currentAppLocale = AppLocale[navigator.lastPlatformLang || 'tr'];
    const appHeight = window.innerHeight;

    return (
      <ChannelPkContext.Provider value={channelPk}>
        <ConfigProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: '55px 0 0',
                        flexShrink: '0',
                        background: '#293245',
                        position: 'relative',
                      }}
                    >
                      <BreadCrumb />
                      {/* {channelPk && <>
                        <Switch location={{ ...this.props.location, pathname: pathWithoutChannel }}>
                          <Route path="/content-management" component={AuthorizedContentManagement} />
                          <Route exac path="/shop-users/user-detail" component={AuthorizedShopUserDetail} />
                          <Route path="/shop-users" component={AuthorizedShopUsers} />
                          <Route path="/products" component={AuthorizedProducts} />
                          <Route path="/category-management" component={AuthorizedCategoryManagement} />
                          <Route path="/marketing" component={AuthorizedOnlineMarketing} />
                          <Route path="/dashboard" component={AuthorizedDashboard} />
                          <Route path="/payment-management" component={PaymentManagement} />
                          <Route path="/sales-channel-settings" component={AuthorizedSalesChannelSettings} />
                          <Redirect exact from='/' to={{pathname:`/${lang.platform}/${lang.channel}/channel/${channelPk}/dashboard`}} />
                        </Switch>
                        </>} */}

                      <Switch
                        location={{
                          ...this.props.location,
                          pathname: this.props.location.pathname,
                        }}
                      >
                        {this.props.location.pathname === '/'
                          ? navigator.push('/stock/stock-management', false)
                          : ''}

                        {!this.props.auth.isLoggedIn && (
                          <Redirect to="/stock/signin" />
                        )}

                        <Route
                          path="/stock/stock-management"
                          component={StockManagement}
                        />

                        {/* <Route path = "/active-user" component={Profile} />
                          <Route path = "/language-settings" component={LanguageSettings} />
                          <Route path="/products-and-categories" component={AuthorizedProductAndCategories} />
                          <Route path="/oms" component={AuthorizedOms} />
                          <Route path="/order" component={AuthorizedOrders} />
                          <Route path="/customers/customer-detail/:id" component={AuthorizedCustomerDetail} />
                          <Route exact path="/customers/:id?" component={AuthorizedCustomer} />
                          <Route path="/download" component={Download} />
                          <Route path ="/integrations" component = {AuthorizedIntegration}/>
                          <Route path="/settings" component={AuthorizedSettings} />
                          <Route path="/payment-management" component={AuthorizedPaymentManagement} />
                          <Route path="/unified" component={AuthorizedUnified} /> */}
                      </Switch>
                    </Content>
                    <Footer
                      style={{
                        background: '#293245',
                        textAlign: 'center',
                        color: '#fff',
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                  <RightbarWarningMessage />
                </Layout>
              </Layout>
            </AppHolder>
          </IntlProvider>
        </ConfigProvider>
      </ChannelPkContext.Provider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.authReducer,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    height: state.appReducer.height,
    defaultAPILanguage: state.appReducer.defaultAPILanguage,
  }),
  { toggleAll, changeLanguage }
)(App);
