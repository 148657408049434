import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import options from './options';
import appActions, {
  getAllChannels,
  getContextChannel,
} from '@resources/actions/app';
import { navigator } from '@common/navigation';
import { readStorageItem, writeStorageItem } from '@common/storage';
import { store } from '@resources/store';
import { checkAuth } from '@common';
import {
  extractChannelPkFromPathname,
  extractLanguageFromPathname,
} from '@utils/';
import ChannelPkContext from '@containers/MasterPage/channelPkContext';
import { contextChannel } from '@constants/';

import ChannelLink from '@components/ChannelLink';
import Scrollbars from '../utility/customScrollBar.js';
import Menu from '../UIElements/menu';
import IntlMessages from '../utility/intlMessages';
import Logo from '../utility/logo';
import Span from '../Span';
import Anchor from '../Anchor';
import Image from '../Image';
import Intrinsic from '../Intrinsic';
import Block from '../Block';
import SidebarWrapper from './SidebarWrapper';

import selectedChannelImage from '@assets/images/menu-icons/selected-channel.png';

import './style.scss';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

const getInitialChannel = (list, pathname) => {
  const { pathWithoutLanguage } = extractLanguageFromPathname(pathname);
  const { channelPk } = extractChannelPkFromPathname(pathWithoutLanguage);
  if (channelPk && list.some((channel) => channel.pk === channelPk)) {
    return channelPk;
  }
  const storageChannel = readStorageItem('contextChannel');
  if (storageChannel) {
    return storageChannel;
  }

  const [firstWebChannel] = list
    .filter(({ channel_type }) => channel_type === 'web')
    .sort((a, b) => a.pk - b.pk);

  return firstWebChannel ? firstWebChannel.pk : list[0].pk;
};

class Sidebar extends Component {
  static contextType = ChannelPkContext;

  state = {
    channels: [],
    contextChannel: {},
    channelsVisible: true,
  };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentDidMount() {
    // this.props.getAllChannels().then(channels => {
    //   const list = channels.results;
    //   navigator.language = "en";
    //   navigator.channelPk = getInitialChannel(list, this.props.location.pathname);
    //   this.setState({
    //     channels: list,
    //     contextChannel: list[0],
    //     channelsVisible: list.length !== 1
    //   }, ()=>{
    //     store.dispatch(getContextChannel());
    //     const channelPkFromStorage = readStorageItem(contextChannel);
    //     const currentChannel = list.find(item => item.pk == channelPkFromStorage);
    //     this.setContextChannel(channelPkFromStorage ?  currentChannel : list[0]);
    //   });
    // });
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  componentDidUpdate(prevProps) {
    const { pathname: currentPath } = this.props.location;
    if (prevProps.location.pathname !== currentPath) {
      this.props.changeCurrent(currentPath);
    }
  }

  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
      this.getNavigationProp(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }

  getNavigationProp = (key) => {
    const { app } = this.props;
    const pathname = options(checkAuth).find((option) => option.key === key)
      .navigateTo;
    if (!pathname || app.collapsed) return;
    navigator.push(pathname);
  };

  getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };
  setContextChannel = (channel) => {
    if (channel) {
      navigator.channelPk = channel.pk;
      this.setState(
        {
          contextChannel: channel,
          channelsVisible: false,
        },
        () => {
          writeStorageItem('channelType', channel.channel_type);
          store.dispatch(getContextChannel());
        }
      );
    }
  };
  onChangeContextChannel = (channel) => {
    if (channel) {
      this.setState(
        {
          contextChannel: channel,
          channelsVisible: true,
        },
        () => {
          writeStorageItem('contextChannel', channel.pk);
          writeStorageItem('channelType', channel.channel_type);
          store.dispatch(getContextChannel());
        }
      );
    }
  };

  getMenuItem = ({ singleOption, submenuStyle, selectedChannelStyle }) => {
    const channelPk = Number(this.context) || readStorageItem(contextChannel);
    const { key, label, leftIcon, children, leftImage } = singleOption;
    let url = stripTrailingSlash(this.props.url);
    const { channelsVisible } = this.state;
    const platformLang = navigator.lastPlatformLang;
    const channelLang = navigator.lastChannelLang;
    if (key === 'dashboard') url = `/channel/${channelPk}`;
    if (key === 'sales-channels') {
      const { channels } = this.props;
      const channel = channels.find((item) => item.pk === channelPk);
      if (channels.length > 0) {
        return channelsVisible ? (
          <SubMenu
            key={key}
            title={
              <Span className="isoMenuHolder">
                {leftIcon && <Intrinsic className={leftIcon} />}
                {leftImage && <Image src={leftImage} />}
                <Span className="nav-text">
                  <IntlMessages id={label} />
                  <Intrinsic className="chevron-icon icon-chevron_down" />
                  <Intrinsic className="chevron-icon icon-chevron_up" />
                </Span>
              </Span>
            }
          >
            {channels.map((child, index) => {
              return (
                <Menu.Item
                  style={submenuStyle}
                  key={`channel-${child.name}-${index}`}
                  onClick={() => this.setContextChannel(child)}
                >
                  <Span className="isoChildHolder">
                    <Intrinsic className="ion-record" />
                    <Anchor>
                      <Span className="nav-text">{child.name}</Span>
                    </Anchor>
                  </Span>
                </Menu.Item>
              );
            })}
          </SubMenu>
        ) : (
          <SubMenu
            key={key}
            title={
              <Span className="isoMenuHolder">
                {leftIcon && <Intrinsic className={leftIcon} />}
                {leftImage && <Image src={leftImage} />}
                <Span className="nav-text">
                  <IntlMessages id={label} />
                  <Intrinsic className="chevron-icon icon-chevron_down" />
                  <Intrinsic className="chevron-icon icon-chevron_up" />
                </Span>
              </Span>
            }
          >
            <Menu.Item
              className="channel-menu"
              style={selectedChannelStyle}
              key="channel-context"
            >
              <Anchor className="channel">
                <Block className="channel-name">
                  <Image src={selectedChannelImage} />
                  {channel && channel.name}
                </Block>
              </Anchor>
              <Anchor
                className="change-channel--link"
                onClick={() => this.onChangeContextChannel(channelPk)}
              >
                <IntlMessages id="label.change" />
              </Anchor>
            </Menu.Item>
            {children.map((child) => {
              const linkTo = child.withoutDashboard
                ? child.key
                : `${url}${child.key}`;
              return (
                <Menu.Item style={submenuStyle} key={child.key}>
                  <ChannelLink to={linkTo}>
                    <Span className="isoChildHolder">
                      {child.leftIcon && (
                        <Intrinsic className={child.leftIcon} />
                      )}
                      {child.leftImage && <Image src={child.leftImage} />}
                      <Span className="nav-text">
                        <IntlMessages id={child.label} />
                      </Span>
                    </Span>
                  </ChannelLink>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      }
    }
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <Span className="isoMenuHolder">
              {leftIcon && <Intrinsic className={leftIcon} />}
              {leftImage && <Image src={leftImage} />}
              <Span className="nav-text">
                <IntlMessages id={label} />
                <Intrinsic className="chevron-icon icon-chevron_down" />
                <Intrinsic className="chevron-icon icon-chevron_up" />
              </Span>
            </Span>
          }
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard
              ? child.key
              : `/${platformLang}/${channelLang}${url}${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link to={linkTo}>
                  <Span className="isoChildHolder">
                    {child.leftIcon && <Intrinsic className={child.leftIcon} />}
                    <Span className="nav-text">
                      <IntlMessages id={child.label} />
                    </Span>
                  </Span>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <Span className="isoMenuHolder">
            {leftIcon && <Intrinsic className={leftIcon} />}
            {leftImage && <Image src={leftImage} />}
            <Span className="nav-text">
              <IntlMessages id={label} />
            </Span>
          </Span>
        </Link>
      </Menu.Item>
    );
  };
  onNavigate(url) {
    navigator.push(url);
  }
  render() {
    const { app, customizedTheme, height } = this.props;
    const collapsed = !!app.collapsed && !app.openDrawer;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      color: '#ffffff',
    };
    const selectedChannelStyle = {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    };

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={250}
          className="isomorphicSidebar"
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars
            style={{ height: height - 55, borderRight: '1px solid #3b404c' }}
          >
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options(checkAuth).map((singleOption) =>
                this.getMenuItem({
                  submenuStyle,
                  singleOption,
                  selectedChannelStyle,
                })
              )}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      app: state.appReducer,
      customizedTheme: state.ThemeSwitcher.sidebarTheme,
      height: state.appReducer.height,
      channels: state.appReducer.channels,
      authorizationData: state.authReducer.authorizationData,
    }),
    {
      toggleOpenDrawer,
      changeOpenKeys,
      changeCurrent,
      toggleCollapsed,
      getAllChannels,
    }
  ),
  withRouter
)(Sidebar);
