import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import RemoteSelect from '../RemoteSelect';
import DatePicker, { DateRangepicker } from '../UIElements/DatePicker';
import Select, { SelectOption as Option } from '../UIElements/Select';
import Radio, { RadioGroup } from '../UIElements/Radiobox';
import Checkbox from '../UIElements/Checkbox';
import Switch from '../UIElements/Switch';
import Input, { Textarea } from '../UIElements/Input';
import AutoComplete from '../AutoComplete';
import MultipleInput from '../MultipleInput';
import InputWithSelect from '../InputWithSelect';
import InputWithInputSelect from '../MultipleInputWithSelect';
import DateRangePicker from '../DateRangePicker';
import { DraggerFileInput } from '@components/FileInput';

import { isDefinedAndNotEmpty } from '@utils';
import { defaultDateFormat } from '@constants';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
  },
};

export const formItemLayoutBlock = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
    md: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
    md: { span: 10 },
  },
};

export const boxFormItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
    md: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
    md: { span: 19 },
  },
};

export const colBlockType = {
  span: 24,
};

export const formItemLayoutVertical = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
  },
};
export const colVerticalType = {
  span: 24,
};

export const colHorizontalType = {
  xs: { span: 24 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

export const formLayout = {
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  lg: {
    span: 24,
    offset: 0,
  },
};
export const gridFormItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
    md: { span: 22 },
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 },
    md: { span: 15 },
  },
};

export const filterLayout = {
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  lg: {
    span: 24,
    offset: 0,
  },
};

export const inputSpace = {
  xs: 0,
  sm: 8,
  md: 8,
};

export const inputTypes = {
  input: {
    creator: function (fieldProps = {}, { onChange, ...wrapperProps }) {
      return (
        <Input
          onBlur={onChange}
          {...fieldProps}
          type="text"
          {...wrapperProps}
        />
      );
    },
    alias: 'text',
    defaultType: true,
    canWorkWithKeyPress: true,
    valuePropName: 'value',
  },
  password: {
    creator: function (fieldProps = {}, { onChange, ...wrapperProps }) {
      return (
        <Input
          onBlur={onChange}
          {...fieldProps}
          {...wrapperProps}
          type="password"
        />
      );
    },
    alias: 'password',
    canWorkWithKeyPress: true,
    valuePropName: 'value',
  },
  file: {
    creator: function (fieldProps = {}, { onChange, ...wrapperProps }) {
      const fileBeforeUpload = () => {
        onChange();
        return false;
      };
      return (
        <>
          <DraggerFileInput
            beforeUpload={fileBeforeUpload}
            {...fieldProps}
            {...wrapperProps}
          />
        </>
      );
    },
    alias: 'file',
    canWorkWithKeyPress: true,
    valuePropName: 'value',
  },
  editor: {
    creator: function (fieldProps = {}, { onChange, ...wrapperProps }) {
      return (
        <>
          <Editor
            {...wrapperProps}
            {...fieldProps}
            editorState={fieldProps.editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            toolbarClassName="demo-toolbar"
            onEditorStateChange={fieldProps.onEditorStateChange()}
          />
        </>
      );
    },
    alias: 'editor',
    canWorkWithKeyPress: true,
    valuePropName: 'value',
  },
  textarea: {
    creator: function (fieldProps = {}, { onChange, ...wrapperProps }) {
      return <Textarea onBlur={onChange} {...fieldProps} {...wrapperProps} />;
    },
    alias: 'area',
    canWorkWithKeyPress: true,
    valuePropName: 'value',
  },
  datepicker: {
    creator: function (fieldProps = {}, wrapperProps) {
      const { dateFormat = defaultDateFormat } = fieldProps;
      return (
        <DatePicker {...fieldProps} {...wrapperProps} format={dateFormat} />
      );
    },
    alias: 'date',
    valuePropName: 'value',
  },
  dateRangePicker: {
    creator: function (fieldProps = {}, wrapperProps) {
      const { dateFormat = defaultDateFormat } = fieldProps;
      return <DateRangepicker {...fieldProps} {...wrapperProps} />;
    },
    alias: 'daterange',
    valuePropName: 'value',
  },
  select: {
    creator: function (
      { options = [], initialValue, ...props } = {},
      wrapperProps
    ) {
      const optionSelectors = options.map((item, index) => (
        <Option key={index} value={item.value}>
          {isDefinedAndNotEmpty(item.label) ? item.label : item.value}
        </Option>
      ));
      return (
        <Select showArrow {...props} {...wrapperProps}>
          {optionSelectors}
        </Select>
      );
    },
    alias: 'dropdown',
    canMultiple: true,
    canKeyValueResult: true,
    valuePropName: 'value',
  },
  remoteselect: {
    creator: function (
      {
        options = [],
        URLKey,
        valueKeyName,
        labelKeyName,
        objectKey,
        dataSource,
        initialValue,
        url,
        ...props
      } = {},
      wrapperProps
    ) {
      return (
        <RemoteSelect
          url={url}
          valueKeyName={valueKeyName}
          labelKeyName={labelKeyName}
          dataSource={dataSource}
          objectKey={objectKey}
          showArrow
          {...props}
          {...wrapperProps}
        />
      );
    },
    alias: 'remoteselect',
    remote: true,
    canMultiple: true,
    canKeyValueResult: true,
    valuePropName: 'value',
  },
  autocomplete: {
    creator: function (
      {
        options = [],
        URLKey,
        valueKeyName,
        labelKeyName,
        objectKey,
        initialValue,
        url,
        ...props
      } = {},
      wrapperProps
    ) {
      return (
        <AutoComplete
          valueKeyName={valueKeyName}
          labelKeyName={labelKeyName}
          objectKey={objectKey}
          url={url}
          {...props}
          {...wrapperProps}
        />
      );
    },
    alias: 'autocomplete',
    remote: true,
    canMultiple: true,
    valuePropName: 'value',
  },
  radio: {
    creator: function (
      { options = [], initialValue, ...props } = {},
      wrapperProps
    ) {
      const optionSelectors = options.map((item, index) => (
        <Radio key={index} value={item.value}>
          {item.label}
        </Radio>
      ));
      return (
        <RadioGroup {...wrapperProps} {...props}>
          {optionSelectors}
        </RadioGroup>
      );
    },
    alias: 'radio',
    valuePropName: 'value',
  },
  checkbox: {
    creator: function (fieldProps = {}, wrapperProps) {
      return (
        <Checkbox {...fieldProps} {...wrapperProps}>
          {(fieldProps.noLabelCheck || fieldProps.noLabel) && fieldProps.label}
        </Checkbox>
      );
    },
    noLabel: true,
    alias: 'bool',
    valuePropName: 'checked',
  },
  switch: {
    creator: function (fieldProps = {}, wrapperProps) {
      return <Switch {...fieldProps} {...wrapperProps} />;
    },
    noLabel: true,
    alias: 'switch',
    valuePropName: 'checked',
  },
  multipleInputs: {
    creator: function ({ ...props } = {}, wrapperProps) {
      return <MultipleInput {...props} {...wrapperProps} />;
    },
    alias: 'multipleInputs',
    valuePropName: 'value',
    needKeys: true,
    needInputProps: true,
  },
  inputWithSelect: {
    creator: function ({ ...props } = {}, wrapperProps) {
      return <InputWithSelect {...props} {...wrapperProps} />;
    },
    alias: 'inputWithSelect',
    canMultiple: true,
    canKeyValueResult: true,
    valuePropName: 'value',
    needKeys: true,
    needInputProps: true,
  },
  inputWithInputSelect: {
    creator: function ({ ...props } = {}, wrapperProps) {
      return <InputWithInputSelect {...props} {...wrapperProps} />;
    },
    alias: 'inputWithInputSelect',
    canMultiple: true,
    canKeyValueResult: true,
    valuePropName: 'value',
    needKeys: true,
    needInputProps: true,
  },
};
